import styled from "@emotion/styled";
import { ReactNode } from "react";

import { LinearPerpetual } from "types/exchange.types";


interface ExchangeCoinListModalListProps {
    currencies: LinearPerpetual[]
    renderItem: (value: LinearPerpetual, index: number) => ReactNode
}

export const ExchangeCoinListModalList = ({ currencies, renderItem }: ExchangeCoinListModalListProps) => {
    return (
        <StyledExchangeCoinListModalList.Wrapper>
            {currencies.map(renderItem)}
        </StyledExchangeCoinListModalList.Wrapper>
    )
}

const StyledExchangeCoinListModalList ={
    Wrapper: styled.div`
        overflow-y: auto;
        margin-top: 31px;
    `
}