import { createSlice } from "@reduxjs/toolkit";
import { walletsState } from "./wallets.state";
import { getWalletThunk, postTransferThunk } from "./wallets.thunk";
// import { ordersGetThunk, currenciesGetThunk } from "./exchange.thunk";

export const walletsSlice = createSlice({
  name: "wallets",
  initialState: walletsState,
  reducers: {
    getWallets: (state, action) => {
      state.wallets = action.payload;
    },
    getBalance: (state, action) => {
      state.balance = {
        ...state.balance,
        ...action.payload,
      };
    },
    setWalletBalance: (state, action) => {
      state.walletBalance = action.payload;
    },
    setByBitWalletBalance: (state, action) => {
      state.byBitWalletBalance = action.payload;
    },
    getRates: (state, action) => {
      state.rates = action.payload;
    },
    getVolume: (state, action) => {
      state.volume = action.payload;
    },
    setSelectedWithdrawWallet: (state, action) => {
      state.selectedWithdrawWallet = action.payload;
    },
    setSelectedDepositWallet: (state, action) => {
      state.selectedDepositWallet = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(postTransferThunk.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postTransferThunk.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(postTransferThunk.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getWalletThunk.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getWalletThunk.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getWalletThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload && action.payload[0]) {
        state.selectedWallet = action.payload[0];
      }
      return state;
    });

    //Orders
    // builder.addCase(ordersGetThunk.pending, (state) => {
    //   state.isExchangeLoading = true;
    // });
    // builder.addCase(ordersGetThunk.rejected, (state) => {
    //   state.isExchangeLoading = false;
    // });
    // builder.addCase(ordersGetThunk.fulfilled, (state, action) => {
    //   state.orders = action.payload;
    //   state.isExchangeLoading = false;
    // });
    // //Currencies
    // builder.addCase(currenciesGetThunk.pending, (state) => {
    //   state.isExchangeLoading = true;
    // });
    // builder.addCase(currenciesGetThunk.rejected, (state) => {
    //   state.isExchangeLoading = false;
    // });
    // builder.addCase(currenciesGetThunk.fulfilled, (state, action) => {
    //   state.currencies = action.payload;
    //   state.isExchangeLoading = false;
    // });
  },
});

// Action creators are generated for each case reducer function
export const {
  getWallets,
  getBalance,
  getRates,
  getVolume,
  setWalletBalance,
  setSelectedWithdrawWallet,
  setSelectedDepositWallet,
  setByBitWalletBalance,
} = walletsSlice.actions;

export const walletsReducer = walletsSlice.reducer;
