import { CustomHeader } from "components";

import { CSSProperties, PropsWithChildren } from "react";
import { additionalMenu } from "utils/rounting";
import MenuItem from "./components/MenuItem";
import "./additionalLayoutStyles.scss";
import {NavLink} from "react-router-dom";

export const AdditionalLayout = (
  props: PropsWithChildren<{
    className?: string;
    hideMenu?: boolean;
    style?: CSSProperties;
  }>
): JSX.Element => {
  const { style, hideMenu } = props;

  return (
    <div className={"additionalLayout"}>
      <CustomHeader />
      <div className={!hideMenu ? "additionalLayout__wrapper" : ""}>
        {!hideMenu ? (
            <div className="additionalLayout__menu">
                <div className="additionalLayout__menu_nav">
                    {additionalMenu.map((item) => {
                        return (
                            <MenuItem
                                key={item.link}
                                isActive={
                                    document.location.href.includes(item.link)
                                }
                                {...item}
                            />
                        );
                    })}
                </div>

                <div className="additionalLayout__menu_nav__mobile">
                    {additionalMenu.map((item) => (
                        <NavLink to={item.link} className={({isActive}) => isActive ? "mobile-item active" : "mobile-item"}>
                            {item.title}
                        </NavLink>
                        )
                    )}
                </div>

                <div className="additionalLayout__menu_contacts">
                    <p className="title">Have a question?</p>
                    <a className="subtitle" href="mailto:support@bitocol.com">
                        support@bitocol.com
                    </a>
                </div>
            </div>
        ) : (
            <div></div>
        )}
          <div style={style} className="additionalLayout__content">
              {props.children}
          </div>
      </div>
    </div>
  );
};
