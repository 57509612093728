import { CSSProperties } from "react";
import { MarketPlacePairItem } from "../../molecules/MarketPlacePairItem/MarketPlacePairItem";
import { useTypedSelector } from "hooks/useTypedSelector";
import {
  addPairChangingCounter,
  setSelectedPair,
  setSelectedPairId,
} from "store/exchange/exchange.slice";
import { LinearPerpetual } from "types/exchange.types";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useDrawerManager } from "hooks/useDrawerManager";
import { withByBitTicker } from "components/ByBitTickerHOC";

export const Row = ({
  index,
  style,
  data,
}: {
  index: number;
  style: CSSProperties;
  data: LinearPerpetual[];
}) => {
  const dispatch = useAppDispatch();
  const { setDrawerId } = useDrawerManager();
  const { currencies } = useTypedSelector((state) => state.exchange);

  const handleClick = (pair: LinearPerpetual) => {
    const filteredCurrencies = currencies?.result.list.filter(
      (currency) => String(currency.symbol) === String(pair.symbol)
    );
    if (filteredCurrencies && filteredCurrencies.length) {
      dispatch(setSelectedPair(filteredCurrencies[0]));
    }

    dispatch(setSelectedPairId(+pair.symbol));
    setDrawerId(null);
    dispatch(addPairChangingCounter());
  };

  return (
    <MarketPlacePairWithTicker
      style={style}
      key={index}
      onClick={handleClick}
      data={data[index]}
    />
  );
};

const MarketPlacePairWithTicker = withByBitTicker(MarketPlacePairItem);
