import { ChangeEvent } from "react"
import { IconButton } from "@mui/material"
import styled from "@emotion/styled"

import { ExchangeFormButton, ExchangeFormField, ExchangeFormFieldItem, ExchangeFormInput } from "./ExchangeFormField"
import { IMarketsList } from 'types/exchange.types'

interface ExchangeFormProps {
    buySellCoin: string
    payCoin: string
    receiveCoin: string
    payAmount: string
    receiveAmount: string
    payUsdtBalance: string
    payError?: string
    onPayAmountChange: (e: ChangeEvent<HTMLInputElement>) => void
    onBuySellClick: () => void
    onReceiveClick?: () => void
    onPayClick?: () => void
    onPayAmountMaxClick: () => void
    currencies?: IMarketsList | undefined
}

export const ExchangeForm = ({
    buySellCoin,
    payCoin,
    receiveCoin,
    payAmount,
    receiveAmount,
    payUsdtBalance,
    payError,
    onPayAmountChange,
    onBuySellClick,
    onPayClick,
    onReceiveClick,
    onPayAmountMaxClick,
    currencies
}: ExchangeFormProps) => {
    console.log(buySellCoin)
    console.log(currencies)
    return (
        <StyledExchangeForm.Wrapper>
            <StyledExchangeForm.SelectReceiveField>
                <ExchangeFormField
                    label={{
                        left: "Select a coin to buy/sell"
                    }}
                >
                    <ExchangeFormButton
                        style={{ padding: "0 20px 0 33px" }}
                        coinName={buySellCoin}
                        hasArrow
                        onClick={onBuySellClick}
                    />
                </ExchangeFormField>
            </StyledExchangeForm.SelectReceiveField>
            <StyledExchangeForm.PayField>
                <ExchangeFormFieldItem
                    error={payError}
                    label={{
                        left: "You pay",
                        right: (
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <span>Balance:</span>
                                <div style={{ display: "flex", alignItems: "center", gap: 2 }}>{payUsdtBalance}</div>
                            </div>
                        ),
                    }}
                    left={
                        <div
                            style={{
                                position: "relative"
                            }}
                        >
                            <ExchangeFormInput
                                placeholder="Enter amount"
                                style={{
                                    paddingRight: "61px"
                                }}
                                value={payAmount}
                                onChange={onPayAmountChange}
                            />
                            <IconButton
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    bottom: "50%",
                                    right: "10px",
                                    transform: "translateY(50%)",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "21px",
                                    color: "#00B7F1",
                                    padding: "5px"
                                }}
                                onClick={onPayAmountMaxClick}
                            >
                                MAX
                            </IconButton>
                        </div>
                    }
                    right={
                        <ExchangeFormButton
                            style={{ padding: "0 30px 0 19px" }}
                            coinGap={10}
                            hasArrow={!!onPayClick}
                            coinName={payCoin}
                            onClick={onPayClick}
                        />
                    }
                />
            </StyledExchangeForm.PayField>
            <StyledExchangeForm.ReceiveField>
                <ExchangeFormFieldItem
                    label={{
                        left: "You receive"
                    }}
                    left={
                        <ExchangeFormInput
                            placeholder="Enter pay amount"
                            disabled
                            value={receiveAmount}
                        />
                    }
                    right={
                        <ExchangeFormButton
                            style={{ padding: "0 30px 0 19px" }}
                            coinGap={10}
                            hasArrow={!!onReceiveClick}
                            coinName={receiveCoin}
                            onClick={onReceiveClick}
                        />
                    }
                />
            </StyledExchangeForm.ReceiveField>
        </StyledExchangeForm.Wrapper>
    )
}

const StyledExchangeForm = {
    Wrapper: styled.div`
        width: 100%;
        margin-top: 37px;
    `,
    SelectReceiveField: styled.div``,
    PayField: styled.div`
        margin-top: 40px;
    `,
    ReceiveField: styled.div`
        margin-top: 30px;
    `
}