import { ChangeEvent, useEffect, useMemo, useState } from "react"
import styled from "@emotion/styled"

import { IByBitWalletBalance, LinearPerpetual } from "types/exchange.types"
import { StyledDialog } from "pages/Wallets/components/CommonComponents.styles"
import { ExchangeCoinListModalHead } from "./ExchangeCoinListModalHead"
import { ExchangeCoinListModalSearch } from "./ExchangeCoinListModalSearch"
import { ExchangeCoinListModalList } from "./ExchangeCoinListModalList"
import { ExchangeCoinListModalListItem } from "./ExchangeCoinListModalListItem"
import { useTypedSelector } from "hooks/useTypedSelector"

interface ExchangeCoinListModalProps {
    isOpen: boolean
    coinPropName?: 'baseCoin' | 'quoteCoin'
    pairCoinName?: string
    currencies: LinearPerpetual[]
    isShowBalance: boolean
    onClose: () => void
    onSelectCoin: (coin: string) => void
}

export const ExchangeCoinListModal = ({ isOpen, coinPropName, pairCoinName, currencies, onClose, onSelectCoin, isShowBalance }: ExchangeCoinListModalProps) => {
    const [search, setSearch] = useState<string>("")
    const byBitWalletBalance = useTypedSelector(state => state.wallets.byBitWalletBalance)

    const resultCurrencies = useMemo(() => {
        console.log(coinPropName, pairCoinName)
        if(!coinPropName || !pairCoinName) return []
        const pairCoinPropName = coinPropName === 'baseCoin' ? 'quoteCoin' : 'baseCoin'

        const filteredCurrencies = currencies.filter(curr =>
            curr[coinPropName].toLowerCase().includes(search.toLowerCase())
        );

        const filteredCurrenciesByPairCoin = filteredCurrencies.filter(curr =>
            curr[pairCoinPropName].toLowerCase().includes(pairCoinName.toLowerCase())
        );

        const uniqueCoins = filteredCurrenciesByPairCoin.reduce((acc, curr) => {
            const coinName = curr[coinPropName];
            if (!acc[coinName] || curr.deliveryTime < acc[coinName].deliveryTime) {
              acc[coinName] = curr;
            }
            return acc;
        }, {} as Record<string, LinearPerpetual>);
        
        return Object.values(uniqueCoins);
    }, [currencies, search, coinPropName])

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const handleSelectCoin = (coin: string) => () => {
        onSelectCoin(coin)
        onClose()
    }

    const handleClose = () => {
        setSearch("")
        onClose()
    }

    useEffect(() => {
        if(!!isOpen) return
        setSearch("")
    }, [isOpen])

    return (
        <StyledExchangeCoinListModal.Wrapper
            open={isOpen}
            onClose={handleClose}
        >
            <ExchangeCoinListModalHead onClose={handleClose} />
            <ExchangeCoinListModalSearch
                value={search}
                onChange={handleSearchChange}
            />
            <ExchangeCoinListModalList
                currencies={resultCurrencies}
                renderItem={(item) => {
                    const coinName = item[coinPropName!]
                    const coinBalance = byBitWalletBalance.data?.list[0].coin?.find(coin => {
                        return coin.coin === item[coinPropName!]
                    })

                    return (
                        <ExchangeCoinListModalListItem
                            coinName={coinName}
                            usdtValue={coinBalance?.usdValue}
                            amountValue={coinBalance?.availableToWithdraw}
                            isShowBalance={isShowBalance}
                            onClick={handleSelectCoin(coinName)}
                        />
                    )
                }}
            />
        </StyledExchangeCoinListModal.Wrapper>
    )
}

const StyledExchangeCoinListModal = {
    Wrapper: styled(StyledDialog)`
        overflow: "hidden"; 
        & .MuiPaper-root {
            width: 406px;
            height: 458px;
            background: #FFFFFF !important;
            border-radius: 10px;
            color: #000000;
            padding-bottom: 38px;
        }
    `,

}