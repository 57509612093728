import { zodResolver } from '@hookform/resolvers/zod'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import {
	Box,
	Button,
	Checkbox,
	Divider,
	InputAdornment,
	MenuItem,
	Modal,
	Popover,
	Select,
	Slider,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material'
import { ByBitFeeRateBody, byBitApi } from 'api'
import { removeTrailingZeros } from 'helpers/removeTrailingZeros'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { useSnackbar } from 'notistack'
import { useByBit } from 'providers/ByBitProvider'
import {
	Dispatch,
	PropsWithChildren,
	ReactNode,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form'
import { getByBitWalletBalanceThunk } from 'store/wallets/wallets.thunk'
import { z } from 'zod'
import { range } from './helper'

const LEVERAGE_TYPES = [
	{
		label: 'Cross',
		value: 0,
	},
	{
		label: 'Isolated',
		value: 1,
	},
]

const leverageSchema = z.object({
	type: z.number(),
	value: z.string(),
})
const profitSchema = z
	.object({
		type: z.string(),
		profit: z.string().or(z.number()).optional(),
		loss: z.string().or(z.number()).optional(),
	})
	.superRefine((data, ctx) => {
		if (data.profit === undefined && data.loss === undefined) {
			ctx.addIssue({
				path: ['profit'],
				message: '"profit" is required',
				code: z.ZodIssueCode.custom,
			})
			ctx.addIssue({
				path: ['loss'],
				message: '"loss" is required',
				code: z.ZodIssueCode.custom,
			})
		}
	})

const resultSchema = z.object({
	type: z.number(),
	price: z.string({ required_error: 'Price is required' }),
	qty: z.string({ required_error: 'Qty is required' }),
	percent: z.number().optional(),
	customSetup: z.boolean(),
})

type LeverageSchema = z.infer<typeof leverageSchema>
type ProfitSchema = z.infer<typeof profitSchema>
type ResultSchema = z.infer<typeof resultSchema>

export function ExchangeTopRight() {
	return (
		<ExchangeTopRightProvider>
			<Box
				display='flex'
				flexDirection='column'
				gap='18px'
				width='100%'
				sx={{ background: '#181B25', padding: '20px 10px' }}
			>
				<LeverageHeader />
				<ExchangeTopRightTabs>
					<ResultForm />
					<ResultForm showOrderPrice={false} />
				</ExchangeTopRightTabs>
			</Box>
			<LeverageModal />
			<TakeProfitStopLossModal />
		</ExchangeTopRightProvider>
	)
}

function LeverageHeader() {
	const { leverageForm, setLeverageModal } = useModel()
	const { enqueueSnackbar } = useSnackbar()

	const handleTypeChange = async () => {
		try {
			await byBitApi.setMarginMode({
				setMarginMode:
					leverageForm.getValues('type') === 0
						? 'ISOLATED_MARGIN'
						: 'REGULAR_MARGIN',
			})
			enqueueSnackbar('Switch has been applied.', {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			})
		} catch (e) {
			enqueueSnackbar('An error occurred while applying the switch.', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			})
		}
	}

	return (
		<Box display='flex' flexDirection='row' gap='12px'>
			<Controller
				control={leverageForm.control}
				name='type'
				render={({ field }) => (
					<Select
						style={{
							width: '100%',
							borderRadius: 4,
							background: '#26282C',
							color: '#00B7F1',
							fontSize: 12,
							fontWeight: 400,
						}}
						sx={{
							boxShadow: 'none',
							'.MuiOutlinedInput-notchedOutline': { border: 0 },
						}}
						size='small'
						{...field}
					>
						{LEVERAGE_TYPES.map(type => (
							<MenuItem
								key={type.value}
								value={type.value}
								onClick={handleTypeChange}
							>
								{type.label}
							</MenuItem>
						))}
					</Select>
				)}
			/>
			<Controller
				control={leverageForm.control}
				name='value'
				render={({ field }) => (
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'
						p='8.5px 14px'
						style={{
							width: '100%',
							borderRadius: 4,
							background: '#26282C',
							color: '#00B7F1',
							fontSize: 12,
							fontWeight: 400,
							cursor: 'pointer',
						}}
						onClick={() => setLeverageModal(true)}
					>
						{field.value}x
						<ArrowDropDownIcon style={{ color: 'white' }} />
					</Box>
				)}
			/>
		</Box>
	)
}

function ExchangeTopRightTabs({ children }: { children: ReactNode[] }) {
	const { resultForm } = useModel()
	const [tab, setTab] = useState<number>(0)

	const handleChange = (_: any, index: number) => {
		setTab(index)
		resultForm.setValue('type', index)
	}

	return (
		<>
			<Tabs
				style={{ display: 'flex', minHeight: 0 }}
				value={tab}
				onChange={handleChange}
			>
				<Tab
					sx={{
						'&.Mui-selected': {
							color: '#00B7F1',
						},
						flex: 1,
						minWidth: 0,
						minHeight: 0,
						padding: 1,
						textTransform: 'none',
					}}
					label='Limit'
				/>
				<Tab
					sx={{
						'&.Mui-selected': {
							color: '#00B7F1',
						},
						flex: 1,
						minWidth: 0,
						minHeight: 0,
						padding: 1,
						textTransform: 'none',
					}}
					label='Market'
				/>
			</Tabs>
			{children[tab]}
		</>
	)
}

function LeverageModal() {
	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
	const { leverageForm, leverageModal, setLeverageModal } = useModel()
	const { enqueueSnackbar } = useSnackbar()
	const [leverage, setLeverage] = useState<string>(
		leverageForm.getValues('value')
	)
	const minLeverage = +(selectedPair?.leverageFilter.minLeverage ?? 1)
	const maxLeverage = +(selectedPair?.leverageFilter.maxLeverage ?? 100)
	const step = minLeverage % 1 !== 0 || maxLeverage % 1 !== 0 ? 0.5 : 1

	const submit = async () => {
		try {
			const value = String(+leverage)
			await byBitApi.setLeverage({
				symbol: `${selectedPair?.baseCoin.trim()}${selectedPair?.quoteCoin.trim()}`,
				category: 'linear',
				buyLeverage: value,
				sellLeverage: value,
			})
			leverageForm.setValue('value', leverage)
			enqueueSnackbar('A new leverage has been applied.', {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			})
		} catch (e) {
			enqueueSnackbar('An error occurred while applying the new leverage.', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			})
		} finally {
			setLeverageModal(false)
		}
	}

	useEffect(() => {
		setLeverage(leverageForm.getValues('value'))
	}, [leverageModal])

	return (
		<Modal open={leverageModal} onClose={() => setLeverageModal(false)}>
			<Box
				sx={{ outline: 'none' }}
				position='absolute'
				top='50%'
				left='50%'
				width='100%'
				maxWidth='420px'
				style={{ transform: 'translate(-50%, -50%)' }}
				bgcolor='#1C1E24'
				boxShadow={24}
				p='24px'
				borderRadius='4px'
			>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography fontSize='16px' fontWeight='600'>
						Adjust Leverage
					</Typography>
					<CloseIcon
						style={{ color: 'white' }}
						onClick={() => setLeverageModal(false)}
					/>
				</Box>
				<Box marginTop='26px'>
					<Typography marginBottom='4.5px'>Leverage</Typography>
					<TextField
						style={{ width: '100%', background: '#26282C' }}
						onChange={event => {
							const value = event.target.value.replace(/[^0-9]+/g, '')
							setLeverage(value)
						}}
						value={leverage}
					/>

					<Slider
						style={{ marginTop: '12px' }}
						size='small'
						step={step}
						min={minLeverage}
						max={maxLeverage}
						marks={range(minLeverage, maxLeverage, step)
							.filter(
								(item, index, arr) =>
									item === minLeverage ||
									(arr.length > 50 ? item % 10 === 0 : item % 5 === 0) ||
									index === arr.length - 1
							)
							.map(item => ({
								label: `${item}`,
								value: item,
							}))}
						valueLabelDisplay='auto'
						value={Math.round(+leverage)}
						onChange={(e: any) =>
							setLeverage(String(Math.round(+e.target.value)))
						}
					/>
				</Box>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					marginTop='40px'
					gap='16px'
				>
					<Button
						sx={{
							width: '100%',
							color: 'white',
							borderRadius: '4px',
							boxShadow: 'none',
						}}
						variant='contained'
						color='primary'
						onClick={submit}
					>
						Confirm
					</Button>
					<Button
						sx={{
							width: '100%',
							background: '#26282C',
							color: 'white',
							borderRadius: '4px',
							boxShadow: 'none',
						}}
						variant='contained'
						onClick={() => setLeverageModal(false)}
					>
						Cancel
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

function TakeProfitStopLossModal() {
	const { profitModal, setProfitModal, resultForm, profitForm } = useModel()
	const { ticker } = useByBit()
	const [tab, setTab] = useState<number>(0)
	const { handleSubmit } = profitForm
	const type = resultForm.watch('type')
	const orderPrice = Number(resultForm.watch('price'))
	const qty = resultForm.watch('qty')
	const { enqueueSnackbar } = useSnackbar()

	const handleConfirm = () => {
		const profit = profitForm.getValues('profit')
		const loss = profitForm.getValues('loss')

		// console.log(tab, +(profit??0) < orderPrice, loss)

		if (tab === 0) {
			if (!!profit && +profit > orderPrice + orderPrice * 1.5) {
				enqueueSnackbar('The take profit cannot be higher than order price', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}

			if (profit && +profit < orderPrice) {
				enqueueSnackbar('The take profit cannot be lower than order price', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}

			if (loss && +loss > orderPrice) {
				enqueueSnackbar('The loss stop cannot be higher than order price', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}

			if (!!loss && +loss < orderPrice - orderPrice * 0.75) {
				enqueueSnackbar('The loss stop cannot be lower than order price', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}
		}
		if (tab === 1) {
			if (!!profit && +profit < 0) {
				enqueueSnackbar('The stop loss cannot be lower than order price', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}

			if (profit && +profit > orderPrice) {
				enqueueSnackbar('The stop loss cannot be higher than order price', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}

			if (!!loss && +loss > orderPrice + orderPrice * 0.75) {
				enqueueSnackbar('The stop loss cannot be higher than order price', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}

			if (loss && +loss < orderPrice) {
				enqueueSnackbar('The stop loss cannot be lower than order price', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}
		}
		resultForm.setValue('customSetup', true)
		setProfitModal(false)
	}

	const handleCancel = () => {
		profitForm.reset()
		resultForm.setValue('customSetup', false)
		setProfitModal(false)
	}

	return (
		<Modal open={profitModal} onClose={handleCancel}>
			<Box
				sx={{ outline: 'none' }}
				position='absolute'
				top='50%'
				left='50%'
				width='100%'
				maxWidth='420px'
				style={{ transform: 'translate(-50%, -50%)' }}
				bgcolor='#1C1E24'
				boxShadow={24}
				p='24px'
				borderRadius='4px'
			>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography fontSize='16px' fontWeight='600'>
						Add TP/SL
					</Typography>
					<CloseIcon
						style={{ color: 'white' }}
						onClick={() => setProfitModal(false)}
					/>
				</Box>
				<Box marginTop='26px'>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Box>
							<Typography>Order</Typography>
							<Typography>
								{' '}
								{
									{
										0: orderPrice,
										1: 'Last Traded Price',
									}[String(type)]
								}
							</Typography>
						</Box>
						<Box>
							<Typography>Qty</Typography>
							<Typography>{qty}</Typography>
						</Box>
						<Box>
							<Typography>Last Traded Price</Typography>
							<Typography>{ticker?.data.lastPrice}</Typography>
						</Box>
					</Box>
				</Box>
				<Box
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
					width='100%'
					borderRadius='4px'
					bgcolor='#26282C'
					marginTop='10px'
				>
					<Box
						sx={{ cursor: 'pointer' }}
						display='flex'
						justifyContent='center'
						alignItems='center'
						width='100%'
						bgcolor={
							profitForm.getValues('type') === 'Long'
								? '#0CAF60'
								: 'transparent'
						}
						borderRadius='inherit'
						py='6px'
						px='24px'
						onClick={() => {
							profitForm.setValue('type', 'Long')
							setTab(0)
							// profitForm.reset()
						}}
					>
						<Typography color='white'>Long</Typography>
					</Box>
					<Box
						sx={{ cursor: 'pointer' }}
						display='flex'
						justifyContent='center'
						alignItems='center'
						width='100%'
						bgcolor={
							profitForm.getValues('type') === 'Short'
								? '#EF454A'
								: 'transparent'
						}
						borderRadius='inherit'
						py='6px'
						px='24px'
						onClick={() => {
							profitForm.setValue('type', 'Short')
							setTab(1)
							// profitForm.reset()
						}}
					>
						<Typography color='white'>Short</Typography>
					</Box>
				</Box>
				{tab === 0 ? (
					<ProfitLongForm />
				) : tab === 1 ? (
					<ProfitShortForm />
				) : null}
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					marginTop='40px'
					gap='16px'
				>
					<Button
						sx={{
							width: '100%',
							color: 'white',
							borderRadius: '4px',
							boxShadow: 'none',
						}}
						variant='contained'
						color='primary'
						onClick={handleSubmit(() => handleConfirm())}
					>
						Confirm
					</Button>
					<Button
						sx={{
							width: '100%',
							background: '#26282C',
							color: 'white',
							borderRadius: '4px',
							boxShadow: 'none',
						}}
						variant='contained'
						onClick={handleCancel}
					>
						Cancel
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

function ProfitLongForm() {
	const { resultForm, profitForm } = useModel()
	const orderPrice = Number(resultForm.watch('price'))

	return (
		<>
			<Typography
				fontSize={14}
				fontWeight={400}
				color='#80848E'
				marginTop='20px'
			>
				Take Profit-Trigger by ROI (%)
			</Typography>
			<Box display='flex' flexDirection='row' gap='12px' marginTop='4px'>
				<Controller
					control={profitForm.control}
					name='profit'
					render={({ field: { value, onChange, ...field } }) => (
						<TextField
							placeholder='Trigger Price'
							error={!!profitForm.formState.errors.profit?.message}
							helperText={profitForm.formState.errors.profit?.message}
							value={value ? value : ''}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Box>
											<Button
												onClick={() => {
													const value = profitForm.getValues('profit')
													profitForm.setValue(
														'profit',
														String(value ? +value - 1 : 0)
													)
												}}
												sx={{
													padding: '0 !important',
													paddingInline: '10px !important',
													minWidth: 'auto',
												}}
											>
												-
											</Button>
										</Box>
										<Divider orientation='vertical' flexItem />
										<Box>
											<Button
												onClick={() => {
													const value = profitForm.getValues('profit')
													profitForm.setValue(
														'profit',
														String(value ? +value + 1 : 1)
													)
												}}
												sx={{
													padding: '0 !important',
													paddingInline: '10px !important',
													minWidth: 'auto',
												}}
											>
												+
											</Button>
										</Box>
										<Button
											sx={{
												padding: '0 !important',
												paddingInline: '10px !important',
												minWidth: 'auto',
											}}
											onClick={() => onChange(orderPrice)}
										>
											<span
												style={{
													color: '#00B7F1',
													textTransform: 'initial',
												}}
											>
												Last
											</span>
										</Button>
									</InputAdornment>
								),
							}}
							sx={{
								backgroundColor: '#26282C',
								borderRadius: '4px',

								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 },
							}}
							onChange={event => {
								const value = event.target.value.replace(/[^0-9.]+/g, '')
								onChange(
									value.split('.').length === 2
										? value
										: isNaN(parseFloat(value))
										? ''
										: String(parseFloat(value))
								)
							}}
							{...field}
						/>
					)}
				/>
				<Controller
					control={profitForm.control}
					name='profit'
					render={({ field: { value, onChange, ...field } }) => (
						<TextField
							placeholder='ROI'
							error={!!profitForm.formState.errors.profit?.message}
							helperText={profitForm.formState.errors.profit?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography color='white'>%</Typography>
									</InputAdornment>
								),
							}}
							sx={{
								backgroundColor: '#26282C',
								borderRadius: '4px',

								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 },
							}}
							value={
								value
									? Number(((+value - orderPrice) / orderPrice) * 100).toFixed(
											1
									  )
									: ''
							}
							onChange={event => {
								const value = event.target.value.replace(/[^0-9.]+/g, '')
								const result =
									value.split('.').length === 2
										? value
										: isNaN(parseFloat(value))
										? ''
										: String(parseFloat(value))
								if (result === 'number') {
									onChange(orderPrice + +result * orderPrice)
								}
							}}
						/>
					)}
				/>
			</Box>
			<Controller
				control={profitForm.control}
				name='profit'
				render={({ field: { value, onChange, ...field } }) => (
					<Slider
						style={{ marginTop: '12px' }}
						size='small'
						step={1}
						max={150}
						min={0}
						marks={new Array(5).fill(null).map((_, index) => ({
							label: `${index * 50}%`,
							value: index * 50,
						}))}
						valueLabelDisplay='auto'
						value={Math.round(
							value ? ((+value - orderPrice) / orderPrice) * 100 : 1
						)}
						onChange={(e: any) =>
							onChange(orderPrice + orderPrice * (e.target.value / 100))
						}
						{...field}
					/>
				)}
			/>

			<Divider style={{ marginBlock: '28px' }} />

			<Typography
				fontSize={14}
				fontWeight={400}
				color='#80848E'
				marginTop='20px'
			>
				Stop Loss-Trigger by ROI (%)
			</Typography>
			<Box display='flex' flexDirection='row' gap='12px' marginTop='4px'>
				<Controller
					control={profitForm.control}
					name='loss'
					render={({ field: { value, onChange, ...field } }) => (
						<TextField
							placeholder='Trigger Price'
							error={!!profitForm.formState.errors.loss?.message}
							helperText={profitForm.formState.errors.loss?.message}
							value={value ? value : ''}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Box>
											<Button
												onClick={() => {
													const value = profitForm.getValues('loss') ?? 0
													profitForm.setValue(
														'loss',
														String(+value ? +value - 1 : 0)
													)
												}}
												sx={{
													padding: '0 !important',
													paddingInline: '10px !important',
													minWidth: 'auto',
												}}
											>
												-
											</Button>
										</Box>
										<Divider orientation='vertical' flexItem />
										<Box>
											<Button
												onClick={() => {
													const value = profitForm.getValues('loss') ?? 0
													profitForm.setValue(
														'loss',
														String(+value ? +value + 1 : 1)
													)
												}}
												sx={{
													padding: '0 !important',
													paddingInline: '10px !important',
													minWidth: 'auto',
												}}
											>
												+
											</Button>
										</Box>
										<Box
											display='flex'
											flexDirection='row'
											alignItems='center'
											gap='10px'
										>
											<Typography color='#00B7F1' sx={{ cursor: 'pointer' }}>
												Last
											</Typography>
										</Box>
									</InputAdornment>
								),
							}}
							sx={{
								backgroundColor: '#26282C',
								borderRadius: '4px',

								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 },
							}}
							onChange={event => {
								const value = event.target.value.replace(/[^0-9.]+/g, '')
								onChange(
									value.split('.').length === 2
										? value
										: isNaN(parseFloat(value))
										? ''
										: String(parseFloat(value))
								)
							}}
							{...field}
						/>
					)}
				/>
				<Controller
					control={profitForm.control}
					name='loss'
					render={({ field: { value, onChange, ...field } }) => (
						<TextField
							placeholder='ROI'
							error={!!profitForm.formState.errors.loss?.message}
							helperText={profitForm.formState.errors.loss?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography color='white'>%</Typography>
									</InputAdornment>
								),
							}}
							sx={{
								backgroundColor: '#26282C',
								borderRadius: '4px',

								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 },
							}}
							value={
								value
									? Number(((+value - orderPrice) / orderPrice) * 100).toFixed(
											1
									  )
									: ''
							}
							onChange={event => {
								const value = event.target.value.replace(/[^0-9.]+/g, '')
								const result =
									value.split('.').length === 2
										? value
										: isNaN(parseFloat(value))
										? ''
										: String(parseFloat(value))
								if (result === 'number') {
									onChange(orderPrice + +result * orderPrice)
								}
							}}
							{...field}
						/>
					)}
				/>
			</Box>
			<Controller
				control={profitForm.control}
				name='loss'
				render={({ field: { value, onChange, ...field } }) => (
					<Slider
						style={{ marginTop: '12px' }}
						size='small'
						step={1}
						max={75}
						min={0}
						marks={new Array(5).fill(null).map((_, index) => ({
							label: `${index * 25}%`,
							value: index * 25,
						}))}
						valueLabelDisplay='auto'
						value={Math.round(
							value ? ((orderPrice - +value) / orderPrice) * 100 : 1
						)}
						onChange={(e: any) =>
							onChange(orderPrice - orderPrice * (e.target.value / 100))
						}
						{...field}
					/>
				)}
			/>
		</>
	)
}

function ProfitShortForm() {
	const { resultForm, profitForm } = useModel()
	const orderPrice = Number(resultForm.watch('price'))

	return (
		<>
			<Typography
				fontSize={14}
				fontWeight={400}
				color='#80848E'
				marginTop='20px'
			>
				Take Profit-Trigger by ROI (%)
			</Typography>
			<Box display='flex' flexDirection='row' gap='12px' marginTop='4px'>
				<Controller
					control={profitForm.control}
					name='profit'
					render={({ field: { value, onChange, ...field } }) => (
						<TextField
							placeholder='Trigger Price'
							error={!!profitForm.formState.errors.profit?.message}
							helperText={profitForm.formState.errors.profit?.message}
							value={value ? value : ''}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Box>
											<Button
												onClick={() => {
													const value = profitForm.getValues('profit') ?? 0
													profitForm.setValue(
														'profit',
														String(+value ? +value - 1 : 0)
													)
												}}
												sx={{
													padding: '0 !important',
													paddingInline: '10px !important',
													minWidth: 'auto',
												}}
											>
												-
											</Button>
										</Box>
										<Divider orientation='vertical' flexItem />
										<Box>
											<Button
												onClick={() => {
													const value = profitForm.getValues('profit') ?? 0
													profitForm.setValue(
														'profit',
														String(+value ? +value + 1 : 1)
													)
												}}
												sx={{
													padding: '0 !important',
													paddingInline: '10px !important',
													minWidth: 'auto',
												}}
											>
												+
											</Button>
										</Box>
										<Button
											sx={{
												padding: '0 !important',
												paddingInline: '10px !important',
												minWidth: 'auto',
											}}
											onClick={() => onChange(orderPrice)}
										>
											<span
												style={{
													color: '#00B7F1',
													textTransform: 'initial',
												}}
											>
												Last
											</span>
										</Button>
									</InputAdornment>
								),
							}}
							sx={{
								backgroundColor: '#26282C',
								borderRadius: '4px',

								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 },
							}}
							onChange={event => {
								const value = event.target.value.replace(/[^0-9.]+/g, '')
								onChange(
									value.split('.').length === 2
										? value
										: isNaN(parseFloat(value))
										? ''
										: String(parseFloat(value))
								)
							}}
							{...field}
						/>
					)}
				/>
				<Controller
					control={profitForm.control}
					name='profit'
					render={({ field: { value, onChange, ...field } }) => (
						<TextField
							placeholder='ROI'
							error={!!profitForm.formState.errors.profit?.message}
							helperText={profitForm.formState.errors.profit?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography color='white'>%</Typography>
									</InputAdornment>
								),
							}}
							sx={{
								backgroundColor: '#26282C',
								borderRadius: '4px',
								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 },
							}}
							value={
								value
									? Number(((orderPrice - +value) / orderPrice) * 100).toFixed(
											1
									  )
									: ''
							}
							onChange={event => {
								const value = event.target.value.replace(/[^0-9.]+/g, '')
								const result =
									value.split('.').length === 2
										? value
										: isNaN(parseFloat(value))
										? ''
										: String(parseFloat(value))
								if (result === 'number') {
									onChange(orderPrice + +result * orderPrice)
								}
							}}
						/>
					)}
				/>
			</Box>
			<Controller
				control={profitForm.control}
				name='profit'
				render={({ field: { value, onChange, ...field } }) => (
					<Slider
						style={{ marginTop: '12px' }}
						size='small'
						step={1}
						max={150}
						min={0}
						marks={new Array(5).fill(null).map((_, index) => ({
							label: `${index * 50}%`,
							value: index * 50,
						}))}
						valueLabelDisplay='auto'
						value={Math.round(
							value ? ((orderPrice - +value) / orderPrice) * 100 : 1
						)}
						onChange={(e: any) =>
							onChange(
								orderPrice - orderPrice * (e.target.value / 100) > 0
									? orderPrice - orderPrice * (e.target.value / 100)
									: 0
							)
						}
						{...field}
					/>
				)}
			/>

			<Divider style={{ marginBlock: '28px' }} />

			<Typography
				fontSize={14}
				fontWeight={400}
				color='#80848E'
				marginTop='20px'
			>
				Stop Loss-Trigger by ROI (%)
			</Typography>
			<Box display='flex' flexDirection='row' gap='12px' marginTop='4px'>
				<Controller
					control={profitForm.control}
					name='loss'
					render={({ field: { value, onChange, ...field } }) => (
						<TextField
							placeholder='Trigger Price'
							error={!!profitForm.formState.errors.profit?.message}
							helperText={profitForm.formState.errors.profit?.message}
							value={value ? value : ''}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Box>
											<Button
												onClick={() => {
													const value = profitForm.getValues('loss') ?? 0
													profitForm.setValue(
														'loss',
														String(+value ? +value - 1 : 0)
													)
												}}
												sx={{
													padding: '0 !important',
													paddingInline: '10px !important',
													minWidth: 'auto',
												}}
											>
												-
											</Button>
										</Box>
										<Divider orientation='vertical' flexItem />
										<Box>
											<Button
												onClick={() => {
													const value = profitForm.getValues('loss') ?? 0
													profitForm.setValue(
														'loss',
														String(+value ? +value + 1 : 1)
													)
												}}
												sx={{
													padding: '0 !important',
													paddingInline: '10px !important',
													minWidth: 'auto',
												}}
											>
												+
											</Button>
										</Box>
										<Box
											display='flex'
											flexDirection='row'
											alignItems='center'
											gap='10px'
										>
											<Typography color='#00B7F1' sx={{ cursor: 'pointer' }}>
												Last
											</Typography>
										</Box>
									</InputAdornment>
								),
							}}
							sx={{
								backgroundColor: '#26282C',
								borderRadius: '4px',

								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 },
							}}
							onChange={event => {
								const value = event.target.value.replace(/[^0-9.]+/g, '')
								onChange(
									value.split('.').length === 2
										? value
										: isNaN(parseFloat(value))
										? ''
										: String(parseFloat(value))
								)
							}}
							{...field}
						/>
					)}
				/>
				<Controller
					control={profitForm.control}
					name='loss'
					render={({ field: { value, onChange, ...field } }) => (
						<TextField
							placeholder='ROI'
							error={!!profitForm.formState.errors.loss?.message}
							helperText={profitForm.formState.errors.loss?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography color='white'>%</Typography>
									</InputAdornment>
								),
							}}
							sx={{
								backgroundColor: '#26282C',
								borderRadius: '4px',

								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 },
							}}
							value={
								value
									? -Math.round(((+value - orderPrice) / orderPrice) * 100)
									: ''
							}
							onChange={event => {
								const value = event.target.value.replace(/[^0-9.]+/g, '')
								const result =
									value.split('.').length === 2
										? value
										: isNaN(parseFloat(value))
										? ''
										: String(parseFloat(value))
								console.log(result)
								if (result === 'number') {
									onChange(orderPrice + -+result * orderPrice)
								}
							}}
							{...field}
						/>
					)}
				/>
			</Box>
			<Controller
				control={profitForm.control}
				name='loss'
				render={({ field: { value, onChange, ...field } }) => (
					<Slider
						style={{ marginTop: '12px' }}
						size='small'
						step={1}
						max={75}
						min={0}
						marks={new Array(5).fill(null).map((_, index) => ({
							label: `${index * 25}%`,
							value: index * 25,
						}))}
						valueLabelDisplay='auto'
						value={Math.round(
							value ? ((+value - orderPrice) / orderPrice) * 100 : 1
						)}
						onChange={(e: any) =>
							onChange(orderPrice + orderPrice * (e.target.value / 100))
						}
						{...field}
					/>
				)}
			/>
		</>
	)
}

function ResultForm({ showOrderPrice = true }: { showOrderPrice?: boolean }) {
	const { ticker } = useByBit()
	const { setProfitModal, resultForm, submit, feeRate, leverageForm } =
		useModel()
	const { enqueueSnackbar } = useSnackbar()
	const {
		watch,
		getValues,
		setValue,
		control,
		formState: { errors },
		handleSubmit,
	} = resultForm
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const byBitWalletBalance = useTypedSelector(
		state => state.wallets.byBitWalletBalance
	)
	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
	const [prevPrice, setPrevPrice] = useState<number>(+getValues('price'))
	const [lastMarketPrice, setLastMarketPrice] = useState<number | null>(null)
	const [isFirst, setIsFirst] = useState<boolean>(false)
	const type = watch('type')
	const strPrice = watch('price')
	const price = Number(watch('price'))
	const qty = watch('qty')

	const usdtBalance = useMemo(() => {
		let totalUsdt: number = 0
		byBitWalletBalance.data?.list.forEach(item =>
			item.coin?.forEach(coin => {
				if (coin.coin === 'USDT') totalUsdt += Number(coin.walletBalance)
			})
		)
		return totalUsdt
	}, [byBitWalletBalance])

	const handleUpdatePrice = () => {
		if (!lastMarketPrice) return
		resultForm.setValue('price', String(lastMarketPrice))
	}
	console.log(lastMarketPrice)

	useEffect(() => {
		if (!ticker?.data.lastPrice) return
		setLastMarketPrice(Number(ticker.data.lastPrice))
	}, [ticker])

	useEffect(() => {
		if (!lastMarketPrice) return
		if (type === 0 && !isFirst) {
			handleUpdatePrice()
			setIsFirst(true)
		} else if (type === 1) {
			handleUpdatePrice()
		}
	}, [type, isFirst, lastMarketPrice])

	useEffect(() => {
		return () => {
			resultForm.setValue('price', '')
			setLastMarketPrice(null)
			setIsFirst(false)
		}
	}, [selectedPair])

	useEffect(() => {
		return () => setPrevPrice(price)
	}, [price])

	useEffect(() => {
		if (
			selectedPair &&
			!isNaN(parseFloat(qty)) &&
			!qty.endsWith('.') &&
			!qty.endsWith('0')
		) {
			if (
				parseFloat(qty) < +selectedPair?.lotSizeFilter.minOrderQty &&
				resultForm.formState.errors.qty?.message !== 'Qty below min limit'
			) {
				enqueueSnackbar('Quantity below min limit. Please increase.', {
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				resultForm.setError('qty', { message: 'Qty below min limit' })
			} else if (
				parseFloat(qty) > +selectedPair?.lotSizeFilter.maxOrderQty &&
				resultForm.formState.errors.qty?.message !== 'Qty exceeds max limit'
			) {
				enqueueSnackbar('Quantity exceeds max limit. Please adjust.', {
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				resultForm.setError('qty', { message: 'Qty exceeds max limit' })
			} else {
				resultForm.clearErrors('qty')
			}
		}
	}, [qty])

	return (
		<>
			<Box display='flex'>
				<Box
					component='fieldset'
					width='100%'
					flex='1'
					display='flex'
					flexDirection='column'
					gap='18px'
					border='none'
				>
					{showOrderPrice && (
						<Controller
							control={control}
							name='price'
							rules={{ required: true }}
							render={({ field: { onChange, ...field } }) => (
								<Box display='flex' flexDirection='column' gap='4px'>
									<Typography color='#80848E'>Order Price</Typography>
									<TextField
										size='small'
										error={!!errors.price?.message}
										helperText={errors.price?.message}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<Box
														display='flex'
														flexDirection='row'
														alignItems='center'
														gap='10px'
													>
														<Button
															sx={{
																padding: '0 !important',
																paddingInline: '10px !important',
																minWidth: 'auto',
															}}
															onClick={handleUpdatePrice}
														>
															<span
																style={{
																	color: '#00B7F1',
																	textTransform: 'initial',
																}}
															>
																Last
															</span>
														</Button>
														<Divider orientation='vertical' flexItem />
														<Button
															sx={{
																padding: '0 !important',
																paddingInline: '10px !important',
																minWidth: 'auto',
															}}
															onClick={event =>
																setAnchorEl(event.currentTarget)
															}
														>
															±
														</Button>
														<Popover
															open={!!anchorEl}
															onClose={() => setAnchorEl(null)}
															anchorEl={anchorEl}
															anchorOrigin={{
																vertical: 'bottom',
																horizontal: 'left',
															}}
														>
															<Box
																display='grid'
																gridTemplateColumns='repeat(2, 1fr)'
																width='140px'
															>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue(
																			'price',
																			String(
																				Math.round(
																					(+getValues('price') + 0.1) * 10
																				) / 10
																			)
																		)
																	}}
																>
																	+
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue(
																			'price',
																			String(
																				+getValues('price') > 0.1
																					? Math.round(
																							(+getValues('price') - 0.1) * 10
																					  ) / 10
																					: 0.1
																			)
																		)
																	}}
																>
																	{' '}
																	-
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue(
																			'price',
																			String(
																				Math.round(
																					(+getValues('price') + 1) * 10
																				) / 10
																			)
																		)
																	}}
																>
																	+1
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue(
																			'price',
																			String(
																				+getValues('price') > 1
																					? Math.round(
																							(+getValues('price') - 1) * 10
																					  ) / 10
																					: 0.1
																			)
																		)
																	}}
																>
																	-1
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue(
																			'price',
																			String(
																				Math.round(
																					(+getValues('price') + 5) * 10
																				) / 10
																			)
																		)
																	}}
																>
																	+5
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue(
																			'price',
																			String(
																				+getValues('price') > 5
																					? Math.round(
																							(+getValues('price') - 5) * 10
																					  ) / 10
																					: 0.1
																			)
																		)
																	}}
																>
																	-5
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue(
																			'price',
																			String(
																				Math.round(
																					(+getValues('price') + 20) * 10
																				) / 10
																			)
																		)
																	}}
																>
																	+20
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue(
																			'price',
																			String(
																				+getValues('price') > 20
																					? Math.round(
																							(+getValues('price') - 20) * 10
																					  ) / 10
																					: 0.1
																			)
																		)
																	}}
																>
																	-20
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue('price', '0.1')
																	}}
																>
																	c
																</Button>
																<Button
																	sx={{
																		padding: '0 !important',
																	}}
																	onClick={() => {
																		setValue('price', String(prevPrice))
																	}}
																>
																	↩
																</Button>
															</Box>
														</Popover>
													</Box>
												</InputAdornment>
											),
										}}
										sx={{
											backgroundColor: '#26282C',
											borderRadius: '4px',

											boxShadow: 'none',
											'.MuiOutlinedInput-notchedOutline': { border: 0 },
										}}
										onChange={event => {
											const value = event.target.value.replace(/[^0-9.]+/g, '')
											onChange(
												value.split('.').length === 2
													? value
													: isNaN(parseFloat(value))
													? ''
													: String(parseFloat(value))
											)
										}}
										{...field}
									/>
									<></>
								</Box>
							)}
						/>
					)}
					<Controller
						control={control}
						name='qty'
						render={({ field: { value, onChange, ...field } }) => (
							<Box display='flex' flexDirection='column' gap='4px'>
								<Typography color='#80848E'>Order by Qty</Typography>
								<TextField
									size='small'
									error={!!errors.qty?.message}
									helperText={errors.qty?.message}
									sx={{
										backgroundColor: '#26282C',
										borderRadius: '4px',
										boxShadow: 'none',
										'.MuiOutlinedInput-notchedOutline': { border: 0 },
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<BaseCurrencyText />
											</InputAdornment>
										),
									}}
									onChange={event => {
										const value = event.target.value.replace(/[^0-9.]+/g, '')
										let resultValue = ''
										const filterQty = selectedPair?.lotSizeFilter?.qtyStep

										if (filterQty?.includes('.') && value.includes('.')) {
											const parts = value.split('.')
											const filterQtyParts = filterQty.split('.')
											resultValue =
												parts[0] +
												'.' +
												parts[1].slice(0, filterQtyParts[1].length)
										} else if (!isNaN(Number(value))) {
											resultValue = String(parseInt(value))
										}

										onChange(resultValue)
										setValue('percent', undefined)
									}}
									value={value}
									{...field}
								/>
							</Box>
						)}
					/>
					<Controller
						control={control}
						name='percent'
						render={({ field: { value, onChange } }) => (
							<Box
								display='flex'
								flexDirection='row'
								justifyContent='space-between'
								width='100%'
								bgcolor='#26282C'
								borderRadius='4px'
							>
								{[10, 25, 50, 75, 100].map(percent => (
									<Box
										key={percent}
										display='flex'
										justifyContent='center'
										alignItems='center'
										width='100%'
										fontSize='10px'
										color={value === percent ? 'white' : '#80848E'}
										bgcolor={value === percent ? '#00B7F1' : ''}
										borderRadius='inherit'
										style={{ cursor: 'pointer' }}
										onClick={() => {
											let res = 0
											if (selectedPair && price && usdtBalance) {
												onChange(percent)
												const leverage = +leverageForm.getValues('value')
												const type = resultForm.getValues('type')
												let feePercent = 0
												if (feeRate) {
													feePercent =
														(type === 0
															? +feeRate.list[0].makerFeeRate
															: +feeRate?.list[0].takerFeeRate) / 100
												}

												const feePrice = usdtBalance * feePercent
												const usdtBalanceWithFee = usdtBalance - feePrice

												const fullAvailableQty =
													(usdtBalanceWithFee * leverage) / price
												const availableQty = fullAvailableQty * (percent / 100)

												const precision = selectedPair.lotSizeFilter.qtyStep
												const precisionPointIndex = precision.indexOf('.')

												if (precisionPointIndex !== -1) {
													const precisionLenAfterPoint =
														precision.length - precisionPointIndex - 1

													res =
														Math.trunc(
															availableQty * 10 ** precisionLenAfterPoint
														) /
														10 ** precisionLenAfterPoint
												} else {
													res = Math.floor(availableQty)
												}
											}

											setValue('qty', String(res))
										}}
									>
										{percent}%
									</Box>
								))}
							</Box>
						)}
					/>
				</Box>
			</Box>
			<OrderResult />
			<Controller
				control={resultForm.control}
				name='customSetup'
				render={({ field: { value, onChange, ...field } }) => (
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='6px'
						onClick={() => {
							if (!resultForm.getValues('qty')) {
								enqueueSnackbar('Please enter your order qty', {
									variant: 'info',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'center',
									},
								})
							} else {
								setProfitModal(true)
							}
						}}
					>
						<Checkbox
							checked={value}
							sx={{
								padding: 0,
								color: '#363B47',
								'&.Mui-checked': {
									color: '#00B7F1',
								},
							}}
							{...field}
						/>
						<Typography color='#80848E'>Take Profit / Stop Loss</Typography>
					</Box>
				)}
			/>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				gap='10px'
			>
				<Button
					variant='contained'
					color='success'
					style={{
						background: '#0CAF60',
						fontSize: 12,
						lineHeight: '18px',
						fontWeight: 600,
						textTransform: 'initial',
					}}
					disabled={resultForm.formState.isSubmitting}
					onClick={handleSubmit(() => submit('Buy'))}
				>
					Buy/Long
				</Button>
				<Button
					variant='contained'
					color='error'
					style={{
						background: '#D83232',
						color: 'white',
						fontSize: 12,
						lineHeight: '18px',
						fontWeight: 600,
						textTransform: 'initial',
					}}
					disabled={resultForm.formState.isSubmitting}
					onClick={handleSubmit(() => submit('Sell'))}
				>
					Sell/Short
				</Button>
			</Box>
		</>
	)
}

function BaseCurrencyText() {
	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)

	return (
		<Typography
			fontSize='12px'
			fontWeight='400'
			lineHeight='18px'
			color='white'
		>
			{selectedPair?.baseCoin}
		</Typography>
	)
}

function OrderResult() {
	const { leverageForm, resultForm, feeRate } = useModel()
	const { selectedPair } = useTypedSelector(state => state.exchange)
	const { orderbook, ticker } = useByBit()
	const [asks, setAsks] = useState<[string, string][]>([])
	const [bids, setBids] = useState<[string, string][]>([])
	const [lastPrice, setLastPrice] = useState<string | null>(null)

	const type = resultForm.watch('type')
	const leverageValue = Number(leverageForm.watch('value'))
	const price = Number(resultForm.watch('price'))
	const qty = Number(resultForm.watch('qty'))

	// const cheapAskOrderbook = useMemo(() => {
	//   return asks.reduce(
	//     (prev, item) => (+item[0] < prev ? +item[0] : prev),
	//     Infinity
	//   );
	// }, [asks]);
	// const cheapBidOrderbook = useMemo(() => {
	//   return bids.reduce(
	//     (prev, item) => (+item[0] < prev ? +item[0] : prev),
	//     Infinity
	//   );
	// }, [bids]);
	const longPrice = !!lastPrice
		? type === 0
			? price < +lastPrice
				? price
				: +lastPrice
			: +lastPrice
		: NaN
	const shortPrice = !!lastPrice
		? type === 0
			? price > +lastPrice
				? price
				: +lastPrice
			: +lastPrice
		: NaN

	const longValue = longPrice * qty
	const shortValue = shortPrice * qty
	const longCost = feeRate
		? (longPrice * qty) / leverageValue +
		  longPrice * qty * +feeRate.list[0].takerFeeRate +
		  qty *
				((longPrice * (leverageValue - 1)) / leverageValue) *
				+feeRate.list[0].takerFeeRate
		: 0
	const shortCost = feeRate
		? (shortPrice * qty) / leverageValue +
		  shortPrice * qty * +feeRate.list[0].takerFeeRate +
		  qty *
				((shortPrice * (leverageValue + 1)) / leverageValue) *
				+feeRate.list[0].takerFeeRate
		: 0

	useEffect(() => {
		if (!ticker) return
		setLastPrice(prev => ticker?.data?.lastPrice ?? prev)
	}, [ticker])

	useEffect(() => {
		if (!orderbook) return
		setAsks(prev =>
			orderbook.type !== 'snapshot'
				? [...orderbook.data.a, ...prev]
				: [...orderbook.data.a]
		)
		setBids(prev =>
			orderbook.type !== 'snapshot'
				? [...orderbook.data.b, ...prev]
				: [...orderbook.data.b]
		)
	}, [orderbook])

	useEffect(() => {
		;(async () => {
			const defaultLeverage = String(1)
			await byBitApi.setLeverage({
				symbol: `${selectedPair?.baseCoin.trim()}${selectedPair?.quoteCoin.trim()}`,
				category: 'linear',
				buyLeverage: defaultLeverage,
				sellLeverage: defaultLeverage,
			})
			leverageForm.setValue('value', defaultLeverage)
		})()
	}, [selectedPair])

	return (
		<Box padding='6px 12.5px' border='1px solid #363B47' borderRadius='4px'>
			<Box display='flex' flexDirection='row' justifyContent='space-between'>
				<Typography color='#80848E'>Value</Typography>
				<Box display='flex' flexDirection='row' gap='1px'>
					<Typography fontWeight={600} color='green'>
						{!isFinite(longValue)
							? '--'
							: removeTrailingZeros(String(longValue.toFixed(4)))}
					</Typography>
					<Typography color='#80848E'>/</Typography>
					<Typography fontWeight={600} color='red'>
						{!isFinite(shortValue)
							? '--'
							: removeTrailingZeros(String(shortValue.toFixed(4)))}
					</Typography>
					<Typography>{selectedPair?.quoteCoin}</Typography>
				</Box>
			</Box>
			<Box display='flex' flexDirection='row' justifyContent='space-between'>
				<Typography color='#80848E'>Cost</Typography>
				<Box display='flex' flexDirection='row' gap='1px'>
					<Typography fontWeight={600} color='green'>
						{!isFinite(longCost)
							? '--'
							: removeTrailingZeros(String(longCost.toFixed(4)))}
					</Typography>
					<Typography color='#80848E'>/</Typography>
					<Typography fontWeight={600} color='red'>
						{!isFinite(shortCost)
							? '--'
							: removeTrailingZeros(String(shortCost.toFixed(4)))}
					</Typography>
					<Typography>{selectedPair?.quoteCoin}</Typography>
				</Box>
			</Box>
		</Box>
	)
}

const useModel = () => {
	const context = useContext(Context)
	if (!context) throw new Error('context is null')
	return context
}

const Context = createContext<{
	leverageForm: UseFormReturn<LeverageSchema>
	profitForm: UseFormReturn<ProfitSchema>
	resultForm: UseFormReturn<ResultSchema>
	leverageModal: boolean
	setLeverageModal: Dispatch<SetStateAction<boolean>>
	profitModal: boolean
	setProfitModal: Dispatch<SetStateAction<boolean>>
	submit: (side: 'Buy' | 'Sell') => void
	feeRate: ByBitFeeRateBody | null
} | null>(null)

function ExchangeTopRightProvider({ children }: PropsWithChildren) {
	const [leverageModal, setLeverageModal] = useState<boolean>(false)
	const [profitModal, setProfitModal] = useState<boolean>(false)
	const [feeRate, setFeeRate] = useState<ByBitFeeRateBody | null>(null)
	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
	const { enqueueSnackbar } = useSnackbar()
	// const { handleGetPositions, handleGetOrders } = useOpenOrders()
	const dispatch = useAppDispatch()
	const leverageForm = useForm<LeverageSchema>({
		defaultValues: {
			type: LEVERAGE_TYPES[0].value,
			value: '1',
		},
		resolver: zodResolver(leverageSchema),
	})
	const profitForm = useForm<ProfitSchema>({
		defaultValues: {
			type: 'Long',
		},
		resolver: zodResolver(profitSchema),
	})
	const resultForm = useForm<ResultSchema>({
		defaultValues: {
			type: 0,
			price: '',
			qty: '',
			customSetup: false,
		},
		resolver: zodResolver(resultSchema),
	})

	const submit = async (side: 'Buy' | 'Sell') => {
		try {
			const data = {
				type: resultForm.getValues('type'),
				qty: resultForm.getValues('qty'),
				orderType: resultForm.getValues('type'),
				price: resultForm.getValues('price'),
			}

			if (data.qty === '0') {
				enqueueSnackbar('Qty cannot be 0', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				})
				return
			}
			const profit = profitForm.getValues('profit')
			const loss = profitForm.getValues('loss')
			await byBitApi.placeOrder({
				category: 'linear',
				price: data.type === 0 ? String(data.price) : undefined,
				qty: String(data.qty),
				orderType: data.type === 0 ? 'Limit' : 'Market',
				symbol: `${selectedPair?.baseCoin}${selectedPair?.quoteCoin}`,
				side,
				isLeverage: 0,
				takeProfit: profit ? String(profit) : undefined,
				stopLoss: loss ? String(loss) : undefined,
			})
			resultForm.reset()
			enqueueSnackbar('A new order has been placed.', {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			})
			dispatch(getByBitWalletBalanceThunk({}))
		} catch (err) {
			enqueueSnackbar('An error occurred while creating the new order place.', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			})
		}
	}

	useEffect(() => {
		;(async () => {
			if (!selectedPair) return
			const res = await byBitApi.getFeeRate({
				baseCoin: selectedPair.baseCoin,
				symbol: selectedPair.symbol,
			})
			setFeeRate(res)
		})()
	}, [selectedPair])

	return (
		<Context.Provider
			value={{
				leverageForm,
				profitForm,
				resultForm,
				submit,
				leverageModal,
				setLeverageModal,
				profitModal,
				setProfitModal,
				feeRate,
			}}
		>
			{children}
		</Context.Provider>
	)
}
