import { ComponentProps, ReactNode } from "react"
import { ExchangeFormField } from "./ExchangeFormField"
import styled from "@emotion/styled"

interface ExchangeFormInputWithSelectProps extends ComponentProps<typeof ExchangeFormField> {
    left: ReactNode
    right: ReactNode
}

export const ExchangeFormFieldItem = ({ left, right, label, ...props }: ExchangeFormInputWithSelectProps) => {
    return (
        <ExchangeFormField
            label={{
                ...label,
                right: label?.right ? (
                    <StyledExchangeFormFieldItem.Right style={{ maxWidth: "100%", display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}>
                        {label.right}
                    </StyledExchangeFormFieldItem.Right>
                ) : undefined
            }}
            {...props}
        >
            <StyledExchangeFormFieldItem.Wrapper>
                <StyledExchangeFormFieldItem.Left>{left}</StyledExchangeFormFieldItem.Left>
                <StyledExchangeFormFieldItem.Line />
                <StyledExchangeFormFieldItem.Right>{right}</StyledExchangeFormFieldItem.Right>
            </StyledExchangeFormFieldItem.Wrapper>
        </ExchangeFormField>
    )
}

const StyledExchangeFormFieldItem = {
    Wrapper: styled.div`
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: 100%;
        overflow: hidden;
    `,
    Left: styled.div`
        width: 100%;
        height: 100%;
    `,
    Line: styled.div`
        width: 0.5px;
        height: calc(100% - 6px);
        background-color: #D8D8D8;
        border-radius: 5px;
    `,
    Right: styled.div`
        width: 100%;
        max-width: 164px;
        height: 100%;
    `,
}