import { FC, useEffect, useState } from "react";

import { sumSubApi } from "api";

import { Box, Paper } from "@mui/material";
import SumsubWebSdk from "@sumsub/websdk-react";

import { StyledContainer } from "./facialScan.styles";

const FacialScan: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    sumSubApi
      .getSumSubToken()
      .then(({ token }) => {
        setToken(token);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {isLoading ? (
        <span>loading</span>
      ) : token ? (
        <Paper
          square
          sx={{
            background: "#FFFFFF",
            width: 815,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            pb: 10,
            pt: 8,
          }}
        >
          <StyledContainer>
            <SumsubWebSdk
              accessToken={token}
              expirationHandler={() => {}}
              config={{}}
              options={{}}
              onMessage={(msg: any) => {
                // console.log("SUMSUB MSG", msg);
              }}
              onError={(err: any) => {
                // console.log("SUMSUB ERROR", err);
              }}
            />
          </StyledContainer>
        </Paper>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <span style={{ fontSize: 20, fontWeight: "bold" }}>
            Token is Empty
          </span>
        </div>
      )}
    </Box>
  );
};

export default FacialScan;
