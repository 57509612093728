import { useEffect, useState } from "react";
import {
  ByBitDepositsHistoryArgs,
  ByBitDepositsHistoryBody,
  byBitApi,
} from "api";

interface Props {
  render: (args: {
    depositsHistory: ByBitDepositsHistoryBody | null;
    loading: boolean;
    error: string;
    onRequest: (args: ByBitDepositsHistoryArgs) => void;
  }) => React.ReactNode;
}

export const DepositsHistoryContainer = ({ render }: Props) => {
  const [depositsHistory, setDepositsHistory] =
    useState<ByBitDepositsHistoryBody | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleGetDepositsHistory = async (
    args: ByBitDepositsHistoryArgs = {}
  ) => {
    try {
      setLoading(true);
      const res = await byBitApi.getDepositsHistory(args);
      setDepositsHistory(res);
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetDepositsHistory();
  }, []);

  return (
    <>
      {render({
        depositsHistory,
        loading,
        error,
        onRequest: handleGetDepositsHistory,
      })}
    </>
  );
};
