export function removeTrailingZeros(str: string) {
    let parts = str.split('.');

    if (parts.length > 1) {
        parts[1] = parts[1].replace(/0+$/, '');
        
        if (parts[1].length === 0) {
            return parts[0];
        } else {
            return parts.join('.');
        }
    } else {
        return str;
    }
}
