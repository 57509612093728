import {
  IByBitWalletBalance,
  ICurrency,
  IRates,
  IVolume,
  IWalletBalance,
} from "../../types/exchange.types";

interface IState {
  wallets: {
    loading: boolean;
    error: any;
    data: ICurrency[];
  };
  balance: {
    loading: boolean;
    error: any;
    data: any | null;
  };
  rates: {
    loading: boolean;
    error: any;
    data: IRates;
  };
  volume: {
    loading: boolean;
    error: any;
    data: IVolume | null;
  };
  walletBalance: {
    loading: boolean;
    error: any;
    data: IWalletBalance | null;
  };
  byBitWalletBalance: {
    loading: boolean;
    error: any;
    data: IByBitWalletBalance | null;
  };
  selectedWithdrawWallet: string | null;
  selectedDepositWallet: string | null;
  isLoading: boolean;
  selectedWallet?: ICurrency;
}

export const walletsState: IState = {
  isLoading: false,
  selectedWithdrawWallet: null,
  selectedDepositWallet: null,
  wallets: {
    loading: false,
    error: null,
    data: [],
  },
  byBitWalletBalance: {
    loading: false,
    error: null,
    data: null,
  },
  balance: {
    loading: false,
    error: null,
    data: null,
  },
  rates: {
    loading: false,
    error: null,
    data: {},
  },

  volume: {
    loading: false,
    error: null,
    data: null,
  },
  walletBalance: {
    loading: false,
    error: null,
    data: null,
  },
};
