import { ReactNode, useEffect, useState } from "react";
import { ByBitNetworkBody, byBitApi } from "api";

interface Props {
  coin: string;
  render: (args: {
    networks: ByBitNetworkBody | null;
    loading: boolean;
    error: string;
    onRequest: (args: { coin: string }) => void;
  }) => ReactNode;
}

export const NetworksContainer = ({ coin, render }: Props) => {
  const [networks, setNetworks] = useState<ByBitNetworkBody | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleGetNetworks = async ({ coin }: { coin: string }) => {
    try {
      setLoading(true);
      const res = await byBitApi.getNetworkList({ coin });
      setNetworks(res);
    } catch (err) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNetworks({ coin });
  }, [coin]);

  return (
    <>
      {render({
        networks,
        loading,
        error,
        onRequest: handleGetNetworks,
      })}
    </>
  );
};
