import styled from "@emotion/styled"

export const ExchangeFormHead = () => {
    return (
        <StyledExchangeFormHead.Wrapper>
            <StyledExchangeFormHead.Title>
                Select and convert
            </StyledExchangeFormHead.Title>
            <StyledExchangeFormHead.Subtitle>
                Select the currency, payment method and convert!
            </StyledExchangeFormHead.Subtitle>
        </StyledExchangeFormHead.Wrapper>
    )
}

const StyledExchangeFormHead = {
    Wrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16px;
    `,
    Title: styled.h6`
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: #E7E7E7;
    `,
    Subtitle: styled.p`
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #E7E7E7;
    `
}