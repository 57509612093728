import { ChangeEvent, useEffect, useState } from "react";

import { AdditionalLayout } from "components";

import { Alert, Snackbar } from "@mui/material";

import { ITag, ITicket, ticketApi } from "../../api";
import {
  HelpdeskContent,
  HelpdeskInfoTitle,
  HelpdeskLayout,
  HelpdeskQAWrapper,
  HelpdeskRoutingInfo,
  HelpdeskSubtitle,
  HelpdeskTitle,
  HLabelBox,
  HLabelInput,
  HTags,
  HTagsInput,
  HTagText,
  HTextarea,
  InfoItem,
  InfoItemContent,
  InfoItemTitle,
  StyledButton,
} from "./Helpdesk.style";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useSnackbar } from "notistack";

interface IRequestValidate {
  subject: boolean | undefined;
  text: boolean | undefined;
  tag: boolean | undefined;
}

const Helpdesk = () => {
  const profile = useTypedSelector((state) => state.profile)
  // const [tags, setTags] = useState<ITag[]>();
  const [errors, setErrors] = useState<IRequestValidate>({
    subject: undefined,
    text: undefined,
    tag: undefined,
  });
  const [openHelpDeskError, setOpenHelpDeskError] = useState(false);
  const [openHelpDeskSuccess, setOpenHelpDeskSuccess] = useState(false);
  const valid =
    !errors.tag &&
    errors.tag !== undefined &&
    !errors.text &&
    errors.text !== undefined &&
    !errors.subject &&
    errors.subject !== undefined;

  // const tagCheckerHandler = (index: number, val: boolean) => {
  //   const copyTags = [...(tags as ITag[])];
  //   copyTags[index].checked = val;
  //   const isTagThere = copyTags.filter((tag) => tag.checked).length > 0;
  //   if (isTagThere) {
  //     setErrors({ ...errors, tag: false });
  //     setRequestInfo({
  //       ...requestInfo,
  //       tagIds: copyTags.filter((tag) => tag.checked).map((el) => el.ID),
  //     });
  //   } else setErrors({ ...errors, tag: true });
  //   setTags([...copyTags]);
  // };

  const [requestInfo, setRequestInfo] = useState<ITicket>({
    email: "",
    subject: "",
    text: "",
    tagIds: [],
  });
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   if (!tags) ticketApi.getAllTags().then((res) => setTags(res));
  // }, [tags]);

  const messageHandler = async () => {
    try {
      if(!requestInfo.email) {
        enqueueSnackbar("Please wait for email", { variant: "error", anchorOrigin: {horizontal: 'left', vertical: "bottom"} });
        return
      }
      // if(!valid) throw new Error("Is not valid")
      await ticketApi.createTicket(requestInfo)
      setOpenHelpDeskSuccess(true);
    }catch(err) {
      setOpenHelpDeskError(true);
      setErrors({ ...errors, subject: false, tag: false, text: false });
    }
  };

  const inputHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = event.target;

    if (name === "subject" || name === "text") {
      if (value.trim() !== "") {
        setErrors({ ...errors, [name]: false });
        setRequestInfo({ ...requestInfo, [name]: value });
      } else setErrors({ ...errors, [name]: true });
    }
  };

  useEffect(() => {
    if (!profile.me?.user.email ) return
    setRequestInfo({ ...requestInfo, email: profile.me.user.email })
  }, [profile.me?.user.email])

  console.log(profile)

  return (
    <>
      <AdditionalLayout>
        <HelpdeskLayout>
          <HelpdeskQAWrapper>
            <HelpdeskTitle>Bitocol Helpdesk</HelpdeskTitle>
            <HelpdeskContent>
              Please use the box below to let us know which problem you are
              having. Please make your question or request as detailed as
              possible for us to help you quickly.
            </HelpdeskContent>
            <HLabelBox>
              <HelpdeskSubtitle>Question Title</HelpdeskSubtitle>
              <HLabelInput
                maxLength={100}
                onChange={(event) => inputHandler(event)}
                name="subject"
                placeholder="Your question title here"
              />
              {errors.subject && (
                <p style={{ color: "red" }}>Title wasn't provided!</p>
              )}
            </HLabelBox>
            <HLabelBox>
              <HelpdeskSubtitle>Your question</HelpdeskSubtitle>
              <HTextarea
                maxLength={2000}
                onChange={(event) => inputHandler(event)}
                name="text"
                placeholder="Your question or request"
              />
              {errors.text && (
                <p style={{ color: "red" }}>Fill the question blank please</p>
              )}
              {requestInfo.text.length > 2000 && (
                <p style={{ color: "red" }}>
                  Question length must be less than 2000
                </p>
              )}
            </HLabelBox>
            {/* <HLabelBox>
              <HelpdeskSubtitle>Tags</HelpdeskSubtitle>
              <HTags>
                {tags?.map((tag, index) => {
                  return (
                    <HTags
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <HTagsInput
                        style={{
                          borderColor: `${
                            tag?.checked ? "#00B7F1" : "#D1D1D1"
                          }`,
                        }}
                        checked={tag?.checked}
                        type="checkbox"
                        onChange={(val) =>
                          tagCheckerHandler(index, val.target.checked)
                        }
                      />
                      <HTagText
                        style={{
                          color: `${tag?.checked ? "#00B7F1" : "#000"}`,
                        }}
                      >
                        {tag.name}
                      </HTagText>
                    </HTags>
                  );
                })}
              </HTags>
              {errors.tag && (
                <p style={{ color: "red" }}>Set at least one tag</p>
              )}
            </HLabelBox> */}
            <StyledButton
              style={{
                color:
                  // !valid || 
                  requestInfo.text.length > 2000
                    ? "#80848E"
                    : "#00B7F1",
              }}
              disabled={
                // !valid || 
                requestInfo.text.length > 2000}
              onClick={() => messageHandler()}
            >
              Send message
            </StyledButton>
          </HelpdeskQAWrapper>
          <HelpdeskRoutingInfo>
            <HelpdeskInfoTitle>Need to know</HelpdeskInfoTitle>
            <InfoItem>
              <InfoItemTitle>As soon as possible</InfoItemTitle>
              <InfoItemContent>
                Our customer support team aims to solve your issue within 24
                hours of sending your message.{" "}
              </InfoItemContent>
            </InfoItem>
            <InfoItem>
              <InfoItemTitle>Contact method.</InfoItemTitle>
              <InfoItemContent>
                After the message has been sent our customer support team will
                send you an email with the solution or answer to your request.
              </InfoItemContent>
            </InfoItem>
            <InfoItem>
              <InfoItemTitle>Attachment</InfoItemTitle>
              <InfoItemContent>
                You can send attachments or screenshots to our customer support
                team after we contact you via the email, or directly to
                support@bitocol.com{" "}
              </InfoItemContent>
            </InfoItem>
          </HelpdeskRoutingInfo>
        </HelpdeskLayout>
        <Snackbar
          open={openHelpDeskSuccess}
          autoHideDuration={6000}
          onClose={setOpenHelpDeskSuccess.bind(null, false)}
        >
          <Alert
            onClose={setOpenHelpDeskSuccess.bind(null, false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Message sent successfully
          </Alert>
        </Snackbar>

        <Snackbar
          open={openHelpDeskError}
          autoHideDuration={6000}
          onClose={setOpenHelpDeskError.bind(null, false)}
        >
          <Alert
            onClose={setOpenHelpDeskError.bind(null, false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Message could not be sent
          </Alert>
        </Snackbar>
      </AdditionalLayout>
    </>
  );
};

export default Helpdesk;
