import type { News } from "types/news.types"

export interface INews {
  loading: boolean,
  error: Error | null,
  data: Array<News>
}

export const newsState: INews = {
  loading: true,
  error: null,
  data: []
}