import { ByBitOrdersBody, ByBitPositionsBody, byBitApi } from 'api'
import { wsBaseUr } from 'app.config'
import { useEffect, useState } from 'react'
import { useTypedSelector } from './useTypedSelector'

export const useOpenOrders = () => {
	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
	const [positions, setPositions] = useState<ByBitPositionsBody | null>(null)
	const [orders, setOrders] = useState<ByBitOrdersBody | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)
	// const socketRef = useRef<WebSocket | null>(null);

	const handleGetPositions = async () => {
		try {
			const res = await byBitApi.getPositions({
				settleCoin: 'USDT',
			})
			setPositions(res)
		} catch (err) {
			console.log(err)
		}
	}

	const handleGetOrders = async () => {
		try {
			const res = await byBitApi.getOrders()
			setOrders(res)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		const token = localStorage.getItem('accessToken')
		const positionSocket = new WebSocket(
			`${wsBaseUr}/bybit/v1/private/position/${token}`
		)
		positionSocket.onmessage = message => {
			const data = JSON.parse(message.data)
			if (data.topic === 'position') {
				handleGetPositions()
			}
		}
		positionSocket.onerror = err => {
			setError('Socket Error')
		}
	}, [])

	useEffect(() => {
		const token = localStorage.getItem('accessToken')
		const orderSocket = new WebSocket(
			`${wsBaseUr}/bybit/v1/private/order/${token}`
		)
		orderSocket.onmessage = message => {
			try {
				const data = JSON.parse(message.data)
				if (data.topic === 'order') {
					handleGetOrders()
				}
			} catch (err) {}
		}
		orderSocket.onerror = err => {
			setError('Socket Error')
		}
	}, [selectedPair])

	useEffect(() => {
		handleGetPositions()
	}, [])

	useEffect(() => {
		handleGetOrders()
	}, [])

	return {
		positions,
		orders,
		isLoading,
		error,
		handleGetPositions,
		handleGetOrders,
	}
}
