export type Interval = '1' | '5' | '15' | '30' | '60' | '240' | '1D' | '1W' | '1M';

export const INTERVALS: Record<Interval, { label: string, value: string }> = {
    '1': { label: "1m", value: "1" },
    '5': { label: "5m", value: "5" },
    '15': { label: "15m", value: "15" },
    '30': { label: "30m", value: "30" },
    '60': { label: "1h", value: "60" },
    '240': { label: "4h", value: "240" },
    '1D': { label: "1d", value: "D" },
    '1W': { label: "1w", value: "W" },
    '1M': { label: "1M", value: "M" },
}