import 'react-phone-input-2/lib/style.css';

import {
  forwardRef,
  useEffect,
  useState,
} from 'react';

import { authApi } from 'api';
// import { reactCodeInput } from 'CodeInputField.scss';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { QRCodeCanvas } from 'qrcode.react';
import ReactCodeInput from 'react-code-input';
import { setChangePhone } from 'store/profile/profile.slice';
import {
  creteOptChangedisableThunk,
  creteOptChangeThunk,
} from 'store/profile/profile.thunk';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  DialogContent,
  IconButton,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import {
  StepDescription,
  StyledDialog, StyledDialogContent,
  StyledDialogTitle,
  StyledGoogleAuthButton,
  StyledInputCode,
  StyledStepsWrapper,
  TwoFADescription,
  TwoFATitle,
  TwoFAWrap,
} from '../Settings.style';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export const GoogleAuth = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const verification = useTypedSelector((state) => state.profile.me?.verification);

  const [qrAddress, setQRAddress] = useState('');
  const [qrSecret, setQRSecret] = useState('');
  const [code, setCode] = useState("");
  const [otpState, setOtpState] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    authApi.generateQRCode().then(res => {
      setQRAddress(res?.link)
      setQRSecret(res?.secret)
    })
  },[])
  const {
    data,
    error,
  } = useTypedSelector((state) => state.profile.changePhone);




  const handleSubmit = async () => {
    // await authApi.otpChange({otp: code})
    await dispatch(creteOptChangeThunk({enable: true, otp:code}))
    setOpen(false)
    setOtpState(true)
  };
  const handleDisableSubmit = async () => {
    // await authApi.otpChange({otp: code})
    await dispatch(creteOptChangedisableThunk({enable: false, otp:code}))
    setOpen(false)
    setOtpState(true)
  };
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  
  const otp = useTypedSelector((state) => state.profile.otp);
  useEffect(() => {
    if (data) {
      setOpen(true);
      dispatch(
        setChangePhone({
          loading: false,
          response: null,
          error: null,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setOpenError(true);
      dispatch(
        setChangePhone({
          loading: false,
          response: null,
          error: null,
        })
      );
    }
  }, [error]);
//   useEffect(() => {
//     if (otp) {
//       dispatch(creteOptChangeThunk({enable: false, otp:code}))
//     }
// }, [otp]);
  return (
    <StyledDialog  open={open} onClose={setOpen.bind(null, false)}>
      <StyledDialogTitle>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <TwoFAWrap >
          <TwoFATitle>Setup Two-Factor Authentication (2FA)</TwoFATitle>
            <TwoFADescription >
              Secure your account and enable Two-Factor Authentication <br/>
              when withdrawing funds to keep them as safe as possible.
            </TwoFADescription>
          </TwoFAWrap>
         
          <IconButton onClick={setOpen.bind(null, false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <StyledDialogContent>
        <div style={{display: 'flex'}}>
          <StyledStepsWrapper >
          <div style={{marginBottom: 12, display: "flex", alignItems: "center", gap: "10px"}}>
            <img src="/images/2fcFirst.svg" alt="" />
            <StepDescription>Install Google Authenticator on IOS or Android</StepDescription>
          </div>
          <div style={{marginBottom: 30, display: "flex", alignItems: "center", gap: "10px"}}>
            <img src="/images/2fcFirst.svg" alt="" />
            <StepDescription>Scan code with an authenticator app, or enter code manually</StepDescription>
          </div>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: 30, marginBottom: 40}}>
          <QRCodeCanvas size={57} value={qrAddress} />
          <div style={{display: "flex", flexDirection: "column"}}>
          <StepDescription>Install Google Authenticator on IOS or Android</StepDescription>
          <StyledInputCode
          style={{marginTop: 12}}
                value={qrSecret}
                disabled
                defaultValue={qrSecret}
              />
          </div>
          </div>
          <StepDescription>  Enter the 6-digit code presented in your authenticator app</StepDescription>        
          <ReactCodeInput inputStyle={{
            borderBottom: "2px solid #D1D1D1",
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
            margin: "4px",
            width: "36px",
            outline: "none",
            height: "42px",
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "27px",
            textAlign: "center",
            color: "#000000"
    }} name={'vdvd'} type="number" inputMode={'tel'} fields={6} onChange={(value) => setCode(value)} />
        </StyledStepsWrapper>

        </div>
       
        {
           verification  && verification.otp_required ?   <StyledGoogleAuthButton onClick={handleDisableSubmit} >
           Disable 2FA
         </StyledGoogleAuthButton> : <StyledGoogleAuthButton onClick={handleSubmit} >
         Enable 2FA
         </StyledGoogleAuthButton>
        }
      
      </StyledDialogContent>

      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={setOpenSuccess.bind(null, false)}
      >
        <Alert
          onClose={setOpenSuccess.bind(null, false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Data sent successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={setOpenError.bind(null, false)}
      >
        <Alert
          onClose={setOpenError.bind(null, false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error?.detail || "An error has occurred"}
        </Alert>
      </Snackbar>
    </StyledDialog>
  );
};