import styled from "@emotion/styled"

import { ExchangeTypes } from "../index"
import { ExchangeTabButton } from "./ExchangeTabButton"

interface ExchangeTabHeadProps {
    selectType: ExchangeTypes
    onSelectType: (value: ExchangeTypes) => void
}

export const ExchangeTabHead = ({ selectType, onSelectType }: ExchangeTabHeadProps) => {
    const handleSelectType = (value: ExchangeTypes) => () => {
        onSelectType(value)
    }

    return (
        <StyledExchangeTabHead.Wrapper>
            {Object.entries(ExchangeTypes).map(([key, value]) => (
                <ExchangeTabButton key={key} isActive={value === selectType} onClick={handleSelectType(value)}>{value}</ExchangeTabButton>
            ))}
        </StyledExchangeTabHead.Wrapper>
    )
}

const StyledExchangeTabHead = {
    Wrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        align-items: center;
        margin-top: 40px;
    `
}