import { instance } from './axios'
import { AxiosResponse } from 'axios';

type TokenRes = {
  token: string,
  userId: string
}

export const sumSubApi = {
  getSumSubToken: (): Promise<TokenRes> => instance.get('/api/v1/confirmation/generate_token')
}