import {
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Box,
  Typography,
} from '@mui/material';

import { authApi } from '../../api';
import { useTimer } from '../../hooks/useTimer';
import { AuthLayout } from '../../layouts/AuthLayout/AuthLayout';

interface IProps {
}

export default ({}) => {

    //Router
    const navigation = useNavigate()

    //States
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
    const {timer, startTimer, isDeadline} = useTimer()

    //Effects
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        authApi.userConfirm(code as string).then((res) => {
            setIsConfirmed(true)
            startTimer(5)
        })

    }, [])

    useEffect(() => {
        if(isDeadline) {
            navigation('/signin')
        }
    }, [isDeadline])

    return <AuthLayout>
        <Box display={'flex'} height={'100vh'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
            {
                isConfirmed ?
                    <Box textAlign={'center'}>
                        <Typography variant={'h5'}>
                            Your account is confirmed!{'\n'}
                        </Typography>
                        <Typography variant={'h5'}>
                            We will redirect to you to login page after 00: 0{timer} seconds
                        </Typography>
                    </Box>
                    :
                    <Typography variant={'h1'}>
                        Confirm Email Page
                    </Typography>
            }
        </Box>
    </AuthLayout>
}

