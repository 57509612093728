import { IThunkQuery } from '../../types/common.types';
import {
  EProfileError,
  ESaveMode,
  IMe,
  IReferral,
} from '../../types/profile.types';

export interface IState {
    email?: string
    name?: string
    loginData: {email: string, password: string}
    isLogin: boolean | null
    isProfileLoading: boolean
    me?: IMe
    error: EProfileError,
    saveMode: ESaveMode,
    forgotPassEmail: string,
    otp: boolean,
    otpWrong: boolean,
    changePassword: {
        loading: boolean,
        response: any,
        error: any,
    },
    requestChangePassword: {
        loading: boolean,
        response: any,
        error: any,
        correctEmail: boolean | null,
        sendedStatus: boolean
    },
    phone: {
        data: any,
        loading: boolean,
        error: any,
    },
    changePhone: {
        data: any,
        loading: boolean,
        error: any,
    },
    createAddress: {
        data: any,
        loading: boolean,
        error: any,
    },
    address: {
        data: any,
        loading: boolean,
        error: any,
    },
    tokensUpdated: boolean,
    referral: IThunkQuery<IReferral>
}

export const profileState: IState = {
    isLogin: null,
    loginData:{email: '', password:''},
    otp: false,
    otpWrong: false,
    isProfileLoading: false,
    forgotPassEmail: "",
    error: EProfileError.null,
    saveMode: ESaveMode.sessionStorage,
    changePassword: {
        loading: false,
        response: null,
        error: null,
    },
    requestChangePassword: {
        loading: false,
        response: null,
        error: null,
        correctEmail: null,
        sendedStatus: false
    },
    phone: {
        loading: false,
        data: null,
        error: null,
    },
    changePhone: {
        loading: false,
        data: null,
        error: null,
    },
    address: {
        loading: false,
        data: null,
        error: null,
    },
    createAddress: {
        loading: false,
        data: null,
        error: null,
    },
    tokensUpdated: false,
    referral: {
        loading: false,
        data: null,
        error: null,
    }
}
