import { FC } from 'react';

import Radio from '@mui/material/Radio';

import {
  StyledFormControlLabel,
  StyledPaymentMethod,
  StyledFee
} from '../../../../pages/Deposit/Deposit.style';

interface IPaymentItemProps {
    value: string,
    label: string,
    image: string,
    width?: string,
    height?: string
}
export const DPaymentItem: FC<IPaymentItemProps> = ({value, image, label, width, height}) => {
    return (
        <StyledPaymentMethod
            display="flex"
            justifyContent="space-between"
        >
            <StyledFormControlLabel
                value={value}
                control={<Radio />}
                label={label}
            />
            <StyledFee>(0.25% fee)</StyledFee>
            <img style={{height: height, width: width}} src={`/images/payments/${image}`} alt={label} />
        </StyledPaymentMethod>
    )
}