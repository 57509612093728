import {
  forwardRef,
  useEffect,
  useState,
} from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { setCreateAddress } from 'store/profile/profile.slice';
import {
  createAddressThunk,
  getMeThunk,
} from 'store/profile/profile.thunk';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  DialogContent,
  IconButton,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { getAddressThunk } from '../../../store/profile/profile.thunk';
import {
  StyledCodesWrapper,
  StyledConfirmButton,
  StyledDialog,
  StyledDialogTitle,
  StyledEmailLabel,
  StyledEmailTitle,
  StyledInput,
  StyledInputCode,
  StyledPhoneWrapper,
} from '../Settings.style';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export const ChangeAddress = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const dispatch = useAppDispatch();

  const [streetAndNumber, setStreetAndNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const {
    loading,
    data,
    error
  } = useTypedSelector((state) => state.profile.address);
  const isProfileLoading = useTypedSelector(state => state.profile.isProfileLoading)
  const verification = useTypedSelector((state) => state.profile.me?.verification);
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault()
    await dispatch(createAddressThunk({
        address: streetAndNumber,
        zip: zipCode,
        city,
        state,
        country,
    })).then(res => {
        setOpenSuccess(true)
    })
    setOpen(false)
  };


  useEffect(() => {
    if (!data) {
      dispatch(getAddressThunk());
    }

    if (!verification) {
      dispatch(getMeThunk());
    }
  }, [data])

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (data) {
      dispatch(
        setCreateAddress({
          loading: false,
          response: null,
          error: null,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setOpenError(true);
      dispatch(
        setCreateAddress({
          loading: false,
          response: null,
          error: null,
        })
      );
    }
  }, [error])
  return (
    <StyledDialog open={open} onClose={setOpen.bind(null, false)}>
      <StyledDialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span>Enter address information</span>
          <IconButton onClick={setOpen.bind(null, false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent>
        <StyledEmailTitle>Enter information</StyledEmailTitle>
        <div style={{display: 'flex'}}>
          <StyledPhoneWrapper display="flex" alignItems="center">
          <div>
            <StyledEmailLabel>
            Address (street + number)*
            </StyledEmailLabel>
            <Box display="flex" alignItems="left">
              <div>
              <StyledInput onChange={(e) => setStreetAndNumber(e.target.value)} style={{width: "200px"}}/>
              </div>
            </Box>
          </div>
        </StyledPhoneWrapper>
        <div style={{marginLeft: '34px'}}>        
            <StyledEmailLabel>Zip code*</StyledEmailLabel>
            <StyledInput onChange={(e) => setZipCode(e.target.value)} style={{width: "186px"}}
            />
          </div>
        </div>
        <div style={{display: 'flex', marginTop: "28px"}}>
          <StyledPhoneWrapper display="flex" alignItems="center">
          <div>
            <StyledEmailLabel>
            City*
            </StyledEmailLabel>
            <Box display="flex" alignItems="left">
              <div>
              <StyledInput onChange={(e) => setCity(e.target.value)} style={{width: "200px"}}/>
              </div>
            </Box>
          </div>
        </StyledPhoneWrapper>
        <div style={{marginLeft: '34px'}}>        
            <StyledEmailLabel>State*</StyledEmailLabel>
            <StyledInput onChange={(e) => setState(e.target.value)} style={{width: "186px"}}
            />
          </div>
        </div>

        <StyledCodesWrapper
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div>
            <StyledEmailLabel>
            Country*
            </StyledEmailLabel>

            <Box display="flex" alignItems="center">
              <StyledInputCode
              style={{width: "333px"}}
              onChange={(e) => setCountry(e.target.value)}
                type="text"
              />
            </Box>
          </div>
        </StyledCodesWrapper>
        <div style={{display: "flex", justifyContent: "space-between"}}>
        <StyledConfirmButton
        style={{background: "#fff"}}
          disabled={(city === "" || streetAndNumber === "" || country === "" || zipCode === "" || state === "") || isProfileLoading}
          onClick={handleSubmit}
        >
         Update information
        </StyledConfirmButton>
        <StyledEmailLabel style={{marginRight: "18px", marginTop: "8px"}}>
        *Required
            </StyledEmailLabel>
        </div>
       
      </DialogContent>

      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={setOpenSuccess.bind(null, false)}
      >
        <Alert
          onClose={setOpenSuccess.bind(null, false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Data sent successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={setOpenError.bind(null, false)}
      >
        <Alert
          onClose={setOpenError.bind(null, false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error?.detail || "An error has occurred"}
        </Alert>
      </Snackbar>
    </StyledDialog>
  );
};