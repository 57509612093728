import { IUserBase } from './common.types';

export interface IMe extends IUserBase{
    "referral": {
        "referral_code": string,
        "percent": number
    },
    volume: {
        volume_30d: number,
        current_level: number,
        next_level: number,
        level_from_volume: string,
        level_to_volume: string,
        maker_fee: string,
        taker_fee: string,
        makerFee: string,
        takerFee: string
    }
}

export interface ILogin {
    password: string
    email: string
    otp?: number | string
}

export interface ILoginRes {
    access_token: string
    refresh_token: string
    token_type: string
}


export interface IUserCreateReq extends ILogin {
    "first_name": "string",
    "last_name": "string",
    "referral_code": "string",
    "country_name": "string"
}


export interface IUserCreateRes {
    "email": "user@example.com",
    "id": 0
}

export interface ICountry {
    name: string
    code: string
    code3: string
}


//Forgot Password
export interface IForgotPasswordEmailReq {
    email: string
}

export interface IForgotPasswordPasswordsFields {
    password: string
    passwordConfirm: string
}

export enum EForgotPasswordSteps {
    inputEmail,
    submitEmail,
    inputPassword,
    submitPassword
}


//Refresh password
export interface IRefreshPasswordReq {
    code: string
    password: string
}



export enum EProfileError {
    null,
    inactiveUser,
    incorrectAuthData,
    UserIsExists,
    OtpRequired,
    WrongOtp
}

export enum ESaveMode {
    localStorage,
    sessionStorage
}

export interface IPhoneGetCode {
    "number": string,
    "code": string
}

export interface IPhoneCreate extends IPhoneGetCode {
    security_code: string
}


export interface IReferral {
    url: string,
    code: string,
    total_referrals_count: number,
    balance: number,
    all_time_balance: number
}