import {createContext, FC, ReactNode, useState} from 'react'
import {EModalId} from "../types/common.types";

interface IProps {
    children: ReactNode
}

interface IModalManager {
    modalId: EModalId | null
    setModalId: (modalId: EModalId | null) => void
}

export const ModalManagerContext = createContext<IModalManager>({
    modalId: null,
    setModalId: () => {
    }
})

export const ModalManagerProvider: FC<IProps> = ({children}) => {

    const [modalId, setModalId] = useState<EModalId | null>(null)

    const value: IModalManager = {
        modalId,
        setModalId
    }

    return <ModalManagerContext.Provider value={value}>
        {
            children
        }
    </ModalManagerContext.Provider>
}
