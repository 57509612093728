import {FC, ReactNode} from "react";
import {useDrawerManager} from "../../hooks/useDrawerManager";
import {EDrawerID} from "../../types/common.types";
import {Drawer} from "@mui/material";
import {DrawerProps} from "@mui/material/Drawer/Drawer";

interface IProps {
    drawerId: EDrawerID | null
    onClose?: () => void
    children: ReactNode
    anchor:  'left' | 'top' | 'right' | 'bottom'
    drawerProps?: DrawerProps
}

export const AppDrawer: FC<IProps> = ({drawerId, onClose, children, anchor, drawerProps}) => {

    const manager = useDrawerManager()

    const handleClose = () => {
        manager.setDrawerId(null)
        if(onClose) onClose()
    }

    return <Drawer
        {...drawerProps}
        anchor={anchor}
        open={manager.drawerId === drawerId}
        onClose={handleClose}
    >
        {
            children
        }
    </Drawer>
}