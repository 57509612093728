import { newsState } from "./news.state"
import {
  createSlice
} from "@reduxjs/toolkit"

import { getNewsThunk } from "./news.thunk"

export const newsSlice = createSlice({
  name: 'news',
  initialState: newsState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNewsThunk.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getNewsThunk.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(getNewsThunk.rejected, (state) => {
      state.loading = false
      state.error = new Error('no news')
    })
  }
})

export const newsReducer = newsSlice.reducer