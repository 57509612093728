import { setNotificationMessage } from 'store/notistack/notistack.slice';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  authApi,
  phoneApi,
  referalApi,
} from '../../api';
import {
  addressApi,
  IAddress,
} from '../../api/address.api';
import {
  IForgotPasswordEmailReq,
  ILogin,
  IPhoneCreate,
  IPhoneGetCode,
  IRefreshPasswordReq,
  IUserCreateReq,
} from '../../types/profile.types';
import {
  setAddress,
  setChangePassword,
  setChangePhone,
  setPhone,
  setReferral,
  setRequestChangePassword,
} from './profile.slice';

export const getMeThunk = createAsyncThunk('getMeThunk', async () => {
    try {
        const res: any = await authApi.me()
        return res
    } catch (e) {
        console.log(e)
    }
})


export const loginThunk = createAsyncThunk('loginThunk', async (data: ILogin, {rejectWithValue, dispatch}) => {
    try {
        const auth = await authApi.login(data)
        
        if (data.otp) {
          dispatch(setNotificationMessage({
            msg: `Code entered successfully!`,
            variant: 'success'
          }))
        }

        return auth
    } catch (e: any) {
        if (e!.response.data && e!.response.data.detail === 'Inactive user') {
          await authApi.resendConfirmation(data.email)
          return rejectWithValue('Inactive user')
        } else if (e!.response.data && e!.response.data.detail === 'Incorrect email or password') {
            return rejectWithValue('Incorrect email or password')
        }

        else if (e!.response.data && e!.response.data.detail === 'Otp required') {
          return rejectWithValue('Otp required')
        } else if (e!.response.data && e!.response.data.detail === 'Wrong otp') {
          dispatch(setNotificationMessage({
            msg: `Code incorrect, please retry`,
            variant: 'error'
          }))
          return rejectWithValue('Wrong otp')
        }
        return rejectWithValue(e)

    }
})


export const createUserThunk = createAsyncThunk('createUserThunk', async (data: IUserCreateReq, {rejectWithValue}) => {
    try {
        return await authApi.userCreate(data)
    } catch (e) {
        console.log(e)
        return rejectWithValue('User is exists')
    }
})
export const creteOptChangeThunk = createAsyncThunk('createOtpChangeThunk',async(data: {enable?: boolean, otp: string}, {dispatch}) => {
  try {
    const otpChange = await authApi.otpChange(data)

    dispatch(setNotificationMessage({
      msg: `2FA enabled successfully`,
      variant: 'success'
    }))

    return otpChange
  } catch (error) {
    dispatch(setNotificationMessage({
      msg: `2FA failed to enable`,
      variant: 'error'
    }))
  }
})
export const creteOptChangedisableThunk = createAsyncThunk('creteOptChangedisableThunk',async(data: {enable?: boolean, otp: string}, {dispatch}) => {
  try {
    const otpChange = await authApi.otpChange(data)

    dispatch(setNotificationMessage({
      msg: `2FA disabled successfully`,
      variant: 'success'
    }))

    return otpChange
  } catch (error) {
    dispatch(setNotificationMessage({
          msg: `2FA failed to disable`,
          variant: 'error'
        }))
  }
})



export const getCountriesThunk = createAsyncThunk('getCountries', () => {
        try {
            return authApi.getCountries()
        } catch (e) {
            console.log(e)
        }
    }
)


export const forgotPasswordThunk = createAsyncThunk('forgotPassword', (data: IForgotPasswordEmailReq, {dispatch, rejectWithValue}) => {
  try {
    return authApi.forgotPassword(data)
  } catch (error: any) {
    rejectWithValue(error.response.data.detail)
  }

})

export const refreshPasswordThunk = createAsyncThunk('refreshPassword', (data: IRefreshPasswordReq) => {
    try {
        return authApi.refreshPassword(data)
    } catch (e) {

    }
})

export const logoutThunk = createAsyncThunk('logoutThunk', (token: string) => {
    try {
        return authApi.logout(token)
    } catch (e) {
        console.log(e);        
    }
})

export const requestChangePasswordThunk = () => async (dispatch: any) => {
  try {
    dispatch(
        setRequestChangePassword({
        loading: true,
        error: null,
        response: null,
      })
    );

    const response = await authApi.requestChangePassword();

    dispatch(
        setRequestChangePassword({
        loading: false,
        error: null,
        response,
      })
    );

    dispatch(setNotificationMessage({
      msg: `Password changed successfully`,
      variant: 'success'
    }))
  } catch (error) {
    console.log(error);
    dispatch(
        setRequestChangePassword({
        loading: false,
        error,
        response: null,
      })
    );

    dispatch(setNotificationMessage({
      msg: `Failed to change password`,
      variant: 'error'
    }))
  }
};

export const changePasswordThunk = (data: any) => async (dispatch: any) => {
  try {
    dispatch(
      setChangePassword({
        loading: true,
        error: null,
        response: null,
      })
    );

    const response = await authApi.changePassword(data);

    dispatch(
      setChangePassword({
        loading: false,
        error: null,
        response,
      })
    );

    dispatch(setNotificationMessage({
      msg: `Password changed successfully`,
      variant: 'success'
    }))
  } catch (error) {
    console.log(error);
    dispatch(
      setChangePassword({
        loading: false,
        error,
        response: null,
      })
    );

    dispatch(setNotificationMessage({
      msg: `Failed to change password`,
      variant: 'error'
    }))
  }
};

export const getPhoneThunk = () => async (dispatch: any) => {
  try {
    dispatch(
      setPhone({
        loading: true,
        error: null,
        data: null,
      })
    );

    const data = await authApi.getPhone();

    dispatch(
      setPhone({
        loading: false,
        error: null,
        data,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setPhone({
        loading: false,
        error,
        data: null,
      })
    );
  }
};

export const changePhoneThunk = (data: any) => async (dispatch: any) => {
  try {
    dispatch(
      setChangePhone({
        loading: true,
        error: null,
        data: null,
      })
    );

    const response = await authApi.changePhone(data);

    dispatch(
      setChangePhone({
        loading: false,
        error: null,
        data: response,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setChangePhone({
        loading: false,
        error,
        data: null,
      })
    );
  }
};

export const getCodeThunk = createAsyncThunk('getCodeThunk', async (data: IPhoneGetCode) => {
    try {
        return phoneApi.getCode(data)
    } catch (e) {
        console.log(e)
    }
})

export const createPhoneThunk = createAsyncThunk('createPhone', async (data: IPhoneCreate, {dispatch}) => {
    try {
        return phoneApi.createPhone(data).then(() => {
            dispatch(getMeThunk())
            dispatch(setNotificationMessage({
              msg: `Phone number verified successfully`,
              variant: 'success'
            }))
        })
    } catch (e) {
      dispatch(setNotificationMessage({
        msg: `Failed to verify phone number`,
        variant: 'error'
      }))
    }
})


export const createAddressThunk = createAsyncThunk('createAddress', async (data: IAddress, {dispatch}) => {
  try {
      return addressApi.createAddress(data).then(() => {
          dispatch(getMeThunk())
      })
  } catch (e) {

  }
})

export const getAddressThunk = () => async (dispatch: any) => {
  try {
    dispatch(
      setAddress({
        loading: true,
        error: null,
        data: null,
      })
    );

    const data = await addressApi.getAddress()

    dispatch(
      setAddress({
        loading: false,
        error: null,
        data,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setAddress({
        loading: false,
        error,
        data: null,
      })
    );
  }
}
export const setReferralThunk = () => async (dispatch: any) => {
    try {
        dispatch(
            setReferral({
                loading: true,
                error: null,
                data: null,
            })
        );

        const response = await referalApi.getRef();

        dispatch(
            setReferral({
                loading: false,
                error: null,
                data: response,
            })
        );
    } catch (error) {
        console.log(error);
        dispatch(
            setReferral({
                loading: false,
                error,
                data: null,
            })
        );
    }
};