import React, { FC } from 'react';

interface IProps {
  coinName: string;
  style?: React.CSSProperties;
}

export const CoinIcon: FC<IProps> = ({ coinName, style }) => {
  const defaultStyle = { marginRight: "10px", width: 30, height: 30, ...style };
  const defaultSrc = `/images/coin.png`;

  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = defaultSrc;
  };

  if (!coinName) {
    return (
      <img
        src={defaultSrc}
        style={defaultStyle}
        alt="coin"
      />
    );
  }

  const src = `https://www.bybit.com/bycsi-root/assets/image/coins/dark/${coinName.toLowerCase().trim()}.svg`;

  return (
    <img
      src={src}
      onError={handleError}
      style={defaultStyle}
      alt={coinName}
    />
  );
};
