import {
  FC,
  SetStateAction,
  Dispatch
} from 'react'
import styles from '../auth.module.scss'
import { Link } from 'react-router-dom'

interface ISignUpSuccess {
  setStatus: Dispatch<SetStateAction<string>>
  email: string
}

const SignUpSuccess: FC<ISignUpSuccess> = ({setStatus, email}) => {
  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img src="/images/auth/letter.svg" width={128} height={128} alt='E-mail'/>
      </div>
      <h1 className={styles["auth-title"]} style={{
        fontSize: '26px'
      }}>Waiting for email verification</h1>
      <p className={styles["auth-info"]}>We have emailed you with a link to verify your email address.</p>
      <p className={styles["auth-info"]}>
        <b>{email}</b>
      </p>
      <p className={styles["auth-info"]}>Please go to your email inbox, verify your email address, and follow the instructions.</p>
      <span className={styles['auth-note']}>
        <Link to={'/signin'}>Log in</Link>
      </span>
    </>
  )
}

export default SignUpSuccess