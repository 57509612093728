import { ComponentProps } from "react"
import styled from "@emotion/styled"

interface ExchangeTabButtonProps extends ComponentProps<'button'> {
    isActive: boolean
}

export const ExchangeTabButton = ({ children, isActive, ...props }: ExchangeTabButtonProps) => {
    return (
        <StyledExchangeTabButton.Button data-active={isActive} {...props}>
            {children}
        </StyledExchangeTabButton.Button>
    )
}

const StyledExchangeTabButton = {
    Button: styled.button<{ 'data-active': boolean }>`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 35px;
        border-radius: 17.5px;
        transition: border-color 0.33s ease, background-color 0.33s ease;
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
        line-height: 21px;
        color: #FFF;
        background-color: transparent;
        border: 1px solid transparent;
        cursor: pointer;

        &:hover {
            border: 1px solid #FFF; 
        }

        &[data-active="true"] {
            background-color: #001645;
            border: 1px solid #00B7F1;
            color: #00B7F1;
        }
    `
}