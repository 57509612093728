import {IConfirmationGet} from "../../types/confirmation.types";

interface IState {
    isDashboardLoading: boolean
    confirmationInfo?: IConfirmationGet
    isConfirmedUser?: boolean
    confirmation: {
        loading: boolean,
        success: boolean | null,
        response: null,
        error: {
            detail: string
        } | null
    }
}

export const dashboardState: IState = {
    isDashboardLoading: false,
    confirmation: {
        loading: false,
        success: null,
        response: null,
        error: null
    }
}
