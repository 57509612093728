import './dashboardStyles.scss'

import { FC, useEffect, useMemo, useState } from 'react'

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { StyledFieldWrapper } from 'pages/Wallets/components/CommonComponents.styles'
import { StyledSearchIcon, StyledTextField } from 'pages/Wallets/Wallets.styles'

import { Doughnut } from 'react-chartjs-2'
import { Link, useNavigate } from 'react-router-dom'
import {
	addPairChangingCounter,
	setSelectedPair,
	setSelectedPairId,
} from 'store/exchange/exchange.slice'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
	Box,
	Button,
	InputAdornment,
	Skeleton,
	Typography,
} from '@mui/material'

import { CoinIcon } from 'components/UI/atoms/CoinIcon/CoinIcon'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { IByBitWalletBalanceCoin } from 'types/exchange.types'

ChartJS.register(ArcElement, Tooltip, Legend)

const OverviewBlock: FC = (): JSX.Element => {
	const { me } = useTypedSelector(state => state.profile)
	const navigate = useNavigate()
	const byBitWalletBalance = useTypedSelector(
		state => state.wallets.byBitWalletBalance
	)
	const { currencies } = useTypedSelector(state => state.exchange)
	const [search, setSearch] = useState<string>('')

	const dispatch = useAppDispatch()
	const router = useNavigate()
	const [chartConfig, setChartConfig] = useState<any>({
		datasets: [
			{
				data: [0, 0, 0, 0],
				backgroundColor: [
					'#0157FF',
					'#95B8FD',
					'#00B7F1',
					'#011031',
					'#DADADA',
				],
				borderWidth: 0,
			},
		],
	})

	const total = useMemo(() => {
		if (!byBitWalletBalance.data || !byBitWalletBalance.data.list.length)
			return null
		return (byBitWalletBalance.data as any).list[0].totalEquity || 0
	}, [byBitWalletBalance.data])

	const change24 = useMemo(() => {
		if (!byBitWalletBalance.data) return null
		return byBitWalletBalance.data.list.reduce(
			(total, item) => total + Number(item.totalWalletBalanceChanges),
			0
		)
	}, [byBitWalletBalance.data])

	const computedBalances = useMemo(() => {
		const coins: IByBitWalletBalanceCoin[] =
			byBitWalletBalance.data?.list.reduce(
				(prev: any, item) => (item.coin ? [...prev, ...item.coin] : prev),
				[]
			) || []
		const result = coins.filter(
			(item: any) =>
				!search || item.coin.toLowerCase().includes(search.toLowerCase())
		) as IByBitWalletBalanceCoin[]
		return result.map(item => ({
			...item,
			coin: item,
			coinName: item.coin,
			quoteCoin: item.coin,
		}))
	}, [byBitWalletBalance, search])

	// const computedBalances = useMemo(() => {
	//   if(!currencies) return []

	//   const coins: IByBitWalletBalanceCoin[] =
	//     byBitWalletBalance.data?.list.reduce(
	//       (prev: any, item) => (item.coin ? [...prev, ...item.coin] : prev),
	//       []
	//     ) || [];
	//   // const filteredCurrencies = currencies.result.list.filter(
	//   //   (item) =>
	//   //     !search || item.baseCoin.toLowerCase().includes(search.toLowerCase())
	//   // )

	//   const uniqueCurrencies = currencies.result.list.reduce((acc, curr) => {
	//     const baseCoinName = curr.baseCoin;
	//     const quoteCoinName = curr.quoteCoin
	//     if (!acc[baseCoinName]) {
	//       acc[baseCoinName] = {
	//         ...curr,
	//         coinName: baseCoinName,
	//       };
	//     }
	//     if(!acc[quoteCoinName]) {
	//       acc[quoteCoinName] = {
	//         ...curr,
	//         coinName: quoteCoinName
	//       };
	//     }
	//     return acc;
	// }, {} as Record<string, LinearPerpetual & { coinName: string }>);

	//   const result = Object.values(uniqueCurrencies).map((item) => {
	//     const foundCoin = coins.find(
	//       (coin) => coin.coin === item.coinName
	//     );
	//     return {
	//       ...item,
	//       coin: foundCoin ? foundCoin : null,
	//     };
	//   })

	//   return result.filter((item) => {
	//     return !search || item.coinName.toLowerCase().includes(search.toLowerCase())
	//   })
	// }, [byBitWalletBalance, currencies, search]);

	const setSelectedPairForTrade = (pair: string) => {
		const pairBody = currencies?.result.list.find(
			currency => currency.baseCoin === pair && currency.quoteCoin === 'USDT'
		)
		if (pairBody) {
			dispatch(setSelectedPairId(Number(pairBody.symbol)))
			dispatch(setSelectedPair(pairBody))
			dispatch(addPairChangingCounter())
			router(`/exchange?pair=${pairBody.baseCoin}/${pairBody.quoteCoin}`)
		}
	}

	console.log(byBitWalletBalance.data)

	useEffect(() => {
		if (byBitWalletBalance.data) {
			setChartConfig({
				labels: [
					byBitWalletBalance.data.list[0].coin &&
					byBitWalletBalance.data.list[0].coin[0]
						? byBitWalletBalance.data.list[0].coin[0].coin
						: 0,
					byBitWalletBalance.data.list[0].coin &&
					byBitWalletBalance.data.list[0].coin[1]
						? byBitWalletBalance.data.list[0].coin[1].coin
						: 0,
					byBitWalletBalance.data.list[0].coin &&
					byBitWalletBalance.data.list[0].coin[2]
						? byBitWalletBalance.data.list[0].coin[2].coin
						: 0,
					byBitWalletBalance.data.list[0].coin &&
					byBitWalletBalance.data.list[0].coin[3]
						? byBitWalletBalance.data.list[0].coin[3].coin
						: 0,
					byBitWalletBalance.data.list[0].coin &&
					byBitWalletBalance.data.list[0].coin[4]
						? byBitWalletBalance.data.list[0].coin[4].coin
						: 0,
				],
				datasets: [
					{
						data: [
							byBitWalletBalance.data.list[0].coin &&
							byBitWalletBalance.data.list[0].coin[0]
								? byBitWalletBalance.data.list[0].coin[0].usdValue
								: 0,
							byBitWalletBalance.data.list[0].coin &&
							byBitWalletBalance.data.list[0].coin[1]
								? byBitWalletBalance.data.list[0].coin[1].usdValue
								: 0,
							byBitWalletBalance.data.list[0].coin &&
							byBitWalletBalance.data.list[0].coin[2]
								? byBitWalletBalance.data.list[0].coin[2].usdValue
								: 0,
							byBitWalletBalance.data.list[0].coin &&
							byBitWalletBalance.data.list[0].coin[3]
								? byBitWalletBalance.data.list[0].coin[3].usdValue
								: 0,
							byBitWalletBalance.data.list[0].coin &&
							byBitWalletBalance.data.list[0].coin[4]
								? byBitWalletBalance.data.list[0].coin[4].usdValue
								: 0,
						],
						backgroundColor: [
							'#0d0901',
							'#0157ff',
							'#00b7f1',
							'#95b8fd',
							'#dadada',
						],
						borderColor: [
							'#0d0901',
							'#0157ff',
							'#00b7f1',
							'#95b8fd',
							'#dadada',
						],
						borderWidth: 1,
					},
				],
			})
		}
	}, [byBitWalletBalance])

	// const getFormattedPrice = (price: string | number) => {
	//   const fmtPrice = String(price).slice(0, 8);
	//   return Number(fmtPrice);
	// };

	return (
		<div className='background mb20 paddingBig overviewBlock welcome'>
			<Box display={'flex'} justifyContent={'left'} alignItems={'center'} className={"profile-deposit-box"}>
				<div className='overview-block__header__name'>
					<span className={'web21'}>Welcome, </span>
					{me && me?.user?.first_name ? (
						<span className={'web21'} style={{ color: '#00B7F1' }}>
							{me && me?.user?.first_name}
						</span>
					) : (
						<Skeleton />
					)}
				</div>
				<Button
					size={'small'}
					className='no-hover mobile-deposit-btn'
					onClick={() => navigate('/dashboard/deposit')}
					sx={{
						color: '#00B7F1',
						minWidth: 100,
						height: 'fit-content',
						ml: 53 / 8,
						border: '1.5px solid #00B7F1',
						fontWeight: 400,
					}}
				>
					<span className={'web10-2 default-case'}>Deposit</span>
				</Button>
			</Box>
			<div className='subtitleBlock'>
				<Box display={'flex'} alignItems={'center'}>
					<div>
						<Typography variant={'h5'} color={'#80848E'}>
							Total balance
						</Typography>
						{byBitWalletBalance.data && total !== null ? (
							<p>
								<span className={'web25'}>
									{byBitWalletBalance.data
										? Number(total).toLocaleString('en-US', {
												style: 'currency',
												currency: 'USD',
										  })
										: (0.0).toLocaleString('en-US', {
												style: 'currency',
												currency: 'USD',
										  })}
								</span>
							</p>
						) : (
							<Skeleton />
						)}
					</div>
					<Box ml={9}>
						<Typography variant={'h5'} color={'#80848E'}>
							24h change
						</Typography>
						<p
							style={{
								color: change24 === 0 ? 'gray' : '',
							}}
							className={`${
								change24 && change24 > 0
									? 'subtitleBlock_percentage'
									: 'subtitleBlock_negative'
							}`}
						>
							{byBitWalletBalance.data && change24 !== null ? (
								<>
									<span className={'web25'}>
										{byBitWalletBalance.data && change24 >= 0 ? '+' : ''}
										{byBitWalletBalance.data ? change24.toFixed(2) : 0}
									</span>
									<span className={'web25-l'}>%</span>
								</>
							) : (
								<Skeleton />
							)}
						</p>
					</Box>
				</Box>

				<div className='doughnut'>
					<Doughnut
						data={chartConfig}
						width={130}
						height={130}
						options={{
							cutout: '80%',
							responsive: true,
							maintainAspectRatio: true,
							plugins: {
								legend: {
									display: false,
								},
							},
						}}
					/>
				</div>
			</div>
			<Link to='/markets'>
				<Typography
					variant={'h6'}
					color={'black'}
					sx={{ mb: 3, display: 'inline-flex', alignItems: 'center' }}
				>
					Markets
					<ChevronRightIcon
						sx={{ width: 17, height: 20, marginLeft: 0.5, color: '#00B7F1' }}
					/>
				</Typography>
			</Link>
			<StyledFieldWrapper>
				<StyledTextField
					id='input-with-icon-textfield'
					style={{ marginTop: 10 }}
					onChange={e => setSearch(e.target.value)}
					startAdornment={
						<InputAdornment position='start'>
							<StyledSearchIcon />
						</InputAdornment>
					}
					placeholder='Search for coin'
				/>
			</StyledFieldWrapper>

			<div className='tableBlock'>
				<div>
					<div className='tableBlock_grid'>
						<p className='tableBlock_th'>Coin</p>
						<p className='tableBlock_th'>In portfolio</p>
						<p className='tableBlock_th'>Amount</p>
						<p className='tableBlock_th'>Transferable Amount</p>
						<p className='tableBlock_th textAlignRight'>24h change</p>
					</div>
					{computedBalances ? (
						computedBalances.map((item, itemIdx) => (
							<div key={itemIdx} className='tableBlock_grid'>
								<div className='tableBlock_td d-inline-flex align-center'>
									<div className={'crypto d-inline-flex align-center'}>
										<CoinIcon coinName={item.coinName.toLowerCase()} />
										<span
											className={
												itemIdx < 5
													? `crypto__name crypto__name-priority-${itemIdx}`
													: ''
											}
										>
											{item.coinName}
										</span>
									</div>
								</div>
								<p className='tableBlock_td'>
									{Number(item.coin?.usdValue ?? 0).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</p>
								<p className='tableBlock_td'>{item.coin?.equity ?? 0}</p>
								<p className='tableBlock_td'>
									{item.coin?.availableToWithdraw}
								</p>
								<p
									className={`tableBlock_td textAlignRight ${
										item.coin?.usdValueChanges == null ||
										Number(item.coin?.usdValueChanges) <= 0
											? 'red'
											: 'green'
									}`}
								>
									{+(item.coin?.usdValueChanges ?? 0) >= 0 ? '+' : null}
									{item.coin?.usdValueChanges
										? `${(+(item.coin?.usdValueChanges ?? 0)).toFixed(2)}%`
										: '0%'}
								</p>
								<div>
									<Button
										className={"trade_button_overview"}
										variant={'text'}
										sx={{
											opacity:
												item.coinName.toLowerCase() === 'usdt' ||
												item.quoteCoin.toLowerCase() === 'usdt'
													? '0'
													: 'none',
										}}
										disabled={
											item.coinName.toLowerCase() === 'usdt' ||
											item.quoteCoin.toLowerCase() === 'usdt'
										}
										onClick={() => setSelectedPairForTrade(item.coinName)}
									>
										<Typography
											variant={'caption'}
											sx={{
												textTransform: 'initial !important',
												color: '#00B7F1',
											}}
										>
											Trade
										</Typography>
									</Button>
								</div>
							</div>
						))
					) : (
						<div>loading</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default OverviewBlock
