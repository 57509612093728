// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import styled from "@emotion/styled"

import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon"
import { ComponentProps } from 'react';

interface ExchangeFormButton extends ComponentProps<'div'> {
    coinName: string
    hasArrow?: boolean
    coinGap?: number
}

export const ExchangeFormButton = ({ coinName, hasArrow, coinGap, ...props }: ExchangeFormButton) => {
    return (
        <StyledExchangeFormButton.Wrapper {...props}>
            <StyledExchangeFormButton.Left style={{ gap: coinGap }}>
                <CoinIcon style={{ width: "25px", height: "25px" }} coinName={coinName} />
                {coinName}
            </StyledExchangeFormButton.Left>
            {hasArrow && <PlayArrowIcon style={{ transform: "rotate(90deg)", color: "#FFF" }} />}
        </StyledExchangeFormButton.Wrapper>
    )
}

const StyledExchangeFormButton = {
    Wrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    `,
    Left: styled.div`
        display: flex;
        align-items: center;
        gap: 19px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #E7E7E7;
    `,
}