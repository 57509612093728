import { useEffect, useState } from "react";
import {
  ByBitWithdrawsHistoryArgs,
  ByBitWithdrawsHistoryBody,
  byBitApi,
} from "api";

interface Props {
  render: (args: {
    withdrawsHistory: ByBitWithdrawsHistoryBody | null;
    loading: boolean;
    error: string;
    onRequest: (args: ByBitWithdrawsHistoryArgs) => void;
  }) => React.ReactNode;
}

export const WithdrawsHistoryContainer = ({ render }: Props) => {
  const [withdrawsHistory, setWithdrawsHistory] =
    useState<ByBitWithdrawsHistoryBody | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleGetWithdrawsHistories = async (
    args: ByBitWithdrawsHistoryArgs = {}
  ) => {
    try {
      setLoading(true);
      const res = await byBitApi.getWithdrawsHistory(args);
      setWithdrawsHistory(res);
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetWithdrawsHistories();
  }, []);

  return (
    <>
      {render({
        withdrawsHistory,
        loading,
        error,
        onRequest: handleGetWithdrawsHistories,
      })}
    </>
  );
};
