import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import moment from "moment";


interface IUseTimer {
    timer: number,
    startTimer: (seconds: number) => void
    isDeadline: boolean
}

export const useTimer = (): IUseTimer => {

    const [touched, setTouched] = useState<boolean>(false)
    const [timer, setTimer] = useState<number>(0);
    const intervalRef = useRef<any>();

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setTimer((t) => t as number - 1);
        }, 1000);

        return () => clearInterval(intervalRef.current);
    }, [intervalRef.current, timer]);

    useEffect(() => {
        if (timer as number <= 0) {
            clearInterval(intervalRef.current);
        }
    }, [timer, intervalRef.current]);


    const startTimer = (seconds: number) => {
        setTouched(true)
        setTimer(seconds)
    }

    const isDeadline = useMemo(() => {
        return timer <= 0 && touched
    }, [timer, touched])


    return {
        timer: timer as number,
        startTimer,
        isDeadline
    }

}
