import {
  ByBitDepositsHistoryArgs,
  ByBitDepositsHistoryBody,
  ByBitWithdrawsHistoryArgs,
  ByBitWithdrawsHistoryBody,
  byBitApi,
} from "api";
import { useEffect, useState } from "react";

export interface Props {
  render: (args: {
    data: {
      withdraws: ByBitWithdrawsHistoryBody | undefined;
      deposits: ByBitDepositsHistoryBody | undefined;
    } | null;
    loading: boolean;
    error: string;
    onRequest: (args: {
      deposits: ByBitDepositsHistoryArgs;
      withdraws: ByBitWithdrawsHistoryArgs;
    }) => void;
  }) => React.ReactNode;
}

export const WithdrawsAndDepositsHistoryContainer = ({ render }: Props) => {
  const [data, setData] = useState<{
    withdraws: ByBitWithdrawsHistoryBody | undefined;
    deposits: ByBitDepositsHistoryBody | undefined;
  }>({ deposits: undefined, withdraws: undefined, });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleGetWithdrawsAndDeposits = async ({
    deposits,
    withdraws,
  }: {
    deposits?: ByBitDepositsHistoryArgs;
    withdraws?: ByBitWithdrawsHistoryArgs;
  } = {}) => {
    try {
      setLoading(true);
      const withdrawsRes = await byBitApi.getWithdrawsHistory(withdraws);
      setData(prev => ({ ...prev, withdraws: withdrawsRes, }));
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }

    try {
      setLoading(true);
      const depositsRes = await byBitApi.getDepositsHistory(deposits);
      setData(prev => ({ ...prev, deposits: depositsRes }));
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetWithdrawsAndDeposits();
  }, []);

  return (
    <>
      {render({
        data,
        loading,
        error,
        onRequest: handleGetWithdrawsAndDeposits,
      })}
    </>
  );
};
