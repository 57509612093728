import { useEffect, useMemo, useState } from "react";
import { Box, styled, Typography, CircularProgress } from "@mui/material";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useByBit } from "providers/ByBitProvider";
import {
  StyledLoadingBook,
  StyledOrderBookHeader,
  StyledOrderBookWrapper,
  StyledOrderBookRow,
  StyledOrderBookPrice,
  StyledOrderBookAmount,
  StyledOrderBookTotal,
  StyledOrderBookProgress,
  StyledOrderBookMarketPrice,
} from "./ExchangeBook.styles";
import { removeTrailingZeros } from "helpers/removeTrailingZeros";

export const ExchangeBook = () => {
  const { orderbook, ticker } = useByBit();
  const { selectedPair } = useTypedSelector((state) => state.exchange);
  const [sell, setSell] = useState<number[][]>([]);
  const [buy, setBuy] = useState<number[][]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const allTotalSell = useMemo(
    () => sell.reduce((total, item) => total + +item[1] * +item[0], 0),
    [sell]
  );
  const allTotalBuy = useMemo(
    () => buy.reduce((total, item) => total + +item[1] * +item[0], 0),
    [buy]
  );

  useEffect(() => {
    const data = orderbook as any;
    if(!data || !!isUpdating) return;
    let timeout: NodeJS.Timeout | null = null;

    if (data.type === "snapshot") {
      setSell(data.data.a.filter((item: any) => +item[1] !== 0));
      setBuy(data.data.b.filter((item: any) => +item[1] !== 0));
    } else if (data.type === "delta") {
      setIsUpdating(true);
      timeout = setTimeout(() => {
        setSell((prev) => [...prev, ...data.data.a].slice(-20).filter((item: any) => +item[1] !== 0));
        setBuy((prev) => [...prev, ...data.data.b].slice(-20).filter((item: any) => +item[1] !== 0));
        setIsUpdating(false);
      }, 500);
    }
  }, [orderbook, isUpdating]);

  return (
    <StyledBook
      position={"relative"}
      component={"div"}
      width={"100%"}
      height={"100%"}
    >
      <Typography variant={"body2"} style={{ marginBottom: 14 }}>
        Book
      </Typography>
      <Box
        minHeight="400px"
        height="calc(100vh - 140px)"
        display="grid"
        gridTemplateRows="auto 1fr auto 1fr"
      >
        <StyledOrderBookHeader>
          <span>Price</span>
          <span>Amount</span>
          <span>Total</span>
        </StyledOrderBookHeader>
        {!orderbook ? (
          <StyledLoadingBook>
            <CircularProgress />
          </StyledLoadingBook>
        ) : (
          <>
            <StyledOrderBookWrapper>
              {sell.map((sellOrder, index) => (
                <StyledOrderBookRow key={index}>
                  <StyledOrderBookPrice
                    style={{ color: "rgba(216, 50, 50, 1)" }}
                  >
                    {removeTrailingZeros(String(sellOrder[0] && Number(sellOrder[0]).toFixed(5)))}
                  </StyledOrderBookPrice>
                  <StyledOrderBookAmount>
                    {removeTrailingZeros(String(sellOrder[1] && Number(sellOrder[1]).toFixed(5)))}
                  </StyledOrderBookAmount>
                  <StyledOrderBookTotal>
                    {sellOrder[0] &&
                      sellOrder[1] &&
                      removeTrailingZeros(String((Number(sellOrder[0]) * Number(sellOrder[1])).toFixed(2)))}
                  </StyledOrderBookTotal>
                  <StyledOrderBookProgress
                    style={{
                      background: "rgba(216, 50, 50, 0.15)",
                      width:
                        sellOrder[0] && sellOrder[1]
                          ? `${
                              ((Number(sellOrder[0]) * Number(sellOrder[1])) /
                                allTotalSell) *
                              100
                            }%`
                          : 0,
                    }}
                  />
                </StyledOrderBookRow>
              ))}
            </StyledOrderBookWrapper>
            <StyledOrderBookMarketPrice>
              <span>
                {ticker?.data?.lastPrice} {selectedPair?.quoteCoin}
              </span>
            </StyledOrderBookMarketPrice>
            <StyledOrderBookWrapper>
              {buy.map((buyOrder, index) => (
                <StyledOrderBookRow key={index}>
                  <StyledOrderBookPrice
                    style={{ color: "rgba(65, 158, 106, 1)" }}
                  >
                    {buyOrder[0] && removeTrailingZeros(String(Number(buyOrder[0]).toFixed(5)))}
                  </StyledOrderBookPrice>
                  <StyledOrderBookAmount>
                    {buyOrder[1] && removeTrailingZeros(String(Number(buyOrder[1]).toFixed(5)))}
                  </StyledOrderBookAmount>
                  <StyledOrderBookTotal>
                    {buyOrder[0] &&
                      buyOrder[1] &&
                      removeTrailingZeros(String((Number(buyOrder[0]) * Number(buyOrder[1])).toFixed(2)))}
                  </StyledOrderBookTotal>
                  <StyledOrderBookProgress
                    style={{
                      background: "rgba(65, 158, 106, 0.15)",
                      width:
                        buyOrder[0] && buyOrder[1]
                          ? `${
                              ((Number(buyOrder[0]) * Number(buyOrder[1])) /
                                allTotalBuy) *
                              100
                            }%`
                          : 0,
                    }}
                  />
                </StyledOrderBookRow>
              ))}
            </StyledOrderBookWrapper>
          </>
        )}
      </Box>
    </StyledBook>
  );
};

const StyledBook = styled(Box)`
  background: #181b25;
  padding: 11px 14px;
  top: 0;
  bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

