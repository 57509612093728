import { useNavigate } from "react-router-dom"
import { Button } from "@mui/material"
import { format } from 'date-fns'
import styled from "@emotion/styled"

import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon"
import { StyledDialog } from "pages/Wallets/components/CommonComponents.styles"

interface ExchangeSuccessResultModalProps {
    isOpen: boolean
    info?: {
        time: number
        payCoin: string
        payAmount: string
        receiveCoin: string
        receiveAmount: string
    }
    onClose: () => void
}

export const ExchangeSuccessResultModal = ({ isOpen, info, onClose }: ExchangeSuccessResultModalProps) => {
    const navigate = useNavigate()

    return (
        <StyledDialog
          sx={{ overflow: "hidden", 
            "& .MuiPaper-root": {
              width: "486px", 
              height: "384px",
              background: "#FFFFFF !important",
              borderRadius: "10px",
              color: "#000000",
              padding: "34px 24px 34px 24px"
            },
           }}
          open={isOpen}
          onClose={onClose}
        >
            <StyledExchangeSuccessResultModal.Wrapper>
                <StyledExchangeSuccessResultModal.Head>
                    <StyledExchangeSuccessResultModal.Title>Conversion successful</StyledExchangeSuccessResultModal.Title>
                    <StyledExchangeSuccessResultModal.Subtitle>
                        <span>Buy</span>
                        <span>{format(info?.time??0, 'dd-MM-yyyy hh:mm')}</span>
                    </StyledExchangeSuccessResultModal.Subtitle>
                    <StyledExchangeSuccessResultModal.Content>
                        <ExchangeSuccessResultModalCoin title="You pay" coin={info?.payCoin} amount={(info?.payAmount.length??0) > 10 ? `${info?.payAmount.slice(0, 10)}...` : info?.payAmount} />
                        <ExchangeSuccessResultModalCoin title="You receive" coin={info?.receiveCoin} amount={(info?.receiveAmount.length??0) > 10 ? `${info?.receiveAmount.slice(0, 10)}...` : info?.receiveAmount} />
                    </StyledExchangeSuccessResultModal.Content>
                </StyledExchangeSuccessResultModal.Head>
                <StyledExchangeSuccessResultModal.Foot>
                    <StyledExchangeSuccessResultModal.Cancel onClick={() => navigate("/dashboard/wallets")}>To wallet</StyledExchangeSuccessResultModal.Cancel>
                    <StyledExchangeSuccessResultModal.Continue onClick={onClose}>Another conversation</StyledExchangeSuccessResultModal.Continue>
                </StyledExchangeSuccessResultModal.Foot>
            </StyledExchangeSuccessResultModal.Wrapper>
        </StyledDialog>
    )
}

const ExchangeSuccessResultModalCoin = ({ coin, amount, title }: any) => {
    return (
        <StyledExchangeSuccessResultModalCoin.Wrapper>
            <StyledExchangeSuccessResultModalCoin.Title>{title}</StyledExchangeSuccessResultModalCoin.Title>
            <StyledExchangeSuccessResultModalCoin.Content>
                <StyledExchangeSuccessResultModalCoin.Amount>{amount}</StyledExchangeSuccessResultModalCoin.Amount>
                <StyledExchangeSuccessResultModalCoin.Coin>
                    <CoinIcon style={{ width: "25px", height: "25px" }} coinName={coin} />
                    {coin}
                </StyledExchangeSuccessResultModalCoin.Coin>
            </StyledExchangeSuccessResultModalCoin.Content>
        </StyledExchangeSuccessResultModalCoin.Wrapper>
    )
}

const StyledExchangeSuccessResultModal = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    `,
    Head: styled.div`
        padding-inline: 24px;
    `,
    Content: styled.div`
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 34px;
    `,
    Foot: styled.div`
        display: flex;
        gap: 10px;
    `,
    Title: styled.h5`
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #419E6A;
    `,
    Subtitle: styled.p`
        display: flex;
        align-items: center;
        gap: 27px;
        width: fit-content;
        margin-top: 14px;
        position: relative;
        font-size: 12px;
        font-family: Poppins;
        font-weight: 500;
        line-height: 16px;
        color: #727272;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 34px;
            width: 1px;
            height: 100%;
            background-color: #727272;
        }
    `,
    Cancel: styled(Button)`
        width: 100%;
        height: 40px;
        background-color: #80848E;
        font-size: 14px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 21px;
        color: #FFF;
        white-space: nowrap;

        &:hover {
            background-color: #747476;
        }
    `,
    Continue: styled(Button)`
        width: 100%;
        height: 40px;
        background-color: #00B7F1;
        font-size: 14px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 21px;
        color: #FFF;
        white-space: nowrap;

        &:hover {
           background-color: #0097c5;
        }
    `
}

const StyledExchangeSuccessResultModalCoin = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 10px;
    `,
    Title: styled.span`
        font-size: 14px;
        font-family: Poppins;
        font-weight: 400;
        line-height: 21px;
        color: #80848E;

    `,
    Content: styled.div`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        align-items: start;
    `,
    Amount: styled.span`
        font-size: 20px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 30px;
        color: #000;

    `,
    Coin: styled.span`
        display: flex;
        align-items: center;
        gap: 16px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #000;
    `
}