import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Dialog, DialogContent,
  DialogTitle,
  styled,
  TextField,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export const StyledPageWrapper = styled("div")(({theme}) => ({
  background: "#FFFFFF",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: 5,
  padding: "44px 69px",
  // marginTop: 20,
  marginRight: 54,
  // marginBottom: 65,
  // maxHeight: "calc(100vh - 0)",
  // overflow: "auto",
  // minHeight: "calc(100vh - 81px)",
  display: "grid",

  [theme.breakpoints.down('md')]: {
    padding: 14,
    margin: 0
  }
}));

export const StyledPageTitle = styled("div")(({theme}) => ({
  fontWeight: 600,
  fontSize: 20,
  lineHeight: "30px",
  marginBottom: 24,

  [theme.breakpoints.down('md')]: {
    marginBottom: 14
  }
}));

export const StyledCheckCircleIcon = styled(CheckCircleIcon)(() => ({
  marginRight: 8,
  width: 15,
  height: 15,
  color: "#419E6A",
}));

export const StyledCancelIcon = styled(CancelIcon)(() => ({
  marginRight: 8,
  width: 15,
  height: 15,
  color: "#80848E",
}));

export const StyledStatusContainer = styled(Box)(({theme}) => ({
  marginBottom: 50,

  [theme.breakpoints.down('md')]: {
    flexWrap: "wrap",
    gap: "34px 12px",
    marginBottom: 40
  }
}));

export const StyledStatus = styled(Box)(() => ({
  marginRight: 29,
}));

export const StyledStatusTitle = styled("span")(() => ({
  color: "#000",
  fontSize: 12,
}));

export const StyledSubTitle = styled("div")(() => ({
  fontSize: 15,
  marginBottom: 12,
  fontWeight: 600,
}));

export const StyledListItem = styled(Box)(() => ({
  borderBottom: "1px solid #D1D1D1",
  padding: "15px 0",
  width: "90%",
}));

export const StyledListItemTitle = styled("div")(() => ({
  fontSize: 12,
  fontWeight: 600,
  marginBottom: 6,
  color: "#000",
}));

export const StyledListItemText = styled("div")(() => ({
  fontSize: 10,
  fontWeight: 400,
  marginBottom: 6,
  color: "#80848E",
}));

export const StyledListItemStatusTitle = styled("div")(() => ({
  fontSize: 12,
  fontWeight: 600,
  color: "#000",
}));

export const StyledListItemButton = styled(Button)(() => ({
  width: 100,
  textTransform: "unset",
  border: "1.5px solid #00B7F1",
  padding: "0 30px",
  color: "#00B7F1",
}));

export const StyledListItemButtonLang = styled(Button)(() => ({
  width: 100,
  textTransform: "unset",
  padding: "0 30px",
}));

export const StyledListItemBlock = styled("div")(() => ({
  padding: "15px 0",
}));

export const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: "1px solid #D1D1D1",
}));

export const StyledTableContainer = styled(TableContainer)(() => ({
  width: "90%",
  marginBottom: 38,
}));

export const StyledSettingsItem = styled(Box)(() => ({
  width: "90%",
  borderBottom: "1px solid #D1D1D1",
  paddingBottom: 10,
  marginBottom: 17,
  fontFamily: "Poppins",
}));

export const StyledButtonWrapper = styled("div")(() => ({
  paddingTop: 10,
}));

export const StyledDialogTitle = styled(DialogTitle)(({theme}) => ({
  padding: "16px 0",
  margin: "0 24px",
  marginBottom: 30,
  fontSize: 18,
  fontWeight: 600,
  minWidth: 425,

  [theme.breakpoints.down('md')]: {
    margin: 0,
    padding: 16,
    minWidth: "auto"
  }
}));

export const StyledDialogContent = styled(DialogContent)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    margin: 0,
    padding: 16,
    minWidth: "auto"
  }
}))

export const StyledEmailTitle = styled("div")(() => ({
  fontSize: 12,
  fontWeight: 600,
  marginBottom: 16,
}));

export const StyledEmailLabel = styled("div")(() => ({
  fontSize: 12,
  color: "#80848E !important",
  marginBottom: 6,
}));

export const StyledInput = styled("input")(() => ({
  width: 186,
  border: "1px solid #E5E7EB",
  borderRadius: 16,
  background: "#F9FAFB",
  padding: "7px 15px",
  "&[type=number]": {
    "-moz-appearance": "textfield",
  },
  "&::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "&::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: 186,
  height: 33,
  border: "1px solid #E5E7EB",
  borderRadius: 16,
  background: "#F9FAFB",
  padding: "7px 15px",
  "&[type=number]": {
    "-moz-appearance": "textfield",
  },
  "&::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "&::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));

export const StyledInputCode = styled("input")(() => ({
  width: "maxContent",
  border: "1px solid #E5E7EB",
  borderRadius: 16,
  background: "#F9FAFB",
  padding: "7px 15px",
  "&[type=number]": {
    "-moz-appearance": "textfield",
  },
  "&::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "&::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));

export const StyledPasswordsWrapper = styled(Box)(({theme}) => ({
  marginTop: 28,
  marginBottom: 40,
  paddingRight: 69,

  [theme.breakpoints.down('md')]: {
    marginTop: 16,
    marginBottom: 26,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    padding: "0"
  }
}));
export const StyledPhoneWrapper = styled(Box)(() => ({}));
export const StyledCodesWrapper = styled(Box)(({theme}) => ({
  marginTop: 28,
  marginBottom: 40,

  [theme.breakpoints.down('md')]: {
    marginBlock: "12px 26px",
  }
}));

export const StyledButtonCode = styled("div")(({theme}) => ({
  width: 84,
  padding: "0 !important",
  color: "#00B7F1 !important",
  cursor: "pointer",
  fontSize: 12,
  marginLeft: 10,

  [theme.breakpoints.down('md')]: {
    width: "auto",
    marginLeft: 10
  }
}));

export const StyledButtonLang = styled(Button)(() => ({
  textTransform: "capitalize",
}));

export const StyledButtonLangValue = styled("span")(() => ({
  color: "#D1D1D1",
}));

export const StyledButtonLangValueActive = styled("span")(() => ({
  color: "#00B7F1",
}));

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    background: "#FFF",
    color: "#000",
    "& div": {
      color: "#000",
    },
    "& button": {
      color: "#000",
    },
  },
}));

export const StyledCodeButton = styled("span")(() => ({
  color: "#00B7F1",
  cursor: "pointer",
  fontSize: 12,
  marginLeft: 20,
  "&.disabled": {
    color: "#93999b",
  },
}));

export const StyledLangName = styled("span")(() => ({
  fontWeight: 600,
}));

export const StyledButton = styled(Button)(() => ({
  border: "1px solid #00B7F1",
  textTransform: "unset",
  color: "#00B7F1 !important",
  width: 152,
}));

export const StyledConfirmButton = styled("button")(({theme}) => ({
  border: "1.5px solid #00B7F1",
  textTransform: "unset",
  color: "#00B7F1 !important",
  width: 152,
  padding: "10px 16px",
  cursor: "pointer",

  [theme.breakpoints.down('md')]: {
    width: "100%"
  }
}));

export const StyledGoogleAuthButton = styled(Button)(() => ({
  border: "1.5px solid #00B7F1",
  textTransform: "unset",
  fontWeight: 400,
  color: "#00B7F1 !important",
  width: "100%",
  fontSize: "15px",
  lineHeight: "18px",
  padding: "10px 16px",
  cursor: "pointer",
  marginTop: 37,
}));
export const StyledTip = styled("div")(() => ({
  fontSize: 7,
  marginTop: 9,
  marginLeft: 17,
  color: "#80848E !important",
}));

export const TwoFAWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  // padding: "38px 0",
}));
export const TwoFATitle = styled("div")(({theme}) => ({
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "27px",
  marginBottom: 5,

  [theme.breakpoints.down('md')]: {
    fontSize: "18px",
    lineHeight: "19.8px"
  }
}));

export const TwoFADescription = styled("span")(({theme}) => ({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "11px",
  lineHeight: "16px",
  color: "#80848E",

}));
export const StyledStepsWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const StepDescription = styled("span")(({theme}) => ({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#80848E",
}));
