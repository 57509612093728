import styled from "@emotion/styled"
import { ComponentProps } from "react"

interface ExchangeFormInputProps extends ComponentProps<'input'> {
}

export const ExchangeFormInput = ({ ...props }: ExchangeFormInputProps) => {
    return (
        <StyledExchangeFormInput.Input {...props} />
    )
}

const StyledExchangeFormInput = {
    Input: styled.input`
        width: 100%;
        height: 100%;
        padding: 16px 20px 13px 33px;
        background-color: transparent;
        border: none;
        outline: none;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #E7E7E7;
    `
}