import './verificationStyles.scss';

// import img from 'next/image'
import {
  FC,
  forwardRef,
  useEffect,
  useState,
} from 'react';

import { AdditionalLayout } from 'components';
import { Link, useNavigate } from 'react-router-dom';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { confirmationGetThunk } from '../../store/dashboard/dashboard.thunk';
import { IStatusConfirmation } from '../../types/confirmation.types';
import FacialScan from './children/facialScan';
import IdentifyVerification from './children/IdentifyVerification';
import VerificationLayout from './children/verificationLayout';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Verification: FC = (): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const {confirmationInfo} = useTypedSelector((state) => state.dashboard);
    const verification = useTypedSelector((state) => state.profile.me?.verification);

    //TODO Interface segregation
    const [status, setVerificationStatus] = useState<IStatusConfirmation>('default')

    useEffect(() => {
        dispatch(confirmationGetThunk());
    }, []);

    const {loading, error, response} = useTypedSelector(
        (state) => state.dashboard.confirmation
    );

    const nav = useNavigate()

    const [open, setOpen] = useState(false);

    //Handlers

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleClickVerify = (e: any) => {
        setVerificationStatus(e)
        if (confirmationInfo && confirmationInfo.applicant !== null) {
            e.preventDefault()
        }
    }

    useEffect(() => {
        if (response) {
            setOpen(true);
        }
    }, [response]);

    return (
        <AdditionalLayout>
            <div className="verification--container">
                <div className="verification--container-main">
                    {
                        status === 'default' ?
                            <VerificationLayout confirmationInfo={confirmationInfo}  verification={verification} statusHandler={handleClickVerify}/>
                        : status === 'identity' ? <IdentifyVerification statusHandler={handleClickVerify} />
                        : status === 'facial-scan' && <FacialScan />
                    }
                </div>
                <div className="verification--container-secondary">
                    <div className="verification--card verification--card-shadow">
                        <div className="verification--desc-container">
                            <div className="level__main__title">Verification levels</div>
                            <div className="verification--smallSteps mt-30">
                                <div className="verification--step">
                                    <div className="verification--step-info">
                                        <div className="verification--badges">
                                            <img
                                                alt=""
                                                src="/images/verification/badgeSmall.svg"
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                        <div className="level__title">Level 1</div>
                                        <p>
                                            Crypto deposits & withdrawal, and trading
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="verification--step">
                                    <div className="verification--step-info">
                                        <div className="verification--badges">
                                            <img
                                                alt=""
                                                src="/images/verification/badgeSmall.svg"
                                                width={20}
                                                height={20}
                                            />
                                            <img
                                                alt=""
                                                src="/images/verification/badgeSmall.svg"
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                        <div className="level__title">Level 2</div>
                                        <p>
                                            Fiat deposit, crypto deposit & withdrawl and trading
                                        </p>
                                    </div>
                                </div>
                                <div className="verification--step">
                                    <div className="verification--step-info">
                                        <div className="verification--badges">
                                            <img
                                                alt=""
                                                src="/images/verification/badgeSmall.svg"
                                                width={20}
                                                height={20}
                                            />
                                            <img
                                                alt=""
                                                src="/images/verification/badgeSmall.svg"
                                                width={20}
                                                height={20}
                                            />
                                            <img
                                                alt=""
                                                src="/images/verification/badgeSmall.svg"
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                        <div className="level__title">Level 3</div>
                                        <p>
                                            Fiat deposit & withdrawl, crypto deposit & withdrawl and trading
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="verification--card verification--card-shadow">
                        <div className="verification--desc-container">
                            <div className="level__main__title">Your data</div>
                            <div className="verification--steps mt-15">
                                <div className="verification--step">
                                    <div className="verification--step-info">
                                        <h4>Terms and conditions</h4>
                                        <p>
                                            Read our Terms & Conditions about how we keep your data safe
                                        </p>
                                        <a href="/Terms_and_conditions_Bitocol.pdf" target='_blank'>
                                            <Box className="link" display="flex" alignItems="center">
                                                <div>Read more</div>
                                                <ArrowRightAltIcon/>
                                            </Box>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: "100%"}}>
                    Data sent successfully!
                </Alert>
            </Snackbar>
        </AdditionalLayout>
    );
};

export default Verification;
