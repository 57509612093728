import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon";

import { StyledCurrencyWrapper } from "pages/Wallets/components/CommonComponents.styles";
import { ComponentProps } from "react";

interface ExchangeCoinListModalListItemProps extends ComponentProps<typeof Box> {
    coinName: string
    usdtValue?: string
    amountValue?: string
    isShowBalance: boolean
}

export const ExchangeCoinListModalListItem = ({ coinName, usdtValue, amountValue, isShowBalance, ...props }: ExchangeCoinListModalListItemProps) => {
    // const coinBalance = byBitWalletBalance.data?.list[0].coin?.find((walletCoin) => walletCoin.coin === item[propName])
    const numberUsdtValue = (usdtValue ? +usdtValue : 0).toFixed(5)

    return (
        <StyledExchangeCoinListModalListItem.Wrapper {...props}>
            <StyledExchangeCoinListModalListItem.Content>
                <CoinIcon
                    style={{
                        width: "25px",
                        height: "25px"
                    }}
                    coinName={coinName}
                />
                <StyledExchangeCoinListModalListItem.Text>
                    {coinName}{" "}
                    <span
                        style={{
                            color: "#80848E",
                            fontWeight: 500,
                        }}
                    >
                        {coinName}
                    </span>
                </StyledExchangeCoinListModalListItem.Text>
            </StyledExchangeCoinListModalListItem.Content>
            {isShowBalance && 
                <StyledExchangeCoinListModalListItem.BalanceWrapper>
                    <StyledExchangeCoinListModalListItem.BalanceText>{amountValue}</StyledExchangeCoinListModalListItem.BalanceText>
                    <StyledExchangeCoinListModalListItem.BalanceText>≈ ${numberUsdtValue}</StyledExchangeCoinListModalListItem.BalanceText>
                </StyledExchangeCoinListModalListItem.BalanceWrapper>
            }
        </StyledExchangeCoinListModalListItem.Wrapper>
    )
}

const StyledExchangeCoinListModalListItem = {
    Wrapper: styled(StyledCurrencyWrapper)`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 36px 30px 36px;
    `,
    Content: styled.div`
        display: flex;
        align-items: start;
    `,
    Text: styled.span`
        display: flex;
        flex-direction: column;
    `,
    BalanceWrapper: styled.div`
        display: flex;
        flex-direction: column;
    `,
    BalanceText: styled.span`
        display: flex;
        justify-content: flex-end;
    `
}