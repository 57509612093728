import { FC } from "react";
import { useTypedSelector } from "hooks/useTypedSelector";
import { Skeleton } from "@mui/material";
import "./dashboardStyles.scss";

const AnnoucementsBlock: FC = (): JSX.Element => {
  const { loading, data } = useTypedSelector((state) => state.news);

  return (
    <div className="background paddingSmall annoucementsBlock">
      <h3 className="title">Bitocol announcements</h3>
      {loading && (
        <>
          <div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="text"
                width="280px"
                height="30px"
                style={{ marginRight: "20px" }}
              />
              <Skeleton variant="text" width="80px" height="30px" />
            </div>
            <Skeleton variant="text" width="70%" height="60px" />
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="text"
                width="280px"
                height="30px"
                style={{ marginRight: "20px" }}
              />
              <Skeleton variant="text" width="80px" height="30px" />
            </div>
            <Skeleton variant="text" width="70%" height="60px" />
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="text"
                width="280px"
                height="30px"
                style={{ marginRight: "20px" }}
              />
              <Skeleton variant="text" width="80px" height="30px" />
            </div>
            <Skeleton variant="text" width="70%" height="60px" />
          </div>
        </>
      )}
      {!loading && data.length ? (
        <>
          {data.map((news, index) => (
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <p className="annoucementsBlock_title">{news.title}</p>
                <p className="annoucementsBlock_date">
                  {news.created_at.split("T")[0]}
                </p>
              </div>
              <p className="annoucementsBlock_body">{news.text}</p>
            </div>
          ))}
        </>
      ) : (
        <p style={{ color: "#80848E", fontSize: "14px" }}>
          No news at the moment
        </p>
      )}
    </div>
  );
};

export default AnnoucementsBlock;
