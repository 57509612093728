import { Fragment, useMemo } from "react";
import { Box, ListItem, Typography } from "@mui/material";
import {
  IByBitWalletBalance,
  IByBitWalletBalanceCoin,
  IByBitWalletBalanceData,
} from "types/exchange.types";
import { ExchangeBalanceItem } from "../ExchangeBalanceItem/ExchangeBalanceItem";
import { useTypedSelector } from "hooks/useTypedSelector";

export const ExchangeBalances = () => {
  const byBitWalletBalance = useTypedSelector(
    (state) => state.wallets.byBitWalletBalance
  );

  const computedBalances = useMemo(() => {
    if(!byBitWalletBalance?.data) return []
    const coins: IByBitWalletBalanceCoin[] =
    byBitWalletBalance.data.list.reduce(
        (prev: any, item: any) => (item.coin ? [...prev, ...item.coin] : prev),
        []
      ) || [];

    return coins;
  }, [byBitWalletBalance]);

  return (
    <Box sx={{ background: "#181B25", padding: "17px 13px", marginTop: "5px", color: 'white' }}>
      <Typography variant={"body2"}>Balances</Typography>
      {!!computedBalances.length ? 
        <ListItem
          component={"div"}
          sx={{ flexDirection: "column", maxHeight: 350, overflowY: "auto" }}
        >
          {!!computedBalances.length &&
            computedBalances.map((item, i) => (
              <Fragment key={i}>
                <ExchangeBalanceItem key={i} value={item} />
              </Fragment>
            ))}
        </ListItem>
      :
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px 0" }}>
          Balances is empty…
        </Box>
      }
    </Box>
  );
};
