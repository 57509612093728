import { SVGProps } from "react";


export const LessSlippageIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 15V3.75" stroke="#414E52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.7383 9.375L5.26172 20.625" stroke="#414E52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.93762 17.0625C3.80972 16.3825 3.74693 15.6919 3.75012 15C3.74863 12.6733 4.46931 10.4036 5.8127 8.50396C7.15609 6.60432 9.05596 5.16846 11.2501 4.39453V12.832L3.93762 17.0625Z" stroke="#414E52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 3.75C16.9713 3.75022 18.9079 4.26842 20.6159 5.25269C22.3239 6.23696 23.7432 7.65274 24.7318 9.35822C25.7204 11.0637 26.2435 12.999 26.2487 14.9703C26.2539 16.9416 25.741 18.8796 24.7615 20.5903C23.7819 22.301 22.37 23.7242 20.6673 24.7175C18.9645 25.7108 17.0307 26.2392 15.0594 26.2498C13.0882 26.2605 11.1487 25.753 9.43535 24.7781C7.72198 23.8033 6.29484 22.3953 5.29688 20.6953" stroke="#414E52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}