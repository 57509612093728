import { Box } from '@mui/material';

export const SuccessSent = () => {
    return <Box display={'flex'} justifyContent={'center'} marginLeft="8px" marginTop="16px" alignItems={'center'} gap={'6px'}>
        <img src="/images/common/checkmark-green.svg" alt="Check mark"/>
        <Box>
            <p className={'web11 success-color'}>Success</p>
            <p className={'web10 subtitle-1-color'} style={{lineHeight: '20px'}}>Code Sent</p>
        </Box>
    </Box>
}

export const SuccessSentForPasswordChange = () => {
    return <Box display={'flex'} justifyContent={'center'} marginLeft="8px" marginTop="16px" alignItems={'center'} gap={'6px'}>
        <img src="/images/common/checkmark-green.svg" alt="Check mark"/>
        <Box>
            <p className={'web11 success-color'} style={{lineHeight: '20px'}}>Success code Sent!</p>
        </Box>
    </Box>
}