import { SVGProps } from "react"


export const FinishIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.38672 23.6133C5.30859 22.5352 6.02344 20.2734 5.47266 18.9492C4.92188 17.625 2.8125 16.4648 2.8125 15C2.8125 13.5352 4.89844 12.4219 5.47266 11.0508C6.04688 9.67969 5.30859 7.46484 6.38672 6.38672C7.46484 5.30859 9.72656 6.02344 11.0508 5.47266C12.375 4.92188 13.5352 2.8125 15 2.8125C16.4648 2.8125 17.5781 4.89844 18.9492 5.47266C20.3203 6.04688 22.5352 5.30859 23.6133 6.38672C24.6914 7.46484 23.9766 9.72656 24.5273 11.0508C25.0781 12.375 27.1875 13.5352 27.1875 15C27.1875 16.4648 25.1016 17.5781 24.5273 18.9492C23.9531 20.3203 24.6914 22.5352 23.6133 23.6133C22.5352 24.6914 20.2734 23.9766 18.9492 24.5273C17.625 25.0781 16.4648 27.1875 15 27.1875C13.5352 27.1875 12.4219 25.1016 11.0508 24.5273C9.67969 23.9531 7.46484 24.6914 6.38672 23.6133Z" stroke="#414E52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.1562 12.1875L13.2773 18.75L9.84375 15.4688" stroke="#414E52" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
    )
}