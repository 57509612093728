import React, { FC, ReactNode } from "react";
import { ModalManagerProvider } from "./ModalManagerProvider";
import { defaultTheme, themeDark } from "../styles/theme";
import { ThemeProvider } from "@mui/material";
import { useCustomTheme } from "../hooks/useCustomTheme";
import {DrawerManagerProvider} from "./DrawerManagerProvider";


interface IProps {
  children: ReactNode;
}

export const AppWrapper: FC<IProps> = ({ children }) => {
  //States
  const { theme } = useCustomTheme();
  const themeVal = theme === "dark" ? themeDark : defaultTheme;

  return (
    <ThemeProvider theme={themeVal}>
      <ModalManagerProvider>
        <DrawerManagerProvider>
          {children}
        </DrawerManagerProvider>
      </ModalManagerProvider>
    </ThemeProvider>
  );
};
