import React, {
  FC,
  useMemo,
  useState,
} from 'react';

import { authApi } from 'api';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import {
  Box,
  Typography,
} from '@mui/material';

import { AuthLayout } from '../../../../layouts/AuthLayout/AuthLayout';
import {
  EForgotPasswordSteps,
  IForgotPasswordPasswordsFields,
} from '../../../../types/profile.types';
import styles from '../../../auth/auth.module.scss';

const RefreshPasswordPage: FC = () => {
    const navigate = useNavigate()

    const code = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('code') as string;
    }, [])

    const passwordsValues: IForgotPasswordPasswordsFields = {
      password: '',
      passwordConfirm: ''
  }
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const passwordSchema = yup.object({
      password: yup
          .string()
          .min(8, 'Length must exceed 8 characters')
          .required('Password is required')
          .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character',
          ),
      passwordConfirm: yup
          .string()
          .oneOf([yup.ref('password')], 'Your passwords do not match.')
          .required('Password Confirm is required')
  
  });
  const onSubmit = async (values: IForgotPasswordPasswordsFields) => {
    await authApi.refreshPassword({
      code: code,
     password: values.password
   }).then(res => {
    navigate('/signin')
   }) 
   .catch(err => {
    console.log(err)
   })
}
    return (
    <AuthLayout>
    <Box component={'div'} height={'100%'}
         display={'flex'} flexDirection={'column'} 
         alignItems={'center'} justifyContent={'center'}>
      <Typography variant={'h3'} sx={{mb: 5}}>
          Forgot Your Password?
      </Typography>
      <Box width={'100%'}>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={passwordsValues}
          validationSchema={passwordSchema}
          onSubmit={(values) => {
            onSubmit(values)
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors
          }) => (
            <form
              className={styles["auth-form"]}
              onSubmit={handleSubmit}
            >
            <div className={styles["auth-input--wrapper"]}>
                  <img
                    src="/images/auth/eye.svg"
                    width={32}
                    height={32}
                    alt="eye"
                    style={{cursor: 'pointer', marginTop: 7}}
                    onMouseDown={() => {
                      setShowPassword(true)
                    }}
                    onMouseUp={() => {
                      setShowPassword(false)
                    }}
                    onMouseLeave={() => {
                      if (showPassword) {
                      setShowPassword(false)
                    }
                    }}
                  />
                  <input
                    id="password"
                    className={errors.password ? styles["error"] : ""}
                    name="password"
                    onCopy={(e)=>{
                      e.preventDefault()
                      return false;
                    }}
                    onChange={(e: any) => {
                      const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/
                      if (e.target.value === '' || re.test(e.target.value)) {
                        handleChange(e)
                      }
                    }}
                    value={values.password}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password *"
                  />
                </div>
              {errors.password && (
                <p className={styles["error"]}>{errors.password}</p>
              )}
                <div className={styles["auth-input--wrapper"]}>
                   <img
                    src="/images/auth/eye.svg"
                    width={32}
                    height={32}
                    alt="eye"
                    style={{cursor: 'pointer', marginTop: 7}}
                    // onClick={(e) => showPass("password")}
                    onMouseDown={() => {
                      setShowPasswordConfirm(true)
                    }}
                    onMouseUp={() => {
                        setShowPasswordConfirm(false)
                    }}
                    onMouseLeave={() => {
                      if (showPasswordConfirm) {
                        setShowPasswordConfirm(false)
                    }
                    }}
                  />
                  <input
                    id="passwordConfirm"
                    onPaste={(e)=>{
                      e.preventDefault()
                      return false;
                    }}
                    className={
                      errors.passwordConfirm ? styles["error"] : ""
                    }
                    name="passwordConfirm"
                    onChange={(e: any) => {
                      const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/
                      if (e.target.value === '' || re.test(e.target.value)) {
                        handleChange(e)
                      }
                    }}
                    value={values.passwordConfirm}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    placeholder="Password confirmation *"
                  />
                </div>

              {errors.passwordConfirm && (
                <p className={styles["error"]}>
                  {errors.passwordConfirm}
                </p>
              )}
              
              <button style={{marginTop: 42}} className={styles['auth-form--btn']} type='submit'>Submit</button>
            </form>
          )}
        </Formik>
        </Box>  
    </Box> 
    </AuthLayout>)
}

export default RefreshPasswordPage
