import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from '@mui/icons-material/Edit';
import { useTypedSelector } from "hooks/useTypedSelector";
import { removeTrailingZeros } from "helpers/removeTrailingZeros";
import { ByBitOrder, byBitApi } from "api";
import { AddTakeProfitStopLossModal, TakeProfitStopLossSchema } from "../AddTakeProfitStopLossModal";
import { useSnackbar } from "notistack";

interface IProps {
  items: ByBitOrder[];
  title: string;
  renderMessage: (args: { item: ByBitOrder | null, setItem: Dispatch<SetStateAction<ByBitOrder | null>>, handleClose: () => void }) => ReactNode;
}

const HEADS = ["Contracts", "Qty", "Order Price", "TP/SL", "Trade Type", "Order Type", "Status", " "];

export const ExchangeOrders: FC<IProps> = ({ items, title, renderMessage }: IProps) => {
  const selectedPair = useTypedSelector((state) => state.exchange.selectedPair);
  const [orderForClose, setOrderForClose] = useState<ByBitOrder | null>(null);
  const [orderForTPLS, setOrderForTPLS] = useState<ByBitOrder | null>(null)
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = async (data: TakeProfitStopLossSchema) => {
    if(!orderForTPLS) return;

    // if(orderForTPLS.side === 'Buy') {
    //   if(!!data.profit && +data.profit > (+orderForTPLS.avgPrice + (+orderForTPLS.avgPrice * 1.5))) {
    //     enqueueSnackbar("The take profit cannot be higher than order price", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //     });
    //     return
    //   }

    //   if(data.profit && +data.profit < +orderForTPLS.avgPrice) {
    //     enqueueSnackbar("The take profit cannot be lower than order price", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //     });
    //     return
    //   }

    //   if(data.loss && +data.loss > +orderForTPLS.avgPrice) {
    //     enqueueSnackbar("The loss stop cannot be higher than order price", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //     });
    //     return
    //   }

    //   if(!!data.loss && +data.loss < (+orderForTPLS.avgPrice - (+orderForTPLS.avgPrice * 0.75))) {
    //     enqueueSnackbar("The loss stop cannot be lower than order price", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //     });
    //     return
    //   }
    // }
    // if(orderForTPLS.side === 'Sell') {
    //   if(!!data.profit && +data.profit < 0) {
    //     enqueueSnackbar("The stop loss cannot be lower than order price", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //     });
    //     return
    //   }

    //   if(data.profit && +data.profit > +orderForTPLS.avgPrice) {
    //     enqueueSnackbar("The stop loss cannot be higher than order price", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //     });
    //     return
    //   }

    //   if(!!data.loss && +data.loss > (+orderForTPLS.avgPrice + (+orderForTPLS.avgPrice * 0.75))) {
    //     enqueueSnackbar("The stop loss cannot be higher than order price", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //     });
    //     return
    //   }

    //   if(data.loss && +data.loss < +orderForTPLS.avgPrice) {
    //     enqueueSnackbar("The stop loss cannot be lower than order price", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //     });
    //     return
    //   }
    // }
    
    try {
      const profitData = data.profit ? { takeProfit: String(data.profit) } : {};
      const lossData = data.loss ? { stopLoss: String(data.loss) } : {};
      await byBitApi.amendOrder({ category:'linear', symbol: orderForTPLS.symbol, orderId: orderForTPLS.orderId, tpTriggerBy: "LastPrice", slTriggerBy: "LastPrice", tpslMode: "Full", ...profitData, ...lossData })
      setOrderForTPLS(null)
    }catch(err) {
      console.log(err);
      enqueueSnackbar("Failed to set TP/SL", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  };

  return (
    <Box
        mt={2}
        width="100%"
    >
      <table>
          <thead>
              <tr>
                  {HEADS.map((head, headIdx) => (
                      <th key={headIdx} style={{ paddingInline: "16px" }}>
                          <Box component="span" fontSize="12px" fontWeight="400" color="#52555E" lineHeight="18px" whiteSpace="nowrap">
                              {head}
                          </Box>
                      </th>
                  ))}
              </tr>
          </thead>
          <tbody>
              {items.map((item, itemIdx) => (
                  <tr key={itemIdx}>
                    <td style={{ paddingInline: "16px" }}>
                        <Box sx={{
                            position: "relative",
                            paddingLeft: "12px",
                            "&::before": {
                                content: '""',
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "4px",
                                height: "100%",
                                background: item.side === "Buy" ? "#419E6A" : "#D83232"
                            }
                        }}>
                          <Typography fontWeight="600">{item.symbol}</Typography>
                        </Box>
                    </td>
                    <td style={{ paddingInline: "16px" }}>
                        <Box>
                            <Typography fontWeight="600" color={item.side === "Buy" ? "#419E6A" : "#D83232"}>
                                {item.qty}
                            </Typography>
                        </Box>
                    </td>
                    <td style={{ paddingInline: "16px" }}>
                        <Box>
                            <Typography>
                                {removeTrailingZeros(!item.stopOrderType ? item.price : item.triggerPrice)}
                            </Typography>
                        </Box>
                    </td>
                    <td style={{ paddingInline: "16px" }}>
                        <Box display="flex" justifyContent="start" alignItems="center" color="white">
                          {(item.takeProfit || item.stopLoss) ?
                          <Box>
                            <Box display="flex" justifyContent="center" alignItems="center" gap="5px">
                                <Typography color="#419E6A">
                                  {!!item.takeProfit ? item.takeProfit : "--"}
                                </Typography>/<Typography color="#D83232">
                                  {!!item.stopLoss ? item.stopLoss : "--"}
                                </Typography>
                            </Box>
                            <Button variant="contained" color="secondary" sx={{ minWidth: "auto", width: 30, height: 30, padding: "5px !important" }} onClick={() => setOrderForTPLS(item)}>
                              <EditIcon sx={{ width: 15 }} />
                            </Button>
                          </Box>
                          : (item.stopOrderType !== 'TakeProfit' && item.stopOrderType !== 'StopLoss') ?
                          <Button variant="contained" color="secondary" sx={{ padding: "5px !important" }} onClick={() => setOrderForTPLS(item)}>
                              <Typography textTransform="initial" fontWeight="600" color="white">
                                  +Add
                              </Typography>
                          </Button>
                          :
                          <Typography color="#00B7F1">--</Typography>}
                        </Box>
                    </td>
                    <td style={{ paddingInline: "16px" }}>
                        <Box>
                            <Typography fontWeight={600} color={item.side == "Buy" ? "#419E6A" : "#D83232"}>
                                {item.side === "Buy" ? "Open Long" : "Open Short"}
                            </Typography>
                        </Box>
                    </td>
                    <td style={{ paddingInline: "16px" }}>
                        <Box>
                            <Typography fontWeight={600}>
                                {item.orderType}
                            </Typography>
                        </Box>
                    </td>
                    <td style={{ paddingInline: "16px" }}>
                        <Box>
                            <Typography fontWeight={600}>
                                {item.orderStatus}
                            </Typography>
                        </Box>
                    </td>
                    <td style={{ paddingInline: "16px" }}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <IconButton onClick={() => setOrderForClose(item)}>
                                <ClearIcon
                                    fill={"#80848E"}
                                    sx={{ color: "#80848E", width: 15, height: 15 }}
                                />
                            </IconButton>
                        </Box>
                    </td>
                  </tr>
              ))}
          </tbody>
      </table>
      {renderMessage({ item: orderForClose, setItem: setOrderForClose, handleClose: () => setOrderForClose(null) })}
      <AddTakeProfitStopLossModal 
        item={orderForTPLS ? { price: orderForTPLS.price, side: orderForTPLS.side, qty: orderForTPLS.qty, liqPrice: "", symbol: orderForTPLS.symbol, takeProfit: orderForTPLS.takeProfit, stopLoss: orderForTPLS.stopLoss } : null} 
        onClose={() => setOrderForTPLS(null)}
        onConfirm={handleConfirm}
      />
    </Box>
  );
};
