import { ComponentProps, ReactNode } from "react";
import styled from "@emotion/styled";

interface ExchangeFormFieldProps extends ComponentProps<'div'> {
    label?: {
        left?: ReactNode,
        right?: ReactNode
    }
    error?: string
}

export const ExchangeFormField = ({ children, label, error, ...props }: ExchangeFormFieldProps) => {
    return (
        <StyledExchangeFormField.Wrapper {...props}>
            {label && 
                <StyledExchangeFormField.LabelWrapper>
                    {label?.left && <StyledExchangeFormField.Label>{label.left}</StyledExchangeFormField.Label>}
                    {label?.right && <StyledExchangeFormField.Label>{label.right}</StyledExchangeFormField.Label>}
                </StyledExchangeFormField.LabelWrapper>
            }
            <StyledExchangeFormField.Content data-error={!!error}>
                {children}
            </StyledExchangeFormField.Content>
            {error && <StyledExchangeFormField.Error>{error}</StyledExchangeFormField.Error>}
        </StyledExchangeFormField.Wrapper>
    )
}

const StyledExchangeFormField = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 9px;
    `,
    LabelWrapper: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    `,
    Label: styled.div`
        display: flex;
        width: 100%;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #E7E7E7;
    `,
    Content: styled.div<{ 'data-error': boolean }>`
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background-color: #0C2250;
        border: 1px solid transparent;
        cursor: pointer;
        transition: border-color 0.33s ease;

        &:hover {
            border: 1px solid #00B7F1;
        }

        &[data-error="true"] {
            border: 1px solid #D83232;
        }
    `,
    Error: styled.span`
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #D83232;
    `
}
