import { ByBitCoinBody, ByBitCoinData, byBitApi, walletsApi } from "api";
import { AxiosError } from "axios";
import { ReactNode, useEffect, useMemo, useState } from "react";

interface Props {
  render: (args: {
    coins: ByBitCoinBody | null;
    notDuplicatedCoins: ByBitCoinBody | null;
    loading: boolean;
    error: string;
    onRequest: (args: { cursor: string }) => void;
  }) => ReactNode;
}

export const CoinsContainer = ({ render }: Props) => {
  const [coins, setCoins] = useState<ByBitCoinBody | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleGetCoins = async ({ cursor }: { cursor?: string } = {}) => {
    try {
      setLoading(true);
      const res = await byBitApi.getCoinList({ cursor });
      if (res)
        setCoins((prev) => {
          if (prev && prev.nextPageCursor !== res.nextPageCursor) {
            return {
              ...res,
              configList: [...prev.configList, ...res.configList],
            };
          } else {
            return res;
          }
        });
    } catch (err: any) {
      if (err instanceof AxiosError) {
        setError(err.message);
      } else {
        setError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const notDuplicatedCoins = useMemo(() => {
    if (!coins) return null;
    const notDuplicatedCoins = coins.configList.reduce(
      (arr, cur) =>
        !!arr.find((item: any) => cur.coin === item.coin) ? arr : [...arr, cur],
      [] as any
    );
    return { ...coins, configList: notDuplicatedCoins };
  }, [coins]);

  useEffect(() => {
    handleGetCoins();
  }, []);

  return (
    <>
      {render({
        coins,
        notDuplicatedCoins,
        loading,
        error,
        onRequest: handleGetCoins,
      })}
    </>
  );
};
