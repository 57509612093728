import { ComponentProps } from "react"
import { Button } from "@mui/material"
import styled from "@emotion/styled"

type ExchangeFormSubmitButtonProps = Omit<ComponentProps<typeof Button>, 'form' | 'children'> & {
}

export const ExchangeFormSubmitButton = ({ ...props }: ExchangeFormSubmitButtonProps) => {
    return (
        <StyledExchangeFormSubmitButton type="submit" {...props}>
            Convert
        </StyledExchangeFormSubmitButton>
    )
}

const StyledExchangeFormSubmitButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-top: 37px;
    background-color: #00B7F1;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #FFF;
    text-transform: unset;
    transition: background-color 0.33s ease;

    &:disabled {
        cursor: pointer;
    }

    &:hover {
        background-color: #0097c4;
    }
`