import { createContext, FC } from "react";

import { Level2, Market, Order, Rate, Ticker } from "hooks/useCentrifuge";

interface CentrifugeContextValue {
  simpleExchange: Order | null;
  market: Market | null;
  ticker: Ticker | null;
  level2: Level2 | null;
  order: Order | null;
  rate: Rate | Rate[] | null;
}

export const CentrifugeContext = createContext<CentrifugeContextValue>({
  market: null,
  ticker: null,
  level2: null,
  order: null,
  rate: null,
  simpleExchange: null,
});

type Props = {
  children: React.ReactNode;
  contextValue: CentrifugeContextValue;
};

const CentrifugeProvider: FC<Props> = ({ children, contextValue }) => {
  return (
    <CentrifugeContext.Provider value={contextValue}>
      {children}
    </CentrifugeContext.Provider>
  );
};

export default CentrifugeProvider;
