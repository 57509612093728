import { changeTicker, removeOrderById } from "store/exchange/exchange.slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { exchangeApi } from "../../api/exchange.api";
import { IOrderCreateReq } from "../../types/exchange.types";
import { setNotificationMessage } from "store/notistack/notistack.slice";
import { setCancelOrder, setTrending, setMarketList } from "./exchange.slice";
import { byBitApi } from "api";

export const ordersGetThunk = createAsyncThunk("ordersGetThunk", () => {
  try {
    return exchangeApi.getOrders();
  } catch (e) {
    console.log(e);
  }
});

export const bybitOrdersGetThunk = createAsyncThunk("bybitOrdersGetThunk", () => {
  try {
    return exchangeApi.getBybitOrders();
  } catch (e) {
    console.log(e);
  }
});

export const currenciesGetThunk = createAsyncThunk("currenciesGetThunk", () => {
  try {
    return exchangeApi.getCurrencies();
  } catch (e) {
    console.log(e);
  }
});
export const currenciesMapGetThunk = createAsyncThunk("currenciesMapGetThunk", () => {
  try {
    return exchangeApi.getCurrencyMap();
  }catch(e) {
    console.log(e);
  }
})

export const pairTradesGetThunk = createAsyncThunk(
  "pairTradesGetThunk",
  (id: number) => {
    try {
      return exchangeApi.getTradesPairHistory(id);
    } catch (e) {
      console.log(e);
    }
  }
);

export const pairBookGetThunk = createAsyncThunk(
  "pairBookGetThunk",
  (id: number) => {
    try {
      return exchangeApi.getBookPairHistory(id);
    } catch (e) {
      console.log(e);
    }
  }
);

export const pairTickersGetThunk = createAsyncThunk(
  "pairTickersGetThunk",
  async (id: number, { dispatch }) => {
    try {
      const res = await exchangeApi.getTickersPairHistory(id);
      dispatch(changeTicker(res));
      return exchangeApi.getTickersPairHistory(id);
    } catch (e) {
      console.log(e);
    }
  }
);

// export const orderCreateThunk = createAsyncThunk(
//   "orderCreateThunk",
//   (data: IOrderCreateReq) => {
//     try {
//       return exchangeApi.orderCreate(data);
//     } catch (e) {
//       console.log(e);
//     }
//   }
// );

export const orderCreateThunk =
  (data: IOrderCreateReq) => async (dispatch: any) => {
    try {
      // добавить обработку загрузки
      const response = await exchangeApi.orderCreate(data);

      dispatch(
        setNotificationMessage({
          msg: "Order successfully created",
          variant: "success",
        })
      );
      dispatch(ordersGetThunk());
    } catch (err) {
      dispatch(
        setNotificationMessage({
          msg: `You don't have enough balance`,
          variant: "error",
        })
      );
    }
  };

export const orderCancelThunk =
  (order: { orderId: string; symbol: string }) => async (dispatch: any) => {
    try {
      dispatch(
        setCancelOrder({
          loading: true,
          error: null,
          response: null,
        })
      );

      const response = await byBitApi.cancelOrder(order);

      dispatch(removeOrderById(order.orderId));

      dispatch(
        setCancelOrder({
          loading: false,
          error: null,
          response,
        })
      );

      dispatch(
        setNotificationMessage({
          msg: "Order canceled",
          variant: "success",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        setCancelOrder({
          loading: false,
          error,
          response: null,
        })
      );
    }
  };

export const allOrderCancelThunk = () => async (dispatch: any) => {
  try {
    dispatch(
      setCancelOrder({
        loading: true,
        error: null,
        response: null,
      })
    );
    byBitApi
      .cancelAllOrder()
      .then((res) => {
        dispatch(
          setCancelOrder({
            loading: false,
            error: null,
            response: null,
          })
        );
        dispatch(
          setNotificationMessage({
            msg: "All orders canceled",
            variant: "success",
          })
        );
        dispatch(ordersGetThunk());
      })
      .catch((error) => {
        dispatch(
          setCancelOrder({
            loading: false,
            error,
            response: null,
          })
        );
      });
  } catch (error) {
    dispatch(
      setCancelOrder({
        loading: false,
        error,
        response: null,
      })
    );
  }
};

export const getTrendingThunk = () => async (dispatch: any) => {
  try {
    dispatch(
      setTrending({
        loading: true,
        error: null,
        data: [],
      })
    );

    const data = await exchangeApi.getTrending();

    dispatch(
      setTrending({
        loading: false,
        error: null,
        data,
      })
    );
  } catch (err) {
    dispatch(
      setTrending({
        loading: false,
        error: err,
        data: [],
      })
    );
  }
};

// export const getMarketListThunk = () => async (dispatch: any) => {
//   try {
//     const data = await exchangeApi.getMarketList();

//     dispatch(setMarketList(data.data));
//   } catch (error) {}
// };

export const getMarketThunk = createAsyncThunk("getMarketThunk", () => {
  try {
    return exchangeApi.getMarket();
  } catch (e) {
    console.log(e);
  }
});
