import { FC, useCallback, useEffect, useContext, useState, MouseEvent } from "react";
import { Box, Typography, Skeleton, Popover } from "@mui/material";
import { themeDark } from "../../../../styles/theme";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { pairTickersGetThunk } from "store/exchange/exchange.thunk";
import { StyledSelectPair } from "./styles";
import { MarketPlaceDrawer } from "components/Drawers/MarketPlaceDrawer";
import { useDrawerManager } from "hooks/useDrawerManager";
import { EDrawerID } from "types/common.types";
import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon";
import { ByBitTicker, useByBit } from "providers/ByBitProvider";
import styled from "@emotion/styled";
import BoltIcon from '@mui/icons-material/Bolt';
import { removeTrailingZeros } from "helpers/removeTrailingZeros";
import moment from "moment";

interface IProps {}

export const ExchangeHeader: FC<IProps> = () => {
  const { ticker } = useByBit();
  const selectedPair = useTypedSelector((state) => state.exchange.selectedPair);
  const dispatch = useAppDispatch();
  const { setDrawerId } = useDrawerManager();
  const [nextFundingTime, setNextFundingTime] = useState<string>("")
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback(() => {
    setDrawerId(EDrawerID.markets);
  }, []);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  }

  useEffect(() => {
    if (selectedPair) {
      dispatch(pairTickersGetThunk(Number(selectedPair?.symbol)));
    }
  }, [selectedPair]);

  useEffect(() => {
    if(!ticker?.data.nextFundingTime) return;
    let timeout: any;
    timeout = setTimeout(function timeoutFunc() {
      const nextFundDate = new Date(+ticker.data.nextFundingTime);
      const nowDate = new Date();

      const timeDiff = nextFundDate.getTime() - nowDate.getTime();

      const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutesDiff = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const secondsDiff = Math.floor((timeDiff % (1000 * 60)) / 1000);

      setNextFundingTime(`${hoursDiff}:${minutesDiff}:${secondsDiff}`);
      timeout = setTimeout(timeoutFunc, 1000)
    }, 1000)
    
    return () => {
      clearTimeout(timeout);
    }
  }, [ticker?.data.nextFundingTime])

  return (
    <Container
      component={"div"}
      sx={{ background: "#181B25", marginBottom: "5px" }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      rowGap={1}
      pt={20 / 8}
      pl={1.5}
      pb={20 / 8}
      pr={30 / 8}
      whiteSpace="nowrap"
      overflow={'auto'}
    >
      <MarketPlaceDrawer />
      <Box
        display={"flex"}
        lineHeight={1}
        alignItems={"center"}
        height={"100%"}
      >
        {selectedPair && <CoinIcon coinName={selectedPair?.baseCoin} />}

        <Box lineHeight={1}>
          <Typography component={"span"} variant={"body2"}>
            {selectedPair?.baseCoin}
          </Typography>
          <Typography
            component={"span"}
            style={{ color: themeDark.palette.primary.contrastText }}
            variant={"body2"}
          >
            /{selectedPair?.quoteCoin}
          </Typography>
        </Box>
      </Box>

      <Box
        display={"flex"}
        lineHeight={1}
        height={"100%"}
        justifyContent={"left"}
        alignItems={"start"}
        flexDirection={"column"}
      >
        {/* <StyledSelect
          value={currentPairId}
          onChange={(e) => setCurrentPairId(e?.target?.value)}
					IconComponent={Icon}
        >
          {pairs?.map((pair, index) => {
            return (
              <MenuItem
                key={pair.ID}
                value={pair.ID}
              >{`${pair.BaseCurrency}/${pair.QuoteCurrency}`}</MenuItem>
            );
          })}
        </StyledSelect> */}
        <StyledSelectPair onClick={handleClick}>
          <Typography>All markets</Typography>
          <p>
            <img src="/images/exchange/chewronDown.svg" alt="" />
          </p>
        </StyledSelectPair>
      </Box>
      <Box
        display={"flex"}
        lineHeight={1}
        height={"100%"}
        justifyContent={"left"}
        alignItems={"start"}
        flexDirection={"column"}
        // width={"160px"}
      >
        <Typography sx={{ color: "secondary.main" }}>
          Last market price
        </Typography>
        {!ticker?.data.lastPrice ? (
          <Skeleton width={100} height={21} />
        ) : (
          <Typography variant={"h6"} sx={{ color: "success.main" }}>
            {removeTrailingZeros(String(Number(ticker.data.lastPrice).toFixed(5)))}
          </Typography>
        )}
      </Box>

      <Box
        display={"flex"}
        lineHeight={1}
        height={"100%"}
        justifyContent={"left"}
        alignItems={"start"}
        flexDirection={"column"}
        // width={"240px"}
      >
        <Box>
          <Typography component={"span"} sx={{ color: "secondary.main" }}>
            24h change:{" "}
          </Typography>
          {!ticker?.data?.price24hPcnt ? (
            <Skeleton
              width={70}
              height={16}
              style={{
                display: "inline-flex",
                position: "relative",
                top: "1px",
              }}
            />
          ) : (
            <Typography
              component={"span"}
              sx={{
                color:
                  Number(ticker.data.price24hPcnt) < 0
                    ? "#f44336"
                    : "success.main",
              }}
            >
              {`${(+ticker.data.price24hPcnt * 100).toFixed(2)}%` || ""}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography component={"span"} sx={{ color: "secondary.main" }}>
            Market volume:{" "}
          </Typography>
          {!ticker?.data?.volume24h ? (
            <Skeleton
              width={70}
              height={16}
              style={{
                display: "inline-flex",
                position: "relative",
                top: "1px",
              }}
            />
          ) : (
            <Typography component={"span"}>
              {removeTrailingZeros(ticker?.data?.volume24h)}
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        display={"flex"}
        lineHeight={1}
        height={"100%"}
        justifyContent={"left"}
        alignItems={"start"}
        flexDirection={"column"}
      >
        <Box>
          <Typography component={"span"} sx={{ color: "secondary.main" }}>
            24h High:{" "}
          </Typography>
          {!ticker?.data?.highPrice24h ? (
            <Skeleton
              width={70}
              height={16}
              style={{
                display: "inline-flex",
                position: "relative",
                top: "1px",
              }}
            />
          ) : (
            <Typography component={"span"}>
              {removeTrailingZeros(String(Number(ticker?.data?.highPrice24h).toFixed(5)))}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography component={"span"} sx={{ color: "secondary.main" }}>
            24h Low:{" "}
          </Typography>
          {!ticker?.data?.lowPrice24h ? (
            <Skeleton
              width={70}
              height={16}
              style={{
                display: "inline-flex",
                position: "relative",
                top: "1px",
              }}
            />
          ) : (
            <Typography component={"span"}>
              {removeTrailingZeros(String(Number(ticker?.data?.lowPrice24h).toFixed(5)))}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        display={"flex"}
        lineHeight={1}
        height={"100%"}
        justifyContent={"left"}
        alignItems={"start"}
        flexDirection={"column"}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ cursor: "help" }}
      >
        <Typography sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "5px", color: "secondary.main" }}>
          <span>
            Funding Rate
          </span> / <span>
            Countdown
          </span>
        </Typography>
        {!ticker?.data?.fundingRate ? (
          <Skeleton width={100} height={21} />
        ) : (
          <Typography variant={"h6"} sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "5px", color: "#00B7F1" }}>
            <Box component="span" display="flex" justifyContent="center" alignItems="center">
              <BoltIcon/>
              {Number(ticker.data.fundingRate)*100 >= 0 ? `+${(Number(ticker?.data?.fundingRate)*100).toFixed(5)}` : (Number(ticker?.data?.fundingRate)*100).toFixed(5)}%
            </Box> / <span>
              {nextFundingTime}
            </span>
          </Typography>
        )}
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          // maxWidth: "700px"
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          style: { maxWidth: "300px" }
        }}
      >
        <Typography sx={{ p: 1 }}>
          {+(ticker?.data.fundingRate??0) >= 0 ? 
            <>
              Funding fees will be exchanged between long and short position holders every 8 hours. Please note that the funding rate will fluctuate in real time every 8 hours. If the funding rate is positive upon settlement, long position holders will pay short position holders. If the funding rate is negative, short position holders will pay long position holders.              
              Your position value at the timestamp when funding is settled will be used to derive your funding fees.   
              Funding Fees = Position Value * Funding Rate
            </> 
            : 
            <>
              The funding rate at {moment(+(ticker?.data.nextFundingTime??0)).format("YYYY-MM-DD hh:mm:ss")}. If the rate is positive, the long traders will pay the short traders. If the rate is negative, the short traders will pay the long traders. 
              (Estimated {Number(ticker?.data.fundingRate)*100 >= 0 ? `+${(Number(ticker?.data?.fundingRate)*100).toFixed(5)}` : (Number(ticker?.data?.fundingRate)*100).toFixed(5)}% of holding position's worth to be paid by the long traders to the short traders in the current cycle, subject to the actual funding rate at the time of settlement.)

              (Estimated {Number(ticker?.data.fundingRate)*100 >= 0 ? `+${(Number(ticker?.data?.fundingRate)*100).toFixed(5)}` : (Number(ticker?.data?.fundingRate)*100).toFixed(5)}% of holding position's worth to be paid by the short traders to the long traders in the current cycle, subject to the actual funding rate at the time of settlement.)
            </>
          }
        </Typography>
      </Popover>
    </Container>
  );
};

const Container = styled(Box)`
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: start;
  }
`;
