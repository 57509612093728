import styled from "@emotion/styled"

export const ExchangeFormLoader = () => {
    return (
      <StyledExchangeFormLoader.Wrapper>
        <StyledExchangeFormLoader.Icon src="/images/logo.svg" />
      </StyledExchangeFormLoader.Wrapper>
    )
  }
  
  const StyledExchangeFormLoader = {
    Wrapper: styled.div`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    Icon: styled.img`
      animation: pulseAnimation 1.5s ease-in-out infinite;
  
      @keyframes pulseAnimation {
        0%, 100% {
          transform: scale(1);
          opacity: 1;
        }
        50% {
          transform: scale(1.2);
          opacity: 0.8;
        }
      }
    `,
  }