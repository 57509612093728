import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import { AdditionalLayout } from "components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import LanguageIcon from "@mui/icons-material/Language";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import CloseIcon from "@mui/icons-material/Close";
import {
  StyledDialogTitle,
  StyledButtonLang,
  StyledButtonLangValue,
  StyledButtonLangValueActive,
  StyledDialog,
  StyledLangName,
} from "../Settings.style";

const languages = [
  {
    name: "English",
    value: "EN",
  },
  {
    name: "French",
    value: "FR",
  },
  {
    name: "German",
    value: "DE",
  },
  {
    name: "Portugese",
    value: "PT",
  },
  {
    name: "Italian",
    value: "IT",
  },
  {
    name: "Arabic",
    value: "AR",
  },
  {
    name: "Dutch",
    value: "NL",
  },
  {
    name: "Hebrew",
    value: "IL",
  },
  {
    name: "Spanish",
    value: "ES",
  },
];

export const ChangeLanguage = ({
  open,
  setOpen,
  lang,
  setLang,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  lang: any;
  setLang: any;
}) => {
  return (
    <StyledDialog open={open} onClose={setOpen.bind(null, false)}>
      <StyledDialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span>Language</span>
          <IconButton onClick={setOpen.bind(null, false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent>
        <Grid container>
          {languages.map((item, index) => (
            <Grid item key={index} xs={4}>
              <StyledButtonLang
                onClick={() => setLang(item)}
                color={lang.value == item.value ? "primary" : "inherit"}
                variant="text"
              >
                <div>
                  <StyledLangName>{item.name} </StyledLangName>
                  {lang.value == item.value ? (
                    <StyledButtonLangValueActive>{item.value}</StyledButtonLangValueActive>
                  ) : (
                    <StyledButtonLangValue>
                      {item.value}
                    </StyledButtonLangValue>
                  )}
                </div>
              </StyledButtonLang>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};
