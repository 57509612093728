import styled from "@emotion/styled"

interface ExchangeFootProps {
    differences: string
    fee: string
}

export const ExchangeFoot = ({ differences, fee }: ExchangeFootProps) => {
    return (
        <StyledExchangeFoot.Wrapper>
            <StyledExchangeFoot.Text>
                {differences}
            </StyledExchangeFoot.Text>
            <StyledExchangeFoot.Text>
                {fee}
            </StyledExchangeFoot.Text>
        </StyledExchangeFoot.Wrapper>
    )
}

const StyledExchangeFoot = {
    Wrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 9px;
    `,
    Text: styled.span`
        font-family: Poppins;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        color: #E7E7E7;
    `,
}