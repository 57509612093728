// import img from 'next/image'
import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { useAppDispatch } from "hooks/useAppDispatch";
import { useTypedSelector } from "hooks/useTypedSelector";
import ReactCodeInput from "react-code-input";
import { removeOtpError } from "store/profile/profile.slice";
import { loginThunk } from "store/profile/profile.thunk";

import { Alert, Button, Snackbar, Typography } from "@mui/material";

import styles from "../auth.module.scss";

interface ITwoFAcode {
  setStatus: Dispatch<SetStateAction<string>>;
}
const TwoFAcode: FC<ITwoFAcode> = (props) => {
  const { loginData, error, otpWrong } = useTypedSelector(
    (state) => state.profile
  );
  const [code, setCode] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    dispatch(
      loginThunk({
        password: loginData.password,
        email: loginData.email,
        otp: code,
      })
    );
  };

  return (
    <>
      <h1 className={styles["twoFAcode-title"]}>Please enter 2FA code</h1>
      <span className={styles["auth-note"]}>
        2FA has been enabled for your account. Please enter the code in Google
        Authenticator app and press verify
      </span>

      <ReactCodeInput
        type="number"
        className="no-spin"
        inputStyle={{
          borderBottom: otpWrong ? "2px solid red" : "2px solid #2E2009",
          borderLeft: "none",
          borderTop: "none",
          borderRight: "none",
          margin: "4px",
          paddingLeft: "8px",
          width: "36px",
          outline: "none",
          height: "42px",
          fontFamily: "Poppins",
          fontWeight: 600,
          background: "transparent",
          fontSize: "18px",
          lineHeight: "27px",
          textAlign: "center",
          color: "#fff",
          marginLeft: 22,
        }}
        name={"vdvd"}
        inputMode={"tel"}
        fields={6}
        onChange={(value) => {
          dispatch(removeOtpError());
          setCode(value);
        }}
      />
      <Button
        sx={{ mt: 7, whiteSpace: "nowrap" }}
        size={"medium"}
        onClick={handleSubmit}
        variant={"outlined"}
      >
        Submit
      </Button>

      <Typography
        className={"hover"}
        mt={3}
        align={"center"}
        color={"#9F7A40"}
        variant={"h6"}
      >
        Dont have access?{" "}
        <a
          style={{ color: "#9F7A40", textDecoration: "underline" }}
          href="mailto:support@bitocol.com"
        >
          Contact support
        </a>
      </Typography>
      <Snackbar
        open={openSuccess}
        autoHideDuration={111000}
        onClose={setOpenSuccess.bind(null, false)}
      >
        <Alert
          style={{ background: "#419E6A" }}
          onClose={setOpenSuccess.bind(null, false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Code entered successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={setOpenError.bind(null, false)}
      >
        <Alert
          color="error"
          style={{ color: "#fff", background: "#D83232" }}
          onClose={setOpenError.bind(null, false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {"Code incorrect, please retry"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TwoFAcode;
