import { FC, ReactNode } from "react";
import { DefaultLayout } from "../DefaultLayout/DefaultLayout";
import { ExchangeNav } from "../../pages/Exchange/components/ExchangeNav/ExchangeNav";
import { Box, styled } from "@mui/material";

interface IProps {
  children: ReactNode;
}

export const ExchangeLayout: FC<IProps> = ({ children }) => {
  return (
    <DefaultLayout mode={"dark"}>
      <ExchangeNav />
      <StyledContent>
        {children}
      </StyledContent>
    </DefaultLayout>
  );
};

const StyledContent = styled(Box)`
  width: 100%;
  height: 100%;
  padding-left: 96px;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
`