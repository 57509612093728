import { FC, memo, useEffect, useMemo, useState } from 'react'

// import { useSnackbar } from "notistack";

import styled from '@emotion/styled'
import {
	Box,
	Button,
	Grid,
	IconButton,
	Modal,
	Tab,
	Tabs,
	Typography,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { useAppDispatch } from 'hooks/useAppDispatch'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { ExchangeLayout } from 'layouts/ExchangeLayout/ExchangeLayout'
import { ordersGetThunk } from 'store/exchange/exchange.thunk'
import { setUpdatedTokens } from 'store/profile/profile.slice'
import {
	getByBitWalletBalanceThunk,
	getWalletsThunk,
	getWalletThunk,
} from 'store/wallets/wallets.thunk'
// import { IOrderCreateReq } from "types/exchange.types";

import { ExchangeBalances } from './components/ExchangeBalances/ExchangeBalances'
import { ExchangeHeader } from './components/ExchangeHeader/ExchangeHeader'
import { ExchangeOrders } from './components/ExchangeOrders/ExchangeOrders'

import { byBitApi } from 'api'
import { useDrawerManager } from 'hooks/useDrawerManager'
import { useOpenOrders } from 'hooks/useOpenOrders'
import { useSnackbar } from 'notistack'
import { useByBit, withByBitProvider } from 'providers/ByBitProvider'
import { OpenOrdersProvider } from 'providers/OpenOrderProvider'
import {
	addPairChangingCounter,
	setSelectedPair,
	setSelectedPairId,
} from 'store/exchange/exchange.slice'
import {
	StyledButtonWrapper,
	StyledIconButton,
	StyledSnackbar,
	StyledWarningRoundedIcon,
	StyledWrapper,
} from './components/ExchangeOrders/ExchangeOrders.styles'
import { ExchangePnL } from './components/ExchangePnL'
import {
	CloseLimitOrderForm,
	ExchangePositions,
} from './components/ExchangePositions'
import { ExchangeTopRight } from './components/ExchangeTopRight/ExchangeTopRight'
import { ExchangeTradingChartContainer } from './components/ExchangeTradingChart/ExchangeTradingChart'
import { ExchangeBook } from './components/ExhangeBook/ExchangeBook'

interface IProps {}

const Exchange: FC<IProps> = () => {
	const dispatch = useAppDispatch()
	const tokensUpdated = useTypedSelector(state => state.profile.tokensUpdated)

	// const [connected, setConnected] = useState(false);
	const updateList = () => dispatch(ordersGetThunk())

	useEffect(() => {
		dispatch(ordersGetThunk())
		dispatch(getWalletsThunk())
	}, [])

	useEffect(() => {
		if (tokensUpdated) {
			dispatch(setUpdatedTokens(false))
		}
	}, [tokensUpdated])

	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)

	useEffect(() => {
		if (selectedPair) {
			dispatch(getWalletThunk(selectedPair?.quoteCoin))
			// setConnected(false);
		}
	}, [selectedPair])

	return (
		<OpenOrdersProvider>
			<ExchangeLayout>
				<Content container>
					<OrderBook item>
						<ExchangeBook />
					</OrderBook>
					<Center sx={{ padding: '0 5px' }} item gap={1}>
						<ExchangeHeader />
						<MemorizedExchangeTradingChartContainer />
						<ExchangeCenterBottom />
					</Center>

					<Left item>
						<ExchangeTopRight />
						<ExchangeBalances />
					</Left>
				</Content>
			</ExchangeLayout>
		</OpenOrdersProvider>
	)
}

const MemorizedExchangeTradingChartContainer = memo(
	ExchangeTradingChartContainer
)

const ExchangeCenterBottom = () => {
	const { positions, orders } = useOpenOrders()
	const currencies = useTypedSelector(state => state.exchange.currencies)
	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
	const { setDrawerId } = useDrawerManager()
	const { enqueueSnackbar } = useSnackbar()

	const { ticker } = useByBit()
	const [tab, setTab] = useState<number>(0)
	const dispatch = useAppDispatch()

	const mappedPositions = useMemo(() => {
		return (
			positions?.list.map(item => ({
				...item,
				unrealisedPnl:
					item.symbol === selectedPair?.symbol
						? !!ticker
							? String(
									(+(ticker?.data.lastPrice || 0) - +item.avgPrice) * +item.size
							  )
							: '0'
						: item.unrealisedPnl,
			})) ?? []
		)
	}, [positions, selectedPair])

	const mappedOrders = useMemo(() => {
		return orders?.list ?? []
	}, [orders])

	return (
		<Box sx={{ width: '100%', marginBlock: '16px' }}>
			<Tabs
				style={{ minHeight: 0 }}
				value={tab}
				onChange={(_, index) => setTab(index)}
			>
				<Tab
					sx={{
						'&.Mui-selected': {
							color: '#00B7F1',
						},
						minWidth: 0,
						minHeight: 0,
						padding: 1,
						textTransform: 'none',
						color: 'white !important',
					}}
					label={`Positions (${positions?.list.length ?? 0})`}
				/>
				<Tab
					sx={{
						'&.Mui-selected': {
							color: '#00B7F1',
						},
						minWidth: 0,
						minHeight: 0,
						padding: 1,
						textTransform: 'none',
						color: 'white !important',
					}}
					label={`P&L`}
				/>
				<Tab
					sx={{
						'&.Mui-selected': {
							color: '#00B7F1',
						},
						minWidth: 0,
						minHeight: 0,
						padding: 1,
						textTransform: 'none',
						color: 'white !important',
					}}
					label={`Orders (${orders?.list.length ?? 0})`}
				/>
			</Tabs>
			<TabWrapper>
				{tab === 0 ? (
					<>
						{!!positions && (
							<ExchangePositions
								items={mappedPositions}
								title='Positions'
								renderMessage={({ item, setItem, handleClose }) => (
									<Modal open={Boolean(item)} onClose={handleClose}>
										<Box
											sx={{ outline: 'none' }}
											position='absolute'
											top='50%'
											left='50%'
											width='100%'
											maxWidth='420px'
											style={{ transform: 'translate(-50%, -50%)' }}
											bgcolor='#1C1E24'
											boxShadow={24}
											p='24px'
											borderRadius='4px'
										>
											<Box
												display='flex'
												justifyContent='space-between'
												alignItems='center'
											>
												<Typography fontSize='16px' fontWeight='600'>
													Close by order by limit
												</Typography>
												<IconButton>
													<CloseIcon
														style={{ color: 'white' }}
														onClick={handleClose}
													/>
												</IconButton>
											</Box>
											<Box
												sx={{ marginTop: '10px' }}
												display='flex'
												flexDirection='column'
												gap='10px'
											>
												{item && (
													<CloseLimitOrderForm
														item={item}
														onSubmit={async ({ type, price }) => {
															try {
																if (!item) throw new Error('Order is null')
																console.log(item)
																if (item.symbol !== selectedPair?.symbol) {
																	enqueueSnackbar(
																		'You can only close orders on the same pair. You are being redirected to the correct pair, where you can close it.',
																		{
																			variant: 'error',
																			anchorOrigin: {
																				vertical: 'top',
																				horizontal: 'center',
																			},
																		}
																	)
																	const filteredCurrencies =
																		currencies?.result.list.filter(
																			currency =>
																				String(currency.symbol) ===
																				String(item.symbol)
																		)
																	if (
																		filteredCurrencies &&
																		filteredCurrencies.length
																	) {
																		dispatch(
																			setSelectedPair(filteredCurrencies[0])
																		)
																	}

																	dispatch(setSelectedPairId(+item.symbol))
																	setDrawerId(null)
																	dispatch(addPairChangingCounter())
																	return
																}
																await byBitApi.placeOrder({
																	category: 'linear',
																	price: String(price),
																	qty: String(item.size),
																	orderType: type === 0 ? 'Limit' : 'Market',
																	symbol: `${selectedPair?.baseCoin}${selectedPair?.quoteCoin}`,
																	side: item.side === 'Buy' ? 'Sell' : 'Buy',
																	isLeverage: 0,
																})
																dispatch(getByBitWalletBalanceThunk({}))
															} catch (err) {
															} finally {
																setItem(null)
															}
														}}
													/>
												)}
											</Box>
										</Box>
									</Modal>
								)}
							/>
						)}
					</>
				) : tab === 1 ? (
					<ExchangePnL />
				) : (
					<>
						{!!orders && (
							<ExchangeOrders
								items={mappedOrders}
								title='Orders'
								renderMessage={({ item, handleClose, setItem }) => (
									<StyledSnackbar
										anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
										open={Boolean(item)}
										onClose={handleClose}
										message={
											<div>
												<Box display={'flex'} justifyContent={'flex-end'}>
													<StyledIconButton onClick={handleClose}>
														<CloseRoundedIcon />
													</StyledIconButton>
												</Box>
												<StyledWrapper
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}
												>
													<StyledWarningRoundedIcon />
													<div>
														<div>
															Cancel {selectedPair?.baseCoin}/
															{selectedPair?.quoteCoin} order.
														</div>
													</div>
												</StyledWrapper>

												<StyledButtonWrapper
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}
												>
													<Button
														onClick={async () => {
															try {
																if (!item) throw new Error('Order is null')
																await byBitApi.cancelOrder({
																	symbol: item.symbol,
																	orderId: item.orderId,
																})
																dispatch(getByBitWalletBalanceThunk({}))
															} catch (err) {
															} finally {
																setItem(null)
															}
														}}
														variant='outlined'
													>
														Confirm
													</Button>
													<Button onClick={() => setItem(null)}>Decline</Button>
												</StyledButtonWrapper>
											</div>
										}
									/>
								)}
							/>
						)}
					</>
				)}
			</TabWrapper>
		</Box>
	)
}

export default withByBitProvider(Exchange)

const Content = styled(Grid)`
	display: flex;
	flex-wrap: nowrap;
	height: 100%;
	width: 100%;
	min-height: calc(100vh - 81px);
	position: relative;
	overflow: hidden;
	@media (max-width: 1024px) {
		flex-direction: column;
	}
`

const OrderBook = styled(Grid)`
	width: 22.5%;
	max-width: 270px;
	@media (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
	}
`

const Center = styled(Grid)`
	width: 55%;
	flex: 1;
	@media (max-width: 1024px) {
		width: 100%;
	}
`

const Left = styled(Grid)`
	width: 22.5%;
	max-width: 301px;
	@media (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
	}
`

const TabWrapper = styled.div`
	width: 100%;
	max-height: 300px;
	overflow: auto;
	/* @media (max-width: 1024px) {
    width: 100vw;
  } */
`
