import {FC, ReactNode} from 'react'
import {createPortal} from "react-dom";

interface IProps {
    children: ReactNode
}

export const Portal: FC<IProps> = ({children}) => {
    return createPortal(children, document.getElementById('modal') as HTMLElement)
}
