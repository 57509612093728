import './Checkbox.style.scss';

export const Checkbox = (props: {checked: any, changeEvent: any}) => {
    return (
  <form style={{display: "inline"}}>
    <div className="form-group">
    {/* <input checked={props.checked} onChange={props.changeEvent} />pe="checkbox" id="html"/> */}
    <input name="policyAgreement" type="checkbox" onChange={props.changeEvent} id="html"/>
      <label htmlFor="html" style={{color: "#fff"}}></label>
    </div>

  </form>
//   <form>
//         <div className="form-group">
//         <input checked={props.checked} onChange={props.changeEvent} type="checkbox" id="html"/>
//       <label htmlFor="css">CSS</label>
//     </div>
//   </form>
    )
}