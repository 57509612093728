export const headerNav = [
  {
    title: "Dashboard",
    icon: "/images/navigation/dashboardMenuIcon.svg",
    activeIcon: "/images/navigation/dashboardMenuActiveIcon.svg",
    link: "/dashboard/overview",
  },
  {
    title: "Markets",
    icon: "/images/navigation/marketsMenuIcon.svg",
    activeIcon: "/images/navigation/marketsMenuActiveIcon.svg",
    link: "/markets",
  },
  {
    title: "Trade",
    icon: "/images/navigation/swap.svg",
    activeIcon: "/images/navigation/swapActive.svg",
    link: "/exchange",
  },
  {
    title: "Balances",
    icon: "/images/navigation/balancesMenuIcon.svg",
    activeIcon: "/images/navigation/balancesMenuActiveIcon.svg",
    link: "/dashboard/wallets",
  },
  {
      title: 'Exchange',
      icon: '/images/navigation/swap.svg',
      activeIcon: '/images/navigation/swapActive.svg',
      link: '/simple-exchange',
  },
];
export const additionalMenu = [
  {
    title: "Overview",
    link: "/dashboard/overview",
    icon: "/images/navigation/overviewMenuIcon.svg",
    activeIcon: "/images/navigation/overviewMenuActiveIcon.svg",
  },
  {
    title: "Verification",
    link: "/dashboard/verification",
    icon: "/images/navigation/verificationMenuIcon.svg",
    activeIcon: "/images/navigation/verificationMenuActiveIcon.svg",
  },
  {
    title: "Deposit",
    link: "/dashboard/deposit",
    icon: "/images/navigation/depositsMenuIcon.svg",
    activeIcon: "/images/navigation/depositsMenuActiveIcon.svg",
  },
  {
    title: "Wallets",
    link: "/dashboard/wallets",
    icon: "/images/navigation/walletIcon.svg",
    activeIcon: "/images/navigation/walletActiveIcon.svg",
  },
  {
    title: "Affiliates",
    link: "/dashboard/affiliates",
    icon: "/images/navigation/affilatesMenuIcon.svg",
    activeIcon: "/images/navigation/affilatesMenuActiveIcon.svg",
  },
  {
    title: "Settings",
    link: "/dashboard/settings",
    icon: "/images/navigation/settings.svg",
    activeIcon: "/images/navigation/settingsActive.svg",
  },
  {
    title: "Helpdesk",
    link: "/dashboard/helpdesk",
    icon: "/images/navigation/helpdeskMenuIcon.svg",
    activeIcon: "/images/navigation/helpdeskMenuActiveIcon.svg",
  },
];

export const loggedNav = [
  {
    name: "Trade",
    activeIcon: (
      <img
        src="/images/navigation/tradeMenuActiveIcon.svg"
        alt="trade navigation icon"
        width={32}
        height={32}
      />
    ),
    defaultIcon: (
      <img
        src="/images/navigation/tradeMenuIcon.svg"
        alt="trade navigation icon"
        width={32}
        height={32}
      />
    ),
    path: "/trading",
  },
  {
    name: "Orders",
    activeIcon: (
      <img
        src="/images/navigation/ordersMenuActiveIcon.svg"
        alt="orders navigation icon"
        width={32}
        height={32}
      />
    ),
    defaultIcon: (
      <img
        src="/images/navigation/ordersMenuIcon.svg"
        alt="orders navigation icon"
        width={32}
        height={32}
      />
    ),
    path: "/orders",
    submenus: [
      {
        name: "All",
        path: "/orders/all",
      },
      {
        name: "Open",
        path: "/orders/open",
      },
    ],
  },
  {
    name: "History",
    activeIcon: (
      <img
        src="/images/navigation/historyMenuActiveIcon.svg"
        alt="history navigation icon"
        width={32}
        height={32}
      />
    ),
    defaultIcon: (
      <img
        src="/images/navigation/historyMenuIcon.svg"
        alt="history navigation icon"
        width={32}
        height={32}
      />
    ),
    path: "/history",
    submenus: [
      {
        name: "Deposits",
        path: "/history/deposits",
      },
      {
        name: "Withdraws",
        path: "/history/withdraws",
      },
    ],
  },
  {
    name: "Settings",
    activeIcon: (
      <img
        src="/images/navigation/settingsMenuActiveIcon.svg"
        alt="settings navigation icon"
        width={32}
        height={32}
      />
    ),
    defaultIcon: (
      <img
        src="/images/navigation/settingsMenuIcon.svg"
        alt="settings navigation icon"
        width={32}
        height={32}
      />
    ),
    path: "/settings",
  },
];

export const notLoggedNav = [
  {
    name: "Settings",
    activeIcon: (
      <img
        src="/images/navigation/settingsMenuActiveIcon.svg"
        alt="trade navigation icon"
        width={32}
        height={32}
      />
    ),
    defaultIcon: (
      <img
        src="/images/navigation/settingsMenuIcon.svg"
        alt="settings navigation icon"
        width={32}
        height={32}
      />
    ),
    path: "/settings",
  },
];
