import { instance } from "./axios";

interface SetLeverageArgs {
  buyLeverage: string;
  category: string;
  sellLeverage: string;
  symbol: string;
}

interface SwitchMarginArgs {
  buyLeverage: string;
  category: string;
  sellLeverage: string;
  symbol: string;
  tradeMode: number;
}

interface PlaceOrderArgs {
  category: string;
  symbol: string;
  isLeverage: number;
  side: string;
  orderType: string;
  qty: string;
  OrderIv?: string;
  TriggerPrice?: string;
  closeOnTrigger?: boolean;
  mmp?: boolean;
  orderFilter?: string;
  orderLinkId?: string;
  positionIdx?: number;
  price?: string;
  reduceOnly?: boolean;
  slLimitPrice?: string;
  slOrderType?: string;
  slTriggerBy?: string;
  smpType?: string;
  stopLoss?: string;
  takeProfit?: string;
  timeInForce?: string;
  tpLimitPrice?: string;
  tpOrderType?: string;
  tpTriggerBy?: string;
  tpslMode?: string;
  triggerBy?: string;
  triggerDirection?: number;
}

interface GetCoinListArgs {
  cursor?: string;
  limit?: number;
}

export interface ByBitCoinBody {
  configList: ByBitCoinData[];
  nextPageCursor: string;
}
export interface ByBitCoinData {
  blockConfirmNumber: number;
  chain: string;
  chainType: string;
  coin: string;
  coinShowName: string;
  minDepositAmount: string;
}

interface GetNetworkListArgs {
  coin: string;
}

export interface ByBitNetworkChain {
  chain: string;
  chainDeposit: string;
  chainType: string;
  chainWithdraw: string;
  confirmation: string;
  depositMin: string;
  minAccuracy: string;
  withdrawFee: string;
  withdrawMin: string;
  withdrawPercentageFee: string;
}

export interface ByBitNetworkRow {
  chains: ByBitNetworkChain[];
  coin: string;
  name: number;
  remainAmount: string;
}

export interface ByBitNetworkBody {
  rows: ByBitNetworkRow[];
}

export interface GetSubDepositAddressArgs {
  chainType: string;
  coin: string;
}

export interface ByBitSubDepositAddress {
  chains: [
    {
      addressDeposit: string;
      batchReleaseLimit: string;
      chain: string;
      chainType: string;
      tagDeposit: string;
    }
  ];
  coin: string;
}

export interface ByBitOpenOrdersBody {
  list: ByBitOpenOrder[];
}

export interface ByBitDepositsHistoryArgs {
  coin?: string;
  cursor?: string;
  endTime?: number;
  limit?: number;
  startTime?: number;
  subMemberId?: string;
}

export interface ByBitDepositsHistoryBody {
  nextPageCursor: string;
  rows: ByBitDepositsHistoryData[];
}

export interface ByBitDepositsHistoryData {
  amount: string;
  batchReleaseLimit: string;
  blockHash: string;
  chain: string;
  coin: string;
  confirmations: string;
  depositFee: string;
  depositType: number;
  status: number;
  successAt: string;
  tag: string;
  toAddress: string;
  txID: string;
  txIndex: string;
}

export interface ByBitOpenOrder {
  bonusChange: string;
  cashBalance: string;
  cashFlow: string;
  category: string;
  change: string;
  currency: string;
  fee: string;
  feeRate: string;
  funding: string;
  id: string;
  orderId: string;
  orderLinkId: string;
  qty: string;
  side: string;
  size: string;
  symbol: string;
  tradeId: string;
  tradePrice: string;
  transactionTime: string;
  type: string;
}

export interface ByBitWithdrawsHistoryArgs {
  coin?: string;
  cursor?: string;
  endTime?: number;
  limit?: number;
  startTime?: number;
  txID?: string;
  withdrawID?: string;
  withdrawType?: number;
}

export interface ByBitWithdrawsHistoryBody {
  nextPageCursor: string;
  rows: ByBitWithdrawsHistoryData[];
}

export interface ByBitWithdrawsHistoryData {

    createdAt: string
    done: boolean
    error: string
    id: string
    request: {
      accountType: string
      address: string
      amount: string
      chain: string
      coin: string
      feeType: number
      forceChain: number
      requestId: string
      tag: string
      timestamp: number
    }
}

export interface ByBitOrdersHistoryArgs {
  baseCoin: string,
  category: string,
  cursor?: string,
  endTime?: number,
  limit?: number,
  orderFilter?: string,
  orderId?: string,
  orderLinkId?: string,
  orderStatus?: string,
  settleCoin?: string,
  startTime?: number,
  symbol?: string
}

export interface ByBitOrdersHistoryBody {
  category: string,
  list: ByBitOrdersHistoryBodyData[],
  nextPageCursor: string
}

export interface ByBitOrdersHistoryBodyData {
  avgPrice: string,
  blockTradeId: string,
  cancelType: string,
  closeOnTrigger: boolean,
  createType: string,
  createdTime: string,
  cumExecFee: string,
  cumExecQty: string,
  cumExecValue: string,
  isLeverage: string,
  lastPriceOnCreated: string,
  leavesQty: string,
  leavesValue: string,
  marketUnit: string,
  ocoTriggerType: string,
  orderId: string,
  orderIv: string,
  orderLinkId: string,
  orderStatus: string,
  orderType: string,
  placeType: string,
  positionIdx: number,
  price: string,
  qty: string,
  reduceOnly: boolean,
  rejectReason: string,
  side: string,
  slLimitPrice: string,
  slTriggerBy: string,
  smpGroup: string,
  smpOrderId: string,
  smpType: string,
  stopLoss: string,
  stopOrderType: string,
  symbol: string,
  takeProfit: string,
  timeInForce: string,
  tpLimitPrice: string,
  tpTriggerBy: string,
  tpslMode: string,
  triggerBy: string,
  triggerDirection: number,
  triggerPrice: string,
  updatedTime: string
}

export interface ByBitOrdersBody {
  category: string;
  list: ByBitOrder[];
  nextPageCursor: string;
}

export interface ByBitOrder {
  avgPrice: string,
  blockTradeId: string,
  cancelType: string,
  closeOnTrigger: boolean,
  createdTime: string,
  cumExecFee: string,
  cumExecQty: string,
  cumExecValue: string,
  isLeverage: string,
  lastPriceOnCreated: string,
  leavesQty: string,
  leavesValue: string,
  orderId: string,
  orderIv: string,
  orderLinkId: string,
  orderStatus: string,
  orderType: string,
  placeType: string,
  positionIdx: number,
  price: string,
  qty: string,
  reduceOnly: boolean,
  rejectReason: string,
  side: string,
  slLimitPrice: string,
  slTriggerBy: string,
  smpGroup: number,
  smpOrderId: string,
  smpType: string,
  stopLoss: string,
  stopOrderType: string,
  symbol: string,
  takeProfit: string,
  timeInForce: string,
  tpLimitPrice: string,
  tpTriggerBy: string,
  tpslMode: string,
  triggerBy: string,
  triggerDirection: number,
  triggerPrice: string,
  updatedTime: string
}

export interface ByBitPositionsBody {
  category: string;
  list: ByBitPosition[];
  nextPageCursor: string;
}

export interface ByBitPosition {
  adlRankIndicator: number;
  autoAddMargin: number;
  avgPrice: string;
  bustPrice: string;
  createdTime: string;
  cumRealisedPnl: string;
  curRealisedPnl: string;
  isReduceOnly: boolean;
  leverage: string;
  leverageSysUpdatedTime: string;
  liqPrice: string;
  markPrice: string;
  mmrSysUpdateTime: string;
  positionBalance: string;
  positionIM: string;
  positionIdx: number;
  positionMM: string;
  positionStatus: string;
  positionValue: string;
  riskId: number;
  riskLimitValue: string;
  seq: number;
  sessionAvgPrice: string;
  side: string;
  size: string;
  stopLoss: string;
  symbol: string;
  takeProfit: string;
  tpslMode: string;
  tradeMode: number;
  trailingStop: string;
  unrealisedPnl: string;
  updatedTime: string;
}

export interface ByBitFeeRateBody {
  category: string;
  list: ByBitFeeRate[]
}

export interface ByBitFeeRate {
  baseCoin: string
  makerFeeRate: string
  symbol: string
  takerFeeRate: string
}

interface SetTradingStopArgs {
  category: string;
  symbol: string;
  takeProfit?: string;
  stopLoss?: string;
  trailingStop?: string;
  tpTriggerBy?: string;
  slTriggerBy?: string;
  activePrice?: string;
  tpslMode: string;
  tpSize?: string;
  slSize?: string;
  tpLimitPrice?: string;
  slLimitPrice?: string;
  tpOrderType?: string;
  slOrderType?: string;
  positionIdx: 0 | 1 | 2;
}

export interface ByBitClosedPnLBody {
  category: string,
  list: ByBitClosedPnL[],
  nextPageCursor: string
}

export interface ByBitClosedPnL {
  avgEntryPrice: string,
  avgExitPrice: string,
  closedPnl: string,
  closedSize: string,
  createdTime: string,
  cumEntryValue: string,
  cumExitValue: string,
  execType: string,
  fillCount: string,
  leverage: string,
  orderId: string,
  orderPrice: string,
  orderType: string,
  qty: string,
  side: string,
  symbol: string,
  updatedTime: string
}

interface AmountOrderArgs {
  category: "linear" | "inverse" | "spot" | "option";
  symbol: string;
  orderId?: string;
  orderLinkId?: string;
  orderIv?: string; // option only
  triggerPrice?: string;
  qty?: string;
  price?: string;
  tpslMode?: "Full" | "Partial"; // Valid for linear & inverse
  takeProfit?: string;
  stopLoss?: string;
  tpTriggerBy?: string;
  slTriggerBy?: string;
  triggerBy?: string;
  tpLimitPrice?: string;
  slLimitPrice?: string;  
}

export const byBitApi = {
  setLeverage: (args: SetLeverageArgs): Promise<void> =>
    instance.post("/bybit/v1/position/set_leverage", args),
  switchMargin: (args: SwitchMarginArgs): Promise<void> =>
    instance.post("/bybit/v1/position/switch_cross_isolated_margin", args),
  placeOrder: async (args: PlaceOrderArgs) => 
    instance.post("/bybit/v1/order/place", args),
  getCoinList: (args: GetCoinListArgs = {}): Promise<ByBitCoinBody> =>
    instance.post("/bybit/v1/asset/deposit/query-allowed-list", args),
  getNetworkList: (args: GetNetworkListArgs): Promise<ByBitNetworkBody> =>
    instance.post("/bybit/v1/asset/coin/query-info", args),
  getOrdersHistory: (args: ByBitOrdersHistoryArgs): Promise<ByBitOrdersHistoryBody> => instance.post("/bybit/v1/order/history", args),
  setMarginMode: (args: { setMarginMode: string }) => instance.post("/bybit/v1/account/set-margin-mode", args),
  getSubDepositAddress: (
    args: GetSubDepositAddressArgs
  ): Promise<ByBitSubDepositAddress> =>
    instance.post("/bybit/v1/asset/get-sub-deposit-address", args),
  getOpenOrders: (): Promise<ByBitOpenOrdersBody> =>
    instance.post("/bybit/v1/account/transaction-log", {}),
  getPositions: ({
    settleCoin,
  }: {
    settleCoin: string;
  }): Promise<ByBitPositionsBody> =>
    instance.get("/bybit/v1/position/list", {
      params: { category: "linear", settleCoin },
    }),
  getOrders: (): Promise<ByBitOrdersBody> => instance.get("/bybit/v1/order/realtime", { params: { category: "linear", settleCoin: "USDT" } }),
  cancelOrder: (args: {
    symbol: string;
    orderId?: string;
    orderLinkId?: string;
  }) =>
    instance.post(`/bybit/v1/order/cancel`, { ...args, category: "linear" }),
  cancelAllOrder: () => instance.post(`/bybit/v1/order/cancel-all`, {}),
  getWithdrawsHistory: (
    args: ByBitWithdrawsHistoryArgs = {}
  ): Promise<ByBitWithdrawsHistoryBody> =>
    instance.get("/bybit/v1/asset/withdraw/list", { params: args }),
  getDepositsHistory: (
    args: ByBitDepositsHistoryArgs = {}
  ): Promise<ByBitDepositsHistoryBody> =>
    instance.post("/bybit/v1/asset/deposit/query-sub-member-record", args),
  getFeeRate: (args: { baseCoin?: string, symbol?: string }): Promise<ByBitFeeRateBody> => instance.post("/bybit/v1/account/fee-rate", {...args, category: "linear"}),
  setTradingStop: (args: SetTradingStopArgs) => instance.post("/bybit/v1/position/set-trading-stop", args),
  amendOrder: (args: AmountOrderArgs) => instance.post("/bybit/v1/order/amend", args),
  getClosedPnL: (args: any): Promise<ByBitClosedPnLBody> => instance.get("/bybit/v1/position/closed-pnl", { params: args }),
};
