// import { GoTrueAuthProvider } from '@openware/opendax-web-sdk'
// import img from 'next/image'
// import { useRouter } from 'next/router'
import React, { FC, useEffect } from "react";

import { AuthLayout } from "../../layouts/AuthLayout/AuthLayout";
import Login from "./components/Login";
import SignUp from "./components/Signup";
import SignUpSuccess from "./components/SignUpSuccess";
import TwoFAcode from "./components/TwoFAcode";

interface IProps {
	status: "login" | "signup" | "success" | "2FACode";
}

const AuthPage: FC<IProps> = ({ status }) => {
	//States
	const [inStatus, setStatus] = React.useState<string>(status);
	const [notificationEmail, setNotificationEmail] = React.useState<string>("");

	useEffect(() => {
		setStatus(status);
	}, [status]);

	const getAuthPage = (status: string) => {
		switch (status) {
			case "login":
				return <Login setStatus={setStatus} />;
			case "signup":
				return <SignUp setStatus={setStatus} setNotificationEmail={setNotificationEmail} />;
			case "success":
				return <SignUpSuccess setStatus={setStatus} email={notificationEmail} />;
			case "2FACode":
				return <TwoFAcode setStatus={setStatus} />;
		}
	};

	return <AuthLayout>{getAuthPage(inStatus)}</AuthLayout>;
};

export default AuthPage;
