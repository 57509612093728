import axios from 'axios'
import { ByBitTickerData } from 'providers/ByBitProvider'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { LinearPerpetual } from 'types/exchange.types'

interface WithByBitTickerProps {
	data: LinearPerpetual
	[key: string]: any
}

export const withByBitTicker =
	(Component: any) =>
	({ data, ...props }: WithByBitTickerProps) => {
		const [currency, setCurrency] = useState<ByBitTickerData>()
		const value = useMemo(() => {
			if (!currency || !data) return null
			return { ...data, ...currency } as LinearPerpetual & ByBitTickerData
		}, [currency, data])

		const getCurrency = useCallback(async () => {
			try {
				const response = await axios.get(
					`https://api.bybit.com/v5/market/tickers?category=linear&symbol=${data.symbol}`
				)
				setCurrency(response.data.result.list[0])
			} catch (error) {
				console.log(error)
			}
		}, [data])

		useEffect(() => {
			getCurrency()
		}, [getCurrency])

		return <>{!!value && <Component value={value} {...props} />}</>
	}

export const withRealtimeByBitTicker =
	(Component: any) =>
	({ data, ...props }: WithByBitTickerProps) => {
		const [currency, setCurrency] = useState<ByBitTickerData>()
		const value = useMemo(() => {
			if (!currency || !data) return null
			return { ...data, ...currency } as LinearPerpetual & ByBitTickerData
		}, [currency, data])

		const getCurrency = useCallback(async () => {
			try {
				const response = await axios.get(
					`https://api.bybit.com/v5/market/tickers?category=linear&symbol=${data.symbol}`
				)
				setCurrency(response.data.result.list[0])
			} catch (error) {
				console.log(error)
			}
		}, [data])

		const subscribeCurrency = useCallback(async () => {
			const socket = new WebSocket(
				'wss://stream-testnet.bybit.com/v5/public/linear'
			)
			socket.send(`tickers.${data.symbol}`)
			socket.onmessage = message => {
				const data = JSON.parse(message.data)
				if (data.topic === `tickers.${data.symbol}`) {
					setCurrency(data.data)
				}
			}
		}, [data])

		useEffect(() => {
			getCurrency()
			subscribeCurrency()
		}, [subscribeCurrency])

		return <>{!!value && <Component value={value} data={data} {...props} />}</>
	}
