import { LinearPerpetual } from "../../../../types/exchange.types";
import { FC } from "react";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { CoinIcon } from "../../atoms/CoinIcon/CoinIcon";
import { CSSProperties } from "react";
import { ByBitTickerData } from "providers/ByBitProvider";

interface IProps {
  value: LinearPerpetual & ByBitTickerData;
  style: CSSProperties;
  onClick: (pair: LinearPerpetual & ByBitTickerData) => void;
}

export const MarketPlacePairItem = ({ value, onClick, style }: IProps) => {
  const {
    baseCoin,
    quoteCoin,
    lastPrice,
    volume24h,
    price24hPcnt: change24h,
  } = value;
  const pair = `${baseCoin}/${quoteCoin}`;

  return (
    <ListItemButton
      onClick={() => onClick(value)}
      component={"div"}
      style={style}
      sx={{
        ".MuiListItemSecondaryAction-root": {
          width: 22,
        },
        padding: 0,
      }}
    >
      <ListItemIcon>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ width: 30, height: 30 }}
        >
          <CoinIcon style={{ marginRight: '0' }} coinName={baseCoin?.toLowerCase()} />
        </Box>
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography component={"span"}>{pair.split("/")[0]}/</Typography>
              <Typography component={"span"} color={"#80848E"}>
                {pair.split("/")[1]}
              </Typography>
            </Box>

            <Typography>{lastPrice}</Typography>
          </Box>
        }
        secondary={
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{ color: Number(change24h) >= 0 ? "#419E6A" : "#f44336" }}
            >
              {Number(change24h).toFixed(2)}%
            </Typography>
            <Typography color={"#80848E"}>Vol {volume24h}</Typography>
          </Box>
        }
      />
    </ListItemButton>
  );
};
