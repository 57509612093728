import "../affiliatesStyles.scss";

// import img from 'next/image'
import { FC, useEffect, useState } from "react";

import { referalApi } from "api";
// import { withAuth } from '@openware/opendax-web-sdk'
import { AdditionalLayout } from "components";
import { DPaymentItem } from "components/UI/molecules/DPaymentItem/DPaymentItem";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useTypedSelector } from "hooks/useTypedSelector";
import { StyledButton } from "pages/Deposit/Deposit.style";
import { StyledPaymentMethodFormControl } from "pages/Withdraw/Withdraw.style";
import { useNavigate } from "react-router-dom";
import { setReferralThunk } from "store/profile/profile.thunk";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Box,
  Button,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";

const WithDrawComisson: FC = (): JSX.Element | null => {
  const navigate = useNavigate();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  //Redux
  const dispatch = useAppDispatch();
  const referral = useTypedSelector((state) => state.profile.referral);

  //Handlers
  const toMakeARequest = () => {
    navigate("/make-a-request");
  };

  //Effects
  useEffect(() => {
    dispatch(setReferralThunk());
  }, []);

  const paymentHandler = () => {
    referalApi
      .withdrawComission()
      .then((res) => {
        setOpenSuccess(true);
      })
      .catch((err) => {
        setOpenError(true);
      });
  };

  return (
    <AdditionalLayout>
      <div className="affiliatesRoot">
        <div className="whiteBg">
          <div className="affiliates">
            <Box
              className="link"
              display="flex"
              onClick={() => navigate("/dashboard")}
              style={{ margin: "31px 0 0 20px", cursor: "pointer" }}
              alignItems="center"
            >
              <ArrowBackIcon
                sx={{
                  color: "#00B7F1",
                  Width: 14,
                  height: 14,
                }}
              />
              <div
                style={{
                  color: "#00B7F1",
                  fontWeight: 400,
                  fontSize: "12px",
                  marginLeft: 2,
                }}
              >
                Back
              </div>
            </Box>
            <div className="affiliates__content">
              <div className="title">Withdraw pending affiliate commission</div>
              <div className="affiliates__content_subtitle">
                Select which payment method you would like to use to withdraw
                your pending commission.
              </div>

              <div
                style={{ marginTop: 59, color: "#000" }}
                className="affiliates__content_subtitle"
              >
                Pending commission
              </div>

              {referral.data && (
                <Typography
                  color={"#000"}
                  style={{
                    fontWeight: 500,
                    fontSize: "30px",
                    lineHeight: "45px",
                  }}
                  variant={"h4"}
                >
                  {referral.data.balance} USDT
                </Typography>
              )}
            </div>

            <div className="withdraw-method-title">Withdraw method</div>
            <span className="withdraw-method-description">
              Commission will be sent to your spot wallet
            </span>

            <StyledPaymentMethodFormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="SEPA"
                onChange={(val) => {
                  console.log(val);
                }}
                name="radio-buttons-group"
              >
                {/*<DPaymentItem value={'SEPA'} label={'SEPA Transfer'} image={'sepa.svg'} />*/}
                {/* <DPaymentItem
      value={"Euro - (€ EUR)"}
      label={"Euro - (€ EUR)"}
      image={"mastercard.svg"}
    /> */}
                <DPaymentItem
                  value={"USDT"}
                  label={"Tether - (USDT) "}
                  image={"USDT.png"}
                  width={"29px"}
                  height={"29px"}
                />
                {/*<DPaymentItem value={'iDeal'} label={'iDeal'} image={'ideal.svg'} />*/}
              </RadioGroup>
            </StyledPaymentMethodFormControl>
            <Box
              style={{ position: "relative" }}
              display="flex"
              justifyContent="center"
            >
              <StyledButton variant="outlined" onClick={() => paymentHandler()}>
                Withdraw
              </StyledButton>
            </Box>

            <div style={{ display: "flex", justifyContent: "center" }}></div>
          </div>
        </div>

        <div className="needToKnow whiteBg">
          <h3 className="title title__center">Need to know</h3>
          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">Affiliate code</p>
            <p className="needToKnow__block_text">
              The code you can share with your friends which they can enter when
              signing up
            </p>
          </div>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">Affiliate link</p>
            <p className="needToKnow__block_text">
              The link you can share with your friends which they can use to
              sign up to Bitocol
            </p>
          </div>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">Commission</p>
            <p className="needToKnow__block_text">
              The amount of commission you have earned in euro’s, this will be
              deposited in your wallet every 24H.
            </p>
          </div>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">Reffered users</p>
            <p className="needToKnow__block_text">
              The amount of new users brought to the Bitocol platform through
              your affiliate code/link
            </p>
          </div>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">
              Earn up to 50% commission?
            </p>
            <p className="needToKnow__block_text">
              (Super) affiliates are eligible for up to 50% commission and other
              rewards.
            </p>
          </div>

          <Button
            sx={{ p: "0 !important" }}
            variant={"text"}
            onClick={toMakeARequest}
            className="needToKnow__moreBtn"
          >
            <span>Make a request</span>
            <img
              src="/images/readMoreVectorIcon.svg"
              alt="request icon"
              width={13}
              height={11}
            />
          </Button>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">
              Affiliate terms & conditions
            </p>
            <Button
              sx={{ p: "0 !important" }}
              variant={"text"}
              className="needToKnow__moreBtn"
            >
              <span>Read more</span>
              <img
                src="/images/readMoreVectorIcon.svg"
                alt="read more vector icon"
                width={13}
                height={11}
              />
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={setOpenSuccess.bind(null, false)}
      >
        <Alert
          onClose={setOpenSuccess.bind(null, false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          withdrawl was successful
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={setOpenError.bind(null, false)}
      >
        <Alert
          onClose={setOpenError.bind(null, false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {"Amount must be greater than 0"}
        </Alert>
      </Snackbar>
    </AdditionalLayout>
  );
};

export default WithDrawComisson;
