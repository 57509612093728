import { ReactNode, useEffect, useRef } from "react";

interface Props {
  onPaginate: () => void;
  render: ({ listRef }: { listRef: any }) => ReactNode;
}

export const ListPaginationContainer = ({ onPaginate, render }: Props) => {
  const scrollRef = useRef<any>(null);

  useEffect(() => {
    if (!scrollRef.current) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        onPaginate();
      }
    };

    const scrollableElement = scrollRef.current;
    scrollableElement.addEventListener("scroll", handleScroll);
    return () => scrollableElement.removeEventListener("scroll", handleScroll);
  }, [scrollRef, render]);

  return <>{render({ listRef: scrollRef })}</>;
};
