import { Box } from "@mui/material";

import { INTERVALS, Interval } from "./consts";

interface ExchangeTradingHeaderProps {
    interval: string;
    onIntervalSelect: (interval: Interval) => void;
}
  
export const ExchangeTradingHeader = ({ interval, onIntervalSelect }: ExchangeTradingHeaderProps) => {
    return (
        <div
        style={{
            display: "flex",
            gap: 15,
            width: "100%",
            paddingInline: 10,
            paddingBlock: 5,
            background: "#131722"
        }}
        >
        <div style={{ display: "flex", gap: 10 }}>
            {Object.entries(INTERVALS).map(([key, item], index) => (
            <Box
                key={index}
                tabIndex={1}
                sx={{
                cursor: "pointer",
                color:
                    key === interval
                    ? "#00b7f1"
                    : "rgb(200, 200, 200)",
                ":hover": { color: "white" },
                }}
                onClick={() => onIntervalSelect(key as Interval)}
            >
                <span>{item.label}</span>
            </Box>
            ))}
        </div>
        </div>
    );
};
