import { FC } from "react";
import { AppModal } from "./AppModal";
import { EModalId } from "../../types/common.types";
import { CreateDeposit } from "../Forms/CreateDeposit";
import { Typography } from "@mui/material";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { orderCreateThunk } from "../../store/exchange/exchange.thunk";
import { IOrderCreateReq } from "../../types/exchange.types";
import { useModalManager } from "../../hooks/useModalManager";

interface IProps {}

export const CreateOrderModal: FC<IProps> = ({}) => {
  const dispatch = useAppDispatch();

  const handleCreateDeposit = (data: IOrderCreateReq) => {
    dispatch(orderCreateThunk(data));
  };

  const { setModalId } = useModalManager();

  return (
    <AppModal
      modalId={EModalId.createOrder}
      maxWidth={512}
      sx={{ background: "#001030" }}
    >
      <Typography sx={{ fontSize: 18, fontWeight: 600, mb: 30 / 8 }}>
        Deposit
      </Typography>
      <CreateDeposit
        onCancel={() => setModalId(null)}
        onChange={handleCreateDeposit}
      />
    </AppModal>
  );
};
