import 'react-phone-input-2/lib/style.css'

import { forwardRef, useEffect, useState } from 'react'

import { useAppDispatch } from 'hooks/useAppDispatch'
import ReactPhoneInput from 'react-phone-input-2'
import { setChangePhone } from 'store/profile/profile.slice'
import { createPhoneThunk, getCodeThunk } from 'store/profile/profile.thunk'

import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogContent, IconButton } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import { useTimer } from '../../../hooks/useTimer'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
	StyledCodeButton,
	StyledCodesWrapper,
	StyledConfirmButton,
	StyledDialog, StyledDialogContent,
	StyledDialogTitle,
	StyledEmailLabel,
	StyledEmailTitle,
	StyledInputCode,
	StyledPhoneWrapper,
} from '../Settings.style'
import { SuccessSent } from './SuccessSent'

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const ChangePhone = ({
	open,
	setOpen,
}: {
	open: boolean
	setOpen: (value: boolean) => void
}) => {
	const dispatch = useAppDispatch()

	const [newPhone, setNewPhone] = useState('')
	const [newPhoneCode, setNewPhoneCode] = useState('')
	const [phoneCode, setPhoneCode] = useState('')
	const [sendCodeSuccess, setSendCodeSuccess] = useState(false)
	const { data, error } = useTypedSelector(state => state.profile.changePhone)
	const isProfileLoading = useTypedSelector(
		state => state.profile.isProfileLoading
	)

	const { timer } = useTimer()

	const handleOnClickGetCode = () => {
		dispatch(
			getCodeThunk({
				code: newPhoneCode,
				number: newPhone,
			})
		).then(res => {
			res.payload && setSendCodeSuccess(true)
		})
	}

	const handleSubmit = async () => {
		await dispatch(
			createPhoneThunk({
				code: newPhoneCode,
				number: newPhone,
				security_code: phoneCode,
			})
		)
		setOpen(false)
	}

	const [openSuccess, setOpenSuccess] = useState(false)
	const [openError, setOpenError] = useState(false)

	useEffect(() => {
		if (data) {
			setOpen(true)
			dispatch(
				setChangePhone({
					loading: false,
					response: null,
					error: null,
				})
			)
		}
	}, [data])

	useEffect(() => {
		if (error) {
			setOpenError(true)
			dispatch(
				setChangePhone({
					loading: false,
					response: null,
					error: null,
				})
			)
		}
	}, [error])

	console.log(newPhoneCode, newPhone)

	return (
		<StyledDialog open={open} onClose={setOpen.bind(null, false)}>
			<StyledDialogTitle>
				<Box display='flex' alignItems='center' justifyContent='space-between'>
					<span>Verify phone number</span>
					<IconButton onClick={setOpen.bind(null, false)}>
						<CloseIcon />
					</IconButton>
				</Box>
			</StyledDialogTitle>
			<StyledDialogContent>
				<StyledEmailTitle>Phone number verification</StyledEmailTitle>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<StyledPhoneWrapper display='flex' alignItems='center'>
						<div>
							<StyledEmailLabel>Country Code</StyledEmailLabel>
							<Box display='flex' alignItems='left'>
								<div>
									<ReactPhoneInput
										placeholder='+'
										onChange={(value, data: any) => {
											const countryCode = data.dialCode

											const newValue = value.replace(
												new RegExp(`^${countryCode}`),
												''
											)

											setNewPhoneCode('+' + countryCode)
											setNewPhone(newValue)
										}}
										inputStyle={{
											maxWidth: '300px',
											minWidth: '170px',
											width: '100%',
											// width: '95px',
											height: '33px',
											background: '#F9FAFB',
											border: '1px solid #E5E7EB',
											borderRadius: '16px',
										}}
										value={newPhoneCode.replace('+', '')}
										dropdownStyle={{ width: '200px' }}
									/>
								</div>
							</Box>
						</div>
					</StyledPhoneWrapper>
					{/* <div style={{ marginLeft: '34px' }}>
						<StyledEmailLabel>Phone number</StyledEmailLabel>
						<StyledInput
							type='text'
							value={newPhone}
							onKeyDown={evt => {
								const allowedKeys = [
									'Backspace',
									'ArrowLeft',
									'ArrowRight',
									'Delete',
									'Tab',
									'Home',
									'End',
								]

								const isControlKey = allowedKeys.includes(evt.key)
								const isNumberKey = /^[0-9]$/.test(evt.key)
								const isSpecialKey = ['+', '(', ')', '-', ' '].includes(evt.key)

								if (!isNumberKey && !isControlKey && !isSpecialKey) {
									evt.preventDefault()
								}
							}}
							onChange={e => setNewPhone(e.target.value)}
						/>
					</div> */}
					<StyledCodeButton
						style={{ marginTop: 30 }}
						onClick={handleOnClickGetCode}
						className={timer || isProfileLoading ? 'disabled' : ''}
					>
						Get code
					</StyledCodeButton>
					{sendCodeSuccess && <SuccessSent />}
				</div>

				<StyledEmailTitle style={{ marginTop: 45 }}>
					Security verification
				</StyledEmailTitle>

				<StyledCodesWrapper
					display='flex'
					alignItems='center'
					justifyContent='space-between'
				>
					<div>
						<StyledEmailLabel>Phone number verification code</StyledEmailLabel>

						<Box display='flex' alignItems='center'>
							<StyledInputCode
								type='number'
								onKeyDown={evt => {
									if (
										(evt.which != 8 && evt.which != 0 && evt.which < 48) ||
										evt.which > 57
									) {
										evt.preventDefault()
									}
								}}
								value={phoneCode}
								onChange={e => setPhoneCode(e.target.value)}
							/>
						</Box>
					</div>
				</StyledCodesWrapper>
				<StyledConfirmButton
					disabled={
						newPhone == '' ||
						newPhoneCode == '' ||
						phoneCode == '' ||
						isProfileLoading
					}
					onClick={handleSubmit}
				>
					Confirm number
				</StyledConfirmButton>
			</StyledDialogContent>

			<Snackbar
				open={openSuccess}
				autoHideDuration={6000}
				onClose={setOpenSuccess.bind(null, false)}
			>
				<Alert
					onClose={setOpenSuccess.bind(null, false)}
					severity='success'
					sx={{ width: '100%' }}
				>
					Data sent successfully!
				</Alert>
			</Snackbar>

			<Snackbar
				open={openError}
				autoHideDuration={6000}
				onClose={setOpenError.bind(null, false)}
			>
				<Alert
					onClose={setOpenError.bind(null, false)}
					severity='error'
					sx={{ width: '100%' }}
				>
					{error?.detail || 'An error has occurred'}
				</Alert>
			</Snackbar>
		</StyledDialog>
	)
}
