import { IThunkQuery } from 'types/common.types';

import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { storageService } from '../../services/storage.service';
import {
  EProfileError,
  ESaveMode,
  IReferral,
} from '../../types/profile.types';
import { profileState } from './profile.state';
import {
  createAddressThunk,
  createPhoneThunk,
  createUserThunk,
  creteOptChangedisableThunk,
  creteOptChangeThunk,
  forgotPasswordThunk,
  getCodeThunk,
  getMeThunk,
  loginThunk,
  logoutThunk,
} from './profile.thunk';

export const profileSlice = createSlice({
    name: 'profile',
    initialState: profileState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload
        },
        setIsLogin(state, action: PayloadAction<boolean>) {
            state.isLogin = action.payload
        },
        setIsProfileLoading(state, action: PayloadAction<boolean>) {
            state.isProfileLoading = action.payload
        },
        setProfileError(state, action: PayloadAction<EProfileError>) {
            state.error = action.payload
        },
        setSaveMode(state, action: PayloadAction<ESaveMode>) {
            state.saveMode = action.payload
            state.tokensUpdated = true
            storageService.saveMode = action.payload
        },
        setUpdatedTokens(state, action: PayloadAction<any>) {
            state.tokensUpdated = action.payload
        },
        setRequestChangePassword(state, action: PayloadAction<any>) {
            state.changePassword = action.payload
        },
        setChangePassword(state, action: PayloadAction<any>) {
            state.changePassword = action.payload
        },
        setEmail(state, action: PayloadAction<any>) {
            state.forgotPassEmail = action.payload
        },
        
        setForgotEmailCorrectEmail(state, action: PayloadAction<any>) {
            state.requestChangePassword.correctEmail = action.payload
        },
        setPhone(state, action: PayloadAction<any>) {
            state.phone = action.payload
        },
        setChangePhone(state, action: PayloadAction<any>) {
            state.phone = action.payload
        },
        setCreateAddress(state, action: PayloadAction<any>) {
            state.createAddress = action.payload
        },
        setAddress(state, action: PayloadAction<any>) {
            state.address = action.payload
        },
        setReferral(state, action: PayloadAction<IThunkQuery<IReferral>>) {
            state.referral = action.payload
        },
        removeOtpError(state) {
            state.otpWrong = false
        }
        
    },
    extraReducers(builder) {
        //Me

        builder.addCase(getMeThunk.fulfilled, (state, action) => {
            state.me = action.payload
            state.isLogin = true
        })

        //Login

        builder.addCase(loginThunk.rejected, (state, action) => {
            state.loginData = action.meta.arg
            state.isLogin = false
            if (action.payload === 'Inactive user') {
                state.error = EProfileError.inactiveUser
            } else if (action.payload === 'Incorrect email or password') {
                state.error = EProfileError.incorrectAuthData
            } else if (action.payload === 'Otp required') {
                state.error = EProfileError.OtpRequired
            } else if (action.payload === 'Wrong otp') {
                state.otpWrong = true
                state.error = EProfileError.WrongOtp
            }
        })


        builder.addCase(loginThunk.fulfilled, (state, action: any) => {     
            state.isLogin = true
            state.isProfileLoading = false
            state.error = EProfileError.null
            storageService.setTokens = action.payload
        })

        //logoutThunk

        builder.addCase(logoutThunk.rejected, (state, action) => {
            state.isLogin = true
            if (action.payload === 'Inactive user') {
                state.error = EProfileError.inactiveUser
            }
        })

        builder.addCase(logoutThunk.fulfilled, (state, action) => {
            state.isLogin = false
            state.isProfileLoading = false
            storageService.removeTokens()
        })

        //Get Phone
        builder.addCase(getCodeThunk.pending, (state, action) => {
            state.isProfileLoading = true
        })

        builder.addCase(getCodeThunk.rejected, (state, action) => {
            state.isProfileLoading = false
        })

        builder.addCase(getCodeThunk.fulfilled, (state, action) => {
            state.isProfileLoading = false
        })

        //createPhone

        builder.addCase(createPhoneThunk.pending, (state, action) => {
            state.isProfileLoading = true
        })

        builder.addCase(createPhoneThunk.rejected, (state, action) => {
            state.isProfileLoading = false
        })

        builder.addCase(createPhoneThunk.fulfilled, (state, action) => {
            state.isProfileLoading = false
        })

        builder.addCase(createUserThunk.rejected, (state, action) => {
            state.error = EProfileError.UserIsExists
        })

        //change otp
        builder.addCase(creteOptChangeThunk.fulfilled, (state, action) => {
            if(state.me?.verification) {
                state.me.verification.otp_required = action.payload;
            }
        })
        builder.addCase(creteOptChangedisableThunk.fulfilled, (state, action) => {
            if(state.me?.verification) {
                state.me.verification.otp_required = false;
            }
        })
        
        builder.addCase(creteOptChangeThunk.rejected, (state, action) => {
            state.error = EProfileError.OtpRequired
        })

        builder.addCase( forgotPasswordThunk.fulfilled, (state, action) => {
            state.requestChangePassword.correctEmail = true
            state.requestChangePassword.sendedStatus = true
        })
        builder.addCase( forgotPasswordThunk.rejected, (state, action) => {
            state.requestChangePassword.correctEmail = false
            state.requestChangePassword.sendedStatus = true
        })
        //createAddress

        builder.addCase(createAddressThunk.pending, (state, action) => {
            state.isProfileLoading = true;
        })
  
        builder.addCase(createAddressThunk.rejected, (state, action) => {
            state.isProfileLoading = false
        })

        builder.addCase(createAddressThunk.fulfilled, (state, action) => {
            state.isProfileLoading = false
        })

    }
})

// Action creators are generated for each case reducer function
export const {
  setIsProfileLoading,
  setSaveMode,
  setProfileError,
  setChangePassword,
  setRequestChangePassword,
  setPhone,
  setChangePhone,
  setUpdatedTokens,
  setCreateAddress,
  setAddress,
  setEmail,
  setReferral,
  removeOtpError,
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer
