// import moment from "moment";
import { format } from 'date-fns'

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Copy from "./Copy";
import { StyledTableCell, StyledTableRow } from "./History.styles";

interface Props {
  data: {
    createTime?: string;
    type: string | number;
    coin: string;
    amount: string;
    address: string;
    txID: string;
    status: number | string;
  }[];
}
export const TransactionTable = ({ data }: Props) => {
  return (
    <TableContainer style={{ boxShadow: "none" }} component={Paper}>
      <Table style={{minWidth: "1000px"}} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              background: "#EFEDED",
              height: "36px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              color: "#80848E",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
            }}
          >
            <TableCell style={{ paddingLeft: "17px" }} width={"20%"}>
              Time
            </TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Wallet source</TableCell>
            <TableCell align="left">Asset</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Destination</TableCell>
            <TableCell align="left">TxID</TableCell>
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <StyledTableRow
                key={index}
                style={{ borderBottom: "1px sold red" }}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell
                  style={{
                    paddingLeft: "15px",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                  }}
                  component="th"
                  scope="row"
                >
                  {" "}
                  {!!row.createTime ?  format(+row.createTime, "dd-MM-yyyy HH:mm") : ""}
                </StyledTableCell>
                <StyledTableCell align="left">{row.type}</StyledTableCell>
                <StyledTableCell align="left">Spot wallet</StyledTableCell>
                <StyledTableCell align="left">{row.coin}</StyledTableCell>
                <StyledTableCell align="left">{row.amount}</StyledTableCell>
                <StyledTableCell align="left">
                  {cutText(row.address, 5, 37)}
                  <Copy
                    onClick={() => {
                      //   setCopiedDestination(copiedDestination);
                      //   setOpenCopied(true);
                    }}
                    text={row.address ? row.address : ""}
                    title={""}
                  />
                </StyledTableCell>

                <StyledTableCell align="left">
                  {cutText(row.txID, 5, 60)}
                  <Copy
                    title={""}
                    text={row.txID ? row.txID : " "}
                    onClick={() => {
                      //   setCopiedTxID(copiedTxID);
                      //   setOpenCopied(true);
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell style={{ position: "relative" }} align="left">
                  <span
                    style={{
                      position: "absolute",
                      marginTop: "5px",
                      left: "-18px",
                      borderRadius: "50%",
                      width: "8px",
                      height: "8px",
                      backgroundColor:
                        row.status === "finished"
                          ? "#419E6A"
                          : row.status === "new"
                          ? "#EFB008"
                          : row.status === "failed"
                          ? "red"
                          : "#9F7A40",
                    }}
                  />
                  {row.status === "finished" ? "success" : row.status === "new" ? "pending" : row.status === "failed" ? "failed" : "unknown"}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const cutText = (str: string, cutStart: number, cutEnd: number) => {
  return str.substring(0, cutStart) + "..." + str.substring(cutEnd + 1);
};
