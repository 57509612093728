import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Typography } from '@mui/material'
import { ByBitClosedPnL, ByBitClosedPnLBody, byBitApi } from 'api'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PnLShareModal } from '../PnLShareModal'

const HEADERS = [
	'Contracts',
	'Qty',
	'Entry Price',
	'Exit Price',
	'Trad Type',
	'Closed P&L',
	'Exit Type',
	'Trading Time',
]

export const ExchangePnL = () => {
	const [pnlForShare, setPnlForShare] = useState<ByBitClosedPnL | null>(null)
	const [pnls, setPnls] = useState<ByBitClosedPnLBody | null>(null)

	const pnlShareForInfo = useMemo(() => {
		console.log(pnlForShare)
		const newSide = pnlForShare?.side === 'Buy' ? 'Sell' : 'Buy'
		const pnlPriceDifferent =
			newSide === 'Buy'
				? +(pnlForShare?.avgExitPrice ?? 0) - +(pnlForShare?.avgEntryPrice ?? 0)
				: +(pnlForShare?.avgEntryPrice ?? 0) - +(pnlForShare?.avgExitPrice ?? 0)
		const pnlPositiveValue = Math.abs(+(pnlForShare?.closedPnl ?? 0))
		const pnl = `${
			(Math.floor(pnlPositiveValue * 100) / 100) *
			(+(pnlForShare?.closedPnl ?? 0) >= 0 ? 1 : -1)
		}`

		const roiValue =
			(pnlPriceDifferent / +(pnlForShare?.avgEntryPrice ?? 0)) *
			(100 * +(pnlForShare?.leverage ?? 1))
		const roi = `${Math.trunc(roiValue * 100) / 100}%`
		console.log(roiValue, pnl)

		const res = {
			fromType: 'pnl',
			symbol: pnlForShare?.symbol,
			entryPrice: +(pnlForShare?.avgEntryPrice ?? 0),
			filledPrice: +(pnlForShare?.avgExitPrice ?? 0),
			side: newSide,
			leverage: +(pnlForShare?.leverage ?? 0),
			roi,
			pnl,
			isRoiIncrease: roiValue >= 0,
			isPnlIncrease: +(pnlForShare?.closedPnl ?? 0) >= 0,
		}
		console.log(res)
		return res
	}, [pnlForShare])

	const handleGetPnLs = useMemo(() => {
		return async (startTime: number, endTime: number) => {
			console.log(startTime, endTime)
			try {
				const res = await byBitApi.getClosedPnL({
					category: 'linear',
					startTime: startTime,
					endTime: endTime,
				})
				console.log(res.list)
				if (!!res.list && !!res.list.length) {
					setPnls(prevPnls => ({
						category: prevPnls?.category || res.category,
						list: [...(prevPnls?.list || []), ...res.list],
						nextPageCursor: res.nextPageCursor,
					}))
				}
			} catch (err) {
				console.log(err)
			}
		}
	}, [])

	useEffect(() => {
		let currentEndTime = Date.now() // Конец времени для первого запроса
		const interval = 7 * 24 * 60 * 60 * 1000 // 7 дней в миллисекундах

		const fetchPnLs = () => {
			// Вычисляем startTime как 7 дней назад от currentEndTime
			const currentStartTime = currentEndTime - interval

			handleGetPnLs(currentStartTime, currentEndTime)

			// Обновляем времена для следующей итерации
			currentEndTime = currentStartTime // Новое endTime будет на 7 дней раньше
			// Следующее начало будет снова на 7 дней раньше
			// Следовательно, startTime уже обновлен на 7 дней назад

			// Продолжать запросы каждые 2 секунды
			// Вы можете настроить общее время, если необходимо
			if (currentEndTime > Date.now() - 26 * interval) {
				// Общая продолжительность 26 недель
				setTimeout(fetchPnLs, 1000) // Запрашивать следующий интервал через 2 секунды
			}
		}

		fetchPnLs()
	}, [handleGetPnLs])

	const handleClosePnlForShare = useCallback(() => {
		setPnlForShare(null)
	}, [])

	return (
		<Box mt={2} width='100%'>
			<table>
				<thead>
					<tr>
						{HEADERS.map((head, headIdx) => (
							<th key={headIdx} style={{ paddingInline: '16px' }}>
								<Box
									component='span'
									fontSize='12px'
									fontWeight='400'
									color='#52555E'
									lineHeight='18px'
									whiteSpace='nowrap'
								>
									{head}
								</Box>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{pnls?.list.map((pnl, index) => (
						<tr key={index}>
							<td style={{ paddingInline: '16px' }}>
								<Box
									sx={{
										position: 'relative',
										paddingLeft: '12px',
										'&::before': {
											content: '""',
											position: 'absolute',
											top: 0,
											left: 0,
											width: '4px',
											height: '100%',
											background: pnl.side === 'Buy' ? '#419E6A' : '#D83232',
										},
									}}
								>
									<Typography
										fontWeight='600'
										color={pnl.side === 'Buy' ? '#419E6A' : '#D83232'}
									>
										{pnl.symbol}
									</Typography>
								</Box>
							</td>
							<td style={{ paddingInline: '16px' }}>
								<Box>
									<Typography>{pnl.qty}</Typography>
								</Box>
							</td>
							<td style={{ paddingInline: '16px' }}>
								<Box>
									<Typography>{pnl.avgEntryPrice}</Typography>
								</Box>
							</td>
							<td style={{ paddingInline: '16px' }}>
								<Box>
									<Typography>{pnl.avgExitPrice}</Typography>
								</Box>
							</td>
							<td style={{ paddingInline: '16px' }}>
								<Box sx={{ whiteSpace: 'nowrap' }}>
									<Typography>
										{pnl.side === 'Buy' ? 'Close Long' : 'Close Short'}
									</Typography>
								</Box>
							</td>
							<td style={{ paddingInline: '16px' }}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Box>
										<Typography>{pnl.closedPnl}</Typography>
									</Box>
									<Box>
										<OpenInNewIcon
											sx={{ color: '#71757a' }}
											cursor='pointer'
											fontSize='small'
											onClick={() => setPnlForShare(pnl)}
										/>
									</Box>
								</Box>
							</td>
							<td style={{ paddingInline: '16px' }}>
								<Box>
									<Typography>
										{pnl.execType === 'BustTrade' ? 'Liquidated' : pnl.execType}
									</Typography>
								</Box>
							</td>
							<td style={{ paddingInline: '16px' }}>
								<Box sx={{ whiteSpace: 'nowrap' }}>
									<Typography fontWeight='600'>
										{moment(+pnl.createdTime).format('DD-MM-YYYY HH:mm:ss')}
									</Typography>
								</Box>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<PnLShareModal
				positionInfo={pnlShareForInfo}
				open={Boolean(pnlForShare)}
				onClose={handleClosePnlForShare}
			/>
		</Box>
	)
}
