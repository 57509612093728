import { styled } from "@mui/material";
import { Drawer, Select, Input, Box } from "@mui/material";

export const StyledSelect = styled(Select)(({ theme }) => ({
  border: 'none',
  '& fieldset': {
    border: 'none',
  }
}));

export const StyledDrawer= styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    background: '#001030',
    height: 'calc(100vh - 81px)',
    top: 81,
    left: 92,
  },
  '& .MuiBackdrop-root': {
    background: 'rgba(37, 50, 79, 0.15)',
    backdropFilter: 'blur(10px)',
    height: 'calc(100vh - 81px)',
    top: 81,
    left: 92,
    width: 'calc(100vw - 107px)',
  },
}));

export const DrawerContent = styled("div")(({ theme }) => ({
  width: 317,
  padding: 20,
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  "& input::placeholder": {
    color: "#FFFFFF",
    fontSize: 13,
  },
  marginBottom: 32,
}));

export const StyledPair = styled("div")(({ theme }) => ({
  cursor: "pointer",
}));

export const StyledSelectPair = styled("div")(({ theme }) => ({
  cursor: "pointer",
}));

export const StyledPairsTitle = styled(Box)(({ theme }) => ({
  fontSize: 12,
  color: '#52555E',
  marginBottom: 20,
}));

export const StyledPercent = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: '#419E6A',
}));