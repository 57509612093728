import { bybitWSUrl } from "app.config";
import { useTypedSelector } from "hooks/useTypedSelector";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export type ByBitTickerData = {
  symbol: string;
  tickDirection: string;
  price24hPcnt: string;
  lastPrice: string;
  prevPrice24h: string;
  highPrice24h: string;
  lowPrice24h: string;
  prevPrice1h: string;
  markPrice: string;
  indexPrice: string;
  openInterest: string;
  openInterestValue: string;
  turnover24h: string;
  volume24h: string;
  nextFundingTime: string;
  fundingRate: string;
  bid1Price: string;
  bid1Size: string;
  ask1Price: string;
  ask1Size: string;
};

export type ByBitTicker = {
  topic: string;
  type: "snapshot" | "delta";
  data: ByBitTickerData;
  cs: number;
  ts: number;
};

export type ByBitOrderbook = {
  topic: string;
  type: "snapshot" | "delta";
  ts: number;
  data: {
    s: string;
    b: [string, string][];
    a: [string, string][];
    u: number;
    seq: number;
  };
  cts: number;
};

export type ByBitKline = {
  topic: string;
  data: ByBitKlineData[];
  ts: number;
  type: string;
};

export type ByBitKlineData = {
  start: number;
  end: number;
  interval: string;
  open: string;
  close: string;
  high: string;
  low: string;
  volume: string;
  turnover: string;
  confirm: boolean;
  timestamp: number;
};

const ByBitContext = createContext<{
  createSocket: () => void;
  disconnectSocket: () => void;
  ticker: ByBitTicker | null;
  orderbook: ByBitOrderbook | null;
  kline: ByBitKline | null;
  interval: { label: string; value: string } | null;
  setInterval: Dispatch<SetStateAction<{ label: string; value: string }>>;
}>({
  createSocket: () => null,
  disconnectSocket: () => null,
  ticker: null,
  orderbook: null,
  kline: null,
  interval: null,
  setInterval: () => {},
});

export function ByBitProvider({ children }: PropsWithChildren) {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [ticker, setTicker] = useState<ByBitTicker | null>(null);
  const [orderbook, setOrderbook] = useState<ByBitOrderbook | null>(null);
  const [kline, setKline] = useState<ByBitKline | null>(null);
  const [interval, setInterval] = useState<{ label: string; value: string }>({
    label: "30m",
    value: "30",
  });
  const selectedPair = useTypedSelector((state) => state.exchange.selectedPair);
  const pairSymbol = selectedPair
    ? formatPair(selectedPair?.baseCoin, selectedPair?.quoteCoin)
    : null;

  const createSocket = () => {
    const byBitSocket = new WebSocket(`${bybitWSUrl}/v5/public/linear`);
    byBitSocket.onopen = () => {
      setSocket(byBitSocket);
    };
    byBitSocket.onclose = () => {
      setSocket(null);
    };
    return byBitSocket;
  };

  const subscribeOrderbook = () => {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        op: "subscribe",
        args: [`orderbook.200.${pairSymbol}`],
      })
    );
  };

  const subscribeTicker = () => {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        op: "subscribe",
        args: [`tickers.${pairSymbol}`],
      })
    );
  };

  const subscribeKline = () => {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        op: "subscribe",
        args: [`kline.${interval.value}.${pairSymbol}`],
      })
    );
  };

  const unsubscribeTicker = () => {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        op: "unsubscribe",
        args: [`tickers.${pairSymbol}`],
      })
    );
    setTicker(null);
  };

  const unsubscribeOrderbook = () => {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        op: "unsubscribe",
        args: [`orderbook.200.${pairSymbol}`],
      })
    );
    setOrderbook(null);
  };

  const unsubscribeKline = () => {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        op: "unsubscribe",
        args: [`kline.${interval.value}.${pairSymbol}`],
      })
    );
    setKline(null);
  };

  const disconnectSocket = () => {
    if (!socket) return;
    socket.close();
    setSocket(null);
    setTicker(null);
    setOrderbook(null);
  };

  useEffect(() => {
    createSocket();
  }, []);

  useEffect(() => {
    if (socket && pairSymbol) {
      const localSocket = socket;

      subscribeOrderbook();
      subscribeTicker();
      subscribeKline();
      localSocket.onmessage = (msg) => {
        const data = JSON.parse(msg.data);
        if (data.topic === `orderbook.200.${pairSymbol}`) {
          setOrderbook(data);
        }
        if (data.topic === `tickers.${pairSymbol}`) {
          setTicker(prev => ({
            ...data,
            data: {
              ...data,
              lastPrice: !!data.data.lastPrice ? data.data.lastPrice : prev?.data.lastPrice,
              volume24h: !!data.data.volume24h ? data.data.volume24h : prev?.data.volume24h,
              fundingRate: !!data.data.fundingRate ? data.data.fundingRate : prev?.data.fundingRate,
              markPrice: !!data.data.markPrice ? data.data.markPriec : prev?.data.markPrice,
              price24hPcnt: !!data.data.price24hPcnt ? data.data.price24hPcnt : prev?.data.price24hPcnt,
              prevPrice24h: !!data.data.prevPrice24h ? data.data.prevPrice24h : prev?.data.prevPrice24h,
              highPrice24h: !!data.data.highPrice24h ? data.data.highPrice24h : prev?.data.highPrice24h,
              lowPrice24h: !!data.data.lowPrice24h ? data.data.lowPrice24h : prev?.data.lowPrice24h,
              nextFundingTime: !!data.data.nextFundingTime ? data.data.nextFundingTime : prev?.data.nextFundingTime
            }
          }));
        }
        if (data.topic === `kline.${interval.value}.${pairSymbol}`) {
          setKline(data);
        }
      };

      return () => {
        unsubscribeTicker();
        unsubscribeOrderbook();
        unsubscribeKline();
        setTicker(null);
        setOrderbook(null);
      };
    }
  }, [socket, pairSymbol, interval]);

  return (
    <ByBitContext.Provider
      value={{
        createSocket,
        disconnectSocket,
        orderbook,
        ticker,
        kline,
        interval,
        setInterval,
      }}
    >
      {children}
    </ByBitContext.Provider>
  );
}

export const useByBit = () => {
  const context = useContext(ByBitContext);
  if (!context) throw new Error("ByBitContext is not defined");
  return context;
};

export const withByBitProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <ByBitProvider>
        <Component {...props} />
      </ByBitProvider>
    );

function formatPair(baseCurrency: string, quoteCurrency: string): string {
  return `${baseCurrency.trim()}${quoteCurrency.trim()}`;
}
