import "./style.scss";

import {
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AdditionalLayout } from "components";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getBalanceThunk } from "../../store/wallets/wallets.thunk";
import {
  StyledCancelIcon,
  StyledCheckCircleIcon,
  StyledDepositButtonWrapper,
  StyledGridContainer,
  StyledGridItemPlug,
  StyledGridItemPlugMain,
  StyledGridItemTitle,
  StyledSpepsText,
  StyledVerificationItem,
  WorkItem,
  WorkItemText,
  WorkItemTitle,
} from "./Deposit.style";
import {
  ArrowDropDown,
  Close,
  ContentCopy,
  QrCode,
  Search,
  Warning,
} from "@mui/icons-material";
import {
  ByBitCoinData,
  ByBitNetworkChain,
  ByBitNetworkRow,
  ByBitSubDepositAddress,
  byBitApi,
} from "api";
import QRCode from "react-qr-code";
import { AxiosError } from "axios";
import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon";

const Deposit: FC = (): JSX.Element | null => {
  // const { data: balance } = useTypedSelector((state) => state.wallets.balance);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBalanceThunk());
  }, []);

  // useEffect(() => {
  //   if (!verification) return;
  //   if (verification.level < 2) {
  //     setVerificationSnackbar(true);
  //     setDepositDisabled(true);
  //   }
  // }, [verification]);

  // const handleClose = (
  //   event?: React.SyntheticEvent | Event,
  //   reason?: string
  // ) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   // setErrorAlert(false);
  // };

  return (
    <VerificationErrorProvider>
      <DepositProvider>
        <AdditionalLayout>
          <StyledGridContainer container justifyContent="space-between">
            <Grid item xs={7.5}>
              <StyledGridItemPlugMain>
                <Box
                  style={{ width: "100%", maxWidth: "450px", margin: "0 auto" }}
                >
                  <StyledGridItemTitle
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid xs={4} item>
                      Deposit Crypto
                    </Grid>
                  </StyledGridItemTitle>
                  <Box display="flex" flexDirection="column" gap="60px">
                    <DepositForm />
                    <DepositAddress />
                  </Box>
                </Box>
              </StyledGridItemPlugMain>
            </Grid>
            <Grid item xs={4}>
              <VerificationSteps />
              <DepositDescription />
            </Grid>
          </StyledGridContainer>
        </AdditionalLayout>

        <CoinSelectModal />
        <NetworkSelectModal />
        <DepositQrModal />
        {/* <VerficationErrorSnackbar /> */}
      </DepositProvider>
    </VerificationErrorProvider>
  );
};

export default Deposit;

const DepositForm = () => {
  const { selectCoin, selectNetwork, setOpenModal } = useDepositContext();

  return (
    <Box display="flex" flexDirection="column" gap="50px">
      <FormControl fullWidth>
        <label style={{ fontSize: 14, color: "#80848E" }}>Select coin</label>
        <Button
          sx={{
            justifyContent: "space-between",
            border: "none",
            borderBottom: "1px solid gray",
            borderRadius: 0,
            paddingInline: 0,
          }}
          style={{ padding: "10px 1px" }}
          onClick={() => setOpenModal("coin")}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            {selectCoin ? (
              <>
              <CoinIcon coinName={selectCoin?.toLowerCase().trim()} style={{ width: 26, height: 26 }} />
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#000000",
                  }}
                >
                  {selectCoin}
                </p>
              </>
            ) : (
              <p
                style={{
                  fontSize: 14,
                  color: "#D8D8D8",
                  textTransform: "initial",
                }}
              >
                Select coin
              </p>
            )}
          </div>
          <ArrowDropDown style={{ color: "#000000" }} />
        </Button>
      </FormControl>
      <FormControl fullWidth>
        <label style={{ fontSize: 14, color: "#80848E" }}>Select network</label>
        <Button
          sx={{
            justifyContent: "space-between",
            border: "none",
            borderBottom: "1px solid gray",
            borderRadius: 0,
          }}
          style={{ padding: "10px 1px" }}
          onClick={() => setOpenModal("network")}
          disabled={!selectCoin}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            {selectNetwork ? (
              <>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#000000",
                  }}
                >
                  {selectNetwork.coin}
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#000000",
                    textTransform: "initial",
                  }}
                >
                  {selectNetwork.chain}({selectNetwork.chainType})
                </p>
              </>
            ) : (
              <p
                style={{
                  fontSize: 14,
                  color: "#D8D8D8",
                  textTransform: "initial",
                }}
              >
                Select network
              </p>
            )}
          </div>
          <ArrowDropDown style={{ color: "#000000" }} />
        </Button>
      </FormControl>
    </Box>
  );
};

const DepositAddress = () => {
  const {
    subDepositAddress,
    setSubDepositAddress,
    setOpenModal,
    selectCoin,
    selectNetwork,
  } = useDepositContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleGetSubDepositAddress = useCallback(async () => {
    try {
      if (!selectCoin || !selectNetwork) return;
      setLoading(true);
      const res = await byBitApi.getSubDepositAddress({
        coin: selectCoin,
        chainType: selectNetwork.chain,
      });
      console.log(res)
      if (!!res.coin && res.chains.length > 0) setSubDepositAddress(res);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [selectCoin, selectNetwork]);

  const handleCopy = (text: string) => () => {
    window.navigator.clipboard.writeText(text)
  }

  useEffect(() => {
    handleGetSubDepositAddress();
  }, [handleGetSubDepositAddress]);

  const renderTagDeposit = (item: any, index: number) => {
    if(!item?.tagDeposit) return null;

    return (
      <Box key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: index === 0 ? "10px" : "20px" }}>
        <Box component='p'>{item.tagDeposit}</Box>
        <Button onClick={handleCopy(item.tagDeposit)}>
          <ContentCopy />
          Copy
        </Button>
      </Box>
    )
  }

  if (!selectCoin || !selectNetwork) return null;

  return (
    <Box>
      {error ? (
        <Typography color="red">{error}</Typography>
      ) : loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : !subDepositAddress ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography>Empty</Typography>
        </Box>
      ) : (
        <>
          <h5>{subDepositAddress.coin} address</h5>
          <Box mt="10px">
            {subDepositAddress.chains.map((item, itemIdx) => (
              <p key={itemIdx}>{item.addressDeposit}</p>
            ))}
          </Box>
          <StyledDepositButtonWrapper
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="20px"
            mt="38px"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={handleCopy(subDepositAddress.chains[0]!.addressDeposit)}
            >
              <ContentCopy />
              <span style={{ fontSize: 13, textTransform: "initial" }}>
                Copy Address
              </span>
            </Button>
            <Button
              variant="outlined"
              color="info"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={() => setOpenModal("qr")}
            >
              <QrCode />
              <span style={{ fontSize: 13, textTransform: "initial" }}>
                Show QR Code
              </span>
            </Button>
          </StyledDepositButtonWrapper>
          {subDepositAddress.chains.findIndex(item => !!item.tagDeposit) !== -1 ? 
            (
              <Box sx={{ mt: "30px" }}>
                <h5>{subDepositAddress.coin} Deposit Tag/MemoPlease fill in and double check.</h5>
                {subDepositAddress.chains.map(renderTagDeposit)}
              </Box>
            )
            :
            null
          }
          <Box display="flex" justifyContent="center" gap="10px" mt="50px">
            <Warning color="info" />
            <Box>
              <h5 style={{ fontSize: 16, fontWeight: 600 }}>
                Only send {selectCoin} to this address!
              </h5>
              <p style={{ fontSize: 12, fontWeight: 400 }}>
                Make sure to only deposit {selectCoin}, or else your funds will
                be lost.
              </p>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const VerificationSteps = () => {
  const verification = useTypedSelector(
    (state) => state.profile.me?.verification
  );

  return (
    <StyledGridItemPlug>
      <StyledGridItemTitle>Verification steps</StyledGridItemTitle>
      <StyledSpepsText>
        The following steps are required before being able to deposit Fiat (€)
      </StyledSpepsText>

      <StyledVerificationItem container>
        <Grid
          item
          xs={7}
          style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}
        >
          Email address
        </Grid>
        <Grid
          item
          xs={5}
          display="flex"
          style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}
          alignItems="center"
        >
          {verification?.email ? (
            <StyledCheckCircleIcon />
          ) : (
            <StyledCancelIcon />
          )}
          {verification?.email ? "Verified" : "Not Verified"}
        </Grid>
      </StyledVerificationItem>

      <StyledVerificationItem
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={7}
          style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}
        >
          Phone number
        </Grid>
        <Grid
          style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}
          item
          xs={5}
          display="flex"
          alignItems="center"
        >
          {verification?.phone ? (
            <StyledCheckCircleIcon />
          ) : (
            <StyledCancelIcon />
          )}
          {verification?.phone ? "Verified" : "Not Verified"}
        </Grid>
      </StyledVerificationItem>
    </StyledGridItemPlug>
  );
};

const DepositDescription = () => {
  return (
    <StyledGridItemPlug>
      <StyledGridItemTitle>How it works</StyledGridItemTitle>

      <WorkItem>
        <img src="/images/works/works1.svg" alt="Select payment method" />
        <WorkItemTitle>Select payment method</WorkItemTitle>
        <WorkItemText>
        Enter the amount and choose your preferred method of payment
        </WorkItemText>
      </WorkItem>

      <WorkItem>
        <img
          src="/images/works/works2.svg"
          alt="Transfer credit to your Bitocol wallet"
        />
        <WorkItemTitle>Transfer credit to your Bitocol wallet</WorkItemTitle>
        <WorkItemText>
        Enter the amount and choose your preferred method of payment
        </WorkItemText>
      </WorkItem>

      <WorkItem>
        <img src="/images/works/works3.svg" alt="Select payment method" />
        <WorkItemTitle>Start buying, holding and trading</WorkItemTitle>
        <WorkItemText>
          Use the Fiat in your wallet to buy and trade crypto
        </WorkItemText>
      </WorkItem>
    </StyledGridItemPlug>
  );
};

const DepositQrModal = withCustomModal(
  function DepositQrModal() {
    const { subDepositAddress, selectCoin } = useDepositContext();

    if (!subDepositAddress) return null;

    return (
      <>
        <Box display="flex" flexDirection="column" flex="1" pb="13px">
          <Box height="100%">
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="space-between"
              gap="10px"
            >
              <h2 style={{ fontSize: 20, fontWeight: 600 }}>QR Code</h2>
              <p style={{ fontSize: 16, fontWeight: 400 }}>
                Scan the QR code for the address
              </p>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt="37px"
            >
              <Box
                display="flex"
                alignItems="center"
                width="175px"
                height="175px"
              >
                <QRCode
                  height="100%"
                  value={subDepositAddress.chains[0]!.addressDeposit}
                />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="18px"
            justifyItems="end"
          >
            <Warning color="info" />
            <span style={{ fontSize: 16, fontWeight: 600 }}>
              Only send {selectCoin} to this address!
            </span>
          </Box>
        </Box>
      </>
    );
  },
  { type: "qr" }
);

const CoinSelectModal = withCustomModal(
  function CoinsSelectModal() {
    const { setOpenModal, setSelectCoin, setSelectNetwork } =
      useDepositContext();
    const [search, setSearch] = useState<string>("");
    const [coins, setCoins] = useState<ByBitCoinData[]>([]);
    const [cursor, setCursor] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const scrollRef = useRef<any>(null);

    const resultCoins = useMemo(() => {
      if (!search) return coins;
      return coins.filter((coin) =>
        coin.coin.toLowerCase().includes(search.toLowerCase().trim())
      );
    }, [coins, search]);

    const handleGetCoins = async ({ cursor }: { cursor?: string } = {}) => {
      try {
        setLoading(true);
        const res = await byBitApi.getCoinList({ cursor });
        if (res) {
          setCoins(prev =>  [...prev, ...res.configList].reduce((acc, item) => {
            const existingCoin = acc.find((obj: any) => obj.coin === item.coin);
            if (!existingCoin) {
              return [...acc, item]
            }
            return acc;
          }, [] as any));
          setCursor(res.nextPageCursor);
        }
      } catch (err: any) {
        if (err instanceof AxiosError) {
          setError(err.message);
        } else {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      handleGetCoins();
    }, []);

    useEffect(() => {
      if (!scrollRef.current) return;

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
          handleGetCoins({ cursor: cursor ?? undefined });
        }
      };

      const scrollableElement = scrollRef.current;
      scrollableElement.addEventListener("scroll", handleScroll);
      return () =>
        scrollableElement.removeEventListener("scroll", handleScroll);
    }, [scrollRef, loading, cursor]);

    return (
      <>
        <FormControl
          fullWidth
          sx={{ mt: "35px", px: "50px" }}
          disabled={loading || !!error}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ paddingLeft: "21px" }}>
                  <Search sx={{ color: "#DEDEDE" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              input: {
                fontSize: "14px",
                color: "black"
              },
            }}
            variant="standard"
            placeholder="Search crypto name"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </FormControl>
        <Box mt="30px" flex="1" overflow="hidden">
          {error ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography color="red">{error}</Typography>
            </Box>
          ) : !loading && !resultCoins.length ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography>Empty</Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              gap="1px"
              height="100%"
              sx={{ overflowY: "scroll" }}
              ref={scrollRef}
            >
              {resultCoins.map((item, coinIdx) => (
                <Button
                  key={coinIdx}
                  sx={{
                    justifyContent: "start",
                    color: "black",
                    gap: "20px",
                    ":hover": {
                      backgroundColor: "#F4F4F4",
                    },
                  }}
                  onClick={() => {
                    setSearch("");
                    setOpenModal(null);
                    setSelectCoin(item.coin);
                    setSelectNetwork(null);
                  }}
                >
                  <img
                    src={`https://assets.coincap.io/assets/icons/${item.coin?.toLowerCase().trim()}@2x.png`}
                    alt=""
                    width={26}
                    height={26}
                  />
                  <p style={{ fontSize: 14, fontWeight: 600 }}>{item.coin}</p>
                </Button>
              ))}
              {loading && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </>
    );
  },
  { type: "coin", title: "Select crypto to deposit" }
);

const NetworkSelectModal = withCustomModal(
  function NetworkSelectModal() {
    const { setOpenModal, selectCoin, setSelectNetwork } = useDepositContext();
    const [networks, setNetworks] = useState<ByBitNetworkRow[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");

    const handleGetNetworks = useCallback(async () => {
      try {
        if (!selectCoin) throw new Error("No coin selected");
        const res = await byBitApi.getNetworkList({ coin: selectCoin });
        setNetworks(res.rows);
      } catch (err) {
        setError(error);
        console.log(err);
      } finally {
        setLoading(false);
      }
    }, [selectCoin]);

    const chains = useMemo(() => {
      return networks.reduce(
        (prev: any, { chains, ...network }) => [
          ...prev,
          ...chains.map((item) => ({ ...item, ...network })),
        ],
        []
      ) as (ByBitNetworkRow & ByBitNetworkChain)[];
    }, [networks]);

    useEffect(() => {
      handleGetNetworks();
    }, [handleGetNetworks]);

    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          px="25px"
          mt="21px"
        >
          <p
            style={{
              fontSize: "14px !important",
              color: "#B8B8B8",
              textAlign: "center",
            }}
          >
            Make sure to select the right network to prevent assets from
            becoming lost!
          </p>
        </Box>
        <Box mt="30px" flex="1" overflow="hidden">
          {error ? (
            <Typography color="red">{error}</Typography>
          ) : loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : !chains.length ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography>Empty</Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              gap="1px"
              height="100%"
              sx={{ overflowY: "scroll" }}
            >
              {chains.map((chain, chainIdx) => (
                <Button
                  key={chainIdx}
                  sx={{
                    flexDirection: "column",
                    alignItems: "start",
                    color: "black",
                    ":hover": {
                      backgroundColor: "#F4F4F4",
                    },
                  }}
                  onClick={() => {
                    setOpenModal(null);
                    setSelectNetwork(chain);
                  }}
                >
                  <p
                    style={{ fontSize: 14, fontWeight: 600, color: "#000000" }}
                  >
                    {chain.coin}
                  </p>
                  <p style={{ fontSize: 14, color: "#80848E" }}>
                    {chain.chain}({chain.chainType})
                  </p>
                </Button>
              ))}
            </Box>
          )}
        </Box>
      </>
    );
  },
  { type: "network", title: "Select network" }
);

function withCustomModal(
  Component: any,
  { type, title }: { type: ModalType; title?: string }
) {
  return () => {
    const { openModal, setOpenModal } = useDepositContext();

    return (
      <Modal
        open={openModal === type}
        onClose={() => setOpenModal(null)}
        closeAfterTransition
      >
        <Fade in={openModal === type}>
          <Box
            display="flex"
            flexDirection="column"
            position="absolute"
            left="50%"
            top="50%"
            maxWidth="341px"
            width="100%"
            height="410px"
            bgcolor="white"
            borderRadius="5px"
            boxShadow="24"
            p="27px 0"
            sx={{ transform: "translate(-50%, -50%)", outline: "none" }}
          >
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: "10px", right: "15px" }}
            >
              <Close />
            </IconButton>
            {!!title && (
              <h5
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "22.5px",
                }}
              >
                {title}
              </h5>
            )}
            <Component />
          </Box>
        </Fade>
      </Modal>
    );
  };
}

const useVerificationErrorContext = () => {
  const context = useContext(VerificationErrorContext);
  if (!context) {
    throw new Error(
      "useVerificationErrorContext must be used within a VerificationErrorProvider"
    );
  }
  return context;
};
// const VerficationErrorSnackbar = () => {
//   const { hasVerificationError, setHasVerificationError } =
//     useVerificationErrorContext();
//   const nav = useNavigate();

//   return (
//     <Snackbar open={hasVerificationError}>
//       <Alert
//         className="alert_verification"
//         severity="error"
//         onClose={() => setHasVerificationError(false)}
//         sx={{
//           alignItems: "center",
//           background: "#D3302F",
//           color: "#fff !important",
//         }}
//       >
//         To deposit verification level 2 is required: pass identity verification
//         and facial scan
//         <IconButton
//           style={{ marginLeft: "10px", color: "#fff" }}
//           onClick={() => nav("/dashboard/verification")}
//         >
//           <ArrowRightAltIcon />
//         </IconButton>
//       </Alert>
//     </Snackbar>
//   );
// };

const VerificationErrorContext = createContext<{
  hasVerificationError: boolean;
  setHasVerificationError: Dispatch<SetStateAction<boolean>>;
} | null>(null);
const VerificationErrorProvider = ({ children }: PropsWithChildren) => {
  const verification = useTypedSelector(
    (state) => state.profile.me?.verification
  );
  const [hasVerificationError, setHasVerificationError] =
    useState<boolean>(false);

  useEffect(() => {
    if (!verification || verification.level >= 2) return;
    setHasVerificationError(true);
  }, [verification]);

  return (
    <VerificationErrorContext.Provider
      value={{ hasVerificationError, setHasVerificationError }}
    >
      {children}
    </VerificationErrorContext.Provider>
  );
};

type ModalType = "coin" | "network" | "qr";
const useDepositContext = () => {
  const context = useContext(DepositContext);
  if (!context) {
    throw new Error("useDepositContext must be used within a DepositProvider");
  }
  return context;
};
const DepositContext = createContext<{
  selectCoin: string | null;
  setSelectCoin: Dispatch<SetStateAction<string | null>>;
  selectNetwork: (ByBitNetworkRow & ByBitNetworkChain) | null;
  setSelectNetwork: Dispatch<
    SetStateAction<(ByBitNetworkRow & ByBitNetworkChain) | null>
  >;
  openModal: ModalType | null;
  setOpenModal: Dispatch<SetStateAction<ModalType | null>>;
  subDepositAddress: ByBitSubDepositAddress | null;
  setSubDepositAddress: Dispatch<SetStateAction<ByBitSubDepositAddress | null>>;
} | null>(null);
const DepositProvider = ({ children }: PropsWithChildren) => {
  const [search] = useSearchParams();
  const coinParam = search.get("coin");
  const [selectCoin, setSelectCoin] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const [selectNetwork, setSelectNetwork] = useState<
    (ByBitNetworkRow & ByBitNetworkChain) | null
  >(null);
  const [subDepositAddress, setSubDepositAddress] =
    useState<ByBitSubDepositAddress | null>(null);

  useEffect(() => {
    if (!coinParam) return;
    setSelectCoin(coinParam);
  }, [coinParam]);

  return (
    <DepositContext.Provider
      value={{
        selectCoin,
        setSelectCoin,
        selectNetwork,
        setSelectNetwork,
        openModal,
        setOpenModal,
        subDepositAddress,
        setSubDepositAddress,
      }}
    >
      {children}
    </DepositContext.Provider>
  );
};
