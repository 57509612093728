import { useEffect, useState } from "react";
import { Box, Button, Divider, InputAdornment, Modal, Slider, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useByBit } from "providers/ByBitProvider";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { byBitApi } from "api";

const takeProfitStopLossSchema = z
.object({
  profit: z.string().or(z.number()).optional(),
  loss: z.string().or(z.number()).optional(),
})
.superRefine((data, ctx) => {
  if (data.profit === undefined && data.loss === undefined) {
    ctx.addIssue({
      path: ["profit"],
      message: '"profit" is required',
      code: z.ZodIssueCode.custom,
    });
    ctx.addIssue({
      path: ["loss"],
      message: '"loss" is required',
      code: z.ZodIssueCode.custom,
    });
  }
});
export type TakeProfitStopLossSchema = z.infer<typeof takeProfitStopLossSchema>

interface Item {
  symbol: string,
  price: string,
  side: string,
  qty: string,
  liqPrice: string,
  takeProfit: string,
  stopLoss: string,
}
interface AddTakeProfitStopLossModalProps {
  item: Item | null,
  show?: {
    liqPrice?: boolean
  }
  onClose: () => void,
  onConfirm: (data: TakeProfitStopLossSchema) => void
}

export const AddTakeProfitStopLossModal = ({item, show={liqPrice:false}, onClose, onConfirm}: AddTakeProfitStopLossModalProps) => {
    const [firstRender, setFirstRender] = useState<boolean>(false);
    const form = useForm<TakeProfitStopLossSchema>({
      defaultValues: {
        profit: !!item?.takeProfit ? +item.takeProfit : undefined,
        loss: !!item?.stopLoss ? +item.stopLoss : undefined
      },
      resolver: zodResolver(takeProfitStopLossSchema),
    });
    const { ticker } = useByBit();

    const handleCancel = () => {
      form.reset();
      onClose();
    };

    useEffect(() => {
      if(!item || firstRender) return;
      if(!!item?.takeProfit) {
        form.setValue("profit", +item.takeProfit);
      }
      if(!!item?.stopLoss) {
        form.setValue("loss", +item.stopLoss);
      }

      setFirstRender(true);
    }, [item, firstRender])

    return (
        <Modal open={!!item} onClose={onClose}>
          {item ? <Box
              sx={{ outline: "none" }}
              position="absolute"
              top="50%"
              left="50%"
              width="100%"
              maxWidth="420px"
              style={{ transform: "translate(-50%, -50%)" }}
              bgcolor="#1C1E24"
              boxShadow={24}
              p="24px"
              borderRadius="4px"
          >
              <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize="16px" fontWeight="600">
                  Add TP/SL
              </Typography>
                <CloseIcon
                    style={{ color: "white" }}
                    onClick={onClose}
                />
              </Box>
              <Box marginTop="26px">
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box>
                      <Typography>Entry Price</Typography>
                      <Typography>
                          {item.price}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>Qty</Typography>
                      <Typography>{item.qty}</Typography>
                    </Box>
                    <Box>
                      <Typography>Last Traded Price</Typography>
                      <Typography>{ticker?.data.lastPrice}</Typography>
                    </Box>
                    {show.liqPrice && <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center">
                      <Typography>Liq. Price</Typography>
                      <Typography>{!!item.liqPrice ? item.liqPrice : "--"}</Typography>
                    </Box>}
                </Box>
              </Box>
              <AddTakeProfitStopLossForm form={form} price={+item.price} side={item.side} />
              <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="40px"
              gap="16px"
              >
              <Button
                  sx={{
                    width: "100%",
                    color: "white",
                    borderRadius: "4px",
                    boxShadow: "none",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={form.handleSubmit((data) => onConfirm(data))}
              >
                  Confirm
              </Button>
              <Button
                  sx={{
                  width: "100%",
                  background: "#26282C",
                  color: "white",
                  borderRadius: "4px",
                  boxShadow: "none",
                  }}
                  variant="contained"
                  onClick={handleCancel}
              >
                  Cancel
              </Button>
              </Box>
          </Box>
          : <></>}
        </Modal>
    );
}

function AddTakeProfitStopLossForm({ form, price, side }: { form: UseFormReturn<TakeProfitStopLossSchema>, price: number, side: string }) {
    return (
      <>
        <Typography
          fontSize={14}
          fontWeight={400}
          color="#80848E"
          marginTop="20px"
        >
          Take Profit-Trigger by ROI (%)
        </Typography>
        <Box display="flex" flexDirection="row" gap="12px" marginTop="4px">
          <Controller
            control={form.control}
            name="profit"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                placeholder="Trigger Price"
                error={!!form.formState.errors.profit?.message}
                helperText={form.formState.errors.profit?.message}
                value={value ? value : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box>
                        <Button
                          onClick={() => {
                            const value = form.getValues("profit");
                            form.setValue(
                              "profit",
                              String(value ? +value - 1 : 0)
                            );
                          }}
                          sx={{
                            padding: "0 !important",
                            paddingInline: "10px !important",
                            minWidth: "auto",
                          }}
                        >
                          -
                        </Button>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box>
                        <Button
                          onClick={() => {
                            const value = form.getValues("profit");
                            form.setValue(
                              "profit",
                              String(value ? +value + 1 : 1)
                            );
                          }}
                          sx={{
                            padding: "0 !important",
                            paddingInline: "10px !important",
                            minWidth: "auto",
                          }}
                        >
                          +
                        </Button>
                      </Box>
                      <Button
                        sx={{
                          padding: "0 !important",
                          paddingInline: "10px !important",
                          minWidth: "auto",
                        }}
                        onClick={() => onChange(price)}
                      >
                        <span
                          style={{
                            color: "#00B7F1",
                            textTransform: "initial",
                          }}
                        >
                          Last
                        </span>
                      </Button>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#26282C",
                  borderRadius: "4px",
  
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                onChange={(event) => {
                  const value = event.target.value.replace(/[^0-9.]+/g, "");
                  onChange(
                    value.split(".").length === 2
                      ? value
                      : isNaN(parseFloat(value))
                      ? ""
                      : String(parseFloat(value))
                  );
                }}
                {...field}
              />
            )}
          />
          <Controller
            control={form.control}
            name="profit"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                placeholder="ROI"
                error={!!form.formState.errors.profit?.message}
                helperText={form.formState.errors.profit?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography color="white">%</Typography>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#26282C",
                  borderRadius: "4px",
  
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                value={
                  value
                    ? (+(Number(((+value - price) / price) * 100).toFixed(1)) * (side === "Buy" ? 1 : -1))
                    : ""
                }
                onChange={(event) => {
                  const value = event.target.value.replace(/[^0-9.]+/g, "");
                  const result =
                    value.split(".").length === 2
                      ? value
                      : isNaN(parseFloat(value))
                      ? ""
                      : String(parseFloat(value));
                  if (result === "number") {
                    onChange(price + +result * price);
                  }
                }}
              />
            )}
          />
        </Box>
        <Controller
          control={form.control}
          name="profit"
          render={({ field: { value, onChange, ...field } }) => (
            <Slider
              style={{ marginTop: "12px" }}
              size="small"
              step={1}
              max={150}
              min={0}
              marks={new Array(5).fill(null).map((_, index) => ({
                label: `${index * 50}%`,
                value: index * 50,
              }))}
              valueLabelDisplay="auto"
              // value={value ? (Math.round(((price - +value) / price) * 100)) : 1}
              onChange={(e: any) => {
                if(side === 'Buy') {
                  onChange(price + price * (e.target.value / 100))
                }
                if(side === 'Sell') {
                  onChange((price - price * (e.target.value / 100)) > 0 ? (price - price * (e.target.value / 100)) : 0)
                }
              }
              }
              {...field}
            />
          )}
        />
  
        <Divider style={{ marginBlock: "28px" }} />
  
        <Typography
          fontSize={14}
          fontWeight={400}
          color="#80848E"
          marginTop="20px"
        >
          Stop Loss-Trigger by ROI (%)
        </Typography>
        <Box display="flex" flexDirection="row" gap="12px" marginTop="4px">
          <Controller
            control={form.control}
            name="loss"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                placeholder="Trigger Price"
                error={!!form.formState.errors.loss?.message}
                helperText={form.formState.errors.loss?.message}
                value={value ? value : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box>
                        <Button
                          onClick={() => {
                            const value = form.getValues("loss") ?? 0;
                            form.setValue(
                              "loss",
                              String(+value ? +value - 1 : 0)
                            );
                          }}
                          sx={{
                            padding: "0 !important",
                            paddingInline: "10px !important",
                            minWidth: "auto",
                          }}
                        >
                          -
                        </Button>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box>
                        <Button
                          onClick={() => {
                            const value = form.getValues("loss") ?? 0;
                            form.setValue(
                              "loss",
                              String(+value ? +value + 1 : 1)
                            );
                          }}
                          sx={{
                            padding: "0 !important",
                            paddingInline: "10px !important",
                            minWidth: "auto",
                          }}
                        >
                          +
                        </Button>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap="10px"
                      >
                        <Typography color="#00B7F1" sx={{ cursor: "pointer" }}>
                          Last
                        </Typography>
                      </Box>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#26282C",
                  borderRadius: "4px",
  
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                onChange={(event) => {
                  const value = event.target.value.replace(/[^0-9.]+/g, "");
                  onChange(
                    value.split(".").length === 2
                      ? value
                      : isNaN(parseFloat(value))
                      ? ""
                      : String(parseFloat(value))
                  );
                }}
                {...field}
              />
            )}
          />
          <Controller
            control={form.control}
            name="loss"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                placeholder="ROI"
                error={!!form.formState.errors.loss?.message}
                helperText={form.formState.errors.loss?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography color="white">%</Typography>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#26282C",
                  borderRadius: "4px",
  
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                value={
                  value
                    ? (+(Number(((+value - price) / price) * 100).toFixed(1)) * (side === "Buy" ? 1 : -1))
                    : ""
                }
                onChange={(event) => {
                  const value = event.target.value.replace(/[^0-9.]+/g, "");
                  const result =
                    value.split(".").length === 2
                      ? value
                      : isNaN(parseFloat(value))
                      ? ""
                      : String(parseFloat(value));
                  if (result === "number") {
                    onChange(price + +result * price);
                  }
                }}
                {...field}
              />
            )}
          />
        </Box>
        <Controller
          control={form.control}
          name="loss"
          render={({ field: { value, onChange, ...field } }) => (
            <Slider
              style={{ marginTop: "12px" }}
              size="small"
              step={1}
              max={75}
              min={0}
              marks={new Array(5).fill(null).map((_, index) => ({
                label: `${index * 25}%`,
                value: index * 25,
              }))}
              valueLabelDisplay="auto"
              // value={value ? Math.round(((+value - price) / price) * 100) : 1}
              onChange={(e: any) => {
                if(side === 'Buy') {
                  onChange(price - price * (e.target.value / 100))
                }
                if(side === 'Sell') {
                  onChange(price + price * (e.target.value / 100))
                }
              }
              }
              {...field}
            />
          )}
        />
      </>
    );
  }