import { FC, MouseEvent, useState } from "react";

import styled from "@emotion/styled";
import {
  Box,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import { IByBitWalletBalanceCoin } from "types/exchange.types";
import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon";

interface IProps {
  value: IByBitWalletBalanceCoin;
}

export const ExchangeBalanceItem: FC<IProps> = ({ value }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <ListItem
      component={"div"}
      sx={{
        ".MuiListItemSecondaryAction-root": {
          width: 22,
        },
        paddingRight: 6,
      }}
    >
      <ListItemIcon>
          <CoinIcon coinName={value?.coin.toLowerCase()} style={{ width: 20, height: 20 }} />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>{value.coin}</Typography>
            <Typography>
              {Number(value.availableToWithdraw.toString()).toFixed(8)}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  );
};

// const formattedValue = (value: string) => {
//   const strValue = String(value);
//   const num = Number(value);

//   if (strValue.includes('.')) {
//     const firstDigit = strValue[0];
//     const pointIndex = strValue.indexOf('.');
//     const digitAfterPoint = strValue[pointIndex + 1] + strValue[pointIndex + 2];
//     const lastTwoDigits = strValue.slice(-2);
//     return `${firstDigit}.${digitAfterPoint}...${lastTwoDigits}`;
//   } else {
//     return num.toFixed(2);
//   }
// };


const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background: #00b7f1;
    color: #01143b;
    transform: translateX(-50px) translateY(-50px) !important;

    &:last-of-type {
      transform: translateX(-50px) translateY(-20px) !important;
    }
  }
`;
