import { AffiliatesRequest } from 'types/form.types';

import { IReferral } from '../types/profile.types';
import { instance } from './axios';

export const referalApi = {
    getRef(): Promise<IReferral> {
        return instance.get('/api/v1/referral')
    },
    makeARequest(data: AffiliatesRequest) {
        return instance.post('/api/v1/ticket_commission', data)
    },
    withdrawComission() {
        return instance.post('/api/v1/referral_transfer')
    },
}
