import {
  useEffect,
  useState,
} from 'react';

import { authApi } from 'api';
import { useTimer } from 'hooks/useTimer';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { AuthLayout } from 'layouts/AuthLayout/AuthLayout';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Typography,
} from '@mui/material';

import { IForgotPasswordEmailReq } from '../../../../types/profile.types';

export const SendedVerificationCodeToEmail = () => {
  const {timer,startTimer, isDeadline} = useTimer()
  const navigate = useNavigate()
  const {forgotPassEmail} = useTypedSelector(state => state.profile)
  const [resend, setResend] = useState(false)
  useEffect(() => {
        startTimer(60)
    
}, [resend])
    return (
      <AuthLayout>
      <Typography align={'center'} variant={'h3'}>
      We sent to your email a password recovery link
      </Typography>

      <Typography align={'center'} variant={'h4'}>
          00:{String(timer)}
      </Typography>
      <Button sx={{mt: 7, whiteSpace: 'nowrap'}} size={'large'} disabled={!isDeadline}
                type={'submit'}
                onClick={() => {
                  authApi.forgotPassword(forgotPassEmail as unknown as IForgotPasswordEmailReq).then(res => {
                    setResend(!resend)
                  })
                  // navigate('/auth/forgot-password')
                }}
                variant={'outlined'}>
            Send again
        </Button>
    </AuthLayout>
    )
}

