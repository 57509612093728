import { useEffect, useState } from "react";

import { AdditionalLayout } from "components";
import { useAppDispatch } from "hooks/useAppDispatch";
import { NavLink } from "react-router-dom";
import { getPhoneThunk } from "store/profile/profile.thunk";

import LanguageIcon from "@mui/icons-material/Language";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getMeThunk } from "../../store/profile/profile.thunk";
import { ChangePassword } from "./components/ChangePassword";
import { ChangePhone } from "./components/ChangePhone";
import { ChangeLanguage } from "./components/ChnageLanguage";
import { GoogleAuth } from "./components/GoogleAuth";
import {
  StyledButtonWrapper,
  StyledCancelIcon,
  StyledCheckCircleIcon,
  StyledListItemBlock,
  StyledListItemButton,
  StyledListItemButtonLang,
  StyledListItemStatusTitle,
  StyledListItemText,
  StyledListItemTitle,
  StyledPageTitle,
  StyledPageWrapper,
  StyledSettingsItem,
  StyledStatus,
  StyledStatusContainer,
  StyledStatusTitle,
  StyledSubTitle,
  StyledTableContainer,
  StyledTableRow,
} from "./Settings.style";

const Settings = () => {
  const dispatch = useAppDispatch();
  const { data: phoneData } = useTypedSelector((state) => state.profile.phone);
  const verification = useTypedSelector(
    (state) => state.profile.me?.verification
  );
  const profile = useTypedSelector((state) => state.profile.me?.user);
  const [openPassword, setOpenPassword] = useState(false);
  const [openGoogleAuth, setOpenGoogleAuth] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [openLang, setOpenLang] = useState(false);

  const [lang, setLang] = useState({
    name: "English",
    value: "EN",
  });

  useEffect(() => {
    if (!phoneData) {
      dispatch(getPhoneThunk());
    }

    if (!verification) {
      dispatch(getMeThunk());
    }
  }, [phoneData]);

  return (
    <AdditionalLayout>
      <ChangePassword open={openPassword} setOpen={setOpenPassword} />
      <ChangePhone open={openPhone} setOpen={setOpenPhone} />
      <GoogleAuth open={openGoogleAuth} setOpen={setOpenGoogleAuth} />
      <ChangeLanguage
        open={openLang}
        setOpen={setOpenLang}
        lang={lang}
        setLang={setLang}
      />

      <StyledPageWrapper>
        <StyledPageTitle>Settings & Security</StyledPageTitle>
        <StyledStatusContainer display="flex" alignItems="center">
          <StyledStatus display="flex" alignItems="center">
            {verification?.email ? (
              <StyledCheckCircleIcon />
            ) : (
              <StyledCancelIcon />
            )}
            <StyledStatusTitle>Email Verified</StyledStatusTitle>
          </StyledStatus>

          <StyledStatus display="flex" alignItems="center">
            {verification && verification.otp_required ? (
              <StyledCheckCircleIcon />
            ) : (
              <StyledCancelIcon />
            )}
            <StyledStatusTitle>
              Two-factor authentication (2FA)
            </StyledStatusTitle>
          </StyledStatus>

          <StyledStatus display="flex" alignItems="center">
            {verification?.phone ? (
              <StyledCheckCircleIcon />
            ) : (
              <StyledCancelIcon />
            )}
            <StyledStatusTitle>Phone number verified</StyledStatusTitle>
          </StyledStatus>

          {/* <StyledStatus display="flex" alignItems="center">
            {verification?.kyc ? (
              <StyledCheckCircleIcon />
            ) : (
              <StyledCancelIcon />
            )}
            <StyledStatusTitle>Identity verified</StyledStatusTitle>
          </StyledStatus> */}
        </StyledStatusContainer>

        <StyledSubTitle>Security</StyledSubTitle>

        <StyledTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <StyledTableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <StyledListItemBlock>
                    <StyledListItemTitle>
                      Google Authenticator
                    </StyledListItemTitle>
                    <StyledListItemText>
                      Maximize security and protect funds
                    </StyledListItemText>
                  </StyledListItemBlock>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center">
                    {verification && !verification.otp_required ? (
                      <>
                        <StyledCancelIcon />
                        <StyledListItemStatusTitle>
                          Not connected
                        </StyledListItemStatusTitle>
                      </>
                    ) : (
                      <>
                        <StyledCheckCircleIcon /> Connected
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <StyledListItemButton
                    onClick={setOpenGoogleAuth.bind(null, true)}
                    size="small"
                    variant="outlined"
                  >
                    Manage
                  </StyledListItemButton>
                </TableCell>
              </StyledTableRow>

              <StyledTableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <StyledListItemBlock>
                    <StyledListItemTitle>
                      Email verification
                    </StyledListItemTitle>
                    <StyledListItemText>
                      Maximize security and protect funds
                    </StyledListItemText>
                  </StyledListItemBlock>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center">
                    {verification?.email ? (
                      <StyledCheckCircleIcon />
                    ) : (
                      <StyledCancelIcon />
                    )}
                    <StyledListItemStatusTitle>
                      {profile?.email}
                    </StyledListItemStatusTitle>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <StyledListItemButton
                    size="small"
                    variant="outlined"
                    disabled
                  >
                    Manage
                  </StyledListItemButton>
                </TableCell>
              </StyledTableRow>

              <StyledTableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <StyledListItemBlock>
                    <StyledListItemTitle>
                      Phone number verification
                    </StyledListItemTitle>
                    <StyledListItemText>
                      Maximize security and protect funds
                    </StyledListItemText>
                  </StyledListItemBlock>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center">
                    {verification && verification.phone ? (
                      <StyledCheckCircleIcon />
                    ) : (
                      <StyledCancelIcon />
                    )}
                    <StyledListItemStatusTitle>
                      {`${phoneData?.code || ""} ${phoneData?.number || ""}`}
                    </StyledListItemStatusTitle>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <StyledListItemButton
                    // disabled
                    size="small"
                    variant="outlined"
                    onClick={setOpenPhone.bind(null, true)}
                  >
                    Manage
                  </StyledListItemButton>
                </TableCell>
              </StyledTableRow>

              {/* <StyledTableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <StyledListItemBlock>
                    <StyledListItemTitle>
                      Identity verification
                    </StyledListItemTitle>
                    <StyledListItemText>
                      Verify ID document and complete facial scan
                    </StyledListItemText>
                  </StyledListItemBlock>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center">
                    {verification?.kyc ? (
                      <StyledCheckCircleIcon />
                    ) : (
                      <StyledCancelIcon />
                    )}
                    <StyledListItemStatusTitle>
                      {verification?.kyc ? "Enabled" : "Disabled"}
                    </StyledListItemStatusTitle>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <NavLink to="/dashboard/verification">
                    <StyledListItemButton size="small" variant="outlined">
                      Manage
                    </StyledListItemButton>
                  </NavLink>
                </TableCell>
              </StyledTableRow> */}
            </TableBody>
          </Table>
        </StyledTableContainer>

        <StyledSubTitle>Settings</StyledSubTitle>

        <StyledSettingsItem
          display="flex"
          alignItems="centert"
          justifyContent="space-between"
        >
          <div>
            <StyledListItemTitle>Login password</StyledListItemTitle>
            <StyledListItemText>
              Your password is used to log in to your Bitocol account.
            </StyledListItemText>
          </div>
          <StyledButtonWrapper>
            <StyledListItemButton
              onClick={setOpenPassword.bind(null, true)}
              size="small"
              variant="outlined"
            >
              Change
            </StyledListItemButton>
          </StyledButtonWrapper>
        </StyledSettingsItem>

        {/* <StyledSettingsItem
          display="flex"
          alignItems="centert"
          justifyContent="space-between"
        >
          <div>
            <StyledListItemTitle>Language</StyledListItemTitle>
            <StyledListItemText>
              The display language of the Bitocol Exchange
            </StyledListItemText>
          </div>
          <StyledButtonWrapper>
            <StyledListItemButtonLang
              startIcon={<LanguageIcon />}
              size="small"
              variant="text"
              onClick={() => setOpenLang(true)}
            >
              {lang.name}
            </StyledListItemButtonLang>
          </StyledButtonWrapper>
        </StyledSettingsItem> */}
      </StyledPageWrapper>
    </AdditionalLayout>
  );
};

export default Settings;
