import { SVGProps } from "react"

export const SwapIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_55_13924)">
            <path d="M10.3125 18.75H24.375C24.6236 18.75 24.8621 18.6512 25.0379 18.4754C25.2137 18.2996 25.3125 18.0611 25.3125 17.8125V5.625C25.3125 5.37636 25.2137 5.1379 25.0379 4.96209C24.8621 4.78627 24.6236 4.6875 24.375 4.6875H11.25C11.0014 4.6875 10.7629 4.78627 10.5871 4.96209C10.4113 5.1379 10.3125 5.37636 10.3125 5.625V6.5625" stroke="#414E52" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M12.1875 15.9375L9.375 18.75L12.1875 21.5625" stroke="#414E52" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M19.6875 11.25H5.625C5.37636 11.25 5.1379 11.3488 4.96209 11.5246C4.78627 11.7004 4.6875 11.9389 4.6875 12.1875V24.375C4.6875 24.6236 4.78627 24.8621 4.96209 25.0379C5.1379 25.2137 5.37636 25.3125 5.625 25.3125H18.75C18.9986 25.3125 19.2371 25.2137 19.4129 25.0379C19.5887 24.8621 19.6875 24.6236 19.6875 24.375V23.4375" stroke="#414E52" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M17.8125 14.0625L20.625 11.25L17.8125 8.4375" stroke="#414E52" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M17.8125 14.0625L20.625 11.25L17.8125 8.4375" stroke="#414E52" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_55_13924">
            <rect width="30" height="30" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}