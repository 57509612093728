import {
  createSlice
} from "@reduxjs/toolkit";
import { notistackState } from "./notistack.state";

export const notistackSlice = createSlice({
  name: 'notistack',
  initialState: notistackState,
  reducers: {
    setNotificationMessage: (state, action) => {
      state.msg = action.payload.msg
      state.variant = action.payload.variant
      state.timeStamp = new Date().getTime()
    }
  }
})

export const {
  setNotificationMessage
} = notistackSlice.actions

export const notistackReducer = notistackSlice.reducer