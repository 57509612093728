import { FC, forwardRef, useCallback, useEffect, useState } from "react";

import { useTypedSelector } from "hooks/useTypedSelector";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { countries } from "../../../../app.config";
import { IdentifyDocumentForm } from "../../../../components/Forms/IdentifyDocumentForm";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { confirmationCreateThunk } from "../../../../store/dashboard/dashboard.thunk";
import { IStatusConfirmation } from "../../../../types/confirmation.types";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface IProps {
  statusHandler: (value: IStatusConfirmation) => void;
}

const IdentifyPage: FC<IProps> = ({ statusHandler }) => {
  //Redux
  const dispatch = useAppDispatch();

  const { error } = useTypedSelector((state) => state.dashboard.confirmation);

  //states
  const [step, setStep] = useState<0 | 1>(1);
  const [checked, setChecked] = useState<boolean>(false);

  const [open, setOpen] = useState(false);

  //Handlers

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = useCallback((data: FormData) => {
    dispatch(confirmationCreateThunk(data));
    statusHandler("default");
  }, []);

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  useEffect(() => {
    const container = document.querySelector(".verification--container");
    if (container) {
      container.scrollTo({ top: 0, left: 0 });
    }
  }, []);

  return (
    <div className="default-layout__light">
      <Box width={"100%"} height={"100%"} display={"flex"}>
        <Paper
          square
          sx={{
            background: "#FFFFFF",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            pb: 10,
            pl: 4.5,
            pt: 3,
            pr: 4,
          }}
        >
          {step === 0 ? (
            <>
              <Typography variant={"h1"}>Let’s get you verified</Typography>
              <Box mt={8} maxWidth={620}>
                <Typography variant={"h5"}>
                  Before you start, please prepare your identity document and
                  make sure it is valid. We also requre you to agree to our
                  processing of your personal data.
                </Typography>
                <FormControlLabel
                  onChange={() => setChecked(!checked)}
                  sx={{ mt: 3 }}
                  control={<Checkbox />}
                  label={"I agree to the terms and conditions...."}
                />
              </Box>
              <Button
                disabled={!checked}
                onClick={() => setStep(1)}
                sx={{ mt: 9 }}
                variant={"outlined"}
                size="large"
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Box
                onClick={() => statusHandler("default")}
                display="inline-flex"
                alignItems="center"
                mb={4}
                sx={{ cursor: "pointer" }}
              >
                <img
                  src="/images/readMoreVectorIcon.svg"
                  width={15}
                  style={{ transform: "rotate(180deg)", marginRight: 11 }}
                  height={12}
                />
                <Typography color="#B3945E" variant="h5">
                  Back
                </Typography>
              </Box>
              <Typography
                sx={{
                  mb: 7,
                }}
                variant={"h1"}
                style={{ color: "#000" }}
              >
                Identity document
              </Typography>
              <IdentifyDocumentForm
                countries={countries}
                onSubmit={handleClick}
                statusHandler={statusHandler}
              />
            </>
          )}
        </Paper>
      </Box>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error?.detail || "An error has occurred"}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default IdentifyPage;
