import { FC } from 'react';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  IconButton,
} from '@mui/material';

interface IProps {
    src: string
    index: number
    onClose: (index: number) => void
}

export const BFileUploadImg: FC<IProps> = ({src, onClose, index}) => {
    return <Box key={index}
                sx={{
                    'img': {
                        width: 150,
                        height: 150,
                        objectFit: 'cover',
                        objectPosition: 'center'
                    },
                    position: 'relative'
                }}
                maxWidth={150}
                maxHeight={150}>
        <img src={src} alt=""/>
        <IconButton onClick={() => {
            onClose(index)
        }} sx={{
            position: 'absolute',
            top: -15,
            right: -30
        }}>
            <HighlightOffIcon  style={{color: "rgb(117,117,117)"}}/>
        </IconButton>
    </Box>
}
