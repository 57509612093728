import { createAsyncThunk } from "@reduxjs/toolkit";
import { confirmationApi } from "../../api/confirmation.api";
import { setConfirmation } from "./dashboard.slice";

export const confirmationCreateThunk = createAsyncThunk(
  "confirmationCreateThunk",
  async (data: FormData, { dispatch }) => {
    try {
      dispatch(
        setConfirmation({
          loading: true,
          sucess: null,
          error: null,
          response: null,
        })
      );

      const response = await confirmationApi.confirmationCreate(data);

      dispatch(
        setConfirmation({
          loading: false,
          success: true,
          error: null,
          response,
        })
      );

      return response;
    } catch (e: any) {
      console.log(e);
      dispatch(
        setConfirmation({
          loading: false,
          success: false,
          error: e?.response?.data || { detail: 'Error' },
          response: null,
        })
      );
    }
  }
);

export const confirmationGetThunk = createAsyncThunk(
  "confirmationGetThunk",
  () => {
    try {
      return confirmationApi.confirmationGet();
    } catch (e) {
      console.log(e);
    }
  }
);

export const confirmationStatusGetThunk = createAsyncThunk(
  "confirmationStatusGetThunk",
  (applicationId: string) => {
    try {
      return confirmationApi.confirmationStatusGet(applicationId);
    } catch (e) {
      console.log(e);
    }
  }
);
