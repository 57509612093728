import { useEffect, useRef, useState } from "react";

import { storageService } from "services/storage.service";

import { wsBaseUr, apiBaseUr, bybitWSUrl } from "app.config";
import { Centrifuge } from "centrifuge";
import { setNotificationMessage } from "store/notistack/notistack.slice";
import { setWalletBalance } from "store/wallets/wallets.slice";

import { Currency, LinearPerpetual } from "../types/exchange.types";
import { useAppDispatch } from "./useAppDispatch";
import { useTypedSelector } from "./useTypedSelector";

// export type ByBitTicker = {
//   topic: string;
//   type: "snapshot" | "delta";
//   data: {
//     symbol: string;
//     tickDirection: string;
//     price24hPcnt: string;
//     lastPrice: string;
//     prevPrice24h: string;
//     highPrice24h: string;
//     lowPrice24h: string;
//     prevPrice1h: string;
//     markPrice: string;
//     indexPrice: string;
//     openInterest: string;
//     openInterestValue: string;
//     turnover24h: string;
//     volume24h: string;
//     nextFundingTime: string;
//     fundingRate: string;
//     bid1Price: string;
//     bid1Size: string;
//     ask1Price: string;
//     ask1Size: string;
//   };
//   cs: number;
//   ts: number;
// };

// export type ByBitOrderbook = {
//   topic: string;
//   type: "snapshot" | "delta";
//   ts: number;
//   data: {
//     s: string;
//     b: [[string, string], [string, string]];
//     a: [[string, string], [string, string]];
//     u: number;
//     seq: number;
//   };
//   cts: number;
// };

export type Market = {
  bestAsk: string;
  bestBid: string;
  high24H: string;
  lastMarketPrice: string;
  lastSize: string;
  low24h: string;
  marketVolume: string;
  open24h: string;
  pairId: string;
  price: string;
  sequence: number;
  side: string;
  time: number;
  tradeId: number;
  type: string;
  volume24h: string;
  volume30d: string;
  change24H: string;
};

export type Ticker = {
  bestAsk: string;
  bestBid: string;
  lastSize: string;
  low24h: string;
  open24h: string;
  pairId: string;
  price: string;
  sequence: number;
  side: string;
  time: string;
  tradeId: number;
  type: string;
  volume24h: string;
  volume30d: string;
};

export type Level2 = {
  asks: Array<[string, string, number]>;
  bids: Array<[string, string, number]>;
};

export type Order = {
  createdAt: string;
  executedValue: string;
  fillFees: string;
  filledSize: string;
  id: string;
  orderType: string;
  pairId: string;
  price: string;
  sequence: number;
  settled: boolean;
  side: string;
  size: string;
  status: string;
  type: string;
  userId: number;
};

export type Rate = {
  change24H: number;
  coin: string;
  lastPriceEUR: number;
  lastPriceUSDT: number;
  pairID: number;
  volume24hEUR: number;
  volume24hUSDT: number;
};

interface ISockets {
  level2: any;
  ticker: any;
  market: any;
  order: any;
  rate: any;
  simpleExchange: any;
}

type UseCentrifuge = () => [
  () => void,
  {
    // byBitOrderbook: ByBitOrderbook | null;
    // byBitTicker: ByBitTicker | null;
    market: Market | null;
    ticker: Ticker | null;
    level2: Level2 | null;
    order: Order | null;
    rate: Rate | Rate[] | null;
    simpleExchange: Order | null;
    // createByBitConnection: (
    //   baseCurrency: string,
    //   quoteCurrency: string
    // ) => WebSocket;
    // subscribeOpenOrders: () => WebSocket;
  },
  (selectedPair: LinearPerpetual) => void
];

export const useCentrifuge: UseCentrifuge = () => {
  const [connection, setConnection] = useState<Centrifuge | null>(null);
  const [byBitConnection, setByBitConnection] = useState<WebSocket | null>(
    null
  );
  // const [byBitTicker, setByBitTicker] = useState<ByBitTicker | null>(null);
  // const [byBitOrderbook, setByBitOrderbook] = useState<ByBitOrderbook | null>(
  //   null
  // );
  const [openOrders, setOpenOrders] = useState<any>(null);
  const [marketState, setMarketState] = useState<Market | null>(null);
  const [tickerState, setTickerState] = useState<Ticker | null>(null);
  const [level2State, setLevel2State] = useState<Level2 | null>(null);
  const [rateState, setRateState] = useState<Rate | Rate[] | null>(null);
  const [orderState, setOrderState] = useState<Order | null>(null);
  const [simpleExchangeState, setSimpleExchangeState] = useState<Order | null>(
    null
  );

  const socketsRef = useRef<ISockets>({
    level2: null,
    order: null,
    market: null,
    ticker: null,
    rate: null,
    simpleExchange: null,
  });

  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({
    ticker: true,
    level2: true,
  });

  const dispatch = useAppDispatch();
  const user = useTypedSelector((state) => state.profile.me?.user);
  const selectedPair = useTypedSelector((state) => state.exchange.selectedPair);
  const { socketLoading } = useTypedSelector((state) => state.exchange);

  // function getToken(url: any, ctx: any): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     fetch(url, {
  //       method: "POST",
  //       headers: new Headers({
  //         "Content-Type": "application/json",
  //         "x-refresh-token": storageService.getRefreshToken,
  //       }),
  //       body: JSON.stringify(ctx),
  //     })
  //       .then((res) => {
  //         if (!res.ok) {
  //           throw new Error(`Unexpected status code ${res.status}`);
  //         }
  //         return res.json();
  //       })
  //       .then((data) => {
  //         storageService.setTokens = {
  //           access_token: data.access_token,
  //           refresh_token: data.refresh_token,
  //           token_type: "Bearer",
  //         };
  //         resolve(data.access_token);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }

  // const createByBitConnection = (
  //   baseCurrency: string,
  //   quoteCurrency: string
  // ) => {
  //   if (byBitConnection) {
  //     byBitConnection.close();
  //   }
  //   const byBitSocket = new WebSocket(`${bybitWSUrl}/v5/public/linear`);
  //   byBitSocket.onopen = () => {
  //     setByBitConnection(byBitSocket);
  //   };
  //   byBitSocket.onclose = () => {
  //     setByBitConnection(null);
  //   };
  //   byBitSocket.onmessage = function (msg) {
  //     const data = JSON.parse(msg.data);
  //     if (data.topic === `orderbook.200.${baseCurrency}${quoteCurrency}`) {
  //       setByBitOrderbook(data);
  //     } else if (data.topic === `tickers.${baseCurrency}${quoteCurrency}`) {
  //       setByBitTicker(data);
  //     }
  //   };

  //   return byBitSocket;
  // };

  const createConnection = () => {
    // const sessionToken = sessionStorage.getItem("accessToken");
    // const localToken = localStorage.getItem("accessToken");
    // const centrifuge = new Centrifuge(`${wsBaseUr}/connection/websocket`, {
    //   getToken: function (ctx) {
    //     return getToken(`${apiBaseUr}/api/v1/refresh`, ctx);
    //   },
    //   token: localToken,
    //   maxReconnectDelay: 0,
    // });
    // centrifuge
    //   .on("connecting", function (ctx) {
    //     // console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
    //     // 3000-3499, 4000-4499, >=5000
    //     if (
    //       (ctx.code > 3000 && ctx.code < 3499) ||
    //       (ctx.code > 4000 && ctx.code < 4499) ||
    //       ctx.code >= 5000
    //     ) {
    //       setConnection(null);
    //       createConnection();
    //     }
    //   })
    //   .on("connected", function (ctx) {
    //     // console.log(`connected over ${ctx.transport}`);
    //     setConnection(centrifuge);
    //   })
    //   .on("error", function (ctx) {
    //     //console.log(`centrifuge error:`, ctx);
    //   })
    //   .on("disconnected", function (ctx) {
    //     // console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
    //   })
    //   .connect();
  };

  // const subscribeByBitOrderbook = (
  //   baseCurrency: string,
  //   quoteCurrency: string
  // ) => {
  //   console.log(byBitConnection, baseCurrency, quoteCurrency);
  //   if (!byBitConnection) return null;
  //   byBitConnection.send(
  //     JSON.stringify({
  //       op: "subscribe",
  //       args: [`orderbook.200.${baseCurrency.trim()}${quoteCurrency.trim()}`],
  //     })
  //   );

  //   return () => {
  //     setByBitOrderbook(null);
  //     byBitConnection.send(
  //       JSON.stringify({
  //         op: "unsubscribe",
  //         args: [`orderbook.200.${baseCurrency.trim()}${quoteCurrency.trim()}`],
  //       })
  //     );
  //   };
  // };

  // const subscribeByBitTicker = (
  //   baseCurrency: string,
  //   quoteCurrency: string
  // ) => {
  //   if (!byBitConnection) return null;
  //   byBitConnection.send(
  //     JSON.stringify({
  //       op: "subscribe",
  //       args: [`tickers.${baseCurrency.trim()}${quoteCurrency.trim()}`],
  //     })
  //   );

  //   return () => {
  //     setByBitTicker(null);
  //     byBitConnection.send(
  //       JSON.stringify({
  //         op: "unsubscribe",
  //         args: [`tickers.${baseCurrency.trim()}${quoteCurrency.trim()}`],
  //       })
  //     );
  //   };
  // };

  // const subscribeOpenOrders = () => {
  //   const localToken = localStorage.getItem("accessToken");
  //   const socket = new WebSocket(
  //     `ws://my.bitocol.com/bybit/v1/private/order/${localToken}`
  //   );
  //   socket.onopen = () => {
  //     console.log("open orders socket connected");
  //   };
  //   socket.onmessage = (msg) => {
  //     const data = JSON.parse(msg.data);
  //     console.log(data);
  //     setOpenOrders(data);
  //   };
  //   socket.onclose = () => {
  //     setOpenOrders(null);
  //   };

  //   return socket;
  // };

  const subscribeBalances = (userId: number) => {
    const balances = connection?.subscriptions()[`found:${userId}`]
      ? connection?.subscriptions()[`found:${userId}`]
      : connection?.newSubscription(`found:${userId}`);
    //const balances = connection?.newSubscription(`found:${userId}`);
    balances
      ?.on("publication", function (ctx: any) {
        // console.log(`ticker:${pairId} publication:`, ctx);
        // container.innerHTML = ctx.data.value;
        dispatch(
          setWalletBalance({
            loading: false,
            error: null,
            data: ctx.data,
          })
        );
      })
      .on("subscribing", function (ctx: any) {})
      .on("subscribed", function (ctx: any) {})
      .on("unsubscribed", function (ctx: any) {})
      .on("error", function (ctx: any) {})
      .subscribe();
  };

  const subscribeTicker = (pairId: number) => {
    const oldSubs = connection?.subscriptions();
    if (oldSubs) {
      const prevSub = Object.keys(oldSubs!).filter((sub) =>
        sub.includes("ticker")
      );

      if (prevSub.length) {
        connection?.removeSubscription(oldSubs[prevSub[0]]);
      }
    }

    const ticker = connection?.newSubscription(`ticker:${pairId}`);
    ticker
      ?.on("publication", (ctx: any) => {
        if (ctx.data.type === "market") {
          socketsRef.current.market = ticker;
          setMarketState(ctx.data);
        } else if (ctx.data.type === "ticker") {
          socketsRef.current.ticker = ticker;
          setTickerState(ctx.data);
        } else {
          setTickerState(null);
          setMarketState(null);
        }

        if (ctx.data && socketLoading.level2) {
          setLoadingState({
            ...loadingState,
            ticker: false,
          });
          // console.log('has ticker data', loadingState)
        }
      })
      .on("subscribed", (ctx: any) => {
        //console.log('ticker subscribed')
      })
      .subscribe();
  };

  const subscribeLevel2 = (pairId: number) => {
    const oldSubs = connection?.subscriptions();
    if (oldSubs) {
      const prevSub = Object.keys(oldSubs!).filter((sub) =>
        sub.includes("level2")
      );

      if (prevSub.length) {
        connection?.removeSubscription(oldSubs[prevSub[0]]);
      }
    }

    const level2 = connection?.newSubscription(`level2:${pairId}`);
    socketsRef.current.level2 = level2;
    level2
      ?.on("publication", (ctx: any) => {
        if (ctx.data) {
          setLevel2State({
            asks: ctx.data.asks,
            bids: ctx.data.bids,
          });
        } else {
          setLevel2State(null);
        }
      })
      .on("subscribed", (ctx: any) => {
        //console.log('level2 subscribed')
      })
      .subscribe();
  };

  const subscribeRate = () => {
    const rate = connection?.subscriptions()[`rate`]
      ? connection?.subscriptions()[`rate`]
      : connection?.newSubscription(`rate`);
    socketsRef.current.rate = rate;
    rate
      ?.on("publication", (ctx: any) => {
        if (ctx.data) {
          setRateState(ctx.data);
        } else {
          console.log("rate null");
        }
      })
      .on("subscribed", (ctx: any) => {
        //console.log('rate subscribed')
      })
      .subscribe();
  };

  const subscribeOrder = (pairId: number, userId: number) => {
    const oldSubs = connection?.subscriptions();
    if (oldSubs) {
      const prevSub = Object.keys(oldSubs!).filter((sub) =>
        sub.includes("order")
      );

      if (prevSub.length) {
        connection?.removeSubscription(oldSubs[prevSub[0]]);
      }
    }
    const orders = connection?.newSubscription(`order:${pairId}:${userId}`);
    socketsRef.current.order = orders;
    orders
      ?.on("publication", (ctx: any) => {
        setOrderState(ctx.data);

        switch (ctx.data.status) {
          case "cancelled":
            dispatch(
              setNotificationMessage({
                msg: "Order was canceled",
                variant: "error",
              })
            );
            break;
          case "filled":
            dispatch(
              setNotificationMessage({
                msg: "Order successfully filled",
                variant: "success",
              })
            );
            break;
          case "partial_filled":
            dispatch(
              setNotificationMessage({
                msg: "Order filled partially",
                variant: "success",
              })
            );
            break;
          case "open":
            dispatch(
              setNotificationMessage({
                msg: "Order successfully created",
                variant: "success",
              })
            );
            break;
        }
      })
      .on("subscribed", (ctx: any) => {
        //console.log('orders subscribed')
      })
      .subscribe();
  };

  const subscribeSimpleExchange = (userId: number) => {
    const oldSubs = connection?.subscriptions();
    if (oldSubs) {
      const prevSub = Object.keys(oldSubs!).filter((sub) =>
        sub.includes("simple_exchange")
      );

      if (prevSub.length) {
        connection?.removeSubscription(oldSubs[prevSub[0]]);
      }
    }
    const simpleExchange = connection?.newSubscription(
      `simple_exchange:${userId}`
    );
    socketsRef.current.simpleExchange = simpleExchange;
    simpleExchange
      ?.on("publication", (ctx: any) => {
        setSimpleExchangeState(ctx.data);

        switch (ctx.data.status) {
          case "cancelled":
            dispatch(
              setNotificationMessage({
                msg: "Order was canceled",
                variant: "error",
              })
            );
            break;
          case "filled":
            dispatch(
              setNotificationMessage({
                msg: "Order successfully filled",
                variant: "success",
              })
            );
            break;
          case "partial_filled":
            dispatch(
              setNotificationMessage({
                msg: "Order filled partially",
                variant: "success",
              })
            );
            break;
          case "open":
            dispatch(
              setNotificationMessage({
                msg: "Order successfully created",
                variant: "success",
              })
            );
            break;
        }

        // if (ctx.data.status === 'cancelled' || ctx.data.status === 'filled') {
        //   dispatch(setNotificationMessage({
        //     msg: ctx.data.status === 'cancelled' ? 'Order was canceled' : 'Order successfully filled',
        //     variant: ctx.data.status === 'cancelled' ? 'error' : 'success'
        //   }))
        // }
      })
      .on("subscribed", (ctx: any) => {
        //console.log('orders subscribed')
      })
      .subscribe();
  };

  // useEffect(() => {
  //   console.log("market", marketState);
  //   console.log("ticker", tickerState);
  //   console.log("level2", level2State);
  // }, [marketState, level2State, tickerState]);

  useEffect(() => {
    if (connection && user?.id) {
      selectedPair && subscribeTicker(Number(selectedPair?.symbol));
      selectedPair && subscribeLevel2(Number(selectedPair?.symbol));
      selectedPair && subscribeOrder(Number(selectedPair?.symbol), user.id);
    }

    // dispatch(setSocketLoading({
    //   level2: true,
    //   tickers: true
    // }))
  }, [selectedPair]);

  const disconnect = () => {
    if (socketsRef.current.level2) socketsRef.current.level2.unsubscribe();
    if (socketsRef.current.order) socketsRef.current.order.unsubscribe();
    if (socketsRef.current.simpleExchange)
      socketsRef.current.simpleExchange.unsubscribe();
    if (socketsRef.current.market) socketsRef.current.market.unsubscribe();
    if (socketsRef.current.ticker) socketsRef.current.ticker.unsubscribe();
  };

  const changePair = (selectedPair: LinearPerpetual) => {
    disconnect();
    setMarketState(null);
    setTickerState(null);
    setLevel2State(null);
    setOrderState(null);
    setRateState(null);
    setSimpleExchangeState(null);
    user && subscribeBalances(user.id);
    selectedPair && subscribeTicker(Number(selectedPair?.symbol));
    selectedPair && subscribeLevel2(Number(selectedPair?.symbol));
    selectedPair && subscribeOrder(Number(selectedPair?.symbol), user?.id || 0);
    selectedPair && subscribeSimpleExchange(user?.id || 0);
  };

  useEffect(() => {
    if (connection && user?.id) {
      user && subscribeBalances(user.id);
      selectedPair && subscribeTicker(Number(selectedPair?.symbol));
      selectedPair && subscribeLevel2(Number(selectedPair?.symbol));
      selectedPair && subscribeOrder(Number(selectedPair?.symbol), user.id);
      selectedPair && subscribeSimpleExchange(user.id);
      subscribeRate();
    }
  }, [connection, user?.id]);

  // useEffect(() => {
  //   // console.log(byBitConnection, selectedPair);
  //   if (byBitConnection && selectedPair) {
  //     const unsubscribeOrderbook = subscribeByBitOrderbook(
  //       selectedPair.BaseCurrency,
  //       selectedPair.QuoteCurrency
  //     );
  //     const unsubscribeTicker = subscribeByBitTicker(
  //       selectedPair.BaseCurrency,
  //       selectedPair.QuoteCurrency
  //     );
  //     return () => {
  //       unsubscribeOrderbook && unsubscribeOrderbook();
  //       unsubscribeTicker && unsubscribeTicker();
  //     };
  //   }
  // }, [byBitConnection, selectedPair]);

  return [
    createConnection,
    {
      // byBitOrderbook,
      // byBitTicker,
      market: marketState,
      ticker: tickerState,
      level2: level2State,
      order: orderState,
      rate: rateState,
      simpleExchange: simpleExchangeState,
      // createByBitConnection,
      // subscribeOpenOrders,
    },
    changePair,
  ];
};
