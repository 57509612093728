import { changeTicker } from "store/exchange/exchange.slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { exchangeApi } from "../../api/exchange.api";
//import {IOrderCreateReq, ITransferWallet} from "../../types/exchange.types";
import {
  IGetByBitWalletCurrency,
  IGetWalletCurrency,
  ITransferWallet,
} from "../../types/exchange.types";
import { IRates } from "../../types/exchange.types";
import { setNotificationMessage } from "store/notistack/notistack.slice";
import {
  getWallets,
  getBalance,
  getRates,
  getVolume,
  setWalletBalance,
  setByBitWalletBalance,
} from "./wallets.slice";
import { walletsApi } from "api";

export const pairTickersGetThunk = createAsyncThunk(
  "pairTickersGetThunk",
  async (id: number, { dispatch }) => {
    try {
      const res = await exchangeApi.getTickersPairHistory(id);
      dispatch(changeTicker(res));
      return exchangeApi.getTickersPairHistory(id);
    } catch (e) {
      console.log(e);
    }
  }
);

export const getWalletsThunk =
  (data?: IGetWalletCurrency) => async (dispatch: any) => {
    try {
      dispatch(
        getWallets({
          loading: true,
          error: null,
          data: null,
        })
      );

      const response = await walletsApi.getWalletCurrency(data);

      dispatch(
        getWallets({
          loading: false,
          error: null,
          data: response,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        getWallets({
          loading: false,
          error,
          data: null,
        })
      );
    }
  };

export const getWalletBalanceThunk = () => async (dispatch: any) => {
  try {
    dispatch(
      setWalletBalance({
        loading: true,
        error: null,
        data: null,
      })
    );

    const walletBalance = await walletsApi.getWalletBalance();

    dispatch(
      setWalletBalance({
        loading: false,
        error: null,
        data: walletBalance,
      })
    );
  } catch (err) {
    dispatch(
      setWalletBalance({
        loading: false,
        error: err,
        data: null,
      })
    );
  }
};

export const getByBitWalletBalanceThunk =
  (data: IGetByBitWalletCurrency) => async (dispatch: any) => {
    try {
      dispatch(
        setWalletBalance({
          loading: true,
          error: null,
          data: null,
        })
      );

      const walletBalance = await walletsApi.getByBitWalletBalance(data);

      dispatch(
        setByBitWalletBalance({
          loading: false,
          error: null,
          data: walletBalance,
        })
      );
    } catch (err) {
      dispatch(
        setByBitWalletBalance({
          loading: false,
          error: err,
          data: null,
        })
      );
    }
  };

export const getBalanceThunk = () => async (dispatch: any) => {
  try {
    dispatch(
      getBalance({
        loading: true,
        error: null,
        // data: null,
      })
    );

    const data = await walletsApi.getBalance();

    dispatch(
      getBalance({
        loading: false,
        error: null,
        data: data.available,
      })
    );
  } catch (error: any) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.detail === "Verify phone"
    ) {
      error.response.data.detail =
        "In order to use this service, you need to pass verification";
    }
    dispatch(
      getBalance({
        loading: false,
        error: error.response.data,
        data: null,
      })
    );
  }
};

export const getRatesThunk = () => async (dispatch: any) => {
  try {
    dispatch(
      getRates({
        loading: true,
        error: null,
        data: [],
      })
    );

    const data = await walletsApi.getRates();
    //const data = tempRates
    const tmpRatesList: IRates = {};

    data?.order.forEach(({ baseCurrency, price, quoteCurrency }) => {
      tmpRatesList[baseCurrency] = {
        [quoteCurrency]: Number(price),
      };
    });
    dispatch(
      getRates({
        loading: false,
        error: null,
        data: tmpRatesList,
      })
    );
  } catch (error) {
    dispatch(
      getRates({
        loading: false,
        error: null,
        data: [],
      })
    );
  }
};

export const getVolumeThunk = () => async (dispatch: any) => {
  try {
    dispatch(
      getVolume({
        loading: true,
        error: null,
        data: null,
      })
    );

    const volume = await walletsApi.getVolume();

    dispatch(
      getVolume({
        loading: false,
        error: null,
        data: volume,
      })
    );
  } catch (err) {
    dispatch(
      getVolume({
        loading: false,
        error: err,
        data: null,
      })
    );
  }
};

export const postTransferThunk = createAsyncThunk(
  "postTransferThunk",
  async (data: ITransferWallet, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setNotificationMessage({
          msg: "Withdrawal in process",
          variant: "success",
        })
      );
      const transferData = await walletsApi.postTransfer(data);
      thunkAPI.dispatch(
        setNotificationMessage({
          msg: "Success",
          variant: "success",
        })
      );
      return transferData;
    } catch (e) {
      thunkAPI.dispatch(
        setNotificationMessage({
          msg: "Your withdrawl is failed. Try later",
          variant: "error",
        })
      );
      console.log(e);
    }
  }
);

export const getWalletThunk = createAsyncThunk(
  "getWalletThunk",
  async (currency: string) => {
    try {
      return await walletsApi.getWalletCurrency({ currency });
    } catch (e) {
      console.log(e);
    }
  }
);
