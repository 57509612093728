import { ComponentProps } from "react"
import { FormControl, InputAdornment } from "@mui/material"
import styled from "@emotion/styled"

import { StyledInputSearch, StyledSearchIcon } from "pages/Wallets/components/CommonComponents.styles"

interface ExchangeCoinListModalSearchProps extends ComponentProps<typeof StyledInputSearch> {
}

export const ExchangeCoinListModalSearch = ({ ...props }: ExchangeCoinListModalSearchProps) => {
    return (
        <StyledExchangeCoinListModalSearch.Wrapper>
          <FormControl style={{width: "100%"}} variant="standard">
              <StyledInputSearch
                style={{ width: "100%", color: "#000", fontSize: "14px", lineHeight: "21px", margin: 0 }}
                id="input-with-icon-adornment"
                placeholder="Search crypto name"
                startAdornment={
                  <InputAdornment position="start">
                    <StyledSearchIcon />
                  </InputAdornment>
                }
                {...props}
              />
          </FormControl>
        </StyledExchangeCoinListModalSearch.Wrapper>
    )
}

const StyledExchangeCoinListModalSearch = {
    Wrapper: styled.div`
        padding: 0 36px 0 36px;
    `
}