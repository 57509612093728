import {createContext, FC, ReactNode, useState} from 'react'
import {TTheme} from "../types/common.types";

interface IProps {
    children: ReactNode
}

interface ICustomThemeContext {
    theme: TTheme
    setTheme: (theme: TTheme) => void,

}

export const CustomThemeContext = createContext<Partial<ICustomThemeContext>>({
    theme: 'light'
})


export const CustomThemeProvider: FC<IProps> = ({children}) => {
    const [theme, setTheme] = useState<TTheme>('light')

    const value = {
        theme,
        setTheme
    }

    return <CustomThemeContext.Provider value={value}>
        {
            children
        }
    </CustomThemeContext.Provider>
}
