import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled"

import { StyledDialogTitle } from "pages/Wallets/components/CommonComponents.styles"

interface ExchangeCoinListModalHeadProps {
    onClose: () => void
}

export const ExchangeCoinListModalHead = ({ onClose }: ExchangeCoinListModalHeadProps) => {
    return (
        <StyledExchangeCoinListModalHead.Wrapper>
            <StyledExchangeCoinListModalHead.Title>
                Select currency
            </StyledExchangeCoinListModalHead.Title>
            <StyledExchangeCoinListModalHead.Close
                onClick={onClose}
            />
        </StyledExchangeCoinListModalHead.Wrapper>
    )
}

const StyledExchangeCoinListModalHead = {
    Wrapper: styled.div`
        display: flex;
        justify-content: start;
    `,
    Title: styled(StyledDialogTitle)`
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        line-height: 22.5px;
        color: #000;
        padding-top: 21px;
        padding-left: 36px;
    `,
    Close: styled(CloseIcon)`
        position: absolute;
        top: 21px;
        right: 36px;
        cursor: pointer;
    `,
}