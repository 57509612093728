import React, { FC, useLayoutEffect } from "react";

import { CustomHeader } from "components";

import { useCustomTheme } from "../../hooks/useCustomTheme";
import { TTheme } from "../../types/common.types";
import styles from "./DefaultLayout.module.scss";

interface IProps {
	children: React.ReactNode;
	mode?: TTheme;
}

export const DefaultLayout: FC<IProps> = ({ children, mode }) => {
	const { setTheme } = useCustomTheme();

	useLayoutEffect(() => {
		setTheme!(mode === "light" ? "light" : "dark");
	}, [mode]);

	return (
		<div className={styles[mode === "light" ? "default-layout__light" : "default-layout__dark"]}>
			<CustomHeader />
			{children}
		</div>
	);
};
