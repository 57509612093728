import {
  Box,
  Button,
  Dialog,
  FormControl,
  Select,
  styled,
  TextField,
} from "@mui/material";

import bgImage from "./assets/simple-exchange-bg.png";

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1400px",
  margin: "0 auto",
  padding: "0 25px",
  fontFamily: "Poppins",
  // marginLeft:60
}));

export const StyledExchangeFlex = styled(Box)`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1.6fr;
  width: 100%;
  max-width: 100%;
  font-family: Poppins;
  
  .form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 57px;
    position: relative;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 591px;
    height: 675px;
    padding: 24px 81px 31px 81px;
    background-color: #011031;
    border-radius: 5px;
    position: relative;
    z-index: 1;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    
    .form {
      padding: 16px;
    }
  }
`;

export const StyledExchangeContent = styled(Box)(({ theme }) => ({
  // width: "37%",
  marginTop: 57,
  marginLeft: 50,
  fontFamily: "Poppins",
}));

export const StyledExchangeForm = styled(Box)(({ theme }) => ({
  // width: "63%",
  // height: 700,
  // marginLeft: "108px",
  marginTop: 57,
  marginBottom: 57,
  fontFamily: "Poppins",
}));

export const StyledExchangeFormBlock = styled(Box)(({ theme }) => ({
  width: "596px",
  maxWidth: "100%",
  // height: 580,
  backgroundColor: "#011031 !important",
  borderRadius: "5px",
  padding: "24px 81px 31px 81px",
  color: "#E7E7E7",
  fontFamily: "Poppins",
}));

export const StyledExchangeFormTitle = styled(Box)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "600",
  fontSize: "20px",
  textAlign: "center",
  marginBottom: "20px",
}));

export const StyledExchangeFormSubtitle = styled(Box)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "14px",
  textAlign: "center",
  marginBottom: "40px",
}));

export const StyledExchangeFormSelect = styled(Button)(({ theme }) => ({
  display: "inline-block",
  border: "1px solid #00B7F1",
  borderRadius: "25.5px",
  backgroundColor: "transparent",
  padding: "7px 41px",
  marginRight: "30px",
  fontFamily: "Poppins",
}));

export const StyledExchangeFormText = styled(Button)(({ theme }) => ({
  display: "inline-block",
  border: "1px solid #00B7F1",
  borderRadius: "25.5px",
  backgroundColor: "transparent",
  padding: "7px 41px",
  marginRight: "30px",
  fontFamily: "Poppins",
}));

export const StyledExchangeFormSubmit = styled(Button)`
  width: 100%;
  display: inline-block;
  border: 1px solid #00B7F1;
  border-radius: 3px;
  text-align: center;
  background-color: #00B7F1;
  padding: 10px 0;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: Poppins;
  &:disabled {
    cursor: not-allowed;
  }
`

export const StyledExchangeFormInputTitle = styled(Box)(({ theme }) => ({
  color: "white",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: "10px",
  fontFamily: "Poppins",
}));

export const StyledExchangeFormInputText = styled(TextField)(({ theme }) => ({
  width: "100%",
  color: "white",
  fontFamily: "Poppins",
}));

export const StyledExchangeFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#0C2250",
  borderRadius: "5px",
  border: "none",
  fontFamily: "Poppins",
}));

export const StyledExchangeFormControlSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  border: "none",
  color: "#E7E7E7",
  fontSize: "16px",
  fontFamily: "Poppins",
}));

export const StyledExchangeTitle = styled(Box)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "35px",
  fontWeight: "600",
  lineHeight: "52.5px",
  marginBottom: "27px",
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "314px",
  color: "#414E52",
  fontFamily: "Poppins",
}));

export const StyledContentTitle = styled(Box)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "30px",
  marginBottom: "36px",
  fontFamily: "Poppins",
}));

export const StyledContentFlex = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const StyledContentFlexBlock = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 20,
  alignItems: "center",
  marginBottom: "30px",
  color: "#414E52",
}));

export const StyledContentFlexText = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  // marginLeft: "20px"
}));
export const StyledExchangeFormDesc = styled("span")(({ theme }) => ({
  fontSize: "10px",
  fontFamily: "Poppins",
  fontWeight: 500,
  color: "#fff",
  marginTop: 8,
}));
export const StyledBottomContent = styled("div")(({ theme }) => ({
  marginTop: 126,
}));
export const StyledRedText = styled("span")(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#D83232",
  display: "block",
  marginBottom: 8,
}));
export const StyledText = styled("span")(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#B3945E",
}));

export const CurrencyBlock = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 36px ",
}));
export const CurrencyBalance = styled("div")(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "column",
}));

export const CurrencyName = styled("div")(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "16px",
  display: "flex",
  verticalAlign: "middle",

  lineHeight: "24px",
  color: "#00B7F1",
  cursor: "pointer",
}));
export const StyledDialogForSelectCoin = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    background: "#FFF",
    width: 406,
    height: 458,
    color: "#000",
    "& div": {
      color: "#000",
    },
    "& button": {
      color: "#000",
    },
  },
}));
