import "./affiliatesStyles.scss";

// import img from 'next/image'
import { FC, useEffect, useState } from "react";

// import { withAuth } from '@openware/opendax-web-sdk'
import { AdditionalLayout } from "components";
import { NavLink, useNavigate } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Button, Grid, Typography } from "@mui/material";

import { useTypedSelector } from "../../hooks/useTypedSelector";
// global components
// import { Layout } from '../../components'
import CopyInput from "./components/CopyInput";
import { SuccessCopy } from "./components/SuccessCopy";

const Affiliates: FC = (): JSX.Element | null => {
  const [copiedLink, setCopiedLink] = useState<string[]>([]);
  const navigate = useNavigate();

  //Redux
  const referral = useTypedSelector((state) => state.profile.referral);

  //Handlers
  const toMakeARequest = () => {
    navigate("/make-a-request");
  };

  return (
    <AdditionalLayout>
      <div className="affiliatesRoot">
        <div className="whiteBg affiliatesMain">
          <div className="affiliates">
            {/* <Box className="link" display="flex" onClick={ () => navigate('/dashboard') }  style={{margin: "31px 0 0 20px", cursor:"pointer"}} alignItems="center">
            <ArrowBackIcon  sx={{ 
               color: "#00B7F1", 
               Width: 14,
               height:14
            }}/>
            <div style={{color: "#00B7F1", fontWeight: 400, fontSize: '12px', marginLeft: 2,}}>Back</div>
          </Box> */}
            <div className="affiliates__content">
              <div className="title">Affiliates</div>
              <div className="affiliates__content_subtitle">
                Invite friends and earn rewards.
              </div>
              <p className="affiliates__content_subtitle mb60px">
                Receive 30% of your friend's fees, unlimited and lifelong!
                <a
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#80848E",
                    textDecoration: "underline",
                  }}
                  href="#"
                ></a>
              </p>
              {/* <button className="affiliates__content_inviteBtn">
                Invite friends
              </button> */}

              <Box display={"flex"} alignItems={"center"} gap={5}>
                <CopyInput
                  onClick={() =>
                    setCopiedLink([...copiedLink, "Affiliate code"])
                  }
                  title={"Affiliate code"}
                  text={referral.data ? referral.data.code : ""}
                />
                {copiedLink.includes("Affiliate code") && (
                  <SuccessCopy text={"code"} />
                )}
              </Box>

              <Box my={"35px"} display={"flex"} alignItems={"center"} gap={5}>
                <CopyInput
                  title={"Affiliate link"}
                  text={`https://my.bitocol.com/signup?code=${referral?.data?.code}`}
                  onClick={() =>
                    setCopiedLink([...copiedLink, "Affiliate link"])
                  }
                />
                {copiedLink.includes("Affiliate link") && (
                  <SuccessCopy text={"link"} />
                )}
              </Box>

              <div className="commission">
                <div className="commission__title">Commission %</div>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>30.00 %</div>
                  <Button
                    className="commission__button"
                    variant="text"
                    endIcon={<ArrowForwardIcon />}
                    onClick={() => toMakeARequest()}
                  >
                    Request custom
                  </Button>
                </Box>
              </div>
            </div>

            {/* <img
              className="affiliates__bg"
              src="/images/affiliatesBG.jpg"
              alt="affiliate background"
              // layout="fill"
            /> */}
          </div>

          <div className="statistics whiteBg">
            <p className="title">Statistics</p>
            <Grid container className="statistics__item">
              <Grid item xs={6.5}>
                <div className="statistics__item__title">Referred users</div>
                <div className="statistics__item__text">
                  The Total amount of users referred to Bitocol exchange
                </div>
              </Grid>

              <Grid className={"statistics__item__center-column"} item xs={2}>
                {referral.data && (
                  <Typography color={"#000"} variant={"h4"}>
                    {referral.data.total_referrals_count}
                  </Typography>
                )}
              </Grid>
              {referral.data && (
                <Grid className="statistics__item__rightcolumn" item xs={2}>
                  <Box
                    className="statistics__item__text__green"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <ArrowUpwardIcon />
                    <span style={{ color: "#000" }}>
                      {referral.data.total_referrals_count}
                    </span>
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid container className="statistics__item">
              <Grid item xs={6.5}>
                <div className="statistics__item__title">Pending commission</div>
                <div className="statistics__item__text">
                  The Commission will be transferred to your wallet every 24h
                </div>
              </Grid>

              <Grid className={"statistics__item__center-column"} item xs={3}>
                {referral.data && (
                  <Typography color={"#000"} variant={"h4"}>
                    ${referral.data.balance.toFixed(4)}
                  </Typography>
                )}
              </Grid>
              <Grid className="statistics__item__rightcolumn" item xs={2.5}>
                <NavLink
                  className="statistics__item__link"
                  to="/withdraw-comission"
                >
                  Transfer to wallet
                </NavLink>
              </Grid>
            </Grid>

            <Grid container className="statistics__item">
              <Grid item xs={6.5}>
                <div className="statistics__item__title">
                  Total commission earned
                </div>
                <div className="statistics__item__text">
                  Total commission earned during the lifetime of Bitocol account
                </div>
              </Grid>

              <Grid className={"statistics__item__center-column"} item xs={3}>
                {referral.data && (
                  <Typography variant={"h4"} color={"#000"}>
                    $
                    {
                      referral.data.all_time_balance
                        .toLocaleString("en-US", {
                          style: "currency",
                          currency: "EUR",
                        })
                        .split("€")[1]
                    }
                  </Typography>
                )}
              </Grid>
              {/* <Grid className="statistics__item__rightcolumn" item xs={2}>
                <NavLink className="statistics__item__link" to="/wallets">Transfer to wallet</NavLink>
              </Grid> */}
            </Grid>
          </div>
        </div>

        <div className="needToKnow whiteBg">
          <h3 className="title title__center needToKnow-mobile-title">Need to know</h3>
          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">Affiliate code</p>
            <p className="needToKnow__block_text">
              The code you can share with your friends which they can enter when
              signing up
            </p>
          </div>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">Affiliate link</p>
            <p className="needToKnow__block_text">
              The link you can share with your friends which they can use to
              sign up to Bitocol
            </p>
          </div>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">Commission</p>
            <p className="needToKnow__block_text">
              The amount of commission you have earned in euro’s, this will be
              deposited in your wallet every 24H.
            </p>
          </div>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">Referred users </p>
            <p className="needToKnow__block_text">
              The amount of new users brought to the Bitocol platform through
              your affiliate code/link
            </p>
          </div>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">
              Earn up to 50% commission?
            </p>
            <p className="needToKnow__block_text">
              (Super) affiliates are eligible for up to 50% commission and other
              rewards.
            </p>
          </div>

          <Button
            sx={{ p: "0 !important" }}
            variant={"text"}
            onClick={toMakeARequest}
            className="needToKnow__moreBtn"
          >
            <span>Make a request</span>
            <img
              src="/images/readMoreVectorIcon.svg"
              alt="request icon"
              width={13}
              height={11}
            />
          </Button>

          <div className="needToKnow__block">
            <p className="needToKnow__block_subtitle">
              Affiliate terms & conditions
            </p>
            <a href="/Terms_and_conditions_Bitocol.pdf" target="_blank">
              <Button
                sx={{ p: "0 !important" }}
                variant={"text"}
                className="needToKnow__moreBtn"
              >
                <span>Read more</span>
                <img
                  src="/images/readMoreVectorIcon.svg"
                  alt="read more vector icon"
                  width={13}
                  height={11}
                />
              </Button>
            </a>
          </div>
        </div>
      </div>
    </AdditionalLayout>
  );
};

export default Affiliates;
