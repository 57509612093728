import { styled, Box } from "@mui/material";

export const StyledPageWrapper = styled("div")(({ theme }) => ({
	background: "#0D0901",
	height: "calc(100vh - 81px)",
	padding: "45px 57px 150px",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
	marginBottom: 32,
}));

export const StyledTitle = styled("div")(({ theme }) => ({
	color: "#FFFFFF",
	fontSize: 45,
	marginBottom: 15,
	fontWeight: 600,
}));
