import { instance } from './axios';

export interface IAddress {
    address: string,
    zip: string,
    city: string,
    state: string,
    country: string,
}

export const addressApi = {
    getAddress(): Promise<IAddress> {
        return instance.get('/api/v1/address')
    },
    createAddress(data: IAddress) {
        return instance.post('/api/v1/address', data)
    },
}
