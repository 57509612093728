import { defaultSelectedPairId } from "../../app.config";
import {
  Currency,
  CurrencyMapBody,
  ILevel2,
  IMarket,
  IMarketsList,
  IOrder,
  ITicker,
  ITrending,
  LinearPerpetual,
} from "../../types/exchange.types";

export const DEFAULT_PAIR = {
  symbol: "BTCUSDT",
  contractType: "LinearPerpetual",
  status: "Trading",
  baseCoin: "BTC",
  quoteCoin: "USDT",
  launchTime: "1585526400000",
  deliveryTime: "0",
  deliveryFeeRate: "",
  priceScale: "2",
  leverageFilter: {
    minLeverage: "1",
    maxLeverage: "100.00",
    leverageStep: "0.01",
  },
  priceFilter: {
    minPrice: "0.10",
    maxPrice: "199999.80",
    tickSize: "0.10",
  },
  lotSizeFilter: {
    maxOrderQty: "500.000",
    minOrderQty: "0.001",
    qtyStep: "0.001",
    postOnlyMaxOrderQty: "500.000",
    maxMktOrderQty: "100.000",
  },
  unifiedMarginTrade: true,
  fundingInterval: 480,
  settleCoin: "USDT",
  copyTrading: "both",
  upperFundingRate: "0.00375",
  lowerFundingRate: "-0.00375",
}

interface IState {
  socketLoading: {
    tickers: boolean;
    level2: boolean;
  };
  isExchangeLoading: boolean;
  insufficientError: boolean;
  sellInsufficientError: boolean;
  orders?: IOrder[];
  cancelOrder: {
    loading: boolean;
    error: null;
    response: null;
  };
  currencies: IMarketsList | null;
  currenciesMap: CurrencyMapBody | null
  level2: ILevel2;
  tickers: ITicker | null;
  selectedPair: LinearPerpetual | null;
  markets: IMarket[];
  marketsList: IMarketsList | null;
  selectedPairID: number;
  trending: {
    loading: boolean;
    error: any;
    data: ITrending[];
  };
  pairChangingCounter: number;
}

export const exchangeState: IState = {
  socketLoading: {
    tickers: true,
    level2: true,
  },
  isExchangeLoading: false,
  insufficientError: false,
  sellInsufficientError: false,
  cancelOrder: {
    loading: false,
    error: null,
    response: null,
  },
  markets: [],
  currencies: null,
  currenciesMap: null,
  trending: {
    loading: false,
    error: null,
    data: [],
  },
  marketsList: null,
  level2: {
    Asks: [[]],
    Bids: [[]],
    Seq: 0,
    PairId: "0",
    // Size: string;
  },
  tickers: null,
  selectedPair: null,
  selectedPairID: defaultSelectedPairId,
  pairChangingCounter: 0,
};
