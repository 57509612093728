import {instance} from "./axios"
import {IPhoneCreate, IPhoneGetCode} from "../types/profile.types";
import {getCodeThunk} from "../store/profile/profile.thunk";

export interface IBankReq {
    number: string,
    code: string,
    is_confirmed: boolean
}

export const phoneApi = {
    getPhoneInformation(): Promise<IBankReq> {
        return instance.post('/api/v1/phone')
    },
    createPhone(data: IPhoneCreate) {
        return instance.post('/api/v1/phone', data)
    },
    getCode(data: IPhoneGetCode) {
        return instance.post('/api/v1/phone/code', data)
    }
}
