import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Currency,
  IMarketsList,
  LinearPerpetual,
} from "../../types/exchange.types";
import { exchangeState } from "./exchange.state";
import {
  currenciesGetThunk,
  currenciesMapGetThunk,
  getMarketThunk,
  ordersGetThunk,
} from "./exchange.thunk";

export interface IOrderRes {
  change24H: string;
  coin: string;
  lastPriceEUR: string;
  lastPriceUSDT: string;
  volume24HEUR: string;
  volume24HUSDT: string;
}
export interface IMarket {
  message: string;
  status: string;
  order: IOrderRes[];
}

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState: exchangeState,
  reducers: {
    setSocketLoading: (state, action) => {
      state.socketLoading = action.payload;
    },
    setCancelOrder: (state, action) => {
      state.cancelOrder = action.payload;
    },
    setInsufficientBalance: (state, action) => {
      state.insufficientError = action.payload;
    },
    setSellInsufficientBalance: (state, action) => {
      state.sellInsufficientError = action.payload;
    },

    addLevel2Socket: (state, action) => {
      state.level2 = {
        ...action.payload,
        Asks: [...(action.payload.Asks || action.payload.asks || [])],
        Bids: [...(action.payload.Bids || action.payload.bids || [])],
      };
    },
    setLevel2Socket: (state, action) => {
      state.level2 = action.payload;
    },
    addOrderSocket: (state, action) => {
      const order = state.orders?.find((o) => o.id === action.payload.id);
      if (order) {
        state.orders = state.orders?.map((_order) => {
          if (_order.id === order.id) {
            return action.payload;
          }
          return _order;
        });
      } else {
        state.orders = [action.payload, ...(state.orders || [])];
      }
    },
    changeTicker: (state, action) => {
      state.tickers = {
        ...action.payload,
        lastMarketPrice:
          action.payload.lastMarketPrice &&
          action.payload.lastMarketPrice.length > 0
            ? action.payload.lastMarketPrice
            : state.tickers?.lastMarketPrice,
        marketVolume:
          action.payload.marketVolume && action.payload.marketVolume.length > 0
            ? action.payload.marketVolume
            : state.tickers?.marketVolume,
        high24H:
          action.payload.high24H && action.payload.high24H.length > 0
            ? action.payload.high24H
            : state.tickers?.high24H,
        low24H:
          action.payload.low24H && action.payload.low24H.length > 0
            ? action.payload.low24H
            : state.tickers?.low24H,
      };
    },
    setSelectedPair(state, action: PayloadAction<LinearPerpetual>) {
      state.selectedPair = action.payload;
    },
    setSelectedPairId(state, action: PayloadAction<number>) {
      state.selectedPairID = action.payload;
    },
    removeOrderById(state, action: PayloadAction<string>) {
      if (Array.isArray(state.orders)) {
        state.orders = state.orders.filter(
          (order) => order.id !== action.payload
        );
      }
    },
    setTrending: (state, action) => {
      state.trending = action.payload;
    },
    addPairChangingCounter(state) {
      state.pairChangingCounter = state.pairChangingCounter + 1;
    },
    setMarketList(state, action: PayloadAction<IMarketsList>) {
      state.marketsList = action.payload;
    },
  },

  extraReducers(builder) {
    //Orders
    builder.addCase(ordersGetThunk.pending, (state) => {
      state.isExchangeLoading = true;
    });
    builder.addCase(ordersGetThunk.rejected, (state) => {
      state.isExchangeLoading = false;
    });

    builder.addCase(ordersGetThunk.fulfilled, (state, action) => {
      state.orders = action.payload;
      state.isExchangeLoading = false;
    });

    //Currencies
    builder.addCase(currenciesGetThunk.pending, (state) => {
      state.isExchangeLoading = true;
    });

    builder.addCase(currenciesGetThunk.rejected, (state) => {
      state.isExchangeLoading = false;
    });

    builder.addCase(currenciesGetThunk.fulfilled, (state, action) => {
      if (action.payload) {
        const data = [...action.payload.data.result.list].sort((a, b) => {
          const tickSizeA = parseFloat(a.priceFilter.tickSize);
          const tickSizeB = parseFloat(b.priceFilter.tickSize);
          if(tickSizeA > tickSizeB) return -1;
          if(tickSizeA < tickSizeB) return 1;
          return 0;
        });
        state.currencies = {result:{list: data}};
      }
      state.isExchangeLoading = false;
    });

    builder.addCase(currenciesMapGetThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.currenciesMap = action.payload.data;
      }
    });

    //Markets getMarketThunk
    builder.addCase(getMarketThunk.pending, (state) => {
      state.isExchangeLoading = true;
    });

    builder.addCase(getMarketThunk.rejected, (state) => {
      state.isExchangeLoading = false;
    });

    builder.addCase(getMarketThunk.fulfilled, (state, action: AnyAction) => {
      state.markets = action.payload?.order;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setCancelOrder,
  addLevel2Socket,
  addOrderSocket,
  changeTicker,
  setInsufficientBalance,
  setSellInsufficientBalance,
  setSelectedPairId,
  setSelectedPair,
  removeOrderById,
  setTrending,
  setSocketLoading,
  addPairChangingCounter,
  setMarketList,
} = exchangeSlice.actions;

export const exchangeReducer = exchangeSlice.reducer;
