import { FC, ReactNode } from "react";

import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import { useModalManager } from "../../hooks/useModalManager";
import { EModalId } from "../../types/common.types";
import { Portal } from "../HOC/Portal";

interface IProps {
  children: ReactNode;
  modalId: EModalId;
  maxWidth?: string | number;
  sx?: SxProps<Theme>;
  keepMounted?: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  width: "100%",
  padding: "15px 33px 31px 33px",
};

export const AppModal: FC<IProps> = ({
  modalId,
  children,
  maxWidth,
  sx,
  keepMounted,
}) => {
  const modalManager = useModalManager();

  //Handlers
  const handleClose = () => {
    modalManager.setModalId(null);
  };

  return (
    <Portal>
      <Modal
        keepMounted={keepMounted}
        open={modalId === modalManager.modalId}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, ...sx, maxWidth: maxWidth ? maxWidth : 400, outline: "none" }}>
          <Box display={"flex"} justifyContent={"right"}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>

          {children}
        </Box>
      </Modal>
    </Portal>
  );
};
