/* eslint-disable no-unused-expressions */
import { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { 
  widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	ResolutionString,
  OnReadyCallback,
  Exchange,
  SymbolType,
  ResolveCallback,
  ErrorCallback,
  HistoryCallback,
  PeriodParams,
  IChartingLibraryWidget,
  LibrarySymbolInfo,
  SubscribeBarsCallback,
} from "charting_library";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useByBit } from "providers/ByBitProvider";
import { useOpenOrdersContext } from "providers/OpenOrderProvider";
import { exchangeApi } from "api/exchange.api";
import { bybitWSUrl } from "app.config";
import { ExchangeTradingHeader } from "./ExchangeTradingHeader";
import { INTERVALS, Interval } from "./consts";
import { removeTrailingZeros } from "helpers/removeTrailingZeros";

export interface ChartContainerProps {
	symbol: ChartingLibraryWidgetOptions['symbol'];
	interval: ChartingLibraryWidgetOptions['interval'];

	datafeedUrl: string;
	libraryPath: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId: ChartingLibraryWidgetOptions['client_id'];
	userId: ChartingLibraryWidgetOptions['user_id'];
	fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
	autosize: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
	container: ChartingLibraryWidgetOptions['container'];
}

interface Props {
  symbol: string;
  orders: any[];
  positions: any[];
  lastPrice: number;
}

export const ExchangeTradingChartContainer = () => {
  const selectedPair = useTypedSelector((state) => state.exchange.selectedPair);
  const { positions, orders } = useOpenOrdersContext();
  const { ticker } = useByBit();

  const positionsData = useMemo(() => {
    if(!positions) return []
    return positions.list
      .filter((item) => item.symbol === selectedPair?.symbol)
      .map((item) => ({
        side: item.side,
        value: Number(item.avgPrice),
        time: new Date(+item.createdTime).getTime() / 1000,
        qty: Number(item.size),
        profit: Number(item.takeProfit),
        loss: Number(item.stopLoss),
      }));
  }, [positions, selectedPair]);

  const ordersData = useMemo(() => {
    if(!orders) return []
    return orders.list
      .filter((item) => item.symbol === selectedPair?.symbol)
      .map((item) => ({
        side: item.side,
        value: Number(item.price),
        time: new Date(+item.createdTime).getTime() / 1000,
        qty: Number(item.qty),
        profit: Number(item.takeProfit),
        loss: Number(item.stopLoss),
      }));
  }, [orders, selectedPair]);

  return (
    <>
      {selectedPair &&  
        <ExchangeTradingChart 
          symbol={selectedPair.symbol}
          orders={ordersData}
          positions={positionsData}
          lastPrice={ticker?.data.lastPrice ? +ticker.data.lastPrice : 0}
        />
      }
    </>
  );
};

export const ExchangeTradingChart = ({ symbol, orders, positions, lastPrice }: Props) => {
  const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget | null>(null);
  const [interval, setInterval] = useState<Interval>('1D');
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

	const defaultProps: Omit<ChartContainerProps, 'container' | 'symbol' | 'interval'> = {
		datafeedUrl: 'https://demo_feed.tradingview.com',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	useEffect(() => {
		const widgetOptions: ChartingLibraryWidgetOptions = {
			symbol: symbol,
			datafeed: new CustomDataFeed() as any,
			interval: interval as any,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath as string,
      theme: 'dark',

			locale: 'en',
			disabled_features: ['header_widget', 'study_templates'],
			enabled_features: ['seconds_resolution'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
      custom_formatters: {
        priceFormatterFactory: (symbolInfo, minTick) => {
          return {
            format: (price, signPositive) => {
              return `${removeTrailingZeros(price.toFixed(4))}`
            }
          }
        }
      }
		};

		const tvWidgetApi = new widget(widgetOptions);
    setTvWidget(tvWidgetApi);
    
		return () => {
			tvWidgetApi.remove();
      setTvWidget(null);
		};
	}, []);

  useEffect(() => {
    if(!tvWidget) return;
    tvWidget.onChartReady(() => {
      tvWidget.activeChart().setResolution(interval as any);
    })
  }, [tvWidget, interval])

  useEffect(() => {
    if(!tvWidget) return;
    tvWidget.onChartReady(() => {
      tvWidget.activeChart().setSymbol(symbol);
    })
  }, [tvWidget, symbol])

  useEffect(() => {
    if (!tvWidget) return;
    let isExpired = false;
    const lines: any[] = [];

    try {
      tvWidget.onChartReady(() => {
        if(isExpired) return;
        orders.forEach((item: any) => {
          if (!item || !item?.value) return;
          const pnlValue = (lastPrice - item.value) * item.qty;
          const color = (item.side === 'Buy' && pnlValue >= 0  || item.side === 'Sell' && pnlValue < 0)? 'rgb(0, 255, 0)' : 'rgb(255, 0, 0)';
    
          const order = tvWidget
            .activeChart()
            .createOrderLine()
            .setPrice(item.value)
            .setBodyBackgroundColor('rgb(19, 23, 34)')
            .setBodyTextColor('rgb(255, 255, 255)')
            .setLineColor(color)
            .setBodyBorderColor(color)
            .setQuantityBackgroundColor(color)
            .setQuantityBorderColor(color)
            .setQuantityTextColor('rgb(255, 255, 255)')
            .setQuantity(String(item.qty))
            .setText(`Limit ${item.value}`);
  
          lines.push(order);
  
          if(!!item.profit) {
            const profit = tvWidget.activeChart()
            .createOrderLine()
            .setPrice(item.profit)
            .setQuantity("")
            .setText("")
            .setLineColor("red")
            
            lines.push(profit);
          }
          if(!!item.loss) {
            const loss = tvWidget.activeChart()
            .createOrderLine()
            .setPrice(item.loss)
            .setQuantity("")
            .setText("")
            .setLineColor("red")
  
            lines.push(loss);
          }
        });
      })
    }catch(err) {
      console.log(err);
    }
    
    return () => {
      isExpired = true;
      lines.forEach((item: any) => item.remove())
    }
  }, [tvWidget, lastPrice, orders]);
  
  useEffect(() => {
    if (!tvWidget) return;
    let isExpired = false;
    const lines: any[] = [];

    try {
      tvWidget.onChartReady(() => {
        if(isExpired) return;
        positions.forEach((item: any) => {
          if (!item || !item?.value || !lastPrice) return;
          const pnlValue = (lastPrice - item.value) * item.qty;
          const color = (item.side === 'Buy' && pnlValue >= 0  || item.side === 'Sell' && pnlValue < 0) ? 'rgb(0, 255, 0)' : 'rgb(255, 0, 0)';
    
          const position = tvWidget
            .activeChart()
            .createPositionLine()
            .setPrice(item.value)
            .setBodyBackgroundColor('rgb(19, 23, 34)')
            .setBodyTextColor('rgb(255, 255, 255)')
            .setLineColor(color)
            .setBodyBorderColor(color)
            .setQuantityBackgroundColor(color)
            .setQuantityBorderColor(color)
            .setQuantityTextColor('rgb(255, 255, 255)')
            .setQuantity(String(item.qty))
            .setText(`P&L ${removeTrailingZeros(pnlValue.toFixed(4))}`);
  
            lines.push(position);
  
            if(!!item.profit) {
              const profit = tvWidget.activeChart()
              .createOrderLine()
              .setPrice(item.profit)
              .setQuantity("All")
              .setText(`TP ${item.profit}`)
              .setBodyBackgroundColor('rgb(19, 23, 34)')
              .setBodyTextColor('rgb(255, 255, 255)')
              .setLineColor("red")
              .setBodyBorderColor("red")
              .setQuantityBackgroundColor("red")
              .setQuantityBorderColor("red")
              
              lines.push(profit);
            }
            if(!!item.loss) {
              const loss = tvWidget.activeChart()
              .createOrderLine()
              .setPrice(item.loss)
              .setQuantity("All")
              .setText(`SL ${item.loss}`)
              .setBodyBackgroundColor('rgb(19, 23, 34)')
              .setBodyTextColor('rgb(255, 255, 255)')
              .setLineColor("red")
              .setBodyBorderColor("red")
              .setQuantityBackgroundColor("red")
              .setQuantityBorderColor("red")
  
              lines.push(loss);
            }
        });
      })
    }catch(err) {
      console.log(err);
    }
    
    return () => {
      isExpired = true;
      lines.forEach((item: any) => item.remove());
    }
  }, [tvWidget, lastPrice, positions]);
  
	return (
    <>
      <ExchangeTradingHeader 
        interval={interval}
        onIntervalSelect={setInterval}
      />
      <div
        style={{ height: 566 }}
        ref={chartContainerRef}
      />
    </>
	);
};

class CustomDataFeed {
  onReady = (callback: OnReadyCallback) => {
    setTimeout(() => callback({
      supported_resolutions: Object.keys(INTERVALS) as ResolutionString[],
      supports_marks: true,
      supports_time: true,
      supports_timescale_marks: true,
    }));
  };
  searchSymbols = (userInput: string, exchange: Exchange, symbolType: SymbolType, onResultReadyCallback: any) => {
    onResultReadyCallback([])
  };
  resolveSymbol = async (symbolName: string, onSymbolResolvedCallback: ResolveCallback, onResolveErrorCallback: ErrorCallback) => {
    try {
      const res = await exchangeApi.getCurrencies({ symbol: symbolName });
      const item = res.data.result.list[0] as any;
      onSymbolResolvedCallback({
        name: item.symbol,
        description: item.symbol,
        type: item.contractType,
        session: '24x7',
        timezone: item.symbol,
        exchange: "crypto",
        minmov: 1,
        pricescale: 100,
        listed_exchange: "",
        format: "price",
        has_seconds: true,
        has_intraday: true,
        has_daily: true,
        has_weekly_and_monthly: true,
        has_ticks: true,
      });
    }catch(err) {
      onResolveErrorCallback("Resolve symbol error");
    }
  
  };
  getBars = async (symbolInfo: LibrarySymbolInfo, resolution: string, periodParams: PeriodParams, onHistoryCallback: HistoryCallback, onErrorCallback: ErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams;
    try {
      const res = await axios.get(
        "https://api.bybit.com/v5/market/kline",
        {
          params: {
            category: "linear",
            symbol: symbolInfo.name,
            interval: INTERVALS[resolution as Interval].value,
            start: from*1000,
            end: to*1000,
            limit: 1000
          },
        }
      );
      if(!res.data.result.list.length) {
        onHistoryCallback([], { noData: true });
      }

        const list = [...res.data.result.list].reverse();
        const history = list
        .filter((item) => Number(item[0]) >= from*1000 && Number(item[0]) < to*1000)
        .map((item) => ({
          open: parseFloat(item[1]),
          high: parseFloat(item[2]),
          low: parseFloat(item[3]),
          close: parseFloat(item[4]),
          time: parseFloat(item[0]),
          // volume: Number(item[5]),
        }));
      setTimeout(() => onHistoryCallback(history, { noData: false }), 0);
    } catch(err) {
      onErrorCallback("Kline request error");
    }
  };
  subscribeBars = (symbolInfo: LibrarySymbolInfo, resolution: string, onRealtimeCallback: SubscribeBarsCallback, subscriberUID: string, onResetCacheNeededCallback: any) => {
    try {
      const byBitSocket = new WebSocket(`${bybitWSUrl}/v5/public/linear`);
      byBitSocket.onopen = () => {
        byBitSocket.send(
          JSON.stringify({
            op: "subscribe",
            args: [`kline.${INTERVALS[resolution as Interval].value}.${symbolInfo.name}`],
          })
        );
      }
      byBitSocket.onmessage = (msg) => {
        const data = JSON.parse(msg.data);
        const item = data?.data?.[0] as any;
        onRealtimeCallback({
          high: parseFloat(item.high),
          low: parseFloat(item.low),
          open: parseFloat(item.open),
          close: parseFloat(item.close),
          time: parseFloat(item.timestamp),
        })
      }
      byBitSocket.onerror =(err) => {
        onResetCacheNeededCallback()
      }
    }catch(err) {
      console.log(err)
    }
  };
  unsubscribeBars = (subscriberUID: string) => {
  };
}
