import React, {
  FC,
  ReactNode,
  useState,
} from 'react';

import { authApi } from 'api';
import { useFormik } from 'formik';
import { useTimer } from 'hooks/useTimer';
import { useDispatch } from 'react-redux';
// import { GoTrueAuthProvider } from '@openware/opendax-web-sdk'
// import img from 'next/image'
// import { useRouter } from 'next/router'
import { useNavigate } from 'react-router-dom';
import { setEmail } from 'store/profile/profile.slice';
import { IForgotPasswordEmailReq } from 'types/profile.types';
import * as yup from 'yup';

import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { AuthLayout } from '../../../../layouts/AuthLayout/AuthLayout';

export const ForgotPasswordEnterEmail: FC = () => {
    //States
    const isProfileLoading = useTypedSelector(state => state.profile.isProfileLoading)
    const navigate = useNavigate()
    const { isDeadline} = useTimer()
    //Redux
    const dispatch = useDispatch()
    const validationSchema = yup.object({
        email: yup.string().required('Required field').email('Enter a valid email')
    })
    const {
        handleSubmit,
        handleChange,
        errors,
        touched,
    } = useFormik({
        initialValues: {email: ''},
        validationSchema: validationSchema,
        onSubmit(data) {
            authApi.forgotPassword(data as unknown as IForgotPasswordEmailReq) 
                .then(res => {
                    dispatch(setEmail(data as unknown as IForgotPasswordEmailReq))
                    navigate('/sended-verification-code-to-email')
                    // navigate('/sended-verification-code-to-email', { state: { email: "fdfdfd" } })
                })
                .catch(err => {
                    setErrorMsg("Email not found")
                })
        }
    })
    const [ErrorMsg, setErrorMsg] = useState("")
    return (
    <AuthLayout>
       <Box component={'form'} height={'100%'} onSubmit={handleSubmit}
                display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <Typography variant={'h3'} sx={{mb: 5}}>
            Forgot Your Password?
        </Typography>
        <Box width={'100%'}>
        <><TextField id={'email'}
                fullWidth
                name={'email'}
                onChange={handleChange}
                label={'Email'}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email as ReactNode}
                variant={'standard'} />
                 <p style={{color: "red"}}>{ErrorMsg}</p>
               </>
        </Box>
        <Button sx={{mt: 7, whiteSpace: 'nowrap'}} size={'large'} disabled={isProfileLoading || isDeadline}
                type={'submit'}
                // onClick={handleClick}
                variant={'outlined'}>
            Submit
        </Button>
    </Box>
    </AuthLayout>
    )
}

