import { useOpenOrders } from "hooks/useOpenOrders";
import { PropsWithChildren, createContext, useContext } from "react";

export type OpenOrdersContextValue = ReturnType<typeof useOpenOrders> & {};

const OpenOrdersContext = createContext<OpenOrdersContextValue | null>(null);

export const OpenOrdersProvider = ({ children }: PropsWithChildren) => {
  const { positions, orders, isLoading, error, handleGetOrders, handleGetPositions } = useOpenOrders();

  return (
    <OpenOrdersContext.Provider value={{ positions, orders, isLoading, error, handleGetOrders, handleGetPositions }}>
      {children}
    </OpenOrdersContext.Provider>
  );
};

export const OpenOrdersConsumer = OpenOrdersContext.Consumer;
export const useOpenOrdersContext = () => {
  const context = useContext(OpenOrdersContext);
  if (!context) throw new Error("useOpenOrdersContext should has provider");
  return context;
};
