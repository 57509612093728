import {IConfirmationGet} from "../types/confirmation.types";
import {instance} from "./axios";

export const confirmationApi = {
  confirmationGet(): Promise<IConfirmationGet> {
    return instance.get("/api/v1/confirmation");
  },

  confirmationCreate(data: FormData) {
    return instance.post("/api/v1/confirmation", data);
  },

  confirmationStatusGet(applicant_id: string): Promise<any> {
    return instance.get("/api/v1/confirmation/status", {
      params: {
        applicant_id,
      },
    });
  },
  confirmPhone({ code, number }: { code: string, number: string }): Promise<any> {
    return instance.post("/api/v1/phone", {
      code,
      number,
    });
  },
  getPhone(): Promise<any> {
    return instance.get("/api/v1/phone");
  },
};
