import React from 'react'
import './App.css'
import {MainRouting} from 'routes/MainRouting'
import './App.scss'
import {Provider} from 'react-redux'
import {store} from 'store/store'
import {AppWrapper} from "./providers/AppWrapper"
import {CustomThemeProvider} from "./providers/CustomThemeProvider"
import { SnackbarProvider } from 'notistack'

function App() {
    return (
        <SnackbarProvider>
            <Provider store={store}>
                <CustomThemeProvider>
                    <AppWrapper>
                        <MainRouting/>
                    </AppWrapper>
                </CustomThemeProvider>
            </Provider>
        </SnackbarProvider>
    )
}

export default App;
