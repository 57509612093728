import "./History.style.scss";

// import { withAuth } from '@openware/opendax-web-sdk'
// import img from 'next/image'
import { FC } from "react";

interface ICopyInput {
	title: string;
	text: string;
	onClick: () => void;
}

const CopyInput: FC<ICopyInput> = (props): JSX.Element | null => {
	const { title, text, onClick } = props;

	const handleCopy = () => {
		const textToClipboard: string = text;
		const tmpTextArea = document.createElement("textarea");
		tmpTextArea.value = textToClipboard;
		document.body.appendChild(tmpTextArea);
		tmpTextArea.select();
		document.execCommand("copy");
		document.body.removeChild(tmpTextArea);
		onClick();
	};

	return (
		<button className='copyInput' onClick={handleCopy}>
			<p className='copyInput_title'>{title}</p>
			<div className='copyInput_textBlock'>
				<img src='/images/copy-gray.svg' alt='dashboard background' width={15} height={15} />
			</div>
		</button>
	);
};

export default CopyInput;
