interface IState {
  msg: string,
  timeStamp: number,
  variant: 'success' | 'error' | 'warning'
}

export const notistackState: IState = {
  msg: '',
  variant: 'success',
  timeStamp: new Date().getTime()
}