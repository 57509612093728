import { AxiosResponse } from "axios";
import {
  ICurrency,
  IGetWalletCurrency,
  IGetByBitWalletCurrency,
  ITransferWallet,
  IVolume,
  IByBitCurrency,
  IByBitWalletBalance,
} from "../types/exchange.types";
import { instance } from "./axios";

export const walletsApi = {
  getWalletCurrency(data?: IGetWalletCurrency): Promise<ICurrency[]> {
    return instance.get("/api/v1/wallet/currency", {
      params: data,
    });
  },
  getBalance(): Promise<any> {
    return instance.get("/api/v1/balance");
  },
  postTransfer(data: ITransferWallet) {
    const timestamp = Date.now();
    return instance.post("/bybit/v1/asset/withdraw/create", {
      coin: data.currency,
      chain: data.chain,
      address: data.to_address,
      amount: String(data.amount),
      accountType: "FUND",
      timestamp,
      tag: data.tag,
      forceChain: 0,
    });
  },
  widthdrawFee(data: ITransferWallet) {
    return instance.post("/api/v1/wallet/fee", data);
  },
  getWalletBalance(): Promise<any> {
    return instance.get("/api/v1/wallet/balances");
  },
  getByBitWalletBalance({
    accountType = "UNIFIED",
    ...data
  }: IGetByBitWalletCurrency): Promise<AxiosResponse<IByBitWalletBalance>> {
    return instance.post("/bybit/v1/account/wallet-balance", {
      accountType,
      ...data,
    });
  },
  getTransactions() {
    return instance.get("/api/v1/wallet/transactions");
  },
  getRates(): Promise<{
    status: string;
    message: string;
    order: {
      baseCurrency: string;
      quoteCurrency: string;
      price: string;
    }[];
  }> {
    return instance.get("/api/v1/rates/eur/list");
  },
  getVolume(): Promise<IVolume> {
    return instance.get("/api/v1/volumes");
  },
};
