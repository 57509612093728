import "./HeaderStyles.scss";

// import Image from 'next/image'
import { CSSProperties, FC, MouseEvent, useState } from "react";

import { useAppDispatch } from "hooks/useAppDispatch";
import {Link, useNavigate} from "react-router-dom";
import { storageService } from "services/storage.service";
import { logoutThunk } from "store/profile/profile.thunk";
import { headerNav } from "utils";

import { IconButton } from "@mui/material";

import { BLogo } from "../UI/atoms/BLogo/BLogo";
import { StyledMenu, StyledMenuItem } from "./Header.styles";
import HeaderNavItem from "./HeaderNavItem";
import headerNavItem from "./HeaderNavItem";

interface IProps {
  style?: CSSProperties;
}

const onChangeMobileCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
  const isChecked = e.target.checked

  if(isChecked) document.body.style.overflow = "hidden"
  else document.body.style.overflow = "auto"
}

const onClickLinkOrClose = () => {
  const checkbox = document.getElementById("mobile-menu-toggle") as HTMLInputElement

  checkbox.checked = false
  document.body.style.overflow = "auto"
}

export const CustomHeader: FC<IProps> = ({ style }): JSX.Element => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await dispatch(logoutThunk(storageService.getRefreshToken));
    window.location.reload();
  };

  const nav = useNavigate();
  return (
    <header
      className={`customHeader ${
        document.location.href.split("/")[3] === "exchange" && "darkHeader"
      }`}
      style={style}
    >
      <div className="customHeader_grid">
        <input onChange={onChangeMobileCheckbox} id="mobile-menu-toggle" type="checkbox"/>
        <label className="mobile-menu-label" htmlFor="mobile-menu-toggle">
          <span className="mobile-menu-icon"/>
        </label>
        <aside className="mobile-menu-sidebar">
          <div className="mobile-menu-logo-close">
            <BLogo onClick={() => nav("/dashboard/overview")}/>
            <button className={"mobile-menu-close-btn"} onClick={onClickLinkOrClose}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.99987 6.2341L14.1871 0.046875L15.9549 1.81464L9.76762 8.00185L15.9549 14.189L14.1871 15.9567L7.99987 9.7696L1.8127 15.9567L0.0449219 14.189L6.23212 8.00185L0.0449219 1.81464L1.8127 0.046875L7.99987 6.2341Z"
                    fill="#F2F2FA"/>
              </svg>
            </button>
          </div>
          <ul className="mobile-menu-list">
            {
              headerNav.map(menuItem => (
                  <li key={menuItem.link}
                      className={`mobile-menu-list-item ${location.pathname === menuItem.link ? 'active' : ''}`}>
                    <Link onClick={onClickLinkOrClose} className="link" to={menuItem.link}>
                      {menuItem.title}
                    </Link>
                  </li>
              ))
            }
          </ul>
          <div className="have-question-block">
            Have a question?
            <a className="mail-link" target={"_blank"} href="mailto:support@bitocol.com">support@bitocol.com</a>
          </div>
        </aside>
        <BLogo onClick={() => nav("/dashboard/overview")}/>
        <div className="customHeader_block">
          {headerNav.map((navItem, index) => {
            return <HeaderNavItem key={index} {...navItem} index={index}/>;
          })}
        </div>
      </div>

      <div className="customHeader_block profileNsettings">
        <div className="customHeader_block__avatar">
          <IconButton
              id="basic-button"
              sx={{
                border: "1.5px solid #FFFFFF",
                borderRadius: "50%",
                width: "39px",
                height: "39px",
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
          >
            <img src={"/images/auth/profile_icon.svg"}/>
          </IconButton>
          <StyledMenu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
          >
            <StyledMenuItem onClick={handleLogout}>Logout</StyledMenuItem>
            {/* <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          </StyledMenu>
        </div>
        {/* <div
          className="customHeader_tabbing"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <img
            style={{ cursor: "pointer", marginRight: "10px" }}
            src="/images/languageIcon.svg"
            alt={`icon`}
            height={30}
            width={30}
          />
          <p
            style={{
              color: "#FFF",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "16px",
            }}
          >
            English
          </p>
        </div> */}
      </div>
    </header>
  );
};

// export default CustomHeader
