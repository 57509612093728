import { AppDrawer } from "./AppDrawer";
import { EDrawerID } from "../../types/common.types";
import { MarketPlacePairs } from "../UI/organisms/MarketPlacePairs/MarketPlacePairs";
import {useMediaQuery} from "@mui/material";

export const MarketPlaceDrawer = () => {

  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <AppDrawer
      drawerId={EDrawerID.markets}
      anchor={"left"}
      drawerProps={{
        sx: {
          "& .MuiPaper-root": {
            background: "#001030",
            height: "calc(100vh - 81px)",
            top: isMobile ? "50%" : 81,
            left: isMobile ? "50%" : 92,
            ...(isMobile && {
              transform: "translate(-50%, -50%) !important"
            })
          },
          "& .MuiBackdrop-root": {
            background: "rgba(37, 50, 79, 0.15)",
            backdropFilter: "blur(10px)",
            height: "calc(100vh - 81px)",
            top: isMobile ? 0 : 81,
            left: isMobile ? 0 : 92,
            width: "calc(100vw - 107px)",
            ...(isMobile && {
              width: "auto",
              height: "auto",
              right: 0,
              bottom: 0
            }),
          },
        },
      }}
    >
      <MarketPlacePairs />
    </AppDrawer>
  );
};
