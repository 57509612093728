import {
  FC,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { ExchangeLayout } from "layouts/ExchangeLayout/ExchangeLayout";
import { useAppDispatch } from "hooks/useAppDispatch";
import { CreateOrderModal } from "components/Modals/CreateOrderModal";
import { useTypedSelector } from "hooks/useTypedSelector";
import moment from "moment";
import { Cancel } from "@mui/icons-material";
import {
  StyledSnackbar,
  StyledWarningRoundedIcon,
  StyledWrapper,
  StyledButtonWrapper,
  StyledIconButton,
} from "pages/Exchange/components/ExchangeOrders/ExchangeOrders.styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CentrifugeContext } from "providers/CentrifugeProvider";
import { IOrder } from "types/exchange.types";
import {
  orderCancelThunk,
  allOrderCancelThunk,
} from "store/exchange/exchange.thunk";
import { ByBitOrdersHistoryBody, byBitApi } from "api";

const HEADERS = ["Contracts", "Filled/Total", "Filled Price/Order Price", "Trade Type", "Order Type", "Status", "Order No.", "Order Time"]

interface IProps { }

const OrdersPage: FC<IProps> = ({ }) => {
  const socketContext = useContext(CentrifugeContext);

  //Redux
  const dispatch = useAppDispatch();
  const { orders, currencies, selectedPair } = useTypedSelector((state) => state.exchange);

  //States
  const [value, setValue] = useState<string>("All");
  const [currentOrder, setCurrentOrder] = useState<any>(null);
  const [allOrders, setAllorders] = useState<any>(null);
  const [compOrders, setCompOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const [myOrders, setMyOrders] = useState<ByBitOrdersHistoryBody | null>(null);

  //Handlers
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose = () => setCurrentOrder(null);
  const handleCloseClearAllOrders = () => setAllorders(null);

  const handleCancelDeposit = () => {
    dispatch(orderCancelThunk(currentOrder?.ID || currentOrder?.id));
    handleClose();
  };

  const handleClearAllOrders = () => {
    const orderIds: number[] = [];
    allOrders.forEach((order: any) => orderIds.push(order.id));
    dispatch(allOrderCancelThunk());
    handleCloseClearAllOrders();
  };

  //Computed

  const pairs = useMemo(() => {
    const allPairs: Record<number, string> = {};

    if (currencies && currencies.result.list.length) {
      currencies?.result.list.forEach((currency) => {
        allPairs[
          Number(currency.symbol)
        ] = `${currency.baseCoin}/${currency.quoteCoin}`;
      });
    }

    return allPairs;
  }, [currencies]);

  const ordersComputed = useMemo(() => {
    if (!compOrders) return [];
    if (value === "Open")
      return compOrders.filter((order) => order.status === "open");
    return compOrders;
  }, [compOrders, value]);

  //Effects


  useEffect(() => {
    const  getMyOrder = async () => {
      try {
        setLoading(true)
        if(!selectedPair) throw new Error("Selected pair is null")
        const res = await byBitApi.getOrdersHistory({
          baseCoin: selectedPair.baseCoin,
          category: "linear"
        })
        setMyOrders(res)
      }catch(err) { 
        console.log(err)
      }finally {
        setLoading(false)
      }
    }
    getMyOrder()
  }, [selectedPair]);

  useEffect(() => {
    if (orders && orders.length) {
      setCompOrders(orders);
    }
  }, [orders]);

  useEffect(() => {
    if (socketContext.order && compOrders.length) {
      const changedOrder = compOrders.filter(
        (order) => String(order.id) === socketContext.order?.id
      )[0];
      const changedOrders = compOrders.filter(
        (order) => String(order.id) !== socketContext.order?.id
      );

      if (changedOrder) {
        changedOrder.filledSize = socketContext.order.filledSize;
        changedOrder.status = socketContext.order.status;

        changedOrders.push(changedOrder);
        setCompOrders(changedOrders);
      }
    }
  }, [socketContext.order]);

  return (
    <ExchangeLayout>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab value={"All"} label={"All"} />
        </Tabs>
      </Box>
      <Box mt="16px">
        {loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
          <CircularProgress/>
        </div> : !myOrders || !myOrders.list.length ?
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", color: 'white'}}>
            <span>Orders list is empty</span>
          </div> 
        : 
        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="grid" gridTemplateColumns={`repeat(${HEADERS.length}, 1fr)`} gap="5px">
            {HEADERS.map((item) => (
              <TableCell key={item}>{item}</TableCell>
            ))}
          </Box>
          <Box display="flex" flexDirection="column" gap="5px">
            {myOrders.list.map((order: any, index) => (
              <Box key={index} display="grid" gridTemplateColumns={`repeat(${HEADERS.length}, 1fr)`} gap="5px">
                <Box sx={{
                  position: "relative",
                  paddingLeft: "12px",
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "4px",
                    height: "100%",
                    background: order.side === "Buy" ? "#419E6A" : "#D83232"
                  }
                }}>
                  <Typography>{order.symbol}</Typography>
                </Box>
                <Box>
                  <Typography>
                    {order.cumExecQty}/{order.qty}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {order.avgPrice}/{order.price}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight="600" color={order.side === "Buy" ? "#419E6A" : "#D83232"}>
                    {order.side === 'Buy' ? "Open Long" : "Open Short"}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight="600">
                    {order.orderType}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight="600">
                    {order.orderStatus}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight="600">
                    {order.orderId.split("-")[0]}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight="600">
                    {moment(+order.createdTime).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      }
      </Box>
      <StyledSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={Boolean(currentOrder)}
        onClose={handleClose}
        message={
          <div>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <StyledIconButton onClick={handleClose}>
                <CloseRoundedIcon />
              </StyledIconButton>
            </Box>
            <StyledWrapper
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <StyledWarningRoundedIcon />
              <div>
                <div>
                  Cancel {currentOrder && pairs[currentOrder.pairID]} order.
                </div>
                <div>Sent you an invite to connect.</div>
              </div>
            </StyledWrapper>

            <StyledButtonWrapper
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Button onClick={handleCancelDeposit} variant="outlined">
                Confirm
              </Button>
              <Button onClick={handleClose}>Decline</Button>
            </StyledButtonWrapper>
          </div>
        }
      />
      <StyledSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={Boolean(allOrders)}
        onClose={handleClose}
        message={
          <div>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <StyledIconButton onClick={handleCloseClearAllOrders}>
                <CloseRoundedIcon />
              </StyledIconButton>
            </Box>
            <StyledWrapper
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <StyledWarningRoundedIcon />
              <div>
                <div>Cancel all orders.</div>
                <div>Sent you an invite to connect.</div>
              </div>
            </StyledWrapper>

            <StyledButtonWrapper
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Button onClick={handleClearAllOrders} variant="outlined">
                Confirm
              </Button>
              <Button onClick={handleCloseClearAllOrders}>Decline</Button>
            </StyledButtonWrapper>
          </div>
        }
      />
      <CreateOrderModal />
    </ExchangeLayout>
  );
};

export default OrdersPage;
