export interface INavItem {
    title?: string;
    icon: string;
    activeIcon: string;
    value?: string | number;
    path: string;
}

export enum EModalId {
  createOrder,
  walletQr
}

export enum EDrawerID {
    markets
}

export type TTheme = "dark" | "light";

export interface IVerification {
    email: boolean;
    kyc: boolean;
    phone: boolean;
    otp_required: boolean;
    level: number;
}

export interface IUserBase {
    user: {
        email: string;
        id: number;
        first_name: string;
        last_name: string;
        country: {
            name: string;
            code: string;
        };
    };

    verification: IVerification;
}


export interface IThunkQuery<T=any> {
  data: T | null
  loading: boolean
  error: any
}