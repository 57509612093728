import {createSlice} from '@reduxjs/toolkit'
import {dashboardState} from "./dashboard.state";
import {confirmationCreateThunk, confirmationGetThunk, confirmationStatusGetThunk} from "./dashboard.thunk";

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: dashboardState,
    reducers: {
        setConfirmation: (state, action) => {
            state.confirmation = action.payload
        }
    },
    extraReducers(builder) {

        //Confirmation Get

        builder.addCase(confirmationGetThunk.pending, (state) => {
            state.isDashboardLoading = true
        })

        builder.addCase(confirmationGetThunk.fulfilled, (state, action) => {
            state.confirmationInfo = action.payload
            state.isDashboardLoading = false
        })

        builder.addCase(confirmationGetThunk.rejected, (state) => {
            state.isDashboardLoading = true
        })

        //Confirmation Status get

        builder.addCase(confirmationStatusGetThunk.fulfilled, (state, action) => {
            state.isConfirmedUser = action.payload
        })

        //Confirmation Create
        builder.addCase(confirmationCreateThunk.pending, (state) => {
            state.isDashboardLoading = true
        })

        builder.addCase(confirmationCreateThunk.rejected, (state) => {
            state.isDashboardLoading = false
        })

        builder.addCase(confirmationCreateThunk.fulfilled, (state) => {
            state.isDashboardLoading = false
        })

    }
})

// Action creators are generated for each case reducer function
export const { setConfirmation } = dashboardSlice.actions

export const dashboardReducer = dashboardSlice.reducer
