import { configureStore } from '@reduxjs/toolkit';

import { dashboardReducer } from './dashboard/dashboard.slice';
import { exchangeReducer } from './exchange/exchange.slice';
import { newsReducer } from './news/news.slice';
import { notistackReducer } from './notistack/notistack.slice';
import { profileReducer } from './profile/profile.slice';
import { walletsReducer } from './wallets/wallets.slice';

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    dashboard: dashboardReducer,
    exchange: exchangeReducer,
    wallets: walletsReducer,
    notistack: notistackReducer,
    news: newsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
