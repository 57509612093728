import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const withdrawSchema = z.object({
  coin: z.any(),
  address: z.string().min(1, { message: "Wallet address is required field" }),
  network: z.any(),
  amount: z.number(),
  tagDeposit: z.string().optional(),
});
export type WithdrawSchema = z.infer<typeof withdrawSchema>;

export const useWithdrawForm = () => {
  const form = useForm<WithdrawSchema>({
    defaultValues: {
      address: "",
      amount: 0,
    },
    resolver: zodResolver(withdrawSchema),
  });

  return { form };
};
