// TODO: refactor this file
import { useToPng } from '@hugocxl/react-to-image'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, ButtonGroup, IconButton, Modal, TextField } from '@mui/material'
import { DownloadBoxIcon, LinkIcon } from 'components/Icons'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { useSnackbar } from 'notistack'
import {
	ReactNode,
	forwardRef,
	memo,
	useCallback,
	useMemo,
	useState,
} from 'react'
import QRCode from 'react-qr-code'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

interface PnLShareModalProps {
	positionInfo: any
	open: boolean
	onClose: () => void
}

const useCustomToPng = (selector: string) => {
	return useToPng({
		selector,
		onSuccess: data => {
			const link = document.createElement('a')
			link.download = 'share.png'
			link.href = data
			link.click()
		},
	})
}

export const PnLShareModal = memo(
	({ positionInfo, open, onClose }: PnLShareModalProps) => {
		const [activeSlide, setActiveSlide] = useState<number>(0)
		const [roiWithIdState, roiWithIdConvert] = useCustomToPng('#share-item-0')
		const [roiWithoutIdState, roiWithoutIdConvert] =
			useCustomToPng('#share-item-2')
		const [pnlWithIdState, pnlWithIdConvert] = useCustomToPng('#share-item-1')
		const [pnlWithoutIdState, pnlWithoutIdConvert] =
			useCustomToPng('#share-item-3')

		const handleDownloadImage = useCallback(() => {
			console.log(
				roiWithIdState,
				roiWithoutIdState,
				pnlWithIdState,
				pnlWithoutIdState
			)
			console.log(activeSlide)
			switch (activeSlide) {
				case 0:
					roiWithIdConvert()
					break
				case 1:
					pnlWithIdConvert()
					break
				case 2:
					roiWithoutIdConvert()
					break
				case 3:
					pnlWithoutIdConvert()
					break
				default:
					break
			}
		}, [
			activeSlide,
			roiWithIdConvert,
			roiWithoutIdConvert,
			pnlWithIdConvert,
			pnlWithoutIdConvert,
		])

		return (
			<Modal open={open} onClose={() => {}}>
				<Box
					sx={{ outline: 'none' }}
					position='absolute'
					top='50%'
					left='50%'
					width='100%'
					maxWidth='991px'
					style={{ transform: 'translate(-50%, -50%)' }}
					bgcolor='#1C1E24'
					boxShadow={24}
					pt='24px'
					px='48px'
					pb='72px'
					borderRadius='4px'
					border='none'
				>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'
					>
						<Box
							component='span'
							fontSize='18px'
							fontWeight='600'
							lineHeight='26px'
							color='white'
						>
							P&L Share
						</Box>
						<IconButton>
							<CloseIcon style={{ color: 'white' }} onClick={onClose} />
						</IconButton>
					</Box>
					<Box
						display='grid'
						gridTemplateColumns='50% 400px'
						justifyContent='space-between'
						alignItems='center'
						gap='24px'
						marginTop='56px'
					>
						<Box position='relative'>
							<PnlResponsiveCarousel
								activeSlide={activeSlide}
								setActiveSlide={setActiveSlide}
								positionInfo={positionInfo}
							/>
						</Box>
						<Box ml='50px'>
							<PnLShareForm onDownloadImage={handleDownloadImage} />
						</Box>
					</Box>
				</Box>
			</Modal>
		)
	}
)

const PnLShareForm = memo(({ onDownloadImage }: any) => {
	const [text, setText] = useState<string>(
		'Join Bitocol with me and earn a  $5000 bonus!'
	)
	const { referralCode, referralUrl } = useUserReferral()
	const { enqueueSnackbar } = useSnackbar()

	const handleChangeText = (event: any) => {
		setText(event.target.value)
	}

	const handleCopyRefferalUrl = () => {
		if (!referralUrl) return
		navigator.clipboard.writeText(referralUrl)
		enqueueSnackbar('The referral link has been copied', {
			variant: 'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
		})
	}

	const handleCopyRefferal = () => {
		if (!referralCode) return
		navigator.clipboard.writeText(referralCode)
		enqueueSnackbar('The referral code has been copied', {
			variant: 'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
		})
	}

	const handleShareTwitter = () => {
		const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
			referralUrl
		)}&text=${encodeURIComponent(text)}`
		window.open(url, '_blank')
	}

	const handleShareFacebook = () => {
		const url = `https://www.facebook.com/sharer.php?quote=${encodeURIComponent(
			text
		)}&u=${encodeURIComponent(referralUrl)}`
		window.open(url, '_blank')
	}

	return (
		<Box width='100%'>
			<Box
				width='100%'
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				bgcolor='#26282C'
				px='12px'
				py='9px'
				borderRadius='4px'
			>
				<Box
					component='span'
					fontSize='16px'
					fontWeight='600'
					lineHeight='24px'
					color='white'
					textAlign='left'
				>
					{referralCode}
				</Box>
				<ContentCopyIcon
					sx={{ color: 'white' }}
					cursor='pointer'
					fontSize='medium'
					onClick={handleCopyRefferal}
				/>
			</Box>
			<Box width='100%' marginTop='28px'>
				<TextField
					sx={{
						width: '100%',
						height: '164px',
						bgcolor: '#26282C',
						borderRadius: '4px',
						'& .MuiOutlinedInput-root': {
							'& .MuiInputBase-input': {
								fontSize: '14px !important',
								fontWeight: '400 !important',
								lineHeight: '22px !important',
							},
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none !important',
							},
						},
					}}
					multiline
					value={text}
					onChange={handleChangeText}
				/>
			</Box>
			<Box
				display='flex'
				marginTop='40px'
				gap='10px'
				flexWrap='wrap'
				justifyContent='space-around'
				alignItems='center'
			>
				<ShareButton
					img={<DownloadBoxIcon fill='white' width='20' height='20' />}
					describe='Save image'
					onClick={onDownloadImage}
				/>
				<ShareButton
					img={<LinkIcon fill='white' width='20' height='20' />}
					describe='Copy link'
					onClick={handleCopyRefferalUrl}
				/>
				<ShareButton
					img={
						<img src='/images/twitter_icon.svg' width='41px' height='41px' />
					}
					describe='Twitter'
					onClick={handleShareTwitter}
				/>
				<ShareButton
					img={
						<img src='/images/facebook_icon.svg' width='41px' height='41px' />
					}
					describe='Facebook'
					onClick={handleShareFacebook}
				/>
			</Box>
		</Box>
	)
})

const ShareButton = ({
	img,
	describe,
	onClick,
}: {
	img: ReactNode
	describe: string
	onClick: () => void
}) => {
	return (
		<Box
			width='62px'
			height='68px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			gap='8px'
		>
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				sx={{
					width: '40px',
					height: '40px',
					bgcolor: '#424449',
					borderRadius: '50%',
					position: 'relative',
					cursor: 'pointer',
					overflow: 'hidden',
					':hover': {
						':after': {
							content: '""',
							display: 'block',
							position: 'absolute',
							width: '100%',
							height: '100%',
							top: '0',
							left: '0',
							background: 'rgba(66, 68, 73, 0.8)',
							borderRadius: '50%',
						},
					},
				}}
				onClick={onClick}
			>
				{img}
			</Box>
			<Box
				component='span'
				fontSize='12px'
				fontWeight='400'
				lineHeight='18px'
				color='#80848E'
				textOverflow='ellipsis'
				whiteSpace='nowrap'
			>
				{describe}
			</Box>
		</Box>
	)
}

const PnlResponsiveCarousel = memo(
	({
		activeSlide,
		setActiveSlide,
		positionInfo: { roi, pnl, isRoiIncrease, isPnlIncrease, ...positionInfo },
	}: any) => {
		const [imageType, setImageType] = useState<'horizontal' | 'vertical'>(
			'horizontal'
		)

		return (
			<>
				<Slider
					dots
					infinite
					adaptiveHeight
					speed={500}
					appendDots={dots => (
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							bottom='-55px'
						>
							{dots}
						</Box>
					)}
					customPaging={i => {
						const isActive = activeSlide === i

						return (
							<Box
								sx={[
									{
										width: '8px',
										height: '8px',
										borderRadius: '50%',
										bgcolor: '#424449',
									},
									isActive && { bgcolor: '#FFFFFF' },
								]}
							/>
						)
					}}
					prevArrow={
						<Arrow
							icon={<KeyboardArrowLeftIcon sx={{ color: 'white' }} />}
							left={-37}
						/>
					}
					nextArrow={
						<Arrow
							icon={<KeyboardArrowRightIcon sx={{ color: 'white' }} />}
							right={-37}
						/>
					}
					beforeChange={(_, next) => {
						setActiveSlide(next)
					}}
				>
					<Box
						sx={{
							display: 'flex !important',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						height='424px'
					>
						<ROIItem
							index={0}
							imageType={imageType}
							hasAvatar={true}
							hasBackground={true}
							image='/images/roi'
							positionInfo={{
								...positionInfo,
								title: 'ROI',
								value: roi,
								isIncrease: isRoiIncrease,
							}}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex !important',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						height='424px'
					>
						<ROIItem
							index={1}
							imageType={imageType}
							hasAvatar={true}
							hasBackground={false}
							image='/images/pnl'
							positionInfo={{
								...positionInfo,
								title: 'Realised P&L(USDT)',
								value: pnl,
								isIncrease: isPnlIncrease,
							}}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex !important',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						height='424px'
					>
						<ROIItem
							index={2}
							imageType={imageType}
							hasAvatar={false}
							hasBackground={true}
							image='/images/roi'
							positionInfo={{
								...positionInfo,
								title: 'ROI',
								value: roi,
								isIncrease: isRoiIncrease,
							}}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex !important',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						height='424px'
					>
						<ROIItem
							index={3}
							imageType={imageType}
							hasAvatar={false}
							hasBackground={false}
							image='/images/pnl'
							positionInfo={{
								...positionInfo,
								title: 'Realised P&L(USDT)',
								value: pnl,
								isIncrease: isPnlIncrease,
							}}
						/>
					</Box>
				</Slider>
				<Box sx={{ position: 'absolute', right: -48, bottom: -12 }}>
					<ButtonGroup>
						<IconButton
							sx={[
								{
									width: 27,
									height: 19,
									borderRadius: 0,
									border: '1px solid #424449',
								},
								imageType === 'horizontal' && { bgcolor: '#424449' },
							]}
							onClick={() => setImageType('horizontal')}
						>
							<Box
								sx={{ width: '13px', height: '8px', border: '1px solid white' }}
							/>
						</IconButton>
						<IconButton
							sx={[
								{
									width: 27,
									height: 19,
									borderRadius: 0,
									border: '1px solid #424449',
								},
								imageType === 'vertical' && { bgcolor: '#424449' },
							]}
							onClick={() => setImageType('vertical')}
						>
							<Box
								sx={{ width: '8px', height: '13px', border: '1px solid white' }}
							/>
						</IconButton>
					</ButtonGroup>
				</Box>
			</>
		)
	}
)

const Arrow = ({ icon, className, style, onClick, left, right }: any) => {
	return (
		<Box
			className={className}
			display='flex !important'
			justifyContent='center'
			alignItems='center'
			sx={[
				{
					width: '24px',
					height: '24px',
					borderRadius: '50%',
					bgcolor: '#424449',
					cursor: 'pointer',
					left,
					right,
					':hover': {
						bgcolor: 'rgba(66, 68, 73, 0.5)',
					},
					':before': {
						content: "''",
					},
				},
				style,
			]}
			onClick={onClick}
		>
			{icon}
		</Box>
	)
}

const ROIItem = memo(
	forwardRef(
		(
			{ index, imageType, hasAvatar, hasBackground, image, positionInfo }: any,
			ref: any
		) => {
			const isHorizontal = imageType === 'horizontal'
			const isIncrease = positionInfo.isIncrease
			// const isIncrease = parseFloat(positionInfo.value) >= 0
			// console.log(positionInfo.value)

			const containerStyles = useMemo(
				() => ({
					width: isHorizontal ? '424px' : '313px',
					height: isHorizontal ? '311px' : '370px',
					position: 'relative',
					marginX: 'auto',
					bgcolor: '#202020',
					borderRadius: '16px',
					overflow: 'hidden',
				}),
				[isHorizontal]
			)

			return (
				<div id={`share-item-${index}`} ref={ref}>
					<Box sx={containerStyles}>
						<ROIHeader
							isIncrease={isIncrease}
							isHorizontal={isHorizontal}
							hasAvatar={hasAvatar}
							hasBackground={hasBackground && isIncrease}
							positionInfo={positionInfo}
						/>
						{isHorizontal ? (
							<BackgroundImage
								height='244px'
								image={
									image + (isIncrease ? '_increase_h.png' : '_decrease_h.png')
								}
								size='auto'
							/>
						) : (
							<BackgroundImage
								height='303px'
								image={
									image + (isIncrease ? '_increase_v.png' : '_decrease_v.png')
								}
								size='contain'
							/>
						)}
						<ROIFooter />
					</Box>
				</div>
			)
		}
	)
)

const ROIHeader = ({
	isIncrease,
	isHorizontal,
	hasAvatar = true,
	hasBackground = true,
	positionInfo,
}: any) => {
	const upperBoxStyles = useMemo(
		() => ({
			borderTopLeftRadius: '16px',
			display: 'flex',
			flexDirection: 'column',
			height: isHorizontal ? '244px' : '303px',
			px: '20px',
			py: '18px',
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 2,
			bgcolor: hasBackground ? '#202020' : '',
		}),
		[isHorizontal]
	)

	return (
		<Box sx={upperBoxStyles}>
			<Box
				component='img'
				src='/images/pnl_share_logo.png'
				width='100px'
				height='22px'
				marginBottom='18px'
			/>
			{hasAvatar && (
				<Box>
					<ROIItemAvatar text='YB0fZjOC3Zs6IG44' />
				</Box>
			)}
			<PositionInfo isIncrease={isIncrease} {...positionInfo} />
		</Box>
	)
}

const PositionInfo = ({
	fromType,
	symbol,
	title,
	entryPrice,
	filledPrice,
	side,
	leverage,
	value,
	isIncrease,
}: any) => {
	return (
		<>
			<Box marginTop='7px' display='flex' alignItems='start' gap='12px'>
				<Box fontSize='16px' fontWeight='600' lineHeight='24px' color='white'>
					{symbol}
				</Box>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					px='6px'
					py='2px'
					bgcolor={side === 'Buy' ? '#419E6A' : '#D83232'}
					color={side === 'Buy' ? '#FFFFFF' : '#FFFFFF'}
					fontSize='10px'
					fontWeight='400'
					lineHeight='15px'
					borderRadius='4px'
				>
					{side === 'Buy' ? 'Long' : 'Short'} {leverage.toFixed(2)}x
				</Box>
			</Box>
			<Box marginTop='8px'>
				<Box
					component='span'
					fontSize='12px'
					fontWeight='400'
					lineHeight='18px'
					color='#80848E'
				>
					{title}
				</Box>
			</Box>
			<Box marginTop='2px'>
				<Box
					component='span'
					fontSize='28px'
					fontWeight='600'
					lineHeight='42px'
					color={isIncrease ? '#0CAF60' : '#D83232'}
				>
					{isIncrease ? '+' : '-'}
					{value.replace(
						/-?(\d+\.?\d*)(.*)/,
						(match: boolean, number: string, text: string) => {
							return `${Math.abs(parseFloat(number))}${text}`
						}
					)}
				</Box>
			</Box>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				gap='20px'
				marginTop='12px'
			>
				<Box display='flex' flexDirection='column'>
					<Box
						component='span'
						fontSize='12px'
						fontWeight='400'
						lineHeight='18px'
						color='#80848E'
					>
						Entry price
					</Box>
					<Box
						component='span'
						fontSize='12px'
						fontWeight='400'
						lineHeight='18px'
						color='#FFFFFF'
					>
						{entryPrice}
					</Box>
				</Box>
				<Box display='flex' flexDirection='column'>
					<Box
						component='span'
						fontSize='12px'
						fontWeight='400'
						lineHeight='18px'
						color='#80848E'
					>
						{fromType === 'position' ? 'Market price' : 'Filled price'}
					</Box>
					<Box
						component='span'
						fontSize='12px'
						fontWeight='400'
						lineHeight='18px'
						color='#FFFFFF'
					>
						{filledPrice}
					</Box>
				</Box>
			</Box>
		</>
	)
}

const BackgroundImage = ({ height, size, image }: any) => {
	return (
		<Box
			width='100%'
			height={height}
			sx={{
				background: `url('${image}')`,
				backgroundSize: size,
				backgroundPositionX: 'right',
				backgroundPositionY: 'top',
				backgroundRepeat: 'no-repeat',
				borderTopRightRadius: '16px',
			}}
			position='absolute'
			top='0'
			right='0'
			zIndex='1'
		/>
	)
}

const ROIFooter = () => {
	const { referralCode, referralUrl } = useUserReferral()

	return (
		<Box
			sx={{
				bgcolor: '#E8EBF2',
				borderBottomLeftRadius: 'inherit',
				borderBottomRightRadius: 'inherit',
			}}
			width='100%'
			height='67px'
			position='absolute'
			bottom='0'
			left='0'
		>
			<Box
				height='100%'
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				alignItems='center'
				px='16px'
				py='10px'
			>
				<Box display='flex' flexDirection='column' gap='4px'>
					<Box
						component='span'
						fontSize='10px'
						fontWeight='400'
						lineHeight='15px'
						color='#1D1D1D'
					>
						Join and claim over $5000 in bonuses!
					</Box>
					<Box
						component='span'
						fontSize='14px'
						fontWeight='600'
						lineHeight='21px'
						color='#1D1D1D'
					>
						Referral Code: {referralCode}
					</Box>
				</Box>
				<Box width='48px' height='48px'>
					<QRCode size={48} value={referralUrl} />
				</Box>
			</Box>
		</Box>
	)
}

const ROIItemAvatar = ({ text }: any) => {
	return (
		<Box display='flex' alignItems='center' justifyContent='start' gap='6px'>
			<Box component='img' src='/images/pnl_share_avatar.png' />
			<Box
				component='span'
				fontSize='10px'
				lineHeight='15px'
				fontWeight='500'
				color='white'
			>
				{text}
			</Box>
		</Box>
	)
}

const useUserReferral = () => {
	const { data } = useTypedSelector(state => state.profile.referral)
	// TODO: remove this mock referral code
	const referralCode = data?.code || 'APKVWN'
	const referralUrl = `https://my.bitocol.com/signup?code=${referralCode}`
	return { referralCode, referralUrl }
}
